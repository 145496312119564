import {
  api_react_v1_autocomplete_newsletters_path,
  api_react_v1_newsletter_path,
  api_react_v1_newsletters_path,
} from '../../routes';
import { AutocompleteRecord, CreateRecordResponse } from '../interfaces';
import request from './request';
import { getRecord, getRecords } from './universal';

export interface Newsletter {
  description: string;
  id: number;
  name: string;
  paid: boolean;
  thirdParty: boolean;
  tiBundled: boolean;
}

export interface NewsletterFormValues extends Omit<NewsletterParams, 'authorId'> {
  author: { label: string; value: number | string };
}

export interface NewsletterParams {
  accessLevel: number;
  active: boolean;
  afterSignupUrl: string;
  authorId: number;
  autoSubscribe: boolean;
  autoSubscribeLead: boolean;
  cardImagePath: string;
  cardImageUrl: string;
  choosePlanText: string;
  customerioSegmentId: number;
  description: string;
  emailStyle: string;
  frequency: string;
  fromEmail?: string;
  fromName?: string;
  heroImagePhonePath: string;
  heroImagePhoneUrl: string;
  landingPageHeading: string;
  landingPageDescription: string;
  lang: string;
  logoImageUrl: string;
  name: string;
  order: number;
  paid: boolean;
  previewDays: number;
  readLatestPath: string;
  replyTo: string;
  rtsuPreviewDays: number;
  stripeAnnualPriceId: number;
  stripeApiKeyName: string;
  stripeMonthlyPriceId: number;
  stripePublishableKeyName: string;
  theme: string;
  thirdParty: boolean;
  tiBundled: boolean;
}

export const getNewsletters = async (filter = {}): Promise<Newsletter[]> => {
  const resp = await getRecords({
    model: 'Newsletter',
    attributes: ['description', 'id', 'name', 'paid', 'tiBundled', 'thirdParty'],
    identifiers: filter,
  });
  return resp.result;
};

export const getNewsletter = async (newsletterId: number): Promise<NewsletterFormValues> => {
  const resp = await getRecord({
    id: newsletterId,
    model: 'Newsletter',
    attributes: [
      'afterSignupUrl',
      'accessLevel',
      'active',
      'autoSubscribe',
      'autoSubscribeLead',
      'cardImagePath',
      'cardImageUrl',
      'choosePlanText',
      'customerioSegmentId',
      'description',
      'emailStyle',
      'frequency',
      'fromEmail',
      'fromName',
      'heroImagePhonePath',
      'lang',
      'landingPageHeading',
      'landingPageDescription',
      'logoImageUrl',
      'name',
      'order',
      'paid',
      'previewDays',
      'replyTo',
      'readLatestPath',
      'rtsuPreviewDays',
      'stripeMonthlyPriceId',
      'stripeAnnualPriceId',
      'stripeApiKeyName',
      'stripePublishableKeyName',
      'theme',
      'thirdParty',
      'tiBundled',
    ],
    helpers: ['heroImagePhoneUrl'],
    relations: { author: { attributes: ['id', 'name'] } },
  });
  const { author } = resp.result;

  return { ...resp.result, author: { label: author.name, value: author.id } };
};

interface AutocompleteParams {
  fragment: string;
}

export async function autocompleteNewsletters(
  values: AutocompleteParams
): Promise<AutocompleteRecord> {
  const resp = await request('get', api_react_v1_autocomplete_newsletters_path(values));
  return resp.newsletters;
}

export async function createNewsletter(params: NewsletterParams): Promise<CreateRecordResponse> {
  const resp = await request('post', api_react_v1_newsletters_path(), { newsletter: params });
  return resp;
}

export async function updateNewsletter(
  newsletterId: number,
  params: NewsletterParams
): Promise<CreateRecordResponse> {
  const resp = await request('patch', api_react_v1_newsletter_path(newsletterId), {
    newsletter: params,
  });
  return resp;
}
