import { getIn, useField, useFormikContext } from 'formik';
import moment from 'moment';
import React from 'react';
import { Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import './DateTimeField.scss';

const DateTimeField = (props) => {
  const {
    dateFormat,
    hideClearButton,
    label,
    maxDaysOut,
    name,
    placeholder,
    showTimeSelect,
    timeIntervals,
    minTime,
    helperText,
  } = props;
  const [field, meta, helpers] = useField(name);
  const { errors } = useFormikContext();
  const apiError = getIn(errors, name);

  const clearDateTime = () => {
    helpers.setValue('');
  };

  return (
    <div
      className={`DateTimeField form-group ${
        helperText || (meta.touched && meta.error) ? 'align-center' : ''
      }`}
    >
      <div className="datetime-input-wrapper">
        {label && (
          <label className="input-label" htmlFor={name}>
            {label}
          </label>
        )}
        <DatePicker
          {...field}
          id={name}
          minTime={
            minTime || field.value < moment().endOf('day').toDate() ? moment().toDate() : null
          }
          maxTime={
            field.value < moment().endOf('day').toDate() ? moment().endOf('day').toDate() : null
          }
          minDate={moment().toDate()}
          maxDate={moment()
            .add(maxDaysOut || 30, 'd')
            .toDate()}
          timeIntervals={timeIntervals || 15}
          selected={(field.value && new Date(field.value)) || null}
          dateFormat={dateFormat || 'MMMM d, yyyy h:mm aa'}
          wrapperClassName={`${meta.touched && meta.error ? 'is-invalid' : ''}`}
          className={`form-control DatePicker ${meta.touched && meta.error ? 'is-invalid' : ''}`}
          showTimeSelect={showTimeSelect || true}
          autoComplete="off"
          placeholderText={placeholder}
          onChange={(date_time) => {
            helpers.setValue(date_time);
          }}
          {...props}
        />
        {helperText && <div className="text-secondary pt-1">{helperText}</div>}
        {meta.touched && meta.error && (
          <div className="invalid-feedback">{meta.error || apiError}</div>
        )}
      </div>
      {!hideClearButton && (
        <Button
          className={`clear-datetime-button button --white${field.value ? '' : ' disabled'}`}
          onClick={clearDateTime}
          disabled={!field.value}
        >
          Clear
        </Button>
      )}
    </div>
  );
};

export default DateTimeField;
