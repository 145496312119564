import {
  api_react_v1_send_test_advertisement_path,
  api_react_v1_update_advertisement_path,
} from '../../routes';
import { AutocompleteOption, AutocompleteRecord } from '../interfaces';
import { AdSchedule } from './ad-schedules';
import { AdministratorList } from './administrator-lists';
import request from './request';
import { getRecord, getRecords } from './universal';

const MAX_ADVERTISEMENTS_PER_PAGE = 25;

interface ScheduleGroup {
  endDate: string;
  sections: AdSchedule[];
  startDate: string;
}

export interface Advertisement {
  administratorList: Pick<AdministratorList, 'id' | 'listName'>;
  adSchedules: AdSchedule[];
  articlePreviewUrl: string;
  availableScheduleSections: { email: string[]; onsite: string[] };
  clickthroughUrl: string;
  entities: { id: number; formattedName: string }[];
  externalImageUrl?: string;
  header: string;
  id: number;
  adImageImgixUrl: string;
  schedulesListFormat: string[];
  scheduleGroups: ScheduleGroup[];
  shortHeader?: string;
  sponsorLogoImgixUrl?: string;
  sponsorName: string;
  sponsorNameMessageHead?: string;
  sponsorNameMessageBody?: string;
  text: string;
  trackingPixelUrl?: string;
}

export interface AdvertisementFormValues
  extends Omit<
    Advertisement,
    | 'administratorList'
    | 'adSchedules'
    | 'articlePreviewUrl'
    | 'availableScheduleSections'
    | 'entities'
    | 'id'
    | 'adImageImgixUrl'
    | 'scheduleGroups'
    | 'schedulesListFormat'
    | 'sponsorName'
  > {
  entities: AutocompleteRecord[];
  excludeList: AutocompleteRecord;
  id?: number;
  image: string;
  sponsorLogo?: string;
  sponsorName: AutocompleteOption;
}

export interface AdvertisementSanitizedValues
  extends Omit<AdvertisementFormValues, 'entities' | 'excludeList' | 'sponsorName'> {
  entities: (number | string)[];
  excludedListId?: number | string;
  sponsorName: string;
}

export interface PaginatedAdvertisements {
  result: Advertisement[];
  nextPage: number;
}

export const getAdvertisement = async (
  advertisementId: number | string
): Promise<Advertisement> => {
  const resp = await getRecord({
    model: 'Advertisement',
    id: advertisementId,
    attributes: [
      'clickthroughUrl',
      'header',
      'shortHeader',
      'sponsorName',
      'sponsorNameMessageBody',
      'sponsorNameMessageHead',
      'text',
      'externalImageUrl',
      'trackingPixelUrl',
    ],
    relations: {
      adSchedules: {
        attributes: ['endDate', 'id', 'section', 'startDate'],
        order: { startDate: 'asc', endDate: 'asc' },
      },
      administratorList: { attributes: ['id', 'listName'] },
      entities: { attributes: ['id'], helpers: ['formattedName'] },
    },
    helpers: ['adImageImgixUrl', 'articlePreviewUrl', 'scheduleGroups', 'sponsorLogoImgixUrl'],
    classHelpers: ['availableScheduleSections'],
  });
  return resp.result;
};

export const getPaginatedAdvertisements = async (page: number): Promise<PaginatedAdvertisements> =>
  await getRecords({
    model: 'Advertisement',
    attributes: ['id', 'header', 'sponsorName', 'externalImageUrl'],
    relations: { adSchedules: { attributes: ['id'] } },
    helpers: ['adImageImgixUrl', 'schedulesListFormat', 'sponsorLogoImgixUrl'],
    order: { createdAt: 'desc', updatedAt: 'desc' },
    page,
    maxPerPage: MAX_ADVERTISEMENTS_PER_PAGE,
  });

export async function getAvailableSponsorNames(): Promise<string[]> {
  const resp = await getRecords({ model: 'Advertisement', attributes: ['sponsorName'] });
  const uniqueSponsorNames = [
    ...Array.from(new Set<string>(resp.result.map((ad: Advertisement) => ad.sponsorName))),
  ];
  return uniqueSponsorNames;
}

// TODO: Add type definitions for this method (difficult with FormData...) -------------
export async function updateAdvertisement(values) {
  const resp = await request('put', api_react_v1_update_advertisement_path(), values, true);
  return resp;
}

export interface SendTestAdvertisementParams {
  advertisementId: number;
  recipients: string[];
}

export async function sendTestAdvertisement(values: SendTestAdvertisementParams) {
  const resp = await request('post', api_react_v1_send_test_advertisement_path(values));
  return resp;
}
