import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

import { useProject } from '@hooks';
import { READER_URL } from '@src/env';
import { autocompleteArticles } from '@utils/backend-api/articles';
import { AutocompleteOption } from '@utils/interfaces';

import {
  ACCESS_LEVEL_OPTIONS,
  FormActions,
  ProjectFormValues,
  ProjectMediaValues,
  THEME_OPTIONS,
  updateProject,
} from '../../../utils/backend-api/projects';
import {
  AutocompleteField,
  DateTimeField,
  DropdownField,
  ImageField,
  MultiValueAutocompleteField,
  TextAreaField,
  TextField,
} from '../../FormFields';
import ProjectNavForm from './Components/ProjectNavForm';
import TitleCalloutPreview from './Components/TitleCalloutPreview';
import TopSectionMediaForm from './Components/TopSectionMediaForm';
import schema from './schema';

export const INITIAL_STATE = {
  accessLevel: {
    label: 'All',
    value: 0,
  },
  action: FormActions.Save,
  article: {
    label: '',
    value: '',
  },
  byline: '',
  csvColumns: '',
  description: '',
  desktopImageUrl: '',
  googleSheetId: '',
  jsonLdData: {
    alternateName: [],
    description: '',
    email: '',
    keywords: [],
    name: '',
    spatialCoverage: '',
    temporalCoverage: '',
  },
  leadType: '',
  metaDescription: '',
  metaImageUrl: '',
  metaTitle: '',
  mobileImageUrl: '',
  notes: '',
  navName: '',
  navOrder: 0,
  navSection: {
    label: 'Data tools',
    value: 'data_tools',
  },
  navActive: false,
  navActivePublish: false,
  navItems: [],
  partnerUnlockCode: '',
  projectMedia: [
    {
      id: '',
      elementId: '',
      height: 100,
      layout: {
        label: 'Standard',
        value: 'standard',
      },
      src: '',
      title: '',
      mediaType: {
        label: 'iframe',
        value: 'iframe',
      },
      order: 0,
    },
  ],
  publishedAt: '',
  slug: '',
  theme: {
    label: 'Default',
    value: '',
  },
  title: '',
  titleCallout: '',
};

interface ProjectFormProps {
  formValues?: ProjectFormValues;
  published?: boolean;
}

const ProjectForm = ({ formValues = INITIAL_STATE, published = false }: ProjectFormProps) => {
  const { id: projectId } = useParams();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { project, refetch: refetchProject } = useProject(projectId);

  const [projectMediaValues, setProjectMediaValues] = useState<ProjectMediaValues[]>(
    formValues.projectMedia ?? []
  );
  const [mediaExpanded, setMediaExpanded] = useState<boolean>(false);
  const MEDIA_LIMIT = 1;

  const expandedFormRef = useRef<HTMLDivElement>(null);
  const mediaSectionFormRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const publishAction = published ? FormActions.Unpublish : FormActions.PublishNow;

  const handleSubmit = async (values: ProjectFormValues) => {
    const {
      accessLevel,
      action: publishAction,
      article,
      jsonLdData,
      projectMedia,
      theme,
      navName: _navName,
      navSection: _navSection,
      navActive: _navActive,
      navItems,
      ...restVals
    } = values;
    const { alternateName, keywords, ...restJsonLdData } = jsonLdData;
    const sanitizedProjectMediaValues = projectMedia.map((media, index) => {
      const { mediaType, elementId, ...restMedia } = media;
      return {
        ...restMedia,
        layout: media.layout.value as string,
        media_type: mediaType.value as string,
        order: index,
        element_id: elementId,
      };
    });
    const sanitizedNavItems =
      navItems?.map((item) => ({
        project_id: item.projectId,
        ...item,
      })) ?? [];
    const sanitizedParams = {
      ...restVals,
      accessLevel: accessLevel.value || 0,
      articleId: article.value || '',
      jsonLdData: JSON.stringify({
        ...restJsonLdData,
        keywords: keywords.map((option: AutocompleteOption) => option.value),
        alternateName: alternateName.map((option: AutocompleteOption) => option.value),
      }),
      publishAction,
      theme: theme.value || '',
      projectMedia: JSON.stringify(sanitizedProjectMediaValues),
      navItems: JSON.stringify(sanitizedNavItems),
    };
    // create FormData object so we can send request with content-type form-data for image uploading
    const formData = new FormData();
    for (const key in sanitizedParams) {
      formData.append(key, sanitizedParams[key]);
    }
    try {
      const resp = await updateProject(formData);
      if (resp.message === 'ok') {
        if (resp.redirect_url) {
          toast.success('Project created!');
          navigate(resp.redirect_url);
        } else {
          toast.success('Project updated!');
          refetchProject();
        }
      }
    } catch (error) {
      toast.error(error || 'Sorry, there was a problem');
    }
  };

  useEffect(() => {
    const { current } = mediaSectionFormRef;
    if (current !== null) {
      current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [mediaExpanded]);

  useEffect(() => {
    setProjectMediaValues(formValues.projectMedia);
  }, [formValues]);

  const createEmptyProjectMediaElement = (formik) => {
    const newMediaElement = {
      id: '',
      elementId: '',
      height: 100,
      layout: {
        label: 'Standard',
        value: 'standard',
      },
      src: '',
      title: '',
      mediaType: {
        label: 'Iframe',
        value: 'iframe',
      },
      order: projectMediaValues.length,
    };
    const updatedProjectMedia = [...projectMediaValues, newMediaElement];
    setProjectMediaValues([...projectMediaValues, newMediaElement]);
    formik.setFieldValue('projectMedia', updatedProjectMedia);
  };

  const onDeleteProjectMedia = (index: number, formik) => {
    const updatedProjectMedia = formik.values.projectMedia.filter((_, i) => i !== index);
    formik.setFieldValue('projectMedia', updatedProjectMedia);
    setProjectMediaValues(updatedProjectMedia);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {(formik) => (
        <Form className="ProjectForm" noValidate onSubmit={formik.handleSubmit}>
          <Row>
            <Col>
              <TextField label="Title*" name="title" />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                hint="Defaults to last word in title"
                label="Title callout*"
                name="titleCallout"
              />
            </Col>
            <Col>
              <p>Preview</p>
              <TitleCalloutPreview
                title={formik.values.title}
                callout={formik.values.titleCallout}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextAreaField hint="Accepts HTML" label="Description*" rows={4} name="description" />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField label="Slug*" name="slug" />
            </Col>
          </Row>
          <Row>
            <Col>
              <ImageField label="Desktop image*" name="desktopImageUrl" height="auto" width={320} />
            </Col>
          </Row>
          <Row>
            <Col>
              <ImageField
                height="auto"
                hint="Defaults to desktop image if not provided"
                label="Mobile image"
                name="mobileImageUrl"
                width={320}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField label="Byline" name="byline" />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextAreaField label="Notes" name="notes" rows={2} hint="Appears below datatable" />
            </Col>
          </Row>
          <Row>
            <Col>
              <DropdownField
                name="accessLevel"
                label="Access level"
                options={ACCESS_LEVEL_OPTIONS}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField name="partnerUnlockCode" label="Partner unlock code" />
            </Col>
          </Row>
          <Row>
            <Col>
              <AutocompleteField
                fetchMethod={(fragment: string) => autocompleteArticles(fragment)}
                hint="Search for articles by title"
                isCreatable={false}
                label="Associated article"
                name="article"
                valueIsInteger
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField label="Google Sheet ID" name="googleSheetId" />
            </Col>
          </Row>
          <Row>
            <Col>
              <DropdownField label="Theme" name="theme" options={THEME_OPTIONS} />
            </Col>
          </Row>
          <hr className="divider" />
          <Row className="mb-3 text-center">
            <h5
              id="media-expand-btn"
              className="expand-btn"
              role="button"
              onClick={() => setMediaExpanded(!mediaExpanded)}
            >
              Media for top section {!mediaExpanded ? '\u25B2' : '\u25BC'}
            </h5>
          </Row>
          {mediaExpanded && projectMediaValues && (
            <>
              {projectMediaValues.map((media, i) => (
                <TopSectionMediaForm
                  key={i}
                  index={i}
                  formik={formik}
                  mediaSectionFormRef={mediaSectionFormRef}
                  onDeleteMedia={(index) => onDeleteProjectMedia(index, formik)}
                />
              ))}
              {projectMediaValues.length < MEDIA_LIMIT && (
                <Row>
                  <Col>
                    <Button
                      id="add-media-btn"
                      onClick={() => {
                        createEmptyProjectMediaElement(formik);
                      }}
                    >
                      Add Media
                    </Button>
                  </Col>
                </Row>
              )}
            </>
          )}
          <hr className="divider" />
          <Row className="mb-3 text-center">
            <h5
              id="associated-data-expand-btn"
              className="expand-btn"
              role="button"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              Associated Data {!isExpanded ? '\u25B2' : '\u25BC'}
            </h5>
          </Row>
          {isExpanded && (
            <>
              {projectId ? <ProjectNavForm /> : <p>*Save project to modify nav items</p>}
              <Row ref={expandedFormRef}>
                <Col>
                  <TextField
                    hint="Defaults to main title if not provided"
                    label="Meta title"
                    name="metaTitle"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextAreaField
                    hint="Defaults to main description if not provided"
                    label="Meta description"
                    rows={4}
                    name="metaDescription"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <ImageField
                    height="auto"
                    hint="Image should be 1.91:1 aspect ratio. Defaults to desktop image if not provided"
                    label="Meta Image"
                    name="metaImageUrl"
                    width={320}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField label="Lead Type" name="leadType" />
                </Col>
              </Row>
              <hr className="divider mb-3" />
              <Row>
                <Col>
                  <TextField
                    hint="Defaults to project title"
                    label="Dataset name"
                    name="jsonLdData.name"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <MultiValueAutocompleteField
                    label="Dataset alternate names"
                    name="jsonLdData.alternateName"
                    placeholder="Add an alternate name..."
                    isCreatable
                    createMethod={() => undefined}
                    formatCreateLabel={(value: string) => `Add "${value}"`}
                    showPills
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <MultiValueAutocompleteField
                    label="Dataset keywords"
                    name="jsonLdData.keywords"
                    placeholder="Add a keyword..."
                    isCreatable
                    createMethod={() => undefined}
                    formatCreateLabel={(value: string) => `Add "${value}"`}
                    showPills
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextAreaField
                    hint="Defaults to project description"
                    label="Dataset Description"
                    rows={4}
                    name="jsonLdData.description"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    hint="Defaults to The Information support email"
                    label="Dataset email"
                    name="jsonLdData.email"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    hint="Defaults to 'Worldwide'"
                    label="Dataset spatial coverage"
                    name="jsonLdData.spatialCoverage"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    hint="For example: 2023/.."
                    label="Dataset temporal coverage"
                    name="jsonLdData.temporalCoverage"
                  />
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col>
              <DateTimeField
                disabled={published}
                hideClearButton={published}
                label={published ? 'Published at' : 'Embargo until'}
                name="publishedAt"
                placeholder={'Select a date and time\u2026'}
              />
            </Col>
          </Row>
          <Row className="ProjectForm__sticky-container">
            <Col className="d-flex justify-content-end gap-8">
              {project?.slug && (
                <Button
                  variant="outline-dark"
                  href={`${READER_URL}/projects/${project.slug}?preview=true`}
                  target="_blank"
                >
                  Preview
                </Button>
              )}
              <Button
                disabled={formik.isSubmitting}
                type="submit"
                className="continue-button button --black"
                id="save"
                onClick={(event) => {
                  if (published) {
                    window.confirm('Are you sure you want to update this live project?')
                      ? formik.setFieldValue('action', FormActions.Save)
                      : event.preventDefault();
                  } else {
                    formik.setFieldValue('action', FormActions.Save);
                  }
                }}
              >
                Save
              </Button>
              {projectId && (
                <Button
                  disabled={formik.isSubmitting || (!!formik.values.publishedAt && !published)}
                  type="submit"
                  className="button --black"
                  id="publish"
                  onClick={(event) => {
                    if (published && publishAction === FormActions.Unpublish) {
                      window.confirm('Are you sure you want to unpublish this live project?')
                        ? formik.setFieldValue('action', FormActions.Unpublish)
                        : event.preventDefault();
                    } else {
                      window.confirm('Are you sure you want to publish this project?')
                        ? formik.setFieldValue('action', FormActions.PublishNow)
                        : event.preventDefault();
                    }
                  }}
                >
                  {publishAction}
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default ProjectForm;
