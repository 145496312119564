import { integer } from 'aws-sdk/clients/cloudfront';

import { Article } from './articles';
import { getRecord, getRecords } from './universal';

export interface DistributionEvent {
  id: number;
  status: string;
  description: string;
  contentType: string;
  contentId: integer;
  articleId: integer;
  administratorId: integer;
  createdAt: string;
  administrator: Administrator;
  article: Article;
}

export interface Administrator {
  id: number;
  email: string;
}

export interface PaginatedDistributionEvents {
  result: DistributionEvent[];
  nextPage: number;
}

const MAX_DISTRIBUTION_EVENTS_PER_PAGE = 50;

export const getPaginatedDistributionEvents = async (
  articleId: number | string | null,
  page: number
): Promise<PaginatedDistributionEvents> =>
  await getRecords({
    model: 'DistributionEvent',
    attributes: [
      'id',
      'administratorId',
      'articleId',
      'contentId',
      'contentType',
      'createdAt',
      'description',
      'status',
    ],
    relations: {
      article: { attributes: ['title', 'emailSubject', 'id'] },
      administrator: { attributes: ['email'] },
    },
    identifiers: articleId ? { articleId: articleId } : null,
    order: { createdAt: 'desc' },
    page: page,
    maxPerPage: MAX_DISTRIBUTION_EVENTS_PER_PAGE,
  });

export const getDistributionEvent = async (
  distributionEventId: string | number
): Promise<DistributionEvent> => {
  const resp = await getRecord({
    id: distributionEventId,
    model: 'DistributionEvent',
    attributes: [
      'id',
      'administratorId',
      'articleId',
      'contentId',
      'contentType',
      'createdAt',
      'description',
      'status',
    ],
  });
  return resp.result;
};
