import React, { useState } from 'react';

import './Tooltip.scss';

/*
  used from https://paladini.dev/posts/how-to-make-an-extremely-reusable-tooltip-component-with-react--and-nothing-else/
*/

const Tooltip = ({ children, content, delay, direction, onClick, onHover }) => {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 400);
    if (onClick) {
      setTimeout(() => setActive(false), 2000);
    }
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className="Tooltip"
      // When to show the tooltip
      onMouseEnter={onHover ? showTip : null}
      onMouseLeave={onHover ? hideTip : null}
      onClick={onClick ? showTip : null}
    >
      {children}
      {active && <div className={`tip ${direction || 'top'}`}>{content}</div>}
    </div>
  );
};

export default Tooltip;
