import React, { useCallback } from 'react';

import { MultiValueAutocompleteField } from '@components/FormFields';
import { getEmailDomains } from '@utils/backend-api/identities';
import { autocompleteFieldOptions } from '@utils/backend-api/identity-profiles';
import { PdlSearchField } from '@utils/backend-api/people-search';
import { AutocompleteOption } from '@utils/interfaces';

import {
  FIELD_COMPANY_EMAIL_DOMAIN,
  FIELD_COMPANY_INDUSTRY,
  FIELD_COMPANY_NAME,
  FIELD_JOB_LEVEL,
  FIELD_JOB_ROLE,
  FIELD_JOB_TITLE,
} from '../options';

interface ValuesInputProps {
  name: string;
  valueOptions: AutocompleteOption[];
  field: PdlSearchField;
}

const SEARCH_OUTPUT_LIMIT = 25;

const subStringSearchOptions = (
  fragment: string,
  options: AutocompleteOption[]
): AutocompleteOption[] => {
  const results = options.filter((option) => option.value.includes(fragment));
  return results.slice(0, SEARCH_OUTPUT_LIMIT);
};

const ValuesInput = ({ name, valueOptions, field }: ValuesInputProps) => {
  const autocompleteMethod = useCallback(
    (value) => {
      const autocompleteMethodsMapper = {
        [FIELD_COMPANY_EMAIL_DOMAIN]: getEmailDomains(value),
        [FIELD_COMPANY_NAME]: autocompleteFieldOptions({
          fragment: value,
          field: FIELD_COMPANY_NAME,
          pdlOnly: true,
          maxResults: SEARCH_OUTPUT_LIMIT,
        }),
        [FIELD_COMPANY_INDUSTRY]: subStringSearchOptions(value, valueOptions),
        [FIELD_JOB_LEVEL]: subStringSearchOptions(value, valueOptions),
        [FIELD_JOB_ROLE]: subStringSearchOptions(value, valueOptions),
        [FIELD_JOB_TITLE]: autocompleteFieldOptions({
          fragment: value,
          field: FIELD_JOB_TITLE,
          pdlOnly: true,
          maxResults: SEARCH_OUTPUT_LIMIT,
        }),
      };

      return autocompleteMethodsMapper[field];
    },
    [field, valueOptions]
  );

  return (
    <MultiValueAutocompleteField
      name={name}
      label="Matches any of these values"
      placeholder="Search options"
      options={valueOptions}
      fetchMethod={autocompleteMethod}
      // allow custom search entries but don't actually create any records
      createMethod={() => undefined}
      isCreatable={true}
      // overwrite create option to not say "Create new" since we're just searching
      formatCreateLabel={(value: string) => `Search for "${value}"`}
    />
  );
};

export default ValuesInput;
