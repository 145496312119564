import { FieldArray } from 'formik';
import React, { useEffect, useState } from 'react';

import dragHandleIcon from '@images/drag_handle_icon.svg';

import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface VerticalSortableListProps {
  name: string;
  listItems: ListItem[];
  onChange: (listItems: ListItem[]) => void;
}

const VerticalSortableList = ({ name, listItems, onChange }: VerticalSortableListProps) => {
  const [listItemsState, setListItemsState] = useState<ListItem[]>(listItems);

  useEffect(() => {
    setListItemsState(listItems);
  }, [listItems]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      setListItemsState((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        const newArray = arrayMove(items, oldIndex, newIndex);
        onChange(newArray);
        return newArray;
      });
    }
  }

  return (
    <FieldArray name={name}>
      {() => (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext strategy={verticalListSortingStrategy} items={listItemsState}>
            {listItemsState.map((item) => (
              <SortableItem key={item.id} {...item} />
            ))}
          </SortableContext>
        </DndContext>
      )}
    </FieldArray>
  );
};

export default VerticalSortableList;

export interface ListItem {
  label: string;
  id: number;
  highlight?: boolean;
}

const SortableItem = (item: ListItem) => {
  const { id, label, highlight } = item;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center',
    border: '1px solid #ced4da',
    borderRadius: '4px',
    marginTop: '4px',
  };

  const labelStyle = {
    margin: '0',
    padding: '8px',
    fontWeight: highlight ? '700' : '400',
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <img src={dragHandleIcon as unknown as string} height="24" width="24" className="link-icon" />
      <p style={labelStyle}>{label}</p>
    </div>
  );
};
