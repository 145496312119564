import React, { useEffect, useMemo } from 'react';

import { Color } from '@tiptap/extension-color';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import TextStyle from '@tiptap/extension-text-style';
import { EditorContent, generateHTML, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

import './TiptapEditor.scss';
import HighlightMenu from './extensions/HighlightMenu';
import MenuBar from './extensions/MenuBar';

interface TiptapEditorProps {
  customContentHTML?: string;
  customContentJSON?: object;
  showMenu?: boolean;
  setTiptapData: React.Dispatch<React.SetStateAction<unknown>>;
}

const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  Link.configure({
    openOnClick: false,
    autolink: true,
  }),
  TextStyle,
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
];

const TiptapEditor = ({
  customContentHTML,
  customContentJSON,
  showMenu = false,
  setTiptapData,
}: TiptapEditorProps): JSX.Element => {
  const customJSON = useMemo(() => {
    if (!customContentJSON) return;
    return generateHTML(customContentJSON, [Color, Link, TextStyle, StarterKit]);
  }, [customContentJSON]);

  const editor = useEditor({
    extensions,
    content: customJSON ?? customContentHTML,
  });

  useEffect(() => {
    if (!setTiptapData) return;

    setTiptapData(editor);
  }, [editor, setTiptapData]);

  return (
    <>
      {showMenu ? <MenuBar editor={editor} /> : null}
      <EditorContent editor={editor} />
      <HighlightMenu editor={editor} />
    </>
  );
};

export default TiptapEditor;
