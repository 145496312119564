import {
  api_react_v1_distribution_group_post_path,
  api_react_v1_distribution_group_posts_path,
} from '../../routes';
import request from '../backend-api/request';
import { getRecords } from './universal';

export type GroupPost = {
  id: string;
  relevantGroupId: string;
  postUrl: string;
  body: string;
  urlParam: string;
  isNew?: boolean;
};

type GroupPostResp = {
  id: string;
};

export async function getGroupPosts(articleId: number | string): Promise<GroupPost[]> {
  const resp = await getRecords({
    model: 'GroupPost',
    attributes: ['id', 'body', 'relevantGroupId', 'postUrl', 'urlParam'],
    identifiers: { articleId },
  });
  return resp.result.reverse();
}

export async function createGroupPost(groupPost: GroupPost, articleId: number | string) {
  const resp: GroupPostResp = await request(
    'post',
    api_react_v1_distribution_group_posts_path(articleId),
    {
      relevant_group_id: groupPost.relevantGroupId,
      post_url: groupPost.postUrl,
      body: groupPost.body,
      url_param: groupPost.urlParam,
    }
  );
  return resp;
}

export async function updateGroupPost(groupPost: GroupPost, articleId: number | string) {
  const resp: GroupPostResp = await request(
    'put',
    api_react_v1_distribution_group_post_path(articleId, groupPost.id),
    {
      relevant_group_id: groupPost.relevantGroupId,
      post_url: groupPost.postUrl,
      body: groupPost.body,
    }
  );
  return resp;
}
