import React from 'react';
import { useParams } from 'react-router-dom';

import useGroupPosts from '../../../hooks/useGroupPosts';
import { GroupPost } from '../../../utils/backend-api/group-posts';
import CardList from './CardList';
import GroupPostForm from './GroupPostForm';

const buildNewGroupPost: () => GroupPost = () => ({
  id: '',
  relevantGroupId: '',
  postUrl: '',
  body: '',
  urlParam: '',
});

function RelevantGroups() {
  const { articleId, groupPostId } = useParams();
  const { groupPosts } = useGroupPosts(articleId);
  const groupPost = groupPosts?.find((gp) => gp.id.toString() === groupPostId);
  return (
    <div>
      <GroupPostForm groupPost={groupPost || buildNewGroupPost()} />
      {!!groupPosts && <CardList groupPosts={groupPosts} />}
    </div>
  );
}

export default RelevantGroups;
