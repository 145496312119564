import { Form, Formik } from 'formik';
import isEqual from 'lodash/isEqual';
import React from 'react';

import { useFilterContext } from '../../../../../contexts/FilterContext';
import { DEFAULT_PAGINATION_LIMIT } from '../../../../../hooks/useIdentities';
import { IntegerField, MultiValueAutocompleteField } from '../../../../FormFields';
import styles from './styles.module.css';

const AiFilters = () => {
  const { metadata, setMetadata, limit, setLimit } = useFilterContext();

  const addMetadata = ({ limit, subscriptionStatuses }, { setSubmitting }) => {
    setMetadata({
      ...(metadata || {}),
      subscriptionStatuses: subscriptionStatuses,
    });

    if (limit !== undefined) {
      setLimit(limit);
    }

    setSubmitting(false);
  };

  const options = [
    { label: 'Active', value: `active`, type: 'string' },
    { label: 'Canceled', value: `canceled`, type: 'string' },
    { label: 'Canceled But Paid Through', value: `canceled_but_paid_through`, type: 'string' },
    { label: 'Comped', value: `comped`, type: 'string' },
    { label: 'Corporate', value: `corporate`, type: 'string' },
    { label: 'Gift', value: `gift`, type: 'string' },
    { label: 'Never Added Payment Info', value: `never_added_payment_info`, type: 'string' },
    { label: 'Past Due', value: `past_due`, type: 'string' },
    { label: 'Team', value: `team`, type: 'string' },
    { label: 'Trialing', value: `trialing`, type: 'string' },
  ];

  const formChanges = (formik) => {
    return (
      !isEqual(formik.values.subscriptionStatuses, metadata.subscriptionStatuses) ||
      (limit !== undefined && limit !== formik.values.limit)
    );
  };

  return (
    <li className="mt-3 pe-3">
      <Formik
        onSubmit={addMetadata}
        initialValues={{ limit: DEFAULT_PAGINATION_LIMIT, subscriptionStatuses: [] }}
        enableReinitialize
      >
        {(formik) => (
          <Form className="g-1" onSubmit={formik.handleSubmit}>
            <MultiValueAutocompleteField
              placeholder="Statuses"
              name="subscriptionStatuses"
              options={options}
            />
            <IntegerField label="limit" name="limit" />

            <button
              disabled={formik.isSubmitting}
              className="btn btn-sm btn-outline-dark me-2 h-100"
              type="submit"
            >
              Update Metadata
            </button>

            {formChanges(formik) && <span className={styles.unsavedForm}>Unsaved changes</span>}
          </Form>
        )}
      </Formik>
    </li>
  );
};

export default AiFilters;
