import { Field, useField } from 'formik';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import './CheckBox.scss';

/**
 * @param {{name, label, options, ...}} props
 * options => [{label: "label 1", value: 1}, {label: "label 2", value: 2}, ...]
 * where label is displayed, and value is an optional value attached for the item.
 * Prepend an option like, { label: 'Check All', value: 'allChecked', allChecked: true } to include
 * a select or deselect all checkbox in the group
 */
const CheckBoxGroup = (props) => {
  const { label, name, negativeFillClass, options, size, onChange } = props;
  const [field, meta, helpers] = useField(name);

  const allCheckedFieldKey = options.find((el) => el.allChecked)?.value;
  const fieldValues = field.value.map((fv) => (typeof fv === 'object' ? fv.value : fv));

  // handle a default value coming in of 'allChecked' by actually setting the array to all entity values
  useEffect(() => {
    if (field.value.length == 1 && field.value[0] == 'allChecked') {
      helpers.setValue(options.map((option) => option.value));
    }
  }, []);

  const handleOnChange = (checkboxKey) => {
    let newValue;
    if (checkboxKey === allCheckedFieldKey) {
      newValue = fieldValues.includes(allCheckedFieldKey)
        ? [] // remove all values if "all" already checked
        : options.map((option) => option.value); // add all values if not
    } else {
      newValue = fieldValues.includes(checkboxKey)
        ? fieldValues.filter((key) => key !== checkboxKey) // remove value if exists
        : [...fieldValues, checkboxKey]; // add value if not
    }
    helpers.setValue(newValue);
    if (onChange) {
      onChange(field, newValue);
    }
  };

  const checkIfDisabled = (key) =>
    field.value.includes(allCheckedFieldKey) && key !== allCheckedFieldKey;

  return (
    <div className="CheckBoxGroup form-group" id={name}>
      {label && (
        <label className="input-label fw-bold mb-2" htmlFor={name} id="check-box-label">
          {label}
        </label>
      )}
      {options.map((el) => {
        return (
          <Row
            key={el.value}
            className={`checkbox-group-option ${meta.touched && meta.error ? 'is-invalid' : ''}`}
          >
            <Col xs={12}>
              <label
                className={`checkbox-container${checkIfDisabled(el.value) ? ' disabled' : ''}${
                  size === 'large' ? ' --large' : ''
                }`}
              >
                <span className="checkbox-text">{el.label}</span>
                <Field
                  type="checkbox"
                  className="checkbox-input"
                  name={name}
                  id={el.value}
                  value={el.value}
                  checked={fieldValues.includes(el.value)}
                  onChange={() => handleOnChange(el.value)}
                />
                <span className={`checkbox-fill ${negativeFillClass ? 'fill-minus' : ''}`}></span>
              </label>
            </Col>
          </Row>
        );
      })}
      {meta.touched && meta.error && <div className="invalid-feedback">{meta.error}</div>}
    </div>
  );
};

export default CheckBoxGroup;
