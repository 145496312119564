import { format, isAfter, isValid, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useProject } from '@hooks';

import {
  ACCESS_LEVEL_LABELS,
  ProjectFormValues,
  PublishStatus,
  THEME_MAP,
  layoutTypeOptions,
  mediaTypeOptions,
} from '../../utils/backend-api/projects';
import ProjectForm, { INITIAL_STATE } from './ProjectForm';
import './Projects.scss';

const replaceNullValues = (valueObj: { [key: string]: string | null }) => {
  for (const key in valueObj) {
    if (valueObj[key] === null) {
      valueObj[key] = '';
    }
  }
};

const buildPublishStatusMessage = (publishStatus: PublishStatus, publishedAt: string) => {
  switch (publishStatus) {
    case PublishStatus.Published:
      return `Published at ${publishedAt}`;
    case PublishStatus.Embargoed:
      return `Embargoed until ${publishedAt}`;
    case PublishStatus.Unpublished:
      return 'Draft';
    default:
      return '';
  }
};

const EditProject = () => {
  const { id: projectId } = useParams();
  const [formValues, setFormValues] = useState<ProjectFormValues>(INITIAL_STATE);
  const [formattedPublishedAt, setFormattedPublishedAt] = useState<string>('');
  const [projectPublishedStatus, setProjectPublishedStatus] = useState<PublishStatus | null>(null);
  const { project, isLoadingProject, isFetching } = useProject(projectId);

  useEffect(() => {
    if (!!project && !isLoadingProject && !isFetching) {
      const {
        accessLevel,
        article,
        jsonLdData: { alternateName, keywords, ...restJsonLdData },
        publishedAt,
        theme,
        projectMedia,
        ...restProject
      } = project;
      [restProject, restJsonLdData].forEach(replaceNullValues);

      const parsedPublishedAt = parseISO(publishedAt || '');
      const isPublishedAtValid = isValid(parsedPublishedAt);
      if (isPublishedAtValid) {
        const projectPublished = !isAfter(parsedPublishedAt, new Date());

        if (projectPublished) {
          setProjectPublishedStatus(PublishStatus.Published);
        } else {
          setProjectPublishedStatus(PublishStatus.Embargoed);
        }

        const formattedPublishedAt = format(parsedPublishedAt, 'PPPp');
        setFormattedPublishedAt(formattedPublishedAt);
      } else {
        setProjectPublishedStatus(PublishStatus.Unpublished);
      }

      const projectFormValues = {
        accessLevel: {
          label: ACCESS_LEVEL_LABELS[accessLevel || 0],
          value: accessLevel || 0,
        },
        article: {
          label: article?.title || '',
          value: article?.id || '',
        },
        jsonLdData: {
          alternateName: alternateName?.map((name: string) => ({ label: name, value: name })) || [],
          keywords: keywords?.map((keyword: string) => ({ label: keyword, value: keyword })) || [],
          ...restJsonLdData,
        },
        publishedAt: isPublishedAtValid ? new Date(publishedAt) : '',
        theme: {
          label: THEME_MAP[theme] || '',
          value: theme || '',
        },
        projectMedia:
          projectMedia.map(({ mediaType, layout, ...restMedia }) => {
            return {
              mediaType: {
                label: mediaTypeOptions.find((mto) => mto.value == mediaType).label || '',
                value: mediaType || '',
              },
              layout: {
                label: layoutTypeOptions.find((lto) => lto.value == layout).label || '',
                value: layout || '',
              },
              ...restMedia,
            };
          }) || [],
        ...restProject,
      };

      setFormValues(projectFormValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingProject, isFetching]);

  if (isLoadingProject || !project) return null;

  const publishStatusMessage = buildPublishStatusMessage(
    projectPublishedStatus,
    formattedPublishedAt
  );
  return (
    <Container className="EditProject">
      <Row>
        <Col md={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 2 }}>
          <Row>
            <Col xs={12}>
              <div>
                <h2 className="mb-1 text-center">{project.title}</h2>
                <p className="text-center fst-italic">{publishStatusMessage}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mb-2">
              <hr className="divider" />
            </Col>
          </Row>
          <ProjectForm
            formValues={formValues}
            published={projectPublishedStatus == PublishStatus.Published}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default EditProject;
