import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { SUBSCRIPTIONS_URL } from '../../../src/env';
import { CustomEmailForm } from '../../BaseUI';
import { useArticleContext } from '../Layout';
import RtsuControl from '../RtsuControl';
import RelevantCompanyIdentitiesForm from './Form';

// fetch this from db by administrator list name in the future (once universal controller is in)
const COMPANY_TARGETING_UNSUBSCRIBE_LIST_GUID = '4JXgcpuyz5YG';

const RelevantCompanyIdentities = () => {
  const [relevantIdentities, setRelevantIdentities] = useState([]);
  const [excludeAlreadyNotified, setExcludeAlreadyNotified] = useState(true);
  const { article } = useArticleContext();
  const { articleId } = useParams();

  return (
    <div className="RelevantCompanyIdentities">
      <RtsuControl />
      <RelevantCompanyIdentitiesForm
        relevantIdentities={relevantIdentities}
        setRelevantIdentities={setRelevantIdentities}
        setExcludeAlreadyNotified={setExcludeAlreadyNotified}
      />
      <CustomEmailForm
        recipients={relevantIdentities}
        unsubscribeUrl={`${SUBSCRIPTIONS_URL}/unsubscribe/${COMPANY_TARGETING_UNSUBSCRIBE_LIST_GUID}`}
        relevantLinks={[{ name: 'Article URL', url: article.shareUrl }]}
        articleId={articleId}
        excludeAlreadyNotified={excludeAlreadyNotified}
      />
    </div>
  );
};

export default RelevantCompanyIdentities;
