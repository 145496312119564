import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import PushNotificationForm from './Form';
import './NewPushNotification.scss';

export default function NewPushNotification() {
  return (
    <>
      <Container id="NewPushNotification">
        <Row>
          <Col>
            <Row>
              <Col xs={12}>
                <h2 className="mb-1">Set up new Push Notification</h2>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="mb-4">
                <hr className="divider" />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <PushNotificationForm />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
