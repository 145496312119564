import React, { FC, useEffect } from 'react';

import { CrmIdentityFilter } from '../../utils/backend-api/identities';
import { Identity } from '../../utils/backend-api/models/identity';
import BulkActionModal from './BulkActionModal';

interface Props {
  showBulkActions: boolean;
  identities: Identity[];
  selectAll: boolean;
  bulkAction: string;
  setBulkAction: React.Dispatch<React.SetStateAction<string>>;
  filters: CrmIdentityFilter[];
  query: string;
  administratorListId: number;
  setAllIdentitiesSelected: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedIdentities: React.Dispatch<React.SetStateAction<Identity[]>>;
}

const BulkActions: FC<Props> = ({
  showBulkActions,
  identities,
  bulkAction,
  setBulkAction,
  selectAll,
  filters,
  query,
  administratorListId,
  setAllIdentitiesSelected,
  setSelectedIdentities,
}) => {
  const options = [
    { label: 'Send Email/Offer', value: 'send-email' },
    { label: '', value: '' },
    { label: 'Add to Personal List', value: 'add-to-list' },
    { label: 'Comp Newsletters', value: 'comp-newsletters' },
    { label: 'Comp The Information', value: 'comp-information' },
    { label: 'Trial Newsletters', value: 'trial' },
    { label: 'Star Identities', value: 'star' },
    { label: '', value: '' },
    { label: 'Remove from Personal List', value: 'remove-from-list' },
    { label: 'Cancel Newsletter Subscriptions', value: 'cancel-subscriptions' },
    { label: 'Unstar Identities', value: 'unstar' },
  ];

  useEffect(() => {
    if (!bulkAction) {
      setAllIdentitiesSelected(false);
      setSelectedIdentities([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkAction]);

  const title = (value) => {
    return options.find((option) => option.value === value).label;
  };

  return (
    <>
      {showBulkActions && (
        <div id="bulk-actions" style={{ display: 'inline' }}>
          <div className="text-small text-muted">
            {selectAll ? 'All Identities' : identities.length} Selected
          </div>
          <select
            value={bulkAction}
            onChange={(e) => setBulkAction(e.currentTarget.value)}
            className="crm-header-input action-select"
          >
            <option value="" disabled selected>
              Pick a bulk action
            </option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      )}
      {!!bulkAction && (
        <BulkActionModal
          title={title(bulkAction)}
          bulkAction={bulkAction}
          setBulkAction={setBulkAction}
          identities={identities}
          selectAll={selectAll}
          filters={filters}
          query={query}
          administratorListId={administratorListId}
        />
      )}
    </>
  );
};

export default BulkActions;
