import { api_react_v1_entity_joins_path } from '../../routes';
import request from './request';

export interface SanitizedEntityJoinParams {
  entity_id: number;
  parent_type: string;
  parent_id: number;
  salience: number;
  relationship_source: string;
}

export interface DeleteEntityJoinParams {
  name?: string;
  parentType: string;
  parentId: number;
}

interface EntityJoinResponse {
  success: boolean;
  newRecord: boolean;
}

export async function createEntityJoin(
  values: SanitizedEntityJoinParams
): Promise<EntityJoinResponse> {
  const resp = await request('post', api_react_v1_entity_joins_path(), { entity_join: values });
  return resp;
}

export async function deleteEntityJoin(params: DeleteEntityJoinParams): Promise<Response> {
  const resp = await request('delete', api_react_v1_entity_joins_path(0), {
    name: params.name,
    entity_join: { parent_type: params.parentType, parent_id: params.parentId },
  });
  return resp;
}
