import {
  api_react_v1_administrator_list_roles_path,
  api_react_v1_destroy_administrator_list_role_path,
} from '../../routes';
import request from './request';

export async function subscribeToAdminList(administratorListId: number | string) {
  return await request(
    'post',
    api_react_v1_administrator_list_roles_path({ administrator_list_id: administratorListId })
  );
}

export async function unsubscribeFromAdminList(
  administratorId: number | string,
  administratorListId: number | string
) {
  return await request(
    'delete',
    api_react_v1_destroy_administrator_list_role_path({
      administrator_id: administratorId,
      administrator_list_id: administratorListId,
    })
  );
}
