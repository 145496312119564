import moment from 'moment';

import * as CSRF from './csrf';

export default class Autosave {
  static _calculateTimeDeltaPhrase = (now, lastSaved) => {
    const delta = (now - lastSaved) / 1000; // convert to seconds
    if (delta < 10) {
      return 'just now';
    } else if (delta < 60) {
      return `about ${Math.floor(delta / 10) * 10} seconds ago`; // e.g., 'about 20 seconds ago'
    } else if (delta < 120) {
      return 'about a minute ago';
    } else if (delta < 600) {
      return `about ${Math.floor(delta / 60)} minutes ago`; // e.g., 'about 3 minutes ago'
    } else if (delta < 3600) {
      return `about ${Math.floor(delta / 300) * 5} minutes ago`; // e.g., 'about 25 minutes ago'
    } else if (delta < 7200) {
      return 'about an hour ago';
    } else if (delta < 86400) {
      return `about ${Math.floor(delta / 3600)} hours ago`; // e.g. 'about 14 hours ago'
    } else {
      return 'more than a day ago';
    }
  };

  static updateLastSavedNotification = () => {
    const now = new Date().valueOf();
    // lastSaved is calculated by server/ruby code, and is in seconds rather than ms
    const lastSaved = document.querySelector('[data-last-local-save]').dataset.lastLocalSave * 1000;
    const phrase = Autosave._calculateTimeDeltaPhrase(now, lastSaved);
    document.querySelector('#last-saved-at').innerHTML = `Last successful save: ${phrase}`;
  };

  static initializeLastSavedNotification = () => {
    document.addEventListener(
      'DOMContentLoaded',
      () => {
        setInterval(() => {
          Autosave.updateLastSavedNotification();
        }, 5000);
        Autosave.updateLastSavedNotification();
      },
      false
    );
  };

  static disableScreen = () => {
    const container = document.querySelector('[data-last-local-save]');
    container.classList.add('disabled');
  };

  static enableScreen = () => {
    const container = document.querySelector('[data-last-local-save]');
    container.classList.remove('disabled');
  };

  static autosaveSettings = async (
    form = document.querySelector('#edit-newsletter-settings-form'),
    saveImage = false
  ) => {
    const formData = new FormData(form);
    const tags = JSON.stringify(
      [...document.querySelectorAll('#select-tags .tag-value')].map((elt) =>
        elt.innerHTML.toLowerCase()
      )
    );
    formData.append('article[tags]', tags);
    // Custom checkboxes don't play nicely with FormData; convert their values to true/false
    const checkboxes = document.querySelectorAll(
      'input[type="checkbox"]:not([data-preserve-value])'
    );
    checkboxes.forEach((checkbox) => {
      formData.append(checkbox.name, checkbox.checked);
    });
    if (!saveImage) {
      formData.delete('article[picture]');
    }

    const localSaveContainer = document.querySelector('[data-last-local-save]');
    formData.append('article[last_local_save]', localSaveContainer.dataset.lastLocalSave);
    // Convert to date format
    if (formData.get('article[embargoed_until]')) {
      formData.set('article[embargoed_until]', new Date(formData.get('article[embargoed_until]')));
    }
    if (formData.get('article[archive_published_at]')) {
      formData.set(
        'article[archive_published_at]',
        new Date(formData.get('article[archive_published_at]'))
      );
    }
    await Autosave.saveArticle(form, formData, true);
  };

  static saveArticle = async (form, formData, savingSettings = false, overwriteChanges = false) => {
    const localSaveContainer = document.querySelector('[data-last-local-save]');
    formData.append('article[overwrite_changes]', overwriteChanges);
    formData.append('article[last_local_save]', localSaveContainer.dataset.lastLocalSave);
    document.querySelector('#continue-col').classList.add('no-pointer-events');
    const response = await fetch(form.dataset.submit, {
      headers: {
        'X-CSRF-Token': CSRF.getCSRFToken(),
      },
      method: form.dataset.method.toUpperCase(),
      body: formData,
    });
    const saveWarning = document.querySelector('#failed-save');
    const saveWarningText = saveWarning.querySelector('span');
    const saveSuccess = document.querySelector('#last-saved-at');
    if (response.ok) {
      const responseBody = await response.json();
      saveWarning.style.display = 'none';
      saveSuccess.classList.remove('--grey');
      saveSuccess.classList.add('--green');
      if (responseBody.last_local_save) {
        localSaveContainer.dataset.lastLocalSave = responseBody.last_local_save;
        Autosave.updateLastSavedNotification();
      }
      if (savingSettings) {
        // Update the preview link by replacing everything after the last slash with the slug
        const previewButton = document.querySelector('#preview-article-button');
        const regex = new RegExp('(.*)[/](.*)');
        if (!!previewButton) {
          previewButton.href = previewButton.href.replace(
            regex,
            `$1/${responseBody.slug}?preview=true`
          );
        }
      }
    } else if (response.status === 409) {
      const responseBody = await response.json();
      saveSuccess.classList.remove('--green');
      saveSuccess.classList.add('--grey');
      saveWarningText.innerHTML = `${
        responseBody.last_updated_by
      } changed this document at ${moment(responseBody.last_updated * 1000).format(
        'lll'
      )}.<br><a href="#" onclick="window.location.reload()">Refresh</a> to get their changes or <a class="overwrite" href="#">Overwrite</a> with your updates to continue working.`;
      saveWarning.style.display = 'block';
      const overwriteBtn = document.querySelector('.overwrite');
      overwriteBtn.addEventListener('click', () =>
        Autosave.overwriteArticle(form, formData, savingSettings)
      );
      Autosave.disableScreen();
    } else {
      console.error(response);
      saveSuccess.classList.remove('--green');
      saveSuccess.classList.add('--grey');
      saveWarningText.innerHTML = 'Most recent save attempt failed';
      saveWarning.style.display = 'block';
    }
    document.querySelector('#continue-col').classList.remove('no-pointer-events');
  };

  static overwriteArticle(form, formData, savingSettings) {
    Autosave.enableScreen();
    Autosave.saveArticle(form, formData, savingSettings, true);
  }
}
