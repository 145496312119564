import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import {
  AdministratorListSanitizedValues,
  updateAdministratorList,
} from '../utils/backend-api/administrator-lists';
import { ADMINISTRATOR_LIST_EDIT_KEY } from './useAdministratorListEdit';
import { ADMINISTRATOR_LISTS_KEY } from './useAdministratorLists';

export default function useAdministratorListUpdate() {
  const queryClient = useQueryClient();

  const { mutate, mutateAsync, data, isLoading, isError } = useMutation(
    async (values: AdministratorListSanitizedValues) => await updateAdministratorList(values),
    {
      onSuccess: (data) => {
        toast.success('Administrator List has been updated');
        queryClient.invalidateQueries(ADMINISTRATOR_LISTS_KEY);
        queryClient.invalidateQueries([ADMINISTRATOR_LIST_EDIT_KEY, data.id]);
      },
      onError: () => {
        toast.error('Updating administrator list has failed');
      },
    }
  );
  return { mutate, mutateAsync, data, isLoading, isError };
}
