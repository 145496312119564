import * as Yup from 'yup';

export const MAX_EMPLOYEES_SEARCH = 3000;

const peopleSearchSchema = () =>
  Yup.object().shape({
    searchAttributesGroups: Yup.array()
      .of(
        Yup.object().shape({
          conditionJoin: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string().required('Condition join is required'),
          }),
          searchAttributes: Yup.array()
            .of(
              Yup.object().shape({
                field: Yup.object().shape({
                  label: Yup.string(),
                  value: Yup.string().nullable().required('Attribute field is required'),
                }),
                values: Yup.array()
                  .of(
                    Yup.object().shape({
                      label: Yup.string(),
                      value: Yup.string().required('At least one value is required'),
                    })
                  )
                  .min(1, 'At least one value is required')
                  .required('At least one value is required'),
              })
            )
            .min(1, 'At least one search attribute group is required'),
        })
      )
      .min(1, 'At least one search attribute group is required'),
    newAdministratorListTitle: Yup.string().nullable(),
    size: Yup.number()
      .required('Number of employees to search for is required')
      .max(MAX_EMPLOYEES_SEARCH, `PDL's rate limit is ${MAX_EMPLOYEES_SEARCH} people / minute`),
    existingAdministratorList: Yup.object()
      .shape({
        label: Yup.string().nullable(),
        value: Yup.number().typeError('This is not a valid administrator list').nullable(),
      })
      .nullable(),
  });

export default peopleSearchSchema;
