import moment from 'moment';
import React from 'react';

import useDistributionEvents from '../../hooks/useDistributionEvents';
import { distribution_events_path } from '../../routes';

const DistributionEventsTable = ({ articleId = null }) => {
  const { distributionEventsData, isLoadingDistributionEvents } = useDistributionEvents(articleId);

  const renderTh = (title: string) => (
    <th style={{ textAlign: 'left', fontWeight: 'bold' }}>{title}</th>
  );

  return (
    <table id="DistributionEventsTable" className="table table-crm">
      <thead>
        <tr>
          {renderTh('Created Date')}
          {renderTh('Article')}
          {renderTh('Administrator')}
          {renderTh('Distribution Type')}
          {renderTh('Id')}
          {renderTh('Description')}
          {renderTh('Status')}
        </tr>
      </thead>
      <tbody>
        {isLoadingDistributionEvents ? (
          <tr>
            <td>Loading...</td>
          </tr>
        ) : (
          distributionEventsData.pages.map((group) =>
            group.result.map((distributionEvent, i) => (
              <tr className="distribution-event" key={`${distributionEvent.id}-${i}`}>
                <td>{moment(distributionEvent.createdAt).format('ll')}</td>
                <td>
                  {distributionEvent.article && (
                    <a
                      className="btn-link"
                      href={distribution_events_path({ article_id: distributionEvent.articleId })}
                    >
                      {distributionEvent.articleId}{' '}
                      {distributionEvent.article.title || distributionEvent.article.emailSubject}
                    </a>
                  )}
                </td>
                <td>{distributionEvent.administrator?.email}</td>
                <td>{distributionEvent.contentType}</td>
                <td>{distributionEvent.contentId}</td>
                <td>{distributionEvent.description}</td>
                <td>{distributionEvent.status}</td>
              </tr>
            ))
          )
        )}
      </tbody>
    </table>
  );
};

export default DistributionEventsTable;
