import { Formik } from 'formik';
import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { useConnectedProviders, useVirtualLists } from '../../hooks';
import { TWITTER_LINE_ITEMS_KEY } from '../../hooks/useTwitterLineItems';
import {
  createTwitterLineItem,
  updateTwitterLineItem,
} from '../../utils/backend-api/twitter-line-items';
import { DateTimeField, DropdownField, TextField } from '../FormFields';
import TweetPicker from './TweetPicker';
import twitterCampaignSchema from './schema';

const INITIAL_STATE = {
  connectedProvider: { label: '', value: '' },
  totalBudgetMicro: '',
  bidAmountMicro: '',
  startAt: '',
  tweet: { label: '', value: '' },
  virtualList: { label: '', value: '' },
};

export const DOLLARS_TO_MICRO = 1_000_000;

const TwitterLineItem = ({ articleId, twitterLineItem }) => {
  const isEditing = !!twitterLineItem;
  const queryClient = useQueryClient();
  const { connectedProviderOptions } = useConnectedProviders('twitter_ads');
  const { virtualListsOptions } = useVirtualLists();
  const initialValues = twitterLineItem
    ? {
        connectedProvider: {
          label: twitterLineItem.connectedProvider.name,
          value: twitterLineItem.connectedProvider.id,
        },
        totalBudgetMicro: twitterLineItem.totalBudgetMicro / DOLLARS_TO_MICRO,
        bidAmountMicro: twitterLineItem.bidAmountMicro / DOLLARS_TO_MICRO,
        startAt: twitterLineItem.startAt,
        tweet: { value: twitterLineItem.tweetId },
        virtualList: {
          label: twitterLineItem.virtualList.listName,
          value: twitterLineItem.virtualList.id,
        },
      }
    : INITIAL_STATE;
  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const { connectedProvider, totalBudget, bidAmount, tweet, startAt, virtualList } = values;
    const sanitizedParams = {
      twitter_line_item: {
        article_id: articleId,
        connected_provider_id: connectedProvider.value,
        total_budget_micro: totalBudget * DOLLARS_TO_MICRO,
        bid_amount_micro: bidAmount * DOLLARS_TO_MICRO,
        start_at: startAt,
        tweet_id: tweet.value,
        virtual_list_id: virtualList.value,
      },
    };
    try {
      const resp = isEditing
        ? await updateTwitterLineItem(twitterLineItem.id, sanitizedParams)
        : await createTwitterLineItem(sanitizedParams);
      if (resp.message == 'ok') {
        isEditing
          ? toast.success('Campaign has been updated')
          : toast.success(
              startAt ? 'Campaign has been scheduled' : 'Campaign draft has been saved'
            );
        queryClient.invalidateQueries(TWITTER_LINE_ITEMS_KEY);
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to save Twitter Campaign');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="ScheduledTweetForm">
      <h2>{isEditing ? 'Edit Twitter Campaign' : 'Create Twitter Campaign'}</h2>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={twitterCampaignSchema}
        enableReinitialize={true}
      >
        {(formik) => (
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={6}>
                <DropdownField
                  name="connectedProvider"
                  label="Twitter Ads Account"
                  placeholder="Select a Twitter Ads Account"
                  options={connectedProviderOptions}
                />
              </Col>
            </Row>
            {formik.values.connectedProvider.value && (
              <>
                <Row>
                  <Col md={6}>
                    <DateTimeField name="startAt" label="Start Campaign At" maxDaysOut={28} />
                  </Col>
                  <Col md={3}>
                    <TextField name="totalBudget" label="Total Budget ($)" />
                  </Col>
                  <Col md={3}>
                    <TextField name="bidAmount" label="Daily Budget ($)" />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <DropdownField
                      name="virtualList"
                      label="Virtual List"
                      placeholder="Select a virtual list"
                      options={virtualListsOptions}
                    />
                    <a href="/virtual-lists">Create Virtual List</a>
                  </Col>
                  <Col md={6}>
                    <TweetPicker connectedProviderId={formik.values.connectedProvider.value} />
                  </Col>
                </Row>
                <div className="schedule-tweet-btn-wrapper --black-top-border">
                  <Button
                    disabled={formik.isSubmitting}
                    type="submit"
                    className="--black"
                    id="create-twitter-campaign-button"
                  >
                    {isEditing ? 'Update' : 'Schedule'} Campaign
                  </Button>
                </div>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default TwitterLineItem;
