function initializeToast() {
  const snakbar = document.getElementById('snakbar');
  if (!snakbar) {
    return;
  }

  snakbar.className = 'show';

  // After 5 seconds, remove the show class from DIV
  setTimeout(function () {
    snakbar.className = snakbar.className.replace('show', '');
  }, 5000);
}

function showMessage(type, message) {
  var containerNode = document.getElementById('snakbar');
  if (containerNode) {
    containerNode.innerHTML = `<div class="snakbar-container ${
      type === 'error' ? 'red' : 'blue'
    }">${message}</div>`;
  } else {
    containerNode = document.createElement('div');
    containerNode.id = 'snakbar';
    containerNode.innerHTML = `<div class="snakbar-container ${
      type === 'error' ? 'red' : 'blue'
    }">${message}</div>`;
    document.body.appendChild(containerNode);
  }
  initializeToast();
}

document.addEventListener('DOMContentLoaded', () => {
  initializeToast();
  window.showMessage = showMessage;
});
