import { DateTime } from 'aws-sdk/clients/devicefarm';

import { api_react_v1_create_talk_path } from '../../routes';
import { Identity } from './models/identity';
import { Newsletter } from './newsletters';
import request from './request';
import { getRecord } from './universal';

export interface Talk {
  description: string;
  id: number;
  model: string;
  name: string;
  scheduledTime: string;
  slug: string;
  newsletter: Newsletter;
  createdByIdentity: Identity;
  talkUrl: string;
}

export const getTalkBySlug = async (slug: string): Promise<Talk> => {
  const resp = await getRecord({
    model: 'Talk',
    id: 0, // required - pass 0 when getting record by slug
    identifier: { slug },
    attributes: ['name', 'description', 'scheduledTime', 'slug'],
    relations: {
      offer: { attributes: ['code'] },
      newsletter: { attributes: ['name', 'tiBundled'] },
      createdByIdentity: { helpers: ['name'] },
    },
    helpers: ['imgixUrl', 'talkUrl'],
  });
  return resp.result;
};

export interface SanitizedTalkParams extends FormData {
  name: string;
  created_by_identity_id: number;
  description: string;
  scheduled_time?: DateTime;
  newsletter_id?: number;
  offer_id?: number;
  image?: string | File;
}

export async function createTalk(values: SanitizedTalkParams) {
  const resp = await request('post', api_react_v1_create_talk_path(), values, true);
  return resp.redirect_url;
}
