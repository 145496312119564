import { useQuery } from 'react-query';

import { Advertisement, getAdvertisement } from '../utils/backend-api/advertisements';

export const ADVERTISEMENT_KEY = 'advertisement';

const SECTION_SUBGROUP_SIZE = 4;

export default function useAdvertisement(advertisementId: number | string) {
  const {
    data: advertisement,
    isLoading: isLoadingAdvertisement,
    isFetching,
    refetch,
  } = useQuery<Advertisement>(
    ADVERTISEMENT_KEY,
    async () => await getAdvertisement(advertisementId)
  );

  const availableScheduleSections = advertisement?.availableScheduleSections || {};
  const formattedSectionOptions = {};
  for (const sectionGroup in availableScheduleSections) {
    formattedSectionOptions[sectionGroup] = formatSectionOptions(
      availableScheduleSections[sectionGroup]
    );
  }
  return {
    advertisement: { formattedSectionOptions, ...advertisement },
    isLoadingAdvertisement,
    isFetching,
    refetch,
  };
}

// Helper function to get the available sections into a usable format for the form
export const formatSectionOptions = (sectionGroup: string[]) => {
  const sectionGroupWithSubGroups = [];
  sectionGroup.reduce((subGroup, option: string, idx: number) => {
    subGroup.push({ label: option, value: option });
    if (subGroup.length === SECTION_SUBGROUP_SIZE || idx === sectionGroup.length - 1) {
      sectionGroupWithSubGroups.push(subGroup);
      subGroup = [];
    }
    return subGroup;
  }, []);
  return sectionGroupWithSubGroups;
};
