import * as Yup from 'yup';

import {
  ARTICLE_AUTOMATIC_LIST_TYPE_OPTION,
  AUTOMATIC_LIST_OPTION_VALUES,
  LIST_TYPE_OPTIONS,
} from '../../../../utils/backend-api/administrator-lists';

const validationSchema = () =>
  Yup.object().shape({
    listName: Yup.string().required('List name is required'),
    listDescription: Yup.string().nullable(),
    listType: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string().oneOf(
        LIST_TYPE_OPTIONS.map((o) => o.value),
        'This is not a valid list type'
      ),
    }),

    entities: Yup.array().test({
      name: 'entities-or-query-required',
      test: function (value) {
        const { listType, query } = this.parent;

        if (
          !listType ||
          !listType.value ||
          listType.value == ARTICLE_AUTOMATIC_LIST_TYPE_OPTION.value
        ) {
          // If listType is not defined, or it's article automatic skip validation of query
          return true;
        }

        const automaticListType = AUTOMATIC_LIST_OPTION_VALUES.includes(listType.value);
        const queryIsEmpty = !query || query === '';

        if (automaticListType) {
          // For automatic list types, either entities or query is required
          return !queryIsEmpty || (value && value.length > 0);
        }

        // For other list types, no additional validation needed
        return true;
      },
      message: 'Either entities or query is required for auto lists',
    }),

    query: Yup.string().test({
      name: 'query-or-entities-required',
      test: function (value) {
        const { listType, entities } = this.parent;

        if (
          !listType ||
          !listType.value ||
          listType.value === ARTICLE_AUTOMATIC_LIST_TYPE_OPTION.value
        ) {
          // If listType is not defined, or it's article automatic skip validation of query
          return true;
        }

        const automaticListType = AUTOMATIC_LIST_OPTION_VALUES.includes(listType.value);
        const entitiesIsEmpty = !entities || entities.length === 0;

        if (automaticListType) {
          // For automatic list types, either query or entities is required
          return !entitiesIsEmpty || (value && value !== '');
        }

        // For other list types, no additional validation needed
        return true;
      },
      message: 'Either entities or query is required for auto lists',
    }),
    articleId: Yup.number().test({
      name: 'articleId required if article_auto list',
      test: function () {
        const { listType, article } = this.parent;
        if (!listType || !listType.value) {
          return true;
        } else if (listType.value === ARTICLE_AUTOMATIC_LIST_TYPE_OPTION.value) {
          return !!article?.value;
        } else {
          return true;
        }
      },
    }),
  });

export default validationSchema;
