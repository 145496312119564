import React from 'react';
import { Col, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

import { useAdminContext } from '../../../contexts/AdminContext';
import { crm_api_key_path, crm_pdl_search_people_by_attributes_path } from '../../../routes';
import DataImportsTable from './DataImportsTable';
import ImportCsvForm from './ImportCsvForm';
import ImportEmailsForm from './ImportEmailsForm';

const IdentitiesImport = () => {
  const { crmEmailToken } = useAdminContext();
  const crmEmail = `crm+${crmEmailToken}@theinformation.com`;

  return (
    <div>
      <h1 className="my-4">Import People</h1>

      <div className="d-flex flex-wrap align-items-baseline mb-4">
        <a className="btn btn-sm btn-secondary me-1" href={crm_api_key_path()}>
          View API
        </a>
        <Link
          className="btn btn-sm btn-secondary me-2"
          to={crm_pdl_search_people_by_attributes_path()}
        >
          Search People by Attributes
        </Link>
        <div className="d-inline-block py-2">
          Your BCC email is{' '}
          <span
            className="btn btn-sm btn-light font-monospace px-1"
            title="Click to copy email"
            onClick={() => {
              navigator.clipboard.writeText(crmEmail).then(() => {
                toast.success('Email copied to clipboard!');
              });
            }}
          >
            {crmEmail} <i className="fa fa-copy" aria-hidden="true" />
          </span>
        </div>
      </div>

      <Row className="mb-5">
        <Col lg={6} className="pe-lg-4 border-end">
          <ImportEmailsForm />
        </Col>
        <Col lg={6} className="mt-4 mt-lg-0 ps-lg-4">
          <ImportCsvForm />
        </Col>
      </Row>

      <DataImportsTable />
    </div>
  );
};

export default IdentitiesImport;
