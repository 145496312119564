import clsx from 'clsx';
import React from 'react';

import styles from './styles.module.css';

const ButtonPlain = (props) => {
  return <button className={clsx(styles.button, props.className)} {...props}></button>;
};

export default ButtonPlain;
