import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { ScheduledShare, getScheduledShare } from '../utils/backend-api/scheduled-shares';

export const SCHEDULED_SHARE_KEY = 'scheduledShare';

export default function useScheduledShare(scheduledShareId: number | string) {
  const { data, isLoading: isLoadingScheduledShare } = useQuery<ScheduledShare>(
    SCHEDULED_SHARE_KEY,
    () => getScheduledShare(scheduledShareId)
  );

  const scheduledShare = useMemo(() => {
    if (data) {
      return {
        ...data,
        connectedProviderOption: {
          label: data.connectedProvider.name,
          value: data.connectedProvider.id,
        },
      };
    }
  }, [data]);

  return { scheduledShare, isLoadingScheduledShare };
}
