import * as Yup from 'yup';
import moment from 'moment';

const scheduledTweetSchema = () =>
  Yup.object().shape({
    connectedProvider: Yup.object().shape({
      label: Yup.string().nullable(),
      value: Yup.number().required('Twitter account is required'),
    }),
    shareText: Yup.string().required('Tweet body is required'),
    sendTweetAt: Yup.date()
      .min(moment().toISOString(), 'Scheduled time must be in the future')
      .nullable(),
  });

export default scheduledTweetSchema;
