import { Formik } from 'formik';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import * as Yup from 'yup';

import { IDENTITY_KEY } from '../../../../hooks/useIdentity';
import { createIdentityNote } from '../../../../utils/backend-api/identity-notes';
import { TextField } from '../../../FormFields';

const INITIAL_VALUES = {
  noteContent: '',
};

const validationSchema = Yup.object().shape({
  noteContent: Yup.string().min(1, 'Note must have text').required('Required'),
});

const NotesForm = ({ identity }) => {
  const queryClient = useQueryClient();

  const handleSubmit = async (values, actions) => {
    try {
      const response = await createIdentityNote(identity.id, values.noteContent);
      if (response.success) {
        queryClient.invalidateQueries([IDENTITY_KEY, identity.id]);
        toast.success(`Created new note for ${identity.email}`);
        actions.resetForm();
      } else {
        toast.error('Failed to create new note');
      }
    } catch (e) {
      toast.error(`There was an error creating the note: ${e}`);
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={INITIAL_VALUES}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <Form noValidate onSubmit={formik.handleSubmit}>
          <label className="fw-bold text-muted text-small text-uppercase">Add a new note:</label>
          <TextField name="noteContent" className="form-control mb-2" />
          {formik.errors.noteContent && formik.touched.noteContent ? (
            <div className="text-small text-danger">{formik.errors.noteContent}</div>
          ) : null}
          <Button type="submit" disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}>
            Add Note
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default NotesForm;
