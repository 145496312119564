/* Identical file in Marketing & Reader. If you modify this file, make the same changes there,
and vice-versa, unless changes should be specific to this project, then remove this comment. */
import { useField } from 'formik';
import React, { useEffect, useState } from 'react';

const TextAreaField = (props) => {
  const { label, name, hint } = props;
  const { maxCharCount, onBlur, ...inputProps } = props;
  const [field, meta, helpers] = useField(name);
  const [charCount, setCharCount] = useState(field.value?.length || 0);

  useEffect(() => {
    if (maxCharCount) {
      setCharCount(field.value?.length || 0);
    }
  }, [field.value, maxCharCount]);

  const handleBlur = () => (onBlur ? onBlur() : helpers.setTouched(true));

  return (
    <div className="form-group">
      {label && (
        <label className="input-label" htmlFor={name}>
          {label}
        </label>
      )}
      {maxCharCount && (
        <div className={`max-limit ${meta.touched && meta.error ? '--red' : ''}`}>
          ({charCount}/{maxCharCount})
        </div>
      )}
      <textarea
        className={`form-control${meta.touched && meta.error ? ' is-invalid' : ''}`}
        id={name}
        {...inputProps}
        {...field}
        onBlur={handleBlur}
      />
      {hint && <div className="text-muted m-0 p-1 text-small">{hint}</div>}
      {meta.touched && meta.error && <div className="invalid-feedback">{meta.error}</div>}
    </div>
  );
};

export default TextAreaField;
