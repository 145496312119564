import { useQuery } from 'react-query';

import { NavItem, getNavItems } from '@utils/backend-api/nav_items';

export default function useNavItems() {
  const NAV_ITEMS_KEY = 'navItems';

  const { data: navItems, isLoading: isLoadingNavItems } = useQuery<NavItem[]>(
    NAV_ITEMS_KEY,
    async () => await getNavItems()
  );

  return { navItems, isLoadingNavItems };
}
