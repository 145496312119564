import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import './ConfirmationModal.scss';

const ConfirmationModal = ({
  buttonMessage,
  close,
  confirmationMessage,
  onConfirmation,
  show,
  size,
}) => {
  const handleConfirmation = () => {
    onConfirmation();
    close();
  };
  return (
    <Modal className="ConfirmationModal" show={show} onHide={close} size={size || 'md'}>
      <Modal.Body className="p-4">
        {confirmationMessage || 'Are you sure you want to continue?'}
      </Modal.Body>
      <Modal.Footer>
        <Button className="--white" onClick={close}>
          Cancel
        </Button>
        <Button className="--black" onClick={handleConfirmation}>
          {buttonMessage || 'Confirm'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
