import { getRecords } from './universal';

export interface NavItem {
  id: number;
  name: string;
  path: string;
  section: string;
  order: number;
  projectId: number;
  active: boolean;
}

export const getNavItems = async (): Promise<NavItem[]> => {
  const navItemsResponse = await getRecords({
    model: 'NavItem',
    attributes: ['id', 'name', 'path', 'section', 'order', 'projectId', 'active'],
    order: { order: 'asc' },
  });
  const navItems = navItemsResponse.result;
  return navItems as NavItem[];
};
