import { useQuery } from 'react-query';

import { Offer, getOffers } from '../utils/backend-api/offers';

export const OFFERS_KEY = 'offers';

export default function useOffers() {
  const { data: offers, isLoading: isLoadingOffers } = useQuery<Offer[]>(
    OFFERS_KEY,
    async () => await getOffers()
  );

  return { offers, isLoadingOffers };
}
