import { useQuery } from 'react-query';

import { CannedMessage, getCannedMessages } from '../utils/backend-api/canned_messages';

export const CANNED_MESSAGES_KEY = 'canned_messages';

export default function useCannedMessages() {
  const { data: cannedMessages, isLoading: isLoadingCannedMessages } = useQuery<CannedMessage[]>(
    CANNED_MESSAGES_KEY,
    async () => await getCannedMessages()
  );

  return { cannedMessages, isLoadingCannedMessages };
}
