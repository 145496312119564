import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useAdminContext } from '../../../../contexts/AdminContext';
import { useAdministrator, useSubscribedAdministratorLists } from '../../../../hooks';
import { crm_new_administrator_list_path } from '../../../../routes';
import AdministratorListItem from '../../SideNav/AdministratorListItem';
import styles from '../styles.module.css';

const AdministratorListsIndex = () => {
  const { currentAdminId } = useAdminContext();
  const { administrator: currentAdmin, isLoadingAdministrator } = useAdministrator(currentAdminId);
  const {
    administratorLists: subscribedAdministratorLists,
    isLoadingAdministratorLists: isLoadingSubscribedAdministratorLists,
  } = useSubscribedAdministratorLists('subscribed');
  const {
    administratorLists: unsubscribedAdministratorLists,
    isLoadingAdministratorLists: isLoadingUnsubscribedAdministratorLists,
  } = useSubscribedAdministratorLists('unsubscribed');
  const [filterText, setFilterText] = useState('');
  const filterRegex = new RegExp(filterText, 'i');

  return (
    <div className="me-4">
      <Row>
        <Col>
          <div className={styles.header}>
            <h2>Manage Lists</h2>
            <Link className="mt-1" to={crm_new_administrator_list_path()}>
              + Create List
            </Link>
          </div>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <input
            type="text"
            value={filterText}
            onChange={(e) => setFilterText(e.currentTarget.value)}
            placeholder="Filter Lists"
          ></input>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4 className={styles.secondaryHeader}>Subscribed Lists</h4>
          {isLoadingSubscribedAdministratorLists || isLoadingAdministrator ? (
            'Loading...'
          ) : (
            <ul className="list-unstyled" id="my-lists-main">
              {subscribedAdministratorLists
                .filter((al) => al.listName.match(filterRegex))
                .map((administratorList) => (
                  <AdministratorListItem
                    administratorList={administratorList}
                    currentAdmin={currentAdmin}
                    isSubscribed={true}
                    key={administratorList.id}
                  />
                ))}
            </ul>
          )}
        </Col>
        <Col>
          <h4 className={styles.secondaryHeader}>Unsubscribed Lists</h4>
          {isLoadingUnsubscribedAdministratorLists || isLoadingAdministrator ? (
            'Loading...'
          ) : (
            <ul className="list-unstyled" id="other-lists">
              {unsubscribedAdministratorLists
                .filter((al) => al.listName.match(filterRegex))
                .map((administratorList) => (
                  <AdministratorListItem
                    administratorList={administratorList}
                    currentAdmin={currentAdmin}
                    isSubscribed={false}
                    key={administratorList.id}
                  />
                ))}
            </ul>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AdministratorListsIndex;
