import { useQuery } from 'react-query';

import { Talk, getTalkBySlug } from '../utils/backend-api/talks';

export const TALK_KEY = 'talk';

export default function useTalk(slug: string) {
  const { data: talk, isLoading: isLoadingTalk } = useQuery<Talk>(TALK_KEY, () =>
    getTalkBySlug(slug)
  );

  return { talk, isLoadingTalk };
}
