import { Formik } from 'formik';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { useAdScheduleDelete, useAdScheduleUpdate, useAdvertisement } from '../../hooks';
import { edit_advertisements_path } from '../../routes';
import { CheckBoxGroup, DateTimeField } from '../FormFields';
import adScheduleSchema from './adScheduleSchema';

const EditAdSchedules = () => {
  const { id: advertisementId } = useParams();
  const { advertisement, isLoadingAdvertisement, isFetching, refetch } =
    useAdvertisement(advertisementId);

  const { mutateAsync: updateAdSchedule } = useAdScheduleUpdate();
  const { mutateAsync: deleteAdSchedule } = useAdScheduleDelete();

  const [adSchedulesFormValues, setAdSchedulesFormValues] = useState([]);
  const [formattedSectionOptions, setFormattedSectionOptions] = useState([]);

  useEffect(() => {
    if (!isLoadingAdvertisement && !isFetching) {
      setAdSchedulesFormValues(() =>
        advertisement.scheduleGroups.map((scheduleGroup) => {
          const { endDate, sections, startDate } = scheduleGroup;
          return {
            adScheduleIds: sections.map((adSchedule) => adSchedule.id),
            sections: sections.map((adSchedule) => adSchedule.section),
            startDate: moment(startDate).format('ll'),
            endDate: moment(endDate).format('ll'),
          };
        })
      );
      setFormattedSectionOptions(advertisement.formattedSectionOptions);
    }
  }, [isLoadingAdvertisement, isFetching]);

  const handleDeleteSchedule = async (ids) => {
    for (const id of ids) {
      await deleteAdSchedule(id, {
        onSuccess: () => {
          refetch();
        },
      });
    }
  };

  const handleSubmit = async (values) => {
    const sanitizedParams = {
      advertisementId,
      ...values,
    };
    await updateAdSchedule(sanitizedParams, {
      onSuccess: () => {
        setAdSchedulesFormValues([]);
        refetch();
      },
    });
  };

  if (isLoadingAdvertisement) return null;
  return (
    <Container>
      <Row className="my-4">
        <Col>
          <h1>
            Edit Schedules for Ad #{advertisementId} for {advertisement.sponsorName}
          </h1>
        </Col>
      </Row>
      <Row>
        <Link to={edit_advertisements_path(advertisementId)} className="text-decoration-underline">
          Return to Ad Details here
        </Link>
      </Row>
      {adSchedulesFormValues.map((adSchedulesFormValue, idx) => (
        <Fragment key={idx}>
          <Formik
            initialValues={adSchedulesFormValue}
            onSubmit={handleSubmit}
            validationSchema={adScheduleSchema}
            enableReinitialize
          >
            {(formik) => (
              <Form className="AdScheduleForm" noValidate onSubmit={formik.handleSubmit}>
                <Row className="align-items-center justify-content-start mt-5">
                  <Col xs={3}>
                    <DateTimeField
                      dateFormat={'MMM dd yyyy'}
                      hideClearButton
                      label="Start Date"
                      maxDaysOut={90}
                      name="startDate"
                      showTimeSelect={false}
                    />
                  </Col>
                  <Col xs={3}>
                    <DateTimeField
                      dateFormat={'MMM dd yyyy'}
                      hideClearButton
                      label="End Date"
                      maxDaysOut={90}
                      name="endDate"
                      showTimeSelect={false}
                    />
                  </Col>
                  <Col xs={2} className="mb-2">
                    <Button variant="success" disabled={formik.isSubmitting} type="submit">
                      Save
                    </Button>
                  </Col>
                  <Col xs={2} className="mb-2">
                    <Button
                      variant="outline-danger"
                      onClick={() => handleDeleteSchedule(adSchedulesFormValue.adScheduleIds)}
                    >
                      Delete
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Row>
                    <label className="input-label mb-1 fs-4" htmlFor={`sections`}>
                      Email sections
                    </label>
                    {formattedSectionOptions.email.map((group, idx) => (
                      <Col xs={3} className="mb-3" key={idx}>
                        <CheckBoxGroup name="sections" options={group} />
                      </Col>
                    ))}
                  </Row>
                  <Row>
                    <label className="input-label mb-1 fs-4" htmlFor={`sections`}>
                      On site sections
                    </label>
                    {formattedSectionOptions.onsite.map((group, idx) => (
                      <Col xs={3} className="mb-3" key={idx}>
                        <CheckBoxGroup name="sections" options={group} />
                      </Col>
                    ))}
                  </Row>
                </Row>
              </Form>
            )}
          </Formik>
          <hr />
        </Fragment>
      ))}
    </Container>
  );
};

export default EditAdSchedules;
