import React from 'react';
import { Link } from 'react-router-dom';

import { distribution_list_path } from '../../routes';
import headerStyles from '../styles.module.css';
import DistributionEventsTable from './DistributionEventsTable';

const DistributionEvents = () => {
  const articleId = new URLSearchParams(location.search).get('article_id');
  return (
    <div className="container-fluid" id="DistributionList">
      <div className="row">
        <div className="col-md-12">
          <div className={headerStyles.headerBtnContainer}>
            <Link to={distribution_list_path()}>Distribution List</Link>
          </div>
          <div className={headerStyles.header}>
            <h2>Distribution Events</h2>
            <ul className={headerStyles.filterList}></ul>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <DistributionEventsTable articleId={articleId} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DistributionEvents;
