import { api_react_v1_update_project_path } from '../../routes';
import { AutocompleteOption, AutocompleteRecord } from '../interfaces';
import { NavItem } from './nav_items';
import request from './request';
import { getRecord, getRecords } from './universal';

const ACCESS_LEVEL_ALL = 'All';
const ACCESS_LEVEL_LEADS_SUBSCRIBERS = 'Leads + Subscribers';
const ACCESS_LEVEL_ALL_SUBSCRIBERS = 'All Subscribers';
const ACCESS_LEVEL_ANNUAL_PRO_SUBSCRIBERS = 'Annual + Pro Subscribers';
const ACCESS_LEVEL_PRO_SUBSCRIBERS = 'Pro Subscribers';

// the order here aligns to the order in the database
export const ACCESS_LEVEL_LABELS = [
  ACCESS_LEVEL_ALL,
  ACCESS_LEVEL_LEADS_SUBSCRIBERS,
  ACCESS_LEVEL_ALL_SUBSCRIBERS,
  ACCESS_LEVEL_ANNUAL_PRO_SUBSCRIBERS,
  ACCESS_LEVEL_PRO_SUBSCRIBERS,
];
export const ACCESS_LEVEL_OPTIONS = ACCESS_LEVEL_LABELS.map((label, value) => ({ label, value }));

const MAX_PROJECTS_PER_PAGE = 25;

const THEME_DATABASE = 'Database';
const THEME_DEFAULT = 'Default';

export const THEME_MAP = {
  [THEME_DEFAULT.toLowerCase()]: THEME_DEFAULT,
  [THEME_DATABASE.toLowerCase()]: THEME_DATABASE,
};
export const THEME_OPTIONS = Object.entries(THEME_MAP).map(([value, label]) => ({ label, value }));

interface JsonLdData {
  alternateName: string[];
  description: string;
  email: string;
  keywords: string[];
  name: string;
  spatialCoverage: string;
  temporalCoverage: string;
}

interface JsonLdFormData extends Omit<JsonLdData, 'alternateName' | 'keywords'> {
  alternateName: AutocompleteOption[];
  keywords: AutocompleteOption[];
}

export interface Project {
  accessLevel: number;
  article: {
    id: number;
    title: string;
  };
  byline: string;
  csvColumns: string;
  description: string;
  desktopImageUrl: string;
  googleSheetId: string;
  id: number;
  jsonLdData: JsonLdData;
  metaDescription: string;
  metaImageUrl: string;
  metaTitle: string;
  mobileImageUrl: string;
  notes: string;
  partnerUnlockCode: string;
  projectMedia: ProjectMediaValues[];
  publishedAt: string;
  slug: string;
  theme: string;
  title: string;
  titleCallout: string;
  leadType: string;
}

export interface ProjectMediaValues {
  elementId: string;
  height: number;
  id: string;
  layout: AutocompleteOption;
  mediaType: AutocompleteOption;
  order: number;
  src: string;
  title: string;
}

export enum FormActions {
  PublishNow = 'Publish now',
  Save = 'Save',
  Unpublish = 'Unpublish',
}

export enum PublishStatus {
  Published = 'published',
  Embargoed = 'embargoed',
  Unpublished = 'unpublished',
}

export const mediaTypeOptions = [
  { label: 'Iframe', value: 'iframe' },
  { label: 'Image', value: 'image' },
];

export const layoutTypeOptions = [
  { label: 'Standard', value: 'standard' },
  { label: 'Full width', value: 'full_width' },
];

export const navSectionOptions = [
  { label: 'Data tools', value: 'data_tools' },
  { label: 'Special', value: 'special' },
];

export interface ProjectFormValues
  extends Omit<Project, 'accessLevel' | 'article' | 'id' | 'jsonLdData' | 'theme' | 'navSection'> {
  accessLevel: AutocompleteRecord;
  action: FormActions;
  article: AutocompleteOption;
  jsonLdData: JsonLdFormData;
  navSection: AutocompleteOption;
  navName: string;
  navOrder: number;
  navActive: boolean;
  navItems: NavItem[];
  theme: AutocompleteRecord;
}

export interface PaginatedProjects {
  result: Project[];
  nextPage: number;
}

export const getProject = async (projectId: number | string): Promise<Project> => {
  const resp = await getRecord({
    model: 'Project',
    id: projectId,
    attributes: [
      'accessLevel',
      'byline',
      'csvColumns',
      'description',
      'googleSheetId',
      'id',
      'metaDescription',
      'metaTitle',
      'partnerUnlockCode',
      'publishedAt',
      'slug',
      'theme',
      'title',
      'titleCallout',
      'notes',
      'leadType',
    ],
    helpers: ['desktopImageUrl', 'metaImageUrl', 'mobileImageUrl'],
    relations: {
      jsonLdData: {
        attributes: [
          'alternateName',
          'description',
          'email',
          'keywords',
          'name',
          'spatialCoverage',
          'temporalCoverage',
        ],
      },
      article: {
        attributes: ['id', 'title'],
      },
      projectMedia: {
        attributes: ['element_id', 'height', 'id', 'layout', 'media_type', 'order', 'src', 'title'],
      },
    },
  });
  return resp.result;
};

export const getPaginatedProjects = async (page: number): Promise<PaginatedProjects> =>
  await getRecords({
    model: 'Project',
    attributes: ['accessLevel', 'id', 'title'],
    helpers: ['desktopImageUrl', 'metaImageUrl', 'mobileImageUrl'],
    order: { published_at: 'desc', updated_at: 'desc', title: 'asc' },
    page,
    maxPerPage: MAX_PROJECTS_PER_PAGE,
  });

export async function updateProject(values: FormData) {
  const resp = await request('put', api_react_v1_update_project_path(), values, true);
  return resp;
}
