import { useFormikContext } from 'formik';
import debounce from 'lodash/debounce';
import moment from 'moment';
import React, { useCallback, useState } from 'react';

const AutoSave = ({ debounceMs, showLastSaveMessage = false }) => {
  const formik = useFormikContext();
  const [lastSaved, setLastSaved] = useState();
  const debouncedSubmit = useCallback(
    debounce(
      () => formik.submitForm().then(() => setLastSaved(moment().format('lll'))),
      debounceMs
    ),
    [debounceMs, formik.submitForm]
  );

  React.useEffect(() => {
    debouncedSubmit();
  }, [debouncedSubmit, formik.values]);

  return (
    <>
      {!showLastSaveMessage
        ? null
        : formik.isSubmitting
        ? 'Saving...'
        : lastSaved !== null
        ? `Last Saved: ${lastSaved}`
        : null}
    </>
  );
};

export default AutoSave;
