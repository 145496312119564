import { useField } from 'formik';
import React from 'react';

const IntegerField = (props) => {
  const { label, name } = props;
  const [field, meta] = useField(props);
  return (
    <div className="IntegerField form-group">
      {label && (
        <label className="input-label" htmlFor={name}>
          {label}
        </label>
      )}
      <input
        type="number"
        className={`form-control ${meta.touched && meta.error ? 'is-invalid' : ''}`}
        id={name}
        {...field}
        {...props}
      />
      {meta.touched && meta.error && <div className="invalid-feedback">{meta.error}</div>}
    </div>
  );
};

export default IntegerField;
