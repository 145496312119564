import moment from 'moment';
import React, { useState } from 'react';
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import {
  distribution_edit_linkedin_ad_path,
  distribution_new_linkedin_campaign_path,
} from '../../../../routes';
import {
  checkLinkedinAuth,
  getLinkedinAdList,
  linkedinActivateCampaign,
} from '../../../../utils/backend-api/linkedin';
import './LinkedinAdList.scss';

const LinkedinAdList = () => {
  const { articleId } = useParams();
  const navigate = useNavigate();
  const [linkdinAuth, setLinkedinAuth] = useState(true);
  const { isAuthLoading } = useQuery(
    'LinkedinAuth',
    async () => await checkLinkedinAuth(articleId),
    {
      cacheTime: 1000,
      onSuccess: ({ isAuthenticated }) => {
        setLinkedinAuth(isAuthenticated);
      },
    }
  );
  const { data, isLoading } = useQuery(
    'LinkedinAdList',
    async () => await getLinkedinAdList(articleId),
    {
      cacheTime: 1000,
    }
  );

  if (isLoading || isAuthLoading) return null;
  return (
    <Container className="LinkedinAdList">
      <Row>
        {!linkdinAuth ? (
          <Col className="linkedin-auth">
            <Button
              onClick={() => (window.location = '/auth/linkedin')}
              className="button btn --blue"
            >
              Add your Linkedin Account
            </Button>
          </Col>
        ) : (
          <Col>
            <Row>
              <Col md={6}>
                <h2>Campaign List</h2>
              </Col>
              <Col md={6} className="new-ad-btn">
                <Button
                  className="button btn --blue"
                  onClick={() => navigate(distribution_new_linkedin_campaign_path(articleId))}
                >
                  Start New Linkedin Campaign
                </Button>
              </Col>
            </Row>
            <Table className="LinkedinAd-table" striped bordered hover>
              <thead>
                <tr>
                  <th>Campaign Name</th>
                  <th>Start Date</th>
                  <th>Status</th>
                  <th>Action</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {data.map((ad) => (
                  <tr key={ad.id}>
                    <td>{ad.name}</td>
                    <td>{moment(ad.startAt).format('ll')}</td>
                    <td className={ad.status == 'active' ? 'active-status' : ''}>
                      {ad.status.charAt(0).toUpperCase() + ad.status.slice(1)}
                    </td>
                    <td>
                      {ad.status != 'active' && ad.status != 'activating' && (
                        <Button
                          className="button btn --blue"
                          onClick={() =>
                            navigate(distribution_edit_linkedin_ad_path(articleId, ad.id))
                          }
                        >
                          Edit
                        </Button>
                      )}
                      {ad.adPreviewLink && (ad.status === 'active' || ad.status === 'updated') && (
                        <a href={ad.adPreviewLink} className="button btn --blue">
                          Prview Ad
                        </a>
                      )}
                    </td>
                    <td>{moment(ad.createdAt).format('ll')}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <hr></hr>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default LinkedinAdList;
