import clsx from 'clsx';
import { Formik } from 'formik';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import ReactHtmlParser from 'react-html-parser';
import { useQueryClient } from 'react-query';
import * as Yup from 'yup';

import { useAdminContext } from '../../../../contexts/AdminContext';
import { useAdministrator, useOffers } from '../../../../hooks';
import useCannedMessages from '../../../../hooks/useCannedMessages';
import { IDENTITY_KEY } from '../../../../hooks/useIdentity';
import { autocompleteEmailSuggestions } from '../../../../utils/backend-api/email-suggestions';
import { sendEmailToIdentities } from '../../../../utils/backend-api/identities';
import { firstName } from '../../../../utils/helpers';
import { AutocompleteField, DropdownField, TextAreaField, TextField } from '../../../FormFields';
import styles from './styles.module.css';

const validationSchema = Yup.object().shape({
  from: Yup.object({
    value: Yup.string().min(1, 'Subject must have from email').required('From Email Required'),
    label: Yup.string().min(1, 'Subject must have from email').required('From Email Required'),
  }),
  emailSubject: Yup.string().min(1, 'Subject must have text').required('Required'),
  emailBody: Yup.string().min(1, 'Body must have text').required('Required'),
});

const EmailForm = ({ identities }) => {
  const { offers } = useOffers();
  const { cannedMessages } = useCannedMessages();
  const { currentAdminId } = useAdminContext();
  const { administrator, isLoadingAdministrator } = useAdministrator(currentAdminId);
  const queryClient = useQueryClient();

  const getOfferTextFor = (offer) => {
    const offerUrl =
      offer.offerType === 'subscription'
        ? `https://www.theinformation.com/subscribe/promotion?offer=${offer.code}`
        : `https://www.theinformation.com/upgrade/promotion?offer=${offer.code}`;
    const offerBody = offer.emailBody
      ? `${offer.emailBody}\n\n${offerUrl}`
      : `Use this link to get your ${offer.name} offer: ${offerUrl}`;

    return `${offerBody}\n\nBest, ${firstName(administrator.user.name)}`;
  };

  const implementEmailSuggestion = (values, setFieldValue) => {
    const [type, id] = values.email_suggestion.value.split('#');
    switch (type) {
      case 'offer': {
        const offer = offers.filter((offer) => `${offer.id}` === id)[0];
        if (offer) {
          setFieldValue('emailSubject', offer.emailSubject || offer.header);
          setFieldValue('emailBody', getOfferTextFor(offer));
        } else {
          toast.error('Could not find that Offer.');
        }
        break;
      }
      case 'canned': {
        const cannedMessage = cannedMessages.filter(
          (cannedMessage) => `${cannedMessage.id}` === id
        )[0];
        if (cannedMessage) {
          setFieldValue('emailSubject', cannedMessage.subject);
          setFieldValue('emailBody', cannedMessage.body);
        } else {
          toast.error('Could not find that Canned Message.');
        }
        break;
      }
    }
  };

  const emailList = () => {
    return identities.map((i) => i.email).join(', ');
  };

  const fromOptions = () => {
    const defaultOptions = [
      {
        label: 'The Information <team@theinformation.com>',
        value: 'The Information <team@theinformation.com>',
      },
      {
        label: 'The Information <hello@theinformation.com>',
        value: 'The Information <hello@theinformation.com>',
      },
      {
        label: 'TI Admin <admin@theinformation.com>',
        value: 'TI Admin <admin@theinformation.com>',
      },
      {
        label: 'Matthew at The Information <MatthewM@theinformation.com>',
        value: 'Matthew at The Information <MatthewM@theinformation.com>',
      },
    ];
    const administratorEmail = `${administrator.user.name} <${administrator.email}>`;
    return administrator.email.match(/@theinformation\.com/)
      ? [{ label: administratorEmail, value: administratorEmail }, ...defaultOptions]
      : defaultOptions;
  };

  const handleSubmit = async (values, actions) => {
    try {
      const response = await sendEmailToIdentities({
        from: values.from.value,
        emailSubject: values.emailSubject,
        emailBody: values.emailBody,
        identityIds: identities.map((i) => i.id),
        offerId: values.offer.value,
      });
      if (response) {
        toast.success(`Email sent to ${emailList()}`);
        actions.resetForm();
        queryClient.invalidateQueries([IDENTITY_KEY, identities[0].id]);
      } else {
        toast.error('Failed to send email');
      }
    } catch (e) {
      toast.error(`There was an error sending the email: ${e}`);
    }
  };

  if (isLoadingAdministrator) {
    return <div>Loading...</div>;
  }

  const initialValues = {
    emailSubject: '',
    emailBody: '',
    offer: '',
    from: fromOptions()[0],
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <Form noValidate onSubmit={formik.handleSubmit}>
          <label className="fw-bold text-muted text-small text-uppercase">From</label>
          <DropdownField name="from" className="mb-2" options={fromOptions()} />
          <label className="fw-bold text-muted text-small text-uppercase">
            To (Sent individually to each person)
          </label>
          <input
            type="text"
            id="email-form-to"
            disabled
            value={emailList()}
            className="form-control mb-2"
          />
          <label className="fw-bold text-muted text-small text-uppercase">
            Canned Message (optional)
          </label>
          <AutocompleteField
            placeholder="Select an offer to add"
            name="email_suggestion"
            isCreatable={false}
            fetchMethod={(value) =>
              autocompleteEmailSuggestions({
                fragment: value,
              })
            }
            formatOptionLabel={(data) => {
              return <span>{ReactHtmlParser(data.label)}</span>;
            }}
            onBlur={() => implementEmailSuggestion(formik.values, formik.setFieldValue)}
          />
          <label className="fw-bold text-muted text-small text-uppercase">Subject</label>
          <TextField name="emailSubject" className="form-control mb-2" />
          <label className="fw-bold text-muted text-small text-uppercase">Body</label>
          <textarea
            className={clsx(styles.readonlyTextArea, 'form-control')}
            disabled
            value="Hi {firstName}"
          />
          <TextAreaField className={clsx(styles.textAreaBody, 'form-control')} name="emailBody" />
          <Button type="submit" disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}>
            Send Email
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default EmailForm;
