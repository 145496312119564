import { useQuery } from 'react-query';

import { getDataImport, getDataImports } from '../utils/backend-api/identities-import';
import { DataImport } from '../utils/backend-api/models/dataImport';

export const CRM_DATA_IMPORTS_KEY = 'crmDataImports';

export const useCRMDataImports = () => {
  const { data: dataImportsData, isLoading: isLoadingDataImports } = useQuery<DataImport[]>(
    [CRM_DATA_IMPORTS_KEY],
    () => getDataImports()
  );
  return { dataImportsData, isLoadingDataImports };
};

export const useCRMDataImport = (id) => {
  const { data: dataImportData, isLoading: isLoadingDataImport } = useQuery<DataImport>(
    [CRM_DATA_IMPORTS_KEY, id],
    () => getDataImport(id),
    {
      enabled: !!id,
    }
  );
  return { dataImportData, isLoadingDataImport };
};
