import debounce from 'debounce-promise';
import { useField } from 'formik';
import React from 'react';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';

import closeIcon from '../../images/x_icon.svg';

import { capitalize } from '../../utils/helpers';
import Pill from '../BaseUI/Pill';
import './MultiValueAutocompleteField.scss';

const DEBOUNCE_MS = 500;

export default function MultiValueAutocompleteField(props) {
  const {
    createMethod,
    fetchMethod,
    inputLengthFetchThreshold = 2,
    isCreatable = false,
    label,
    name,
    options,
    onChange,
    showPills = false,
    valueIsInteger,
  } = props;
  const [field, meta, helpers] = useField(props);
  const SelectFieldComponent = isCreatable ? AsyncCreatableSelect : AsyncSelect;

  const asyncLoadOptions = async (value) => {
    let loadedOptions = options || [];
    if (value.length >= inputLengthFetchThreshold) {
      // if fetchMethod is present, use it, otherwise, just look at the options list
      loadedOptions = fetchMethod
        ? await fetchMethod(value)
        : options.filter((o) => o.label.toLowerCase().includes(value.toLowerCase()));
    }
    return loadedOptions.map((option) => ({
      label: option?.label || option,
      value: option?.value || option,
    }));
  };

  const debounceLoadOptions = debounce((value) => asyncLoadOptions(value), DEBOUNCE_MS, {
    leading: true,
  });

  const handleChange = (value) => {
    helpers.setValue(
      value || {
        label: '',
        value: '',
      }
    );
    if (onChange) onChange();
  };

  const onCreateOption = async (value) => {
    const result = await createMethod(value);
    handleChange([...field.value, { label: value, value: valueIsInteger ? result.id : value }]);
  };

  const handleRemoveValue = (option) => {
    if (props.isDisabled) return;
    const list = field.value.filter((val) => val.value !== option.value);
    helpers.setValue(list);
  };

  return (
    <div
      className={`MultiValueAutocompleteField form-group ${
        meta.touched && meta.error ? 'is-invalid' : ''
      }`}
    >
      {label && (
        <label className="input-label" htmlFor={name}>
          {label}
        </label>
      )}
      <div className="input-container">
        <SelectFieldComponent
          key={JSON.stringify(options)}
          {...props}
          options={options}
          className={meta.touched && meta.error ? 'is-invalid' : ''}
          classNamePrefix="react-select"
          onChange={handleChange}
          onBlur={helpers.setTouched}
          loadOptions={debounceLoadOptions}
          isClearable={true}
          isMulti={true}
          value={field.value}
          inputId={name}
          controlShouldRenderValue={!showPills}
          // defaultValue={field.value?.label && field.value}
          defaultOptions
          cacheOptions
          backspaceRemovesValue={false}
          onCreateOption={createMethod && onCreateOption}
        />
        {meta.touched && meta.error && <div className="invalid-feedback">{meta.error}</div>}
      </div>
      {showPills && (
        <div className="pill-container">
          {field.value &&
            field.value.map((val, id) => (
              <Pill
                onClick={() => handleRemoveValue(val)}
                className="--neutral"
                /* capitalize first letter of each word (can't use lodash's startCase because it removes special characters) */
                text={capitalize(val.label)}
                icon={closeIcon}
                key={id}
              />
            ))}
        </div>
      )}
    </div>
  );
}
