import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';

import { useFilterContext } from '../../../contexts/FilterContext';
import { ADMINISTRATOR_LISTS_KEY } from '../../../hooks/useAdministratorLists';
import { crm_edit_administrator_list_path, crm_root_path } from '../../../routes';
import {
  subscribeToAdminList,
  unsubscribeFromAdminList,
} from '../../../utils/backend-api/administrator-list-roles';
import {
  USER_SCOPED_LIST_OPTION_VALUES,
  archiveAdministratorList,
} from '../../../utils/backend-api/administrator-lists';
import { pathToUrl } from '../../../utils/helpers';
import { CRM_SIDE_NAV_DATA_KEY } from './index';

const AdministratorListItem = ({ administratorList, currentAdmin, isSubscribed, forceShowEdit = false }) => {
  const { setAdministratorListFilter, setQueryEnabled } = useFilterContext();
  const isAdmin = administratorList.administratorId === currentAdmin.id;
  const isSubscribable = administratorList.listType == 'global_auto' || administratorList.isShared;
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const navToList = () => {
    if (location.pathname === '/crm') {
      setAdministratorListFilter(administratorList);
      setQueryEnabled(true)
    } else {
      navigate(crm_root_path({ administrator_list: administratorList.id }));
    }
  };

  const subscribeAdmin = async () => {
    const resp = await subscribeToAdminList(administratorList.id);
    if (resp.message === 'ok') {
      toast.success(`Added "${administratorList.listName}" to your preferred lists`);
      queryClient.invalidateQueries(CRM_SIDE_NAV_DATA_KEY);
      queryClient.invalidateQueries(ADMINISTRATOR_LISTS_KEY);
    } else {
      toast.error(resp.message);
    }
  };

  const unsubscribeAdmin = async () => {
    const resp = await unsubscribeFromAdminList(currentAdmin.id, administratorList.id);
    if (resp.message === 'ok') {
      toast.success(`Removed "${administratorList.listName}" from your preferred lists`);
      queryClient.invalidateQueries(CRM_SIDE_NAV_DATA_KEY);
      queryClient.invalidateQueries(ADMINISTRATOR_LISTS_KEY);
    } else {
      toast.error(resp.message);
    }
  };

  const archiveList = async () => {
    if (window.confirm('Are you sure you want to archive this list?')) {
      await archiveAdministratorList(administratorList.id);
      toast.success(`List ${administratorList.listName} has been archived`);
    }
  };

  return (
    <li
      className={`administrator-list-item ${
        isSubscribable && !isSubscribed ? 'not-subscribed' : ''
      }`}
      id={`administrator-list-${administratorList.id}`}
    >
      {isSubscribed ? (
        <a
          href="#"
          onClick={unsubscribeAdmin}
          className="me-2"
          style={{ color: administratorList.color }}
        >
          <i className="fa-solid fa-heart"></i>
        </a>
      ) : (
        <a
          href="#"
          onClick={subscribeAdmin}
          className="me-2"
          style={{ color: administratorList.color }}
        >
          <i className="fa-regular fa-heart"></i>
        </a>
      )}
      <span>
        {['twitter_followers', 'twitter_friends'].includes(administratorList.listType) ? (
          <i
            style={{ color: administratorList.color }}
            className="fa-brands fa-twitter me-2"
            title={administratorList.listType}
          ></i>
        ) : administratorList.listType === 'manual' ? (
          <i
            style={{ color: administratorList.color }}
            className="fa-solid fa-person-digging me-2"
            title={administratorList.list_type}
          ></i>
        ) : (
          <i
            style={{ color: administratorList.color }}
            className="fa-solid fa-robot me-2"
            title={administratorList.listType}
          ></i>
        )}
      </span>

      <span className="name">
        <button className="btn btn-sm btn-plain align-baseline" onClick={navToList}>
          {administratorList.listName}
        </button>
      </span>
      <span className="list-count ms-2">({administratorList.identityCount})</span>
      {(isAdmin && USER_SCOPED_LIST_OPTION_VALUES.includes(administratorList.listType) || forceShowEdit) && (
        <>
          <span className="edit-link ms-2">
            - <Link to={crm_edit_administrator_list_path(administratorList)}>edit</Link>
          </span>
          <span className="edit-link ms-2">
            -{' '}
            <a href="#" onClick={archiveList}>
              archive
            </a>
          </span>
        </>
      )}
      <span className="edit-link ms-2">
        -{' '}
        <CopyToClipboard
          text={pathToUrl(crm_root_path({ administrator_list: administratorList.id }))}
          onCopy={() => toast.success(`${administratorList.listName} link copied to clipboard`)}
        >
          <button className="btn-plain m-0 h-auto">link</button>
        </CopyToClipboard>
      </span>
    </li>
  );
};
export default AdministratorListItem;
