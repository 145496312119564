import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { crm_administrator_lists_path } from '@routes';
import { USER_MANUAL_LIST_TYPE_OPTION } from '@utils/backend-api/administrator-lists';
import { SUBSCRIPTION_STATUS_CANCELED, SUBSCRIPTION_STATUS_LEAD } from '@utils/backend-api/users';

import AdminstratorListForm from '../Form';
import styles from '../styles.module.css';

const INITIAL_FORM_STATE = {
  accessLevels: [],
  entities: [],
  listName: '',
  listDescription: '',
  listType: USER_MANUAL_LIST_TYPE_OPTION,
  isShared: false,
  query: '',
  isArchived: false,
  entityJoinTypesViewedArticles: true,
  entityJoinTypesFollows: true,
  entityJoinTypesEmployedBy: true,
  entityJoinTypesProfile: true,
  personalAttributesCompanyIndustry: [],
  personalAttributesCompanyName: [],
  personalAttributesGender: [],
  personalAttributesLocation: [],
  personalAttributesJobLevel: [],
  personalAttributesJobRole: [],
  noUser: false,
  subscriptionStatuses: [SUBSCRIPTION_STATUS_LEAD, SUBSCRIPTION_STATUS_CANCELED],
};

const NewAdministratorList = () => {
  return (
    <Row>
      <Col className="me-4">
        <div className={styles.header}>
          <h2>Create New List</h2>
          <Link className="mt-1" to={crm_administrator_lists_path()}>
            Manage Lists
          </Link>
        </div>
        <AdminstratorListForm initialValues={INITIAL_FORM_STATE} canEditArticle={true} />
      </Col>
    </Row>
  );
};

export default NewAdministratorList;
