import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { LinkedinAdAccount, getLinkedinAdAccounts } from '../utils/backend-api/linkedin';

export const LINKEDIN_AD_ACCOUNTS_KEY = 'LinkedinAdAccounts';

const formatLinkedinAdAccountOptions = (LinkedinAdAccounts: LinkedinAdAccount[]) => {
  return LinkedinAdAccounts.map((account) => ({
    label: account.name,
    value: account.id,
  }));
};

export default function useLinkedinAdAccounts() {
  const { data: linkedinAdAccounts, isLoading: isLoadingLinkedinAdAccounts } = useQuery<
    LinkedinAdAccount[]
  >(LINKEDIN_AD_ACCOUNTS_KEY, getLinkedinAdAccounts);

  const linkedinAdAccountsOptions = useMemo(() => {
    if (linkedinAdAccounts) return formatLinkedinAdAccountOptions(linkedinAdAccounts);
  }, [linkedinAdAccounts]);

  return { linkedinAdAccountsOptions, linkedinAdAccounts, isLoadingLinkedinAdAccounts };
}
