import React, { useCallback, useRef, useState } from 'react';

import Link from '@images/tiptap-icons/link-m.svg';

import { BubbleMenu } from '@tiptap/react';

import './HighlightMenu.scss';

const HighlightMenu = ({ editor }) => {
  const [linkUrl, setLinkUrl] = useState('');
  const linkRef = useRef(null);

  const setLink = useCallback(() => {
    if (linkUrl === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: linkUrl }).run();
    setLinkUrl('');
  }, [editor, linkUrl]);

  const onInputChange = useCallback(() => {
    setLinkUrl(linkRef.current.value);
  }, [linkRef]);

  if (!editor) {
    return null;
  }

  return (
    <BubbleMenu editor={editor} tippyOptions={{ delay: 500, duration: 100 }}>
      <div className="tiptap__highlight-menu">
        <label htmlFor="add_link">URL:</label>
        <input name="add_link" type="text" value={linkUrl} ref={linkRef} onChange={onInputChange} />
        <button
          type="button"
          onClick={setLink}
          className={editor.isActive('link') ? 'is-active' : ''}
        >
          <img
            src={Link as unknown as string}
            height="16"
            width="16"
            className="link-icon"
            title="Add Link"
          />
        </button>
      </div>
    </BubbleMenu>
  );
};

export default HighlightMenu;
