import clsx from 'clsx';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import useNewsletter from '../../../hooks/useNewsletter';
import { admin_newsletters_path } from '../../../routes';
import { CHOOSE_AUTHOR_OPTION } from './AuthorPicker';
import NewsletterBuilderForm from './NewsletterBuilderForm';
import styles from './styles.module.css';

const SHARED_INITIAL_VALUES = {
  active: true,
  afterSignupUrl: '',
  author: CHOOSE_AUTHOR_OPTION,
  autoSubscribe: false,
  autoSubscribeLead: false,
  cardImagePath: '',
  cardImageUrl: '',
  choosePlanText: '',
  customerioSegmentId: '',
  description: '',
  emailStyle: '',
  fromEmail: 'hello@theinformation.com',
  fromName: 'The Information',
  heroImagePhonePath: '',
  landingPageHeading: '',
  landingPageDescription: '',
  lang: 'en',
  logoImageUrl: '',
  name: '',
  order: 0,
  previewDays: 999999,
  readLatestPath: '',
  replyTo: 'hello@theinformation.com',
  rtsuPreviewDays: 7,
  stripeAnnualPriceId: '',
  stripeApiKeyName: '',
  stripeMonthlyPriceId: '',
  stripePublishableKeyName: '',
  theme: '',
};

const INITIAL_FREE_VALUES = {
  ...SHARED_INITIAL_VALUES,
  accessLevel: 0,
  frequency: 'Occasional',
  paid: false,
  thirdParty: false,
  tiBundled: true,
};

const INITIAL_PAID_VALUES = {
  ...SHARED_INITIAL_VALUES,
  accessLevel: 2,
  frequency: 'Daily',
  paid: true,
  thirdParty: true,
  tiBundled: false,
};

const NewsletterBuilder = () => {
  const { id: newsletterId } = useParams();
  const { newsletter, isLoadingNewsletter } = useNewsletter(parseInt(newsletterId));
  const [buildType, setBuildType] = useState(newsletterId ? 'edit' : '');

  return (
    <Container className="mt-5" id="dashboard-page">
      <Row>
        <Col>
          <div className={styles.header}>
            <h2>Newsletter Builder</h2>
            <Link className="mt-1" to={admin_newsletters_path()}>
              Newsletters Admin
            </Link>
          </div>
          {!newsletterId && (
            <div className={styles.builderChooserContainer}>
              <button
                className={clsx(
                  styles.builderChooserBtn,
                  buildType === 'free' ? styles.builderChooserBtnActive : null
                )}
                onClick={() => setBuildType('free')}
              >
                Free Newsletter
              </button>
              <span className={styles.builderChooserConjuction}>OR</span>
              <button
                className={clsx(
                  styles.builderChooserBtn,
                  buildType === 'paid' ? styles.builderChooserBtnActive : null
                )}
                onClick={() => setBuildType('paid')}
              >
                Paid Newsletter
              </button>
              <span className={styles.builderChooserConjuction}>OR</span>
              <button
                className={clsx(
                  styles.builderChooserBtn,
                  buildType === 'unopinionated' ? styles.builderChooserBtnActive : null
                )}
                onClick={() => setBuildType('unopinionated')}
              >
                Unopinionated Newsletter
              </button>
            </div>
          )}

          {buildType && !isLoadingNewsletter && (
            <NewsletterBuilderForm
              initialValues={
                buildType === 'free'
                  ? INITIAL_FREE_VALUES
                  : buildType === 'edit'
                  ? newsletter
                  : INITIAL_PAID_VALUES
              }
              buildType={buildType}
              newsletterId={parseInt(newsletterId)}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default NewsletterBuilder;
