import { AutocompleteOption } from '@utils/interfaces';

import {
  api_react_v1_destroy_landing_page_path,
  api_react_v1_update_landing_page_path,
} from '../../routes';
import request from './request';
import { getRecord, getRecords } from './universal';

const MAX_LANDING_PAGES_PER_PAGE = 25;

export interface LandingPage {
  advertisementId?: number;
  confirmationText: string;
  formButtonText?: string;
  formFields?: string[];
  formHeadline?: string;
  formVerificationText?: string;
  headline: string;
  id: number;
  leadType: string;
  metaDescription?: string;
  metaImageImgixUrl?: string;
  metaImageUrl?: string;
  metaNoindex: boolean;
  metaTitle?: string;
  name: string;
  newsletterId?: number;
  newsletter?: AutocompleteOption;
  newsletterName?: string;
  pageContentsHtml?: string;
  pageContentsJson?: object | string;
  publishedAt?: Date;
  slug: string;
  subheadline?: string;
}

export interface LandingPageIndex {
  advertisementSponsorName?: string;
  headline: string;
  name: string;
  newsletterName?: string;
  id: number;
  publishedAt?: Date;
}

export interface PaginatedLandingPages {
  result: LandingPageIndex[];
  nextPage: number;
}

export interface LandingPageSanitizedValues extends Omit<LandingPage, 'id' | 'metaImageImgixUrl'> {
  landingPageId?: number | string;
}

export const getLandingPage = async (LandingPageId: number | string): Promise<LandingPage> => {
  const resp = await getRecord({
    model: 'LandingPage',
    id: LandingPageId,
    attributes: [
      'advertisementId',
      'confirmationText',
      'formButtonText',
      'formFields',
      'formHeadline',
      'formVerificationText',
      'headline',
      'leadType',
      'metaDescription',
      'metaImageUrl',
      'metaNoindex',
      'metaTitle',
      'name',
      'newsletterId',
      'newsletterName',
      'pageContentsHtml',
      'pageContentsJson',
      'publishedAt',
      'slug',
      'subheadline',
    ],
    helpers: ['newsletterName', 'metaImageImgixUrl'],
  });
  return resp.result;
};

export const getPaginatedLandingPages = async (page: number): Promise<PaginatedLandingPages> =>
  await getRecords({
    model: 'LandingPage',
    attributes: ['id', 'headline', 'name', 'publishedAt'],
    helpers: ['newsletterName', 'advertisementSponsorName'],
    order: { createdAt: 'desc' },
    page,
    maxPerPage: MAX_LANDING_PAGES_PER_PAGE,
  });

export async function updateLandingPage(values: FormData) {
  const resp = await request('put', api_react_v1_update_landing_page_path(), values, true);
  return resp;
}

export async function destroyLandingPage(values: { id: number | string }) {
  const resp = await request('delete', api_react_v1_destroy_landing_page_path(), values, false);
  return resp;
}
