import { api_react_v1_universal_index_path, api_react_v1_universal_path } from '../../routes';
import request from './request';

/**
 * IMPORTANT NOTE: ALL ATTRIBUTES NEED TO BE ADDED TO THE WHITELIST ON THE UNIVERSAL CONTROLLER
 *
 */

interface RelationMethods {
  attributes?: string[];
  helpers?: string[];
  order?: { [key: string]: string };
}
interface ComparisonIdentifiers {
  comparison: string;
  value: string;
}
/**
 * examples for an Article
 * model: "Article"
 * attributes: ["title", "emailSubject", "publishOnWeb", ...]
 * relations: {
 *   "newsletter": { attributes: ["name", "description", ...], helpers: ["archive_url", ...] },
 *   ...
 * }
 * helpers: ["authorsAsSentence", ...]
 * classHelpers: ["shareableArticles", ...]
 */
interface GetParams {
  model: string;
  attributes?: string[];
  relations?: { [key: string]: RelationMethods };
  helpers?: string[];
  classHelpers?: string[];
}

/**
 * examples for an Article
 * id: 4
 * identifier: { slug: "some-article-slug" } // if used method, set id to 0 (because it's required)
 */
interface GetRecordParams extends GetParams {
  id: number | string;
  identifier?: { [key: string]: string | string[] }; // so can alternatively fetch by attributes like "slug"
}

export async function getRecord(options: GetRecordParams) {
  const { id, ...fetchOptions } = options;

  const resp = await request('get', api_react_v1_universal_path(id), fetchOptions);
  return resp;
}

/**
 * examples for Articles
 * identifiers: { publishOnWeb: true, ... }
 * relationIdentifiers: { authorUsers: { name: "Thor" }, ... }
 * comparisonIdentifiers: { publishedAt: { comparison: ">=", value: moment() }, endDate: ... }
 * subStringIdentifiers: { name: "Saving the Worl", ... }
 * order: { publishedAt: "desc", ... }
 * page: 1
 * maxPerPage: 25
 */
type Identifier = string | boolean | number | string[] | number[] | boolean[];
interface GetRecordsParams extends GetParams {
  identifiers?: { [key: string]: Identifier };
  relationIdentifiers?: { [key: string]: { [key: string]: Identifier } };
  comparisonIdentifiers?: { [key: string]: ComparisonIdentifiers };
  subStringIdentifiers?: { [key: string]: string };
  order?: { [key: string]: string };
  page?: number;
  maxPerPage?: number;
}

export async function getRecords(options: GetRecordsParams) {
  const resp = await request('get', api_react_v1_universal_index_path(), options);
  return resp;
}
