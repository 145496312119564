import {
  api_react_v1_autocomplete_identity_profile_field_options_path,
  api_react_v1_distribution_top_profile_values_path,
} from '../../routes';
import { AutocompleteOption } from '../interfaces';
import request from './request';
import { RecipientType } from './users';

export const IDENTITY_PROFILE_COMPANY_INDUSTRY = 'company_industry';
export const IDENTITY_PROFILE_COMPANY_NAME = 'company_name';
export const IDENTITY_PROFILE_JOB_LEVEL = 'job_level';
export const IDENTITY_PROFILE_JOB_ROLE = 'job_role';
export const IDENTITY_PROFILE_JOB_TITLE = 'job_title';

export type IdentityProfileField =
  | 'company_name'
  | 'company_industry'
  | 'job_level'
  | 'job_role'
  | 'job_title';

export interface AutocompleteParams {
  fragment: string;
  field: IdentityProfileField;
  pdlOnly?: boolean;
  maxResults?: number;
}

export interface TopProfileValues {
  [index: string]: { name: string; count: number }[];
}

export async function autocompleteFieldOptions(
  values: AutocompleteParams
): Promise<AutocompleteOption[]> {
  const resp = await request(
    'get',
    api_react_v1_autocomplete_identity_profile_field_options_path(values)
  );
  return resp?.field_options;
}

export async function recipientsTopProfileValuesForArticle(
  articleId: number | string,
  recipientType: RecipientType
): Promise<TopProfileValues> {
  return await request(
    'get',
    api_react_v1_distribution_top_profile_values_path({
      article_id: articleId,
      recipient_type: recipientType,
    })
  );
}
