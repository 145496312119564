import _ from 'lodash';
import moment from 'moment';
import React, { FC, useEffect } from 'react';

import { distribution_dashboard_path } from '../../routes';
import { AggregateArticleData } from '../../utils/backend-api/distribution-list';
import './DistributionList.scss';

interface SortByType {
  attr: string;
  desc: boolean;
}

interface DistributionPageTableProps {
  articles: AggregateArticleData[];
  title: string;
}

const showArrow = (currentAttribute: keyof AggregateArticleData, sortBy: SortByType) => {
  if (sortBy.attr === currentAttribute) {
    return <span style={{ fontSize: 10 }}>{sortBy.desc ? '▼' : '▲'}</span>;
  }
};

const DistributionPageTable: FC<DistributionPageTableProps> = ({ articles, title }) => {
  const [sortedArticles, setSortedArticles] = React.useState<AggregateArticleData[]>([]);
  const [sortBy, setSortBy] = React.useState<SortByType>({
    attr: 'new_subs',
    desc: true,
  });

  useEffect(() => {
    setSortedArticles(
      _.orderBy(
        articles,
        [
          (article) => {
            const sortAttr = article[sortBy.attr];

            if (_.isNumber(sortAttr)) {
              return sortAttr;
            } else if (_.isString(sortAttr)) {
              return _.lowerCase(sortAttr);
            } else {
              return sortAttr;
            }
          },
        ],
        sortBy.desc ? 'desc' : 'asc'
      )
    );
  }, [sortBy, articles]);

  const renderTh = (
    title: string,
    key: keyof AggregateArticleData,
    style: { [key: string]: string } = {}
  ) => (
    <th
      onClick={() => setSortBy({ attr: key, desc: sortBy.attr === key ? !sortBy.desc : false })}
      style={style}
    >
      {title} {showArrow(key, sortBy)}
    </th>
  );

  return (
    <>
      <table className="DistributionPageTable table table-crm">
        <thead>
          <tr>
            {renderTh('Title', 'title')}
            {renderTh('Pub Date', 'published_at')}
            {renderTh('Newsletter', 'newsletter_name')}
            {renderTh('Opens', 'email_opens')}
            {renderTh('Sub-Read', 'subscriber_reads')}
            {renderTh('New-Lead', 'leads')}
            {renderTh('New-Subs', 'new_subs')}
            {renderTh('New-Subs (today)', 'new_subs_today')}
            {renderTh('New-Subs (week)', 'new_subs_this_week')}
            {renderTh('New-Subs (month)', 'new_subs_this_month')}
          </tr>
        </thead>
        <tbody>
          {sortedArticles.map((article, i) => (
            <tr key={`${article.id}-${i}`}>
              <td>
                {title == 'Embargoed' ? (
                  article.title
                ) : (
                  <a href={distribution_dashboard_path(article.id)}>
                    {article.title || 'Article Dashboard'}
                  </a>
                )}
              </td>
              <td>{moment(article.published_at).format('ll')}</td>
              <td>{article.newsletter_name || '--'}</td>
              <td>{article.email_opens}</td>
              <td>{article.subscriber_reads}</td>
              <td>{article.leads}</td>
              <td>{article.new_subs}</td>
              <td>{article.new_subs_today}</td>
              <td>{article.new_subs_this_week}</td>
              <td>{article.new_subs_this_month}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default DistributionPageTable;
