import {
  api_react_v1_crm_identities_csv_import_path,
  api_react_v1_crm_identities_csv_import_status_path,
  api_react_v1_crm_identities_data_imports_path,
  api_react_v1_crm_identities_path,
} from '../../routes';
import { Response } from '../interfaces';
import request from './request';
import { getRecord } from './universal';

export interface DataImportStatus {
  status: string;
  progress?: number;
  location?: string;
  row_count?: number;
  error_count?: number;
  csv_errors_url?: string;
  error?: string;
}

export async function getDataImports(importType = null) {
  return request('get', api_react_v1_crm_identities_data_imports_path({ importType: importType }));
}

export async function getDataImport(id) {
  const resp = await getRecord({
    model: 'DataImport',
    id: id,
    attributes: [
      'id',
      'administrator_id',
      'administrator_list_id',
      'connected_provider_id',
      'created_at',
      'data',
      'error',
      'filename',
      'import_type',
      'jid',
      'progress',
      'status',
    ],
  });
  return resp.result;
}

export const importIdentities = async (values): Promise<Response> => {
  const resp = await request('post', api_react_v1_crm_identities_path(null, values));
  return resp;
};

export const createCsvImport = async (values): Promise<{ data_import_id: number }> => {
  const resp = await request('post', api_react_v1_crm_identities_csv_import_path(null, values));
  return resp;
};

export const getImportStatus = async (data_import_id: number): Promise<DataImportStatus> => {
  const resp = await request(
    'get',
    api_react_v1_crm_identities_csv_import_status_path(null, { data_import_id })
  );
  return resp;
};
