import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useLandingPage } from '../../hooks';
import LandingPageForm from './LandingPageForm';
import { FormActions, INITIAL_STATE_LANDING_PAGE } from './LandingPageForm/constants';
import { LandingPageFormValues } from './LandingPageForm/interfaces';

const EditLandingPage = () => {
  const { id: landingPageId } = useParams();
  const { landingPage, isFetching, isLoadingLandingPage } = useLandingPage(landingPageId);
  const [landingPageValues, setLandingPageValues] = useState<Omit<LandingPageFormValues, 'action'>>(
    INITIAL_STATE_LANDING_PAGE
  );

  useEffect(() => {
    if (isLoadingLandingPage || isFetching) return;

    for (const key in landingPage) {
      if (landingPage[key] === null) {
        landingPage[key] = '';
      }
    }
    const {
      advertisementId,
      confirmationText,
      formButtonText,
      formFields,
      formHeadline,
      formVerificationText,
      headline,
      leadType,
      metaDescription,
      metaImageImgixUrl: metaImageUrl,
      metaNoindex,
      metaTitle,
      name,
      newsletterId,
      newsletterName,
      pageContentsHtml,
      pageContentsJson,
      slug,
      subheadline,
    } = landingPage;

    const landingPageValues = {
      advertisementId,
      confirmationText,
      formButtonText,
      formFields,
      formHeadline,
      formVerificationText,
      headline,
      leadType,
      metaDescription,
      metaImageUrl,
      metaNoindex,
      metaTitle,
      name,
      newsletterId,
      newsletter: { label: newsletterName, value: newsletterId.toString() },
      pageContentsHtml,
      pageContentsJson,
      slug,
      subheadline,
    };

    setLandingPageValues(landingPageValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingLandingPage, isFetching]);

  if (isLoadingLandingPage) return null;

  return (
    <Container className="NewLandingPage">
      {landingPage && (
        <Row>
          <Col md={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 2 }}>
            <Row>
              <Col xs={12}>
                <h2 className="mb-1 text-center">
                  {landingPage.name} —{' '}
                  {`${landingPage.publishedAt ? FormActions.Publish : FormActions.Unpublish}ed`}
                </h2>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="mb-4">
                <hr className="divider" />
              </Col>
            </Row>
            <LandingPageForm formValues={landingPageValues} />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default EditLandingPage;
