import { api_react_v1_distribution_list_article_data_path } from '../../routes';
import request from './request';

interface DistributionResponse {
  embargoedArticles: AggregateArticleData[];
  recentlyPublished: AggregateArticleData[];
  topArticles: AggregateArticleData[];
}

export interface AggregateArticleData {
  article_id: number;
  id: number;
  title: string;
  published_at: string;
  newsletter_name?: string;
  conversions: number;
  email_clicks: number;
  email_opens: number;
  emails_delivered: number;
  leads: number;
  retention: number;
  subscriber_reads: number;
  total_reads: number;
  new_subs: number;
  new_subs_this_month: number;
  new_subs_this_week: number;
  new_subs_today: number;
  updated_at: string;
}

export async function getArticleData(): Promise<DistributionResponse> {
  const resp = await request('get', api_react_v1_distribution_list_article_data_path());
  return resp;
}
