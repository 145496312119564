import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';

import { ARTICLE_KEY } from '../hooks/useArticle';
import { DeleteEntityJoinParams, deleteEntityJoin } from '../utils/backend-api/entity_joins';

export default function useEntityJoinDelete() {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, data } = useMutation(
    async (values: DeleteEntityJoinParams) => await deleteEntityJoin(values),
    {
      onSuccess: (_data, variables) => {
        toast.success(`EntityJoin has been deleted`);
        queryClient.invalidateQueries([ARTICLE_KEY, `${variables.parentId}`]);
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (_err, _variables) => {
        toast.error(`Failed to delete entity because of error ${_err}`);
      },
    }
  );
  return { mutate, data, isLoading, isError };
}
