/**
 * File generated by js-routes 2.1.2
 * Based on Rails 6.1.4.4 routes of NewsletterBuilder::Application
 */
const __jsr = ((that) => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const Root = that;
    const isBroswer = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define(routes) {
                Utils.namespace(Root, null, routes);
            },
            isSupported() {
                return !!Root;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {};
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            const query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBroswer && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBroswer && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBroswer && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        namespace(object, namespace, routes) {
            const parts = (namespace === null || namespace === void 0 ? void 0 : namespace.split(".")) || [];
            if (parts.length === 0) {
                return routes;
            }
            for (let index = 0; index < parts.length; index++) {
                const part = parts[index];
                if (index < parts.length - 1) {
                    object = object[part] || (object[part] = {});
                }
                else {
                    return (object[part] = routes);
                }
            }
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})(this);
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /about-newsletter/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const about_newsletter_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"about-newsletter"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ad-schedules(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ad_schedules_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ad-schedules"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /crm/administrator_lists/:id/add_identity_to_list(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_identity_to_list_crm_administrator_list_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"administrator_lists"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_identity_to_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_newsletter(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_newsletter_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_newsletter"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin_newsletters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_newsletters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin_newsletters"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /advertisements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const advertisements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"advertisements"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api_internal/v1/identities/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_internal_v1_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_internal"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"identities"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_internal/v1/identities/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_internal_v1_email_update_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_internal"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"identities"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_internal/v1/articles/:id/handle_post_published(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_internal_v1_handle_post_published_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_internal"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"articles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"handle_post_published"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_internal/v1/identities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_internal_v1_identities_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_internal"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"identities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api_internal/v1/identities/:identity_id/gpt_query(.:format)
 * @param {any} identity_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_internal_v1_identity_gpt_query_path = __jsr.r({"identity_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_internal"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"identities"],[2,[7,"/"],[2,[3,"identity_id"],[2,[7,"/"],[2,[6,"gpt_query"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_internal/v1/people_search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_internal_v1_people_search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_internal"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"people_search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api_internal/v1/identities/:id/sync_to_customerio(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_internal_v1_sync_identity_to_customerio_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_internal"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"identities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"sync_to_customerio"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/administrator_list_roles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_administrator_list_roles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"administrator_list_roles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/administrator_lists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_administrator_lists_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"administrator_lists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/administrators(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_administrators_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"administrators"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/articles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_article_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"articles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/autocomplete-administrator-lists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_autocomplete_administrator_lists_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"autocomplete-administrator-lists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/autocomplete-articles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_autocomplete_articles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"autocomplete-articles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/autocomplete-email-suggestions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_autocomplete_email_suggestions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"autocomplete-email-suggestions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/autocomplete-entities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_autocomplete_entities_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"autocomplete-entities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/identity-profiles/autocomplete-field-options(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_autocomplete_identity_profile_field_options_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"identity-profiles"],[2,[7,"/"],[2,[6,"autocomplete-field-options"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/autocomplete-li-enditities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_autocomplete_linkedin_entities_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"autocomplete-li-enditities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/newsletters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_autocomplete_newsletters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"newsletters"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/ad-schedule/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_create_ad_schedules_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"ad-schedule"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/talks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_create_talk_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"talks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/crm/administrator_lists/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_crm_administrator_list_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"administrator_lists"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/crm/administrator_lists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_crm_administrator_lists_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"administrator_lists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/crm/connected_providers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_crm_connected_provider_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"connected_providers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/crm/identities/email-domain-options(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_crm_email_domain_options_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"identities"],[2,[7,"/"],[2,[6,"email-domain-options"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/crm/identities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_crm_identities_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"identities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/crm/identities/csv(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_crm_identities_csv_import_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"identities"],[2,[7,"/"],[2,[6,"csv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/crm/identities/csv(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_crm_identities_csv_import_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"identities"],[2,[7,"/"],[2,[6,"csv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/crm/identities/data_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_crm_identities_data_imports_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"identities"],[2,[7,"/"],[2,[6,"data_imports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/crm/identities/:id/people_search_document(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_crm_identities_people_search_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"identities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"people_search_document"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/crm/identities/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_crm_identity_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"identities"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/crm/identity_administrator_lists/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_crm_identity_administrator_list_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"identity_administrator_lists"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/crm/identities/:identity_id/generate_ai_summaries(.:format)
 * @param {any} identity_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_crm_identity_generate_ai_summaries_path = __jsr.r({"identity_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"identities"],[2,[7,"/"],[2,[3,"identity_id"],[2,[7,"/"],[2,[6,"generate_ai_summaries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/crm/identity_notes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_crm_identity_note_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"identity_notes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/crm/identity_notes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_crm_identity_notes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"identity_notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/crm/side-nav(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_crm_side_nav_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"side-nav"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/ad-schedule/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_delete_ad_schedules_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"ad-schedule"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/administrator-list-roles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_destroy_administrator_list_role_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"administrator-list-roles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/landing_page(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_destroy_landing_page_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"landing_page"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/distribution/:article_id/group_posts/:id(.:format)
 * @param {any} article_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_distribution_group_post_path = __jsr.r({"article_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[6,"group_posts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/distribution/:article_id/group_posts(.:format)
 * @param {any} article_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_distribution_group_posts_path = __jsr.r({"article_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[6,"group_posts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/distribution/:article_id/linkedin/activate/:id(.:format)
 * @param {any} article_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_distribution_linkedin_activate_campaign_path = __jsr.r({"article_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[6,"linkedin"],[2,[7,"/"],[2,[6,"activate"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/distribution/:article_id/linkedin/list(.:format)
 * @param {any} article_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_distribution_linkedin_ad_list_path = __jsr.r({"article_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[6,"linkedin"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/distribution/:article_id/linkedin/auth(.:format)
 * @param {any} article_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_distribution_linkedin_authenticate_path = __jsr.r({"article_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[6,"linkedin"],[2,[7,"/"],[2,[6,"auth"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/distribution/:article_id/linkedin/create(.:format)
 * @param {any} article_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_distribution_linkedin_create_path = __jsr.r({"article_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[6,"linkedin"],[2,[7,"/"],[2,[6,"create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/distribution/:article_id/linkedin/update/:id(.:format)
 * @param {any} article_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_distribution_linkedin_update_path = __jsr.r({"article_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[6,"linkedin"],[2,[7,"/"],[2,[6,"update"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/distribution_list/article_data(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_distribution_list_article_data_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"distribution_list"],[2,[7,"/"],[2,[6,"article_data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/distribution/:article_id/relevant-companies/identities(.:format)
 * @param {any} article_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_distribution_relevant_company_identities_path = __jsr.r({"article_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[6,"relevant-companies"],[2,[7,"/"],[2,[6,"identities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/distribution/:article_id/relevant_groups(.:format)
 * @param {any} article_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_distribution_relevant_groups_path = __jsr.r({"article_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[6,"relevant_groups"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/distribution/:article_id/scheduled_shares/:id(.:format)
 * @param {any} article_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_distribution_scheduled_share_path = __jsr.r({"article_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[6,"scheduled_shares"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/distribution/:article_id/scheduled_shares(.:format)
 * @param {any} article_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_distribution_scheduled_shares_path = __jsr.r({"article_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[6,"scheduled_shares"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/distribution/:article_id/relevant-companies/suggested(.:format)
 * @param {any} article_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_distribution_suggested_article_companies_path = __jsr.r({"article_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[6,"relevant-companies"],[2,[7,"/"],[2,[6,"suggested"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/distribution/:article_id/recipients/:recipient_type/top-profile-values(.:format)
 * @param {any} article_id
 * @param {any} recipient_type
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_distribution_top_profile_values_path = __jsr.r({"article_id":{"r":true},"recipient_type":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[6,"recipients"],[2,[7,"/"],[2,[3,"recipient_type"],[2,[7,"/"],[2,[6,"top-profile-values"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/entities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_entities_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"entities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/entity_joins(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_entity_joins_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"entity_joins"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/newsletters/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_newsletter_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"newsletters"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/newsletters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_newsletters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"newsletters"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/people-search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_pdl_search_people_by_attributes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"people-search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/people-search-status/:job_id(.:format)
 * @param {any} job_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_people_search_status_path = __jsr.r({"job_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"people-search-status"],[2,[7,"/"],[2,[3,"job_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/push_notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_push_notifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"push_notifications"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/custom-emails/send(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_send_custom_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"custom-emails"],[2,[7,"/"],[2,[6,"send"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/send-advertisement-test(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_send_test_advertisement_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"send-advertisement-test"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/push-notifications/send-test(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_send_test_push_notification_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"push-notifications"],[2,[7,"/"],[2,[6,"send-test"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/signed_aws(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_signed_aws_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"signed_aws"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/twitter_line_items/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_twitter_line_item_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"twitter_line_items"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/twitter_line_items(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_twitter_line_items_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"twitter_line_items"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/universal/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_universal_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"universal"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/universal(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_universal_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"universal"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/ad-schedule(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_update_ad_schedules_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"ad-schedule"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/advertisement(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_update_advertisement_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"advertisement"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/landing_page(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_update_landing_page_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"landing_page"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/project(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_update_project_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"project"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/virtual_lists/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_virtual_list_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"virtual_lists"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/virtual_lists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_react_v1_virtual_lists_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"virtual_lists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/identities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_identities_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"identities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/subscriptions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_subscriptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/media-redef(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_update_media_redef_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"media-redef"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /crm/administrator_lists/:id/archive(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const archive_crm_administrator_list_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"administrator_lists"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"archive"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/autocomplete-user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const autocomplete_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"autocomplete-user"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/crm/newsletter_subscription_actions/bulk_cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_cancel_api_react_v1_crm_newsletter_subscription_actions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"newsletter_subscription_actions"],[2,[7,"/"],[2,[6,"bulk_cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/crm/identity_administrator_lists/bulk_create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_create_api_react_v1_crm_identity_administrator_lists_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"identity_administrator_lists"],[2,[7,"/"],[2,[6,"bulk_create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/crm/information_comps/bulk_create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_create_api_react_v1_crm_information_comps_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"information_comps"],[2,[7,"/"],[2,[6,"bulk_create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/crm/newsletter_comps/bulk_create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_create_api_react_v1_crm_newsletter_comps_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"newsletter_comps"],[2,[7,"/"],[2,[6,"bulk_create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/crm/newsletter_subscription_actions/bulk_create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_create_api_react_v1_crm_newsletter_subscription_actions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"newsletter_subscription_actions"],[2,[7,"/"],[2,[6,"bulk_create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/crm/newsletter_unsubscribes/bulk_create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_create_api_react_v1_crm_newsletter_unsubscribes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"newsletter_unsubscribes"],[2,[7,"/"],[2,[6,"bulk_create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/crm/identity_administrator_lists/bulk_destroy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_destroy_api_react_v1_crm_identity_administrator_lists_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"identity_administrator_lists"],[2,[7,"/"],[2,[6,"bulk_destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/crm/identity_sources/bulk_update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_update_api_react_v1_crm_identity_sources_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"identity_sources"],[2,[7,"/"],[2,[6,"bulk_update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /talks/:slug(.:format)
 * @param {any} slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirm_talk_path = __jsr.r({"slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"talks"],[2,[7,"/"],[2,[3,"slug"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sessions/link(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_login_link_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"link"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /newsletter_pages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_newsletter_page_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"newsletter_pages"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /crm/administrator_lists/:administrator_list_id/identity_administrator_lists/:id(.:format)
 * @param {any} administrator_list_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_administrator_list_identity_administrator_list_path = __jsr.r({"administrator_list_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"administrator_lists"],[2,[7,"/"],[2,[3,"administrator_list_id"],[2,[7,"/"],[2,[6,"identity_administrator_lists"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /crm/administrator_list_roles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_administrator_list_role_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"administrator_list_roles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /crm/administrator_list_roles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_administrator_list_roles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"administrator_list_roles"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /crm/administrator-lists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_administrator_lists_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"administrator-lists"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /crm/api(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_api_key_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"api"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /crm/people/autocomplete-identity(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_autocomplete_identity_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[6,"autocomplete-identity"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /crm/connected_providers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_connected_provider_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"connected_providers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /crm/api(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_destroy_api_key_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"api"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /crm/administrator-lists/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_edit_administrator_list_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"administrator-lists"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /crm/identities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_identities_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"identities"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /crm/crm_identities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_identities_crm_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"crm_identities"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /crm/crm_count(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_identities_crm_count_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"crm_count"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /crm/people/csv_export(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_identities_csv_export_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[6,"csv_export"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /crm/people/csv_export(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_identities_csv_export_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[6,"csv_export"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /crm/people/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_identities_people_search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /crm/people/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_identities_people_search_form_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /crm/:identity_id(.:format)
 * @param {any} identity_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_identity_path = __jsr.r({"identity_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[3,"identity_id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /crm/identity_details/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_identity_detail_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"identity_details"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /crm/identity_details(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_identity_details_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"identity_details"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /crm/identities/:identity_id/people_search_document(.:format)
 * @param {any} identity_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_identity_people_search_document_path = __jsr.r({"identity_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"identities"],[2,[7,"/"],[2,[3,"identity_id"],[2,[7,"/"],[2,[6,"people_search_document"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /crm/log_in_as_identity(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_log_in_as_identity_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"log_in_as_identity"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /crm/administrator-lists/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_new_administrator_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"administrator-lists"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /crm/people/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_new_identities_import_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /crm/newsletter_unsubscribes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_newsletter_unsubscribes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"newsletter_unsubscribes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /crm/people/search-by-attributes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_pdl_search_people_by_attributes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[6,"search-by-attributes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /crm(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"crm"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /crm/api(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crm_update_api_key_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"api"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /newsletters/:id/destroy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_newsletter_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"newsletters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /sessions/destroy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /distribution/:article_id/*path(.:format)
 * @param {any} article_id
 * @param {any} path
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const distribution_path = __jsr.r({"article_id":{"r":true},"path":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[5,[3,"path"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /distribution/:article_id/administrator-lists(.:format)
 * @param {any} article_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const distribution_administrator_lists_path = __jsr.r({"article_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[6,"administrator-lists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /distribution/:article_id/audience-generator(.:format)
 * @param {any} article_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const distribution_audience_generator_path = __jsr.r({"article_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[6,"audience-generator"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /distribution/:article_id(.:format)
 * @param {any} article_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const distribution_dashboard_path = __jsr.r({"article_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /distribution/:article_id/linkedin-ad/:id(.:format)
 * @param {any} article_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const distribution_edit_linkedin_ad_path = __jsr.r({"article_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[6,"linkedin-ad"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /distribution-events(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const distribution_events_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"distribution-events"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /distribution/:article_id/group-posts/:post_id(.:format)
 * @param {any} article_id
 * @param {any} post_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const distribution_group_post_path = __jsr.r({"article_id":{"r":true},"post_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[6,"group-posts"],[2,[7,"/"],[2,[3,"post_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /distribution/:article_id/group-posts(.:format)
 * @param {any} article_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const distribution_group_posts_path = __jsr.r({"article_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[6,"group-posts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /distribution/:article_id/linkedin-ad/list(.:format)
 * @param {any} article_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const distribution_linkedin_ad_list_path = __jsr.r({"article_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[6,"linkedin-ad"],[2,[7,"/"],[2,[6,"list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /distribution-list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const distribution_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"distribution-list"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /distribution/:article_id/linkedin-ad/new(.:format)
 * @param {any} article_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const distribution_new_linkedin_campaign_path = __jsr.r({"article_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[6,"linkedin-ad"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /distribution/:article_id/push-notifications/new(.:format)
 * @param {any} article_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const distribution_new_push_notification_path = __jsr.r({"article_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[6,"push-notifications"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /distribution/:article_id/relevant-companies/new(.:format)
 * @param {any} article_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const distribution_new_relevant_company_path = __jsr.r({"article_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[6,"relevant-companies"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /distribution/:article_id/rtsu-campaigns(.:format)
 * @param {any} article_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const distribution_rtsu_campaign_path = __jsr.r({"article_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[6,"rtsu-campaigns"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /distribution/:article_id/scheduled-tweets/:scheduled_share_id(.:format)
 * @param {any} article_id
 * @param {any} scheduled_share_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const distribution_scheduled_tweet_path = __jsr.r({"article_id":{"r":true},"scheduled_share_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[6,"scheduled-tweets"],[2,[7,"/"],[2,[3,"scheduled_share_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /distribution/:article_id/scheduled-tweets(.:format)
 * @param {any} article_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const distribution_scheduled_tweets_path = __jsr.r({"article_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[6,"scheduled-tweets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /distribution/:article_id/push-notifications/:id(.:format)
 * @param {any} article_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const distribution_show_push_notification_path = __jsr.r({"article_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[6,"push-notifications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /distribution/:article_id/twitter-campaigns/:id(.:format)
 * @param {any} article_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const distribution_twitter_campaign_path = __jsr.r({"article_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[6,"twitter-campaigns"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /distribution/:article_id/twitter-campaigns(.:format)
 * @param {any} article_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const distribution_twitter_campaigns_path = __jsr.r({"article_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"distribution"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[6,"twitter-campaigns"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /advertisements/:id/ad-schedules(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_ad_schedules_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"advertisements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"ad-schedules"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin_newsletters/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_newsletter_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin_newsletters"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /advertisements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_advertisements_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"advertisements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/crm/administrator_lists/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_api_react_v1_crm_administrator_list_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"administrator_lists"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /landing-pages/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_landing_pages_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"landing-pages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /newsletters/:id/edit-content(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_newsletter_content_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"newsletters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit-content"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /newsletter_pages/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_newsletter_page_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"newsletter_pages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /newsletters/:id/edit-settings(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_newsletter_settings_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"newsletters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit-settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /newsletters/:id/email-preview(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const email_preview_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"newsletters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"email-preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /newsletters/:id/embargo(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const embargo_newsletter_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"newsletters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"embargo"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /landing-pages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const landing_pages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"landing-pages"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/identities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const legacy_identities_api_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"identities"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sessions/link/:token(.:format)
 * @param {any} token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const login_by_link_path = __jsr.r({"token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"link"],[2,[7,"/"],[2,[3,"token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /more_newsletters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const more_newsletters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"more_newsletters"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /advertisements/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_advertisements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"advertisements"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /crm/newsletter_unsubscribes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_crm_newsletter_unsubscribe_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"newsletter_unsubscribes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /landing-pages/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_landing_pages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"landing-pages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /newsletters/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_newsletter_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"newsletters"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /newsletter_pages/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_newsletter_page_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"newsletter_pages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_project_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /push-notifications/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_push_notification_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"push-notifications"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_source_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /sessions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /talks/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_talk_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"talks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /newsletter_admin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newsletter_admin_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"newsletter_admin"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /newsletters/:id/confirmation(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newsletter_confirmation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"newsletters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/newsletter-data(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newsletter_data_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"newsletter-data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /newsletters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newsletters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"newsletters"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /newsletters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const newsletters_result_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"newsletters"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /projects(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const projects_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"projects"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /newsletters/:id/publish(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publish_newsletter_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"newsletters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"publish"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_proxy_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute_path = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_sources_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/info/properties(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_properties_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"properties"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/info/routes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_routes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"routes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/mailers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"mailers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_proxy_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /articles/:article_id/rtsu-actions/:rtsu_action(.:format)
 * @param {any} article_id
 * @param {any} rtsu_action
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rtsu_actions_path = __jsr.r({"article_id":{"r":true},"rtsu_action":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"articles"],[2,[7,"/"],[2,[3,"article_id"],[2,[7,"/"],[2,[6,"rtsu-actions"],[2,[7,"/"],[2,[3,"rtsu_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /newsletters/save-content(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const save_newsletter_content_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"newsletters"],[2,[7,"/"],[2,[6,"save-content"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /newsletters/save-settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const save_newsletter_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"newsletters"],[2,[7,"/"],[2,[6,"save-settings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api_react/v1/crm/identities/send_email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_email_api_react_v1_crm_identities_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_react"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"identities"],[2,[7,"/"],[2,[6,"send_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /newsletters/:id/send-test-email(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_test_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"newsletters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send-test-email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /push-notifications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_push_notification_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"push-notifications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /crm/identity_details/:id/update_do_not_email(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_do_not_email_crm_identity_detail_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"crm"],[2,[7,"/"],[2,[6,"identity_details"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_do_not_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /newsletters/upload-image-from-file(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_image_from_file_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"newsletters"],[2,[7,"/"],[2,[6,"upload-image-from-file"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /newsletters/upload-image-from-url(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_image_from_url_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"newsletters"],[2,[7,"/"],[2,[6,"upload-image-from-url"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /upload_tweet_screenshot(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_tweet_screenshot_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"upload_tweet_screenshot"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /uploads/presigned_s3(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const uploads_presigned_s3_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"uploads"],[2,[7,"/"],[2,[6,"presigned_s3"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /virtual-lists/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const virtual_list_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"virtual-lists"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /virtual-lists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const virtual_lists_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"virtual-lists"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /webhook-event-received(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webhook_event_received_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"webhook-event-received"],[1,[2,[8,"."],[3,"format"]]]]]);

