import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { GroupPost, createGroupPost } from '../utils/backend-api/group-posts';
import { getGroupPostsKey } from './useGroupPosts';

export default function useGroupPostsCreate(articleId: number | string) {
  const queryClient = useQueryClient();
  const groupPostsKey = getGroupPostsKey(articleId);

  const { mutate, isLoading, isError } = useMutation(
    async (groupPost: GroupPost) => await createGroupPost(groupPost, articleId),
    {
      onMutate: async (newGroupPost: GroupPost) => {
        await queryClient.cancelQueries(groupPostsKey);
        const prevGroupPosts = queryClient.getQueryData<GroupPost[]>(groupPostsKey);
        if (prevGroupPosts) {
          queryClient.setQueryData<GroupPost[]>(groupPostsKey, [
            { ...newGroupPost, id: Math.random().toString(), isNew: true },
            ...prevGroupPosts,
          ]);
        }
        return { prevGroupPosts };
      },
      onSuccess: () => {
        toast.success('Group post has been created');
      },
      onError: (_err, _variables, context) => {
        toast.error('Creating group post has failed');
        if (context?.prevGroupPosts)
          queryClient.setQueryData<GroupPost[]>(groupPostsKey, context.prevGroupPosts);
      },
      onSettled: () => {
        queryClient.invalidateQueries(groupPostsKey);
      },
    }
  );
  return { mutate, isLoading, isError };
}
