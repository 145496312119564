import {
  api_react_v1_crm_identity_administrator_list_path,
  bulk_create_api_react_v1_crm_identity_administrator_lists_path,
  bulk_destroy_api_react_v1_crm_identity_administrator_lists_path,
} from '../../routes';
import request from './request';

export const deleteIdentityAdministratorList = async (identityAdministratorListId: number) => {
  return request(
    'delete',
    api_react_v1_crm_identity_administrator_list_path(identityAdministratorListId)
  );
};

export const createIdentityAdministratorList = async ({
  administratorListIds,
  identityIds,
  selectAll = false,
  filters = {},
  query = null,
  administratorListId = null,
  scope = null,
}) => {
  return request(
    'post',
    bulk_create_api_react_v1_crm_identity_administrator_lists_path(null, {
      administrator_list_ids: administratorListIds,
      identity_ids: identityIds,
      select_all: selectAll,
      filters: filters,
      query: query,
      list_id: administratorListId,
      scope: scope,
    })
  );
};

export const bulkDestroyIndentityAdministratorLists = async (
  administratorListIds,
  identityIds,
  selectAll,
  filters,
  query,
  administratorListId,
  scope = null
) => {
  return request(
    'delete',
    bulk_destroy_api_react_v1_crm_identity_administrator_lists_path(null, {
      administrator_list_ids: administratorListIds,
      identity_ids: identityIds,
      select_all: selectAll,
      filters: filters,
      query: query,
      list_id: administratorListId,
      scope: scope,
    })
  );
};
