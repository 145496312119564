import clsx from 'clsx';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import {
  AutocompleteField,
  CheckBoxField,
  CheckBoxGroup,
  MultiValueAutocompleteField,
} from '@components/FormFields';
import useArticleEntities from '@hooks/useArticleEntities';
import { autocompleteArticles } from '@utils/backend-api/articles';
import {
  ENTITY_TYPE_GENDER,
  ENTITY_TYPE_JOB_LEVEL,
  ENTITY_TYPE_JOB_ROLE,
  ENTITY_TYPE_LOCATION,
  ENTITY_TYPE_ORGANIZATION,
  ENTITY_TYPE_ORGANIZATION_INDUSTRY,
  WORD_TYPE_COMMON,
  WORD_TYPE_PROPER,
  autocompleteEntities,
} from '@utils/backend-api/entities';
import {
  ACCESS_LEVEL_VALUES,
  SUBSCRIPTION_STATUS_ACTIVE,
  SUBSCRIPTION_STATUS_BLOCKED,
  SUBSCRIPTION_STATUS_CANCELED,
  SUBSCRIPTION_STATUS_CANCELED_BUT_PAID,
  SUBSCRIPTION_STATUS_COMPED,
  SUBSCRIPTION_STATUS_CORPORATE,
  SUBSCRIPTION_STATUS_LEAD,
  SUBSCRIPTION_STATUS_PAST_DUE,
} from '@utils/backend-api/users';
import { MultiValueEntityAutocompleteParams, SelectFieldOption } from '@utils/interfaces';

import styles from '../styles.module.css';

const SUBSCRIPTION_STATUS_OPTIONS: SelectFieldOption[] = [
  { label: 'Active', value: SUBSCRIPTION_STATUS_ACTIVE },
  { label: 'Blocked', value: SUBSCRIPTION_STATUS_BLOCKED },
  { label: 'Canceled', value: SUBSCRIPTION_STATUS_CANCELED },
  { label: 'Canceled but Paid Through', value: SUBSCRIPTION_STATUS_CANCELED_BUT_PAID },
  { label: 'Comped', value: SUBSCRIPTION_STATUS_COMPED },
  { label: 'Corporate', value: SUBSCRIPTION_STATUS_CORPORATE },
  { label: 'Lead', value: SUBSCRIPTION_STATUS_LEAD },
  { label: 'Past Due', value: SUBSCRIPTION_STATUS_PAST_DUE },
];

const PERSONAL_ATTRIBUTES_FIELDS: MultiValueEntityAutocompleteParams[] = [
  {
    name: 'personalAttributesCompanyName',
    label: 'Company',
    entityType: ENTITY_TYPE_ORGANIZATION,
    wordType: null,
  },
  {
    name: 'personalAttributesCompanyIndustry',
    label: 'Industry',
    entityType: ENTITY_TYPE_ORGANIZATION_INDUSTRY,
    wordType: WORD_TYPE_COMMON,
  },
  {
    name: 'personalAttributesJobLevel',
    label: 'Level',
    entityType: ENTITY_TYPE_JOB_LEVEL,
    wordType: WORD_TYPE_COMMON,
  },
  {
    name: 'personalAttributesJobRole',
    label: 'Role',
    entityType: ENTITY_TYPE_JOB_ROLE,
    wordType: WORD_TYPE_COMMON,
  },
  {
    name: 'personalAttributesLocation',
    label: 'Location',
    entityType: ENTITY_TYPE_LOCATION,
    wordType: WORD_TYPE_PROPER,
  },
  {
    name: 'personalAttributesGender',
    label: 'Gender',
    placeholder: 'male/female',
    entityType: ENTITY_TYPE_GENDER,
    wordType: null,
  },
];

const ArticleFormPartial = ({ canEditArticle, formik, initialValues }) => {
  const { articleEntities } = useArticleEntities(initialValues.article?.value);

  const [showSubscriptionStatuses, setShowSubscriptionStatuses] = useState(
    !!initialValues.subscriptionStatuses?.length
  );
  const [showEntities, setShowEntities] = useState(!!initialValues.entities?.length);
  const [showPersonalAttributes, setShowPersonalAttributes] = useState(
    !!initialValues.guiFilters?.personalAttributes
  );
  const [showPermissions, setShowPermissions] = useState(!!initialValues.guiFilters?.permissions);

  const handleSubscriptionStatuses = (formik, field, newValue) => {
    if (formik.values.subscriptionStatuses.length > 0) {
      formik.setFieldValue('noUser', false);
      const statusesWoNoUser = [
        ...newValue.filter((v) => {
          return v !== 'no_user';
        }),
      ];
      formik.setFieldValue('subscriptionStatuses', statusesWoNoUser);
    }
    formik.handleSubmit();
  };

  const handleNoUser = (formik, field, newValue) => {
    if (newValue) {
      formik.setFieldValue('subscriptionStatuses', ['no_user']);
    }
    formik.handleSubmit();
  };

  return (
    <>
      {canEditArticle ? (
        <AutocompleteField
          label="Build this list off of a published Article"
          name="article"
          fetchMethod={(fragment: string) => autocompleteArticles(fragment)}
          hint="Search for articles by title"
          isCreatable={false}
          valueIsInteger
        />
      ) : (
        <div className="mb-4">
          <label className="input-label">Article</label>
          <div className={styles.articleName}>{formik.values.article.label}</div>
        </div>
      )}
      <div className={clsx(styles.collapsableSection, styles.collapsableSectionTop)}>
        {showSubscriptionStatuses ? (
          <>
            <Row>
              <Col>
                <button
                  type="button"
                  className={styles.hideButton}
                  onClick={() => setShowSubscriptionStatuses(false)}
                >
                  Hide Subscription Statuses
                </button>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col>
                <CheckBoxGroup
                  name="subscriptionStatuses"
                  onChange={(field, newValue) =>
                    handleSubscriptionStatuses(formik, field, newValue)
                  }
                  options={SUBSCRIPTION_STATUS_OPTIONS}
                  label="Subscription Status"
                />
                <CheckBoxField
                  label="No User"
                  name="noUser"
                  hint="if set other subscription statuses cannot also be targeted"
                  onChange={(field, newValue) => handleNoUser(formik, field, newValue)}
                />
              </Col>
              {formik.values.subscriptionStatuses.includes('active') && (
                <Col>
                  <CheckBoxGroup
                    name="accessLevels"
                    onChange={formik.handleSubmit}
                    options={ACCESS_LEVEL_VALUES}
                    label="Access Levels"
                  />
                </Col>
              )}
            </Row>
          </>
        ) : (
          <Row className="mb-2">
            <Col>
              <button
                type="button"
                className={styles.hideButton}
                onClick={() => setShowSubscriptionStatuses(true)}
              >
                Edit Subscription Statuses
              </button>
            </Col>
          </Row>
        )}
      </div>
      <div className={styles.collapsableSection}>
        {showEntities ? (
          <>
            <button
              type="button"
              className={styles.hideButton}
              onClick={() => setShowEntities(false)}
            >
              Hide Article Entity Filters
            </button>
            <Row className="mt-2">
              <Col>
                {articleEntities && (
                  <CheckBoxGroup
                    name="entities"
                    onChange={formik.handleSubmit}
                    options={[
                      { label: 'Check All', value: 'allChecked', allChecked: true },
                      ...articleEntities,
                    ]}
                    label="Entities"
                  />
                )}
              </Col>
              <Col>
                <label className={styles.label}>Follow Types</label>
                <CheckBoxField
                  label="Follows"
                  name="entityJoinTypesFollows"
                  onChange={formik.handleSubmit}
                />
                <CheckBoxField
                  label="Employed By"
                  name="entityJoinTypesEmployedBy"
                  onChange={formik.handleSubmit}
                />
                <CheckBoxField
                  label="Viewed Articles"
                  name="entityJoinTypesViewedArticles"
                  onChange={formik.handleSubmit}
                />
                <CheckBoxField
                  label="Profile Source"
                  name="entityJoinTypesProfile"
                  onChange={formik.handleSubmit}
                />
              </Col>
            </Row>
          </>
        ) : (
          <Row className="mb-2">
            <Col>
              <button
                type="button"
                className={styles.hideButton}
                onClick={() => setShowEntities(true)}
              >
                Edit Article Entity Filters
              </button>
            </Col>
          </Row>
        )}
      </div>
      <div className={styles.collapsableSection}>
        {showPersonalAttributes ? (
          <>
            <button
              type="button"
              className={styles.hideButton}
              onClick={() => setShowPersonalAttributes(false)}
            >
              Hide Personal Attributes Filters
            </button>
            <Row className="mt-2">
              <Col>
                {PERSONAL_ATTRIBUTES_FIELDS.map((field) => (
                  <MultiValueAutocompleteField
                    key={field.label}
                    label={field.label}
                    name={field.name}
                    placeholder={field.placeholder || `filter by ${field.name}`}
                    onChange={formik.handleSubmit}
                    fetchMethod={(value) =>
                      autocompleteEntities({
                        fragment: value,
                        entity_type: field.entityType,
                        word_type: field.wordType,
                      })
                    }
                  />
                ))}
              </Col>
            </Row>
          </>
        ) : (
          <Row className="mb-2">
            <Col>
              <button
                type="button"
                className={styles.hideButton}
                onClick={() => setShowPersonalAttributes(true)}
              >
                Edit Personal Attributes Filters
              </button>
            </Col>
          </Row>
        )}
      </div>
      <div className={styles.collapsableSection}>
        {showPermissions ? (
          <>
            <button
              type="button"
              className={styles.hideButton}
              onClick={() => setShowPermissions(false)}
            >
              Hide Permissions Filters
            </button>
            <Row className="mt-2">
              <Col>
                <CheckBoxField
                  label="Ignore Marketing Email Opt Out"
                  name="permissionsIgnoreMarketingEmailOptOut"
                  hint="Checking this will include users who have explicitly opted out of Marketing Email"
                  onChange={formik.handleSubmit}
                />
                {/* <CheckBoxField
                  label="Allow Duplicate Article Sends"
                  name="permissionsAllowDuplicateArticleSends"
                  hint="Checking this will include users who have already received email relating to this article"
                  onChange={formik.handleSubmit}
                /> */}
              </Col>
            </Row>
          </>
        ) : (
          <Row className="mb-2">
            <Col>
              <button
                type="button"
                className={styles.hideButton}
                onClick={() => setShowPermissions(true)}
              >
                Edit Permissions Filters
              </button>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};
export default ArticleFormPartial;
