import { useFormikContext } from 'formik';
import React from 'react';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { useParams } from 'react-router-dom';

import { CheckBoxField, DropdownField, TextField } from '@components/FormFields';
import VerticalSortableList from '@components/FormFields/VerticalSortableList';
import useMutableNavItems from '@hooks/useMutableNavItems';
import useNavItems from '@hooks/useNavItems';
import { ProjectFormValues, navSectionOptions } from '@utils/backend-api/projects';

const ProjectNavForm = () => {
  const { id: projectId } = useParams();
  const { navItems, isLoadingNavItems } = useNavItems();
  const { filteredNavItemsState, handleNavItemChange } = useMutableNavItems(
    navItems,
    isLoadingNavItems
  );
  const { values } = useFormikContext<ProjectFormValues>();

  return (
    <>
      <Row>
        <Col>
          <CheckBoxField
            id="nav-active-checkbox"
            label="Display on nav"
            name="navActive"
          ></CheckBoxField>
        </Col>
      </Row>
      {values.navActive && (
        <>
          <Row>
            <Col>
              <TextField label="Navigation Name" name="navName" hint="Defaults to project title" />
            </Col>
          </Row>
          <Row>
            <Col>
              <DropdownField
                label="Navigation Section"
                name="navSection"
                options={navSectionOptions}
              />
            </Col>
          </Row>
          {values.navSection !== undefined && !isLoadingNavItems && (
            <>
              <Row>
                <Col>
                  <p style={{ fontWeight: '700' }}>
                    Navigation order for section {values.navSection.label}{' '}
                  </p>
                  <VerticalSortableList
                    name="navItems"
                    key={values.navSection.value}
                    listItems={
                      filteredNavItemsState?.map((navItem) => ({
                        label: navItem.name,
                        id: navItem.id,
                        highlight:
                          navItem.projectId != null && navItem.projectId.toString() === projectId,
                      })) || []
                    }
                    onChange={(newListItems) => {
                      handleNavItemChange(newListItems);
                    }}
                  ></VerticalSortableList>
                </Col>
              </Row>
              <br />
            </>
          )}
        </>
      )}
    </>
  );
};

export default ProjectNavForm;
