import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';

import ScheduledTweetCard from './Card';

const ScheduledTweetCardList = ({ tweets }) => {
  return (
    <div className="scheduled-shares-container">
      <Row>
        <Col className="col-12 col-md-12 col-lg-12 scheduled-share-history mt-4">
          {['Scheduled', 'Drafts', 'Published'].map((status) => (
            <Fragment key={status}>
              <h3 className="section-header">
                {status}
                {status === 'Drafts' ? '' : ' Tweets'}
              </h3>
              <ul className="list-unstyled">
                {tweets[status.toLowerCase()].length > 0 ? (
                  tweets[status.toLowerCase()].map((tweet) => (
                    <ScheduledTweetCard tweet={tweet} key={tweet.scheduled_share_id} />
                  ))
                ) : (
                  <li className="placeholder-list-item">
                    No {status}
                    {status === 'Drafts' ? '' : ' Tweets'}
                  </li>
                )}
              </ul>
            </Fragment>
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default ScheduledTweetCardList;
