import { Form, Formik } from 'formik';
import React, { useState } from 'react';

import { useFilterContext } from '../../../../../contexts/FilterContext';
import { generateRandomString } from '../../../../../utils/helpers';
import { AutocompleteOption, AutocompleteRecord } from '../../../../../utils/interfaces';
import { DropdownField } from '../../../../FormFields';
import ValueInput from './ValueInput';
import {
  FILTER_AUTOCOMPLETE_TYPE,
  FILTER_ENTITIES_TYPE,
  FILTER_OPTIONS,
  FilterOption,
  determineOperatorOptions,
} from './options';
import schema from './schema';

export type FilterValue = string | AutocompleteRecord[] | AutocompleteOption;
interface FilterFormValues {
  field: FilterOption;
  operator: { label: string; value: string };
  filterValue: FilterValue;
}

const INITIAL_STATE: FilterFormValues = {
  field: { label: '', value: '', helper: '', type: '' },
  operator: { label: '', value: '' },
  filterValue: '',
};

const filterValueFromType = (filterValue: FilterValue, type: string) => {
  if (type === FILTER_ENTITIES_TYPE && Array.isArray(filterValue)) {
    return filterValue.map((option) => option.value);
  } else if (
    type === FILTER_AUTOCOMPLETE_TYPE &&
    typeof filterValue === 'object' &&
    !Array.isArray(filterValue)
  ) {
    return filterValue.value;
  } else {
    return filterValue;
  }
};

const SqlFilters = () => {
  // used for adding/deleting filters
  const [filterGuid, setFilterGuid] = useState<string>(generateRandomString());
  const { filters, setFilters, setQueryEnabled } = useFilterContext();

  const addFilter = (values: FilterFormValues, { resetForm }) => {
    setFilters([
      ...filters,
      {
        id: filterGuid,
        label: values.field.label,
        field: values.field.value,
        operator: values.operator.value,
        operatorLabel: values.operator.label,
        type: values.field.type,
        value: filterValueFromType(values.filterValue, values.field.type),
      },
    ]);
    setFilterGuid(generateRandomString());
    resetForm();
    setQueryEnabled(true);
  };

  return (
    <li className="mt-3 pe-3">
      <Formik
        onSubmit={addFilter}
        initialValues={INITIAL_STATE}
        validationSchema={schema}
        enableReinitialize
      >
        {(formik) => (
          <Form className="g-1" onSubmit={formik.handleSubmit}>
            <DropdownField placeholder="Filter type" name="field" options={FILTER_OPTIONS} />
            <DropdownField
              placeholder="Operator"
              name="operator"
              options={determineOperatorOptions(formik.values.field.type)}
            />
            <ValueInput
              fieldType={formik.values.field.type}
              identityProfileField={formik.values.field.identityProfileField}
            />
            <div className="justify-content-between mt-2" style={{ display: 'flex' }}>
              <button
                disabled={formik.isSubmitting}
                className="btn btn-sm btn-outline-dark me-2 h-100"
                type="submit"
              >
                Add
              </button>
              {formik.values.field.helper && (
                <p style={{ fontStyle: 'italic' }} className="text-muted text-small">
                  * {formik.values.field.helper}
                </p>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </li>
  );
};

export default SqlFilters;
