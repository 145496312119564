import React from 'react';

import { useFilterContext } from '../../../../contexts/FilterContext';
import AiFilters from './AiFilters';
import SqlFilters from './SqlFilters';

const Filters = () => {
  const { aiQueryActive } = useFilterContext();

  return (
    <>
      <div style={!aiQueryActive ? { display: 'none' } : {}}>
        <AiFilters />
      </div>

      <div style={aiQueryActive ? { display: 'none' } : {}}>
        <SqlFilters />
      </div>
    </>
  );
};

export default Filters;
