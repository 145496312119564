import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import {
  AdministratorListSanitizedValues,
  createAdministratorList,
} from '../utils/backend-api/administrator-lists';
import { ADMINISTRATOR_LISTS_KEY } from './useAdministratorLists';

export default function useAdministratorListCreate() {
  const queryClient = useQueryClient();

  const { mutate, mutateAsync, isLoading, isError } = useMutation(
    async (values: AdministratorListSanitizedValues) => await createAdministratorList(values),
    {
      onSuccess: () => {
        toast.success('Administrator List has been created');
      },
      onError: () => {
        toast.error('Creating administrator list has failed');
      },
      onSettled: () => {
        queryClient.invalidateQueries(ADMINISTRATOR_LISTS_KEY);
      },
    }
  );
  return { mutate, mutateAsync, isLoading, isError };
}
