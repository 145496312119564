import React, { useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';

import useAdministratorList from '@hooks/useAdministratorList';

import { useAdministratorListDelete } from '../../../../hooks';
import {
  crm_administrator_lists_path,
  crm_root_path,
  distribution_administrator_lists_path,
} from '../../../../routes';
import AdministratorListForm from '../Form';
import styles from '../styles.module.css';

const EditAdministratorList = () => {
  const { id } = useParams();
  const {
    administratorList,
    isLoadingAdministratorList,
    administratorListEntitiesAsOptions,
    administratorListListTypeAsOption,
  } = useAdministratorList(id);
  const { mutate: deleteAdministratorList } = useAdministratorListDelete(id);
  const navigate = useNavigate();

  const handleDelete = () => {
    window.confirm('Are you sure you want to delete this Administrator List?') &&
      deleteAdministratorList();
    if (administratorList.article) {
      navigate(distribution_administrator_lists_path({ article_id: administratorList.article.id }));
    } else {
      navigate(crm_root_path(), { replace: true });
    }
  };

  useEffect(() => {
    // redirect if admin list has loaded and is not found
    if (!isLoadingAdministratorList && !administratorList) {
      navigate(crm_root_path(), { replace: true });
    }
  });

  if (isLoadingAdministratorList) return null;
  const administratorListInitialValues = {
    ...administratorList,
    accessLevels: administratorList.guiFilters?.accessLevels || [],
    article: administratorList.article
      ? { label: administratorList.article.title, value: administratorList.article.id }
      : null,
    entities: administratorListEntitiesAsOptions,
    entityJoinTypesViewedArticles: administratorList.entityJoinTypes?.includes('viewed_articles'),
    entityJoinTypesFollows: administratorList.entityJoinTypes?.includes('follows'),
    entityJoinTypesEmployedBy: administratorList.entityJoinTypes?.includes('employed_by'),
    entityJoinTypesProfile: administratorList.entityJoinTypes?.includes('profile_source'),
    listType: administratorListListTypeAsOption,
    noUser: administratorList.guiFilters?.subscriptionStatuses?.includes('no_user'),
    permissionsIgnoreMarketingEmailOptOut:
      administratorList.guiFilters?.permissions?.ignoreMarketingEmailOptOut,
    permissionsAllowDuplicateArticleSends:
      administratorList.guiFilters?.permissions?.allowDuplicateArticleSends,
    personalAttributesCompanyName: administratorList.guiFilters?.personalAttributes?.companyName,
    personalAttributesJobRole: administratorList.guiFilters?.personalAttributes?.jobRole,
    personalAttributesJobLevel: administratorList.guiFilters?.personalAttributes?.jobLevel,
    personalAttributesCompanyIndustry:
      administratorList.guiFilters?.personalAttributes?.companyIndustry,
    personalAttributesLocation: administratorList.guiFilters?.personalAttributes?.location,
    personalAttributesGender: administratorList.guiFilters?.personalAttributes?.gender,
    subscriptionStatuses: administratorList.guiFilters?.subscriptionStatuses || [],
  };

  return (
    <Row>
      <Col className="me-4">
        <div className={styles.header}>
          <h2>Update List</h2>
          <Link className="mt-1" to={crm_administrator_lists_path()}>
            Manage Lists
          </Link>
        </div>
        <AdministratorListForm
          initialValues={administratorListInitialValues}
          canEditArticle={true}
        />
        <Button onClick={handleDelete} variant="danger">
          Delete List
        </Button>
      </Col>
    </Row>
  );
};

export default EditAdministratorList;
