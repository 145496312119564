import * as yup from 'yup';

import { imageTest } from '../../../utils/helpers';

const landingPageSchema = () =>
  yup.object().shape(
    {
      advertisementId: yup.number().nullable().notRequired(),
      confirmationText: yup.string().required('Confirmation Text is required'),
      formButtonText: yup.string().notRequired(),
      formFields: yup.array().notRequired(),
      formHeadline: yup.string().notRequired(),
      formVerificationText: yup.string().notRequired(),
      headline: yup.string().required('Headline is required'),
      leadType: yup.string().required('Lead Type is required'),
      metaDescription: yup.string().notRequired(),
      metaImageUrl: yup
        .mixed()
        .notRequired()
        .nullable()
        .when('metaImageUrl', {
          is: (metaImageUrl) => metaImageUrl,
          then: yup.mixed().test('fileType', 'Unsupported File Format', imageTest),
        }),

      metaNoindex: yup.boolean().notRequired(),
      metaTitle: yup.string().notRequired(),
      name: yup.string().required('Name is required'),
      newsletterId: yup.number().nullable().notRequired(),
      pageContentsHtml: yup.string().notRequired(),
      slug: yup.string().required('Slug is required'),
      subheadline: yup.string().notRequired(),
    },
    [['metaImageUrl', 'metaImageUrl']]
  );

export default landingPageSchema;
