import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useQueryClient } from 'react-query';

import { SanitizedEntityJoinParams, createEntityJoin } from '../utils/backend-api/entity_joins';
import { ARTICLE_KEY } from './useArticle';

export default function useEntityJoinCreate() {
  const queryClient = useQueryClient();
  const { mutate, mutateAsync, isLoading, isError, data } = useMutation(
    async (values: SanitizedEntityJoinParams) => await createEntityJoin(values),
    {
      onSuccess: (data, variables) => {
        if (data.newRecord) toast.success(`Created new tag`);
        queryClient.invalidateQueries([ARTICLE_KEY, `${variables.parent_id}`]);
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (_err, _variables) => {
        toast.error(`Failed to create entity because of error ${_err}`);
      },
    }
  );
  return { mutate, mutateAsync, data, isLoading, isError };
}
