import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { getAvailableSponsorNames } from '../utils/backend-api/advertisements';

export const AD_SPONSOR_OPTIONS_KEY = 'adSponsorOptions';

const formatSponsorOptions = (availableSponsorNames: string[]) => {
  return availableSponsorNames.map((sponsorName) => ({
    label: sponsorName,
    value: sponsorName,
  }));
};

export default function useAdSponsorOptions() {
  const { data: availableSponsorNames, isLoading: isLoadingSponsorNames } = useQuery<string[]>(
    AD_SPONSOR_OPTIONS_KEY,
    getAvailableSponsorNames
  );

  const adSponsorOptions = useMemo(() => {
    if (availableSponsorNames) return formatSponsorOptions(availableSponsorNames);
  }, [availableSponsorNames]);

  return { adSponsorOptions, availableSponsorNames, isLoadingSponsorNames };
}
