import {
  api_react_v1_distribution_scheduled_share_path,
  api_react_v1_distribution_scheduled_shares_path,
} from '../../routes';
import { ConnectedProvider } from './connected-providers';
import request from './request';
import { getRecord } from './universal';

export interface ScheduledShare {
  shareDate: string;
  shareText: string;
  connectedProvider: ConnectedProvider;
}
export async function getScheduledShares(articleId) {
  const resp = await request('get', api_react_v1_distribution_scheduled_shares_path(articleId));
  return resp;
}

export const getScheduledShare = async (
  scheduledShareId: string | number
): Promise<ScheduledShare> => {
  const resp = await getRecord({
    id: scheduledShareId,
    model: 'ScheduledShare',
    attributes: ['shareDate', 'shareText'],
    relations: { connectedProvider: { attributes: ['name', 'id'] } },
  });
  return resp.result;
};

// TODO: Add types for these methods -----
export async function createScheduledShare(articleId, values) {
  const resp = await request(
    'post',
    api_react_v1_distribution_scheduled_shares_path(articleId, values)
  );
  return resp;
}

export async function updateScheduledShare(articleId, scheduledShareId, values) {
  const resp = await request(
    'put',
    api_react_v1_distribution_scheduled_share_path(articleId, scheduledShareId, values)
  );
  return resp;
}

export async function deleteScheduledShare(articleId, scheduledShareId) {
  const resp = await request(
    'delete',
    api_react_v1_distribution_scheduled_share_path(articleId, scheduledShareId)
  );
  return resp;
}
