import { useInfiniteQuery } from 'react-query';

import { AdSchedule, getPaginatedAdSchedules } from '../utils/backend-api/ad-schedules';

export const AD_SCHEDULES_KEY = 'adSchedules';

interface AdSchedulesData {
  result: AdSchedule[];
  nextPage: number;
}

export default function useAdSchedules() {
  const {
    data: adSchedulesData,
    isLoading: isLoadingAdSchedules,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<AdSchedulesData>(
    'adSchedules',
    ({ pageParam = 1 }) => getPaginatedAdSchedules(pageParam),
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
    }
  );

  // Group schedules by date range
  const scheduleGroups = {};
  adSchedulesData?.pages.forEach((page) => {
    page.result.forEach((adSchedule) => {
      const dateKey = `${adSchedule.startDate}-${adSchedule.endDate}`;
      if (!scheduleGroups[dateKey]) {
        scheduleGroups[dateKey] = [];
      }
      scheduleGroups[dateKey].push(adSchedule);
    });
  });

  return { scheduleGroups, isLoadingAdSchedules, fetchNextPage, hasNextPage };
}
