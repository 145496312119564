import {
  api_react_v1_administrator_lists_path,
  api_react_v1_autocomplete_administrator_lists_path,
  api_react_v1_crm_administrator_list_path,
  api_react_v1_crm_administrator_lists_path,
  archive_crm_administrator_list_path,
  edit_api_react_v1_crm_administrator_list_path,
} from '../../routes';
import { AutocompleteRecord, CreateRecordResponse } from '../interfaces';
import { Entity } from './entities';
import request from './request';
import { getRecord, getRecords } from './universal';

export interface ListTypeOption {
  label: string;
  value: string;
  description?: string;
}

export const USER_MANUAL_LIST_TYPE_OPTION: ListTypeOption = {
  label: 'Manual',
  value: 'manual',
  description:
    'Use when making a list for yourself that you will manually add individual identities to',
};
export const GLOBAL_MANUAL_LIST_TYPE_OPTION: ListTypeOption = {
  label: 'Global Manual',
  value: 'global_manual',
  description: 'Use when making a public list that you and others will manually add identities to',
};
export const USER_AUTOMATIC_LIST_TYPE_OPTION: ListTypeOption = {
  label: 'Automatic',
  value: 'user_auto',
  description:
    'Use when making a list for yourself that you want to populate automatically via picking entities, or writing SQL',
};
export const GLOBAL_AUTOMATIC_LIST_TYPE_OPTION: ListTypeOption = {
  label: 'Global Automatic',
  value: 'global_auto',
  description:
    'Use when making a public list that you want to populate automatically via picking entities, or writing SQL',
};
export const ARTICLE_AUTOMATIC_LIST_TYPE_OPTION: ListTypeOption = {
  label: 'Article Automatic',
  value: 'article_auto',
  description:
    "Use when making a public list scoped to an article and it's relationship to identities",
};

export const SOURCE_FINDER_LIST_TYPE_OPTION: ListTypeOption = {
  label: 'Source Finder',
  value: 'source_finder',
  description: 'Used for Source Finder only',
};

export const USER_SCOPED_LIST_OPTION_VALUES = [
  USER_MANUAL_LIST_TYPE_OPTION.value,
  USER_AUTOMATIC_LIST_TYPE_OPTION.value,
];

export const GLOBAL_LIST_OPTION_VALUES = [
  GLOBAL_MANUAL_LIST_TYPE_OPTION.value,
  GLOBAL_AUTOMATIC_LIST_TYPE_OPTION.value,
];

export const MANUAL_LIST_OPTION_VALUES = [
  USER_MANUAL_LIST_TYPE_OPTION.value,
  GLOBAL_MANUAL_LIST_TYPE_OPTION.value,
];

export const AUTOMATIC_LIST_OPTION_VALUES = [
  USER_AUTOMATIC_LIST_TYPE_OPTION.value,
  GLOBAL_AUTOMATIC_LIST_TYPE_OPTION.value,
  ARTICLE_AUTOMATIC_LIST_TYPE_OPTION.value,
];

export const LIST_TYPE_OPTIONS: ListTypeOption[] = [
  USER_MANUAL_LIST_TYPE_OPTION,
  GLOBAL_MANUAL_LIST_TYPE_OPTION,
  USER_AUTOMATIC_LIST_TYPE_OPTION,
  GLOBAL_AUTOMATIC_LIST_TYPE_OPTION,
  SOURCE_FINDER_LIST_TYPE_OPTION,
  ARTICLE_AUTOMATIC_LIST_TYPE_OPTION,
];

export interface AdministratorList {
  administratorId: number;
  article: ArticleIdTitle;
  article_id: number;
  color: string;
  connectedProviderId: number;
  entities: AutocompleteRecord[] | Entity[];
  entityJoinTypesViewedArticles: boolean;
  entityJoinTypesFollows: boolean;
  entityJoinTypesEmployedBy: boolean;
  entityJoinTypesProfile: boolean;
  entityJoinTypes?: string[];
  guiFilters?: AdministratorListGuiFilters;
  id: number;
  identityCount: number;
  isArchived: boolean;
  isShared: boolean;
  lastPopulatedSummary: string;
  listDescription: string;
  listName: string;
  listType: string;
  query: string;
  status: string;
}

interface ArticleIdTitle {
  id: number;
  title: string;
}

export interface AdministratorListFormValues {
  accessLevels?: number[];
  article?: ArticleOption;
  entities: AutocompleteRecord[] | (string | number)[];
  guiFilters?: AdministratorListGuiFilters;
  id?: number;
  listDescription: string;
  listName: string;
  listType: ListTypeOption;
  noUser?: boolean;
  permissionsAllowDuplicateArticleSends?: boolean;
  permissionsIgnoreMarketingEmailOptOut?: boolean;
  personalAttributesCompanyName?: AutocompleteRecord[];
  personalAttributesCompanyIndustry?: AutocompleteRecord[];
  personalAttributesGender?: AutocompleteRecord[];
  personalAttributesJobLevel?: AutocompleteRecord[];
  personalAttributesJobRole?: AutocompleteRecord[];
  personalAttributesLocation?: AutocompleteRecord[];
  query?: string;
  isArchived: boolean;
  isShared: boolean;
  entityJoinTypesViewedArticles: boolean;
  entityJoinTypesFollows: boolean;
  entityJoinTypesEmployedBy: boolean;
  entityJoinTypesProfile: boolean;
  entityJoinTypes?: string[];
  subscriptionStatuses?: string[];
}

interface ArticleOption {
  label?: string;
  value: number;
}

export interface AdministratorListGuiFilters {
  accessLevels: number[];
  personalAttributes?: PersonalAttributes;
  subscriptionStatuses: string[];
  permissions: PermissionsOptions;
}

interface PermissionsOptions {
  allowDuplicateArticleSends?: boolean;
  ignoreMarketingEmailOptOut?: boolean;
}

export interface PersonalAttributes {
  companyName?: AutocompleteRecord[];
  companyIndustry?: AutocompleteRecord[];
  gender?: AutocompleteRecord[];
  jobLevel?: AutocompleteRecord[];
  jobRole?: AutocompleteRecord[];
  location?: AutocompleteRecord[];
}

export interface AdministratorListSanitizedValues
  extends Omit<
    AdministratorListFormValues,
    | 'entities'
    | 'listType'
    | 'entityJoinTypesViewedArticles'
    | 'entityJoinTypesFollows'
    | 'entityJoinTypesEmployedBy'
    | 'entityJoinTypesProfile'
  > {
  entities: (string | number)[];
  listType: string;
  entityJoinTypes: string[];
}

interface EmailGroupedAdministratorLists {
  [key: string]: AdministratorList[];
}

const MAX_LISTS_PER_PAGE = 100;

export async function getAdminEmailGroupedAdministratorLists(): Promise<EmailGroupedAdministratorLists> {
  const resp = await request('get', api_react_v1_administrator_lists_path());
  return resp.administrator_lists;
}

export interface AdministratorListsParams {
  connectedProviderId?: number | string;
  administratorId?: number | string;
  listType?: string | string[];
}

export const getAdministratorLists = async ({
  connectedProviderId,
  administratorId,
  listType,
}: AdministratorListsParams): Promise<AdministratorList[]> => {
  const identifiers = {};
  if (connectedProviderId) identifiers['connectedProviderId'] = connectedProviderId;
  if (administratorId) identifiers['administratorId'] = administratorId;
  if (listType) identifiers['listType'] = listType;

  const resp = await getRecords({
    model: 'AdministratorList',
    attributes: ['id', 'listName', 'color', 'listType', 'identityCount'],
    order: { listName: 'asc' },
    identifiers,
  });

  return resp.result;
};

export const getArticleAdministratorLists = async (
  articleId: number | string
): Promise<AdministratorList[]> => {
  const resp = await getRecords({
    model: 'AdministratorList',
    attributes: ['id', 'listName', 'color', 'listType', 'identityCount'],
    order: { createdAt: 'desc' },
    identifiers: { articleId: articleId },
  });

  return resp.result;
};

export const getPaginatedAdministratorLists = async (page: number) =>
  await getRecords({
    model: 'AdministratorList',
    attributes: ['id', 'listName', 'color', 'listType', 'identityCount'],
    order: { listName: 'asc' },
    page: page,
    maxPerPage: MAX_LISTS_PER_PAGE,
  });

export const getAdministratorList = async (
  administratorListId: string | number
): Promise<AdministratorList> => {
  const resp = await getRecord({
    id: administratorListId,
    model: 'AdministratorList',
    attributes: [
      'administratorId',
      'color',
      'entities',
      'entityJoinTypes',
      'guiFilters',
      'id',
      'identityCount',
      'isArchived',
      'isShared',
      'lastPopulatedSummary',
      'listDescription',
      'listName',
      'listType',
      'query',
      'status',
    ],
    relations: {
      article: { attributes: ['id', 'title'] },
      entities: { attributes: ['id', 'name', 'entity_type'] },
    },
  });
  return resp.result;
};

export const archiveAdministratorList = async (id: number | string) => {
  return await request('patch', archive_crm_administrator_list_path(id));
};

export async function autocompleteAdministratorLists({
  fragment,
  scope = 'manual',
}: {
  fragment: string;
  scope?: 'manual' | 'pdl_friendly';
}): Promise<AutocompleteRecord[]> {
  const resp = await request(
    'get',
    api_react_v1_autocomplete_administrator_lists_path({ fragment: fragment, scope: scope })
  );
  return resp.administrator_lists;
}

export const createAdministratorList = async (
  values: AdministratorListSanitizedValues
): Promise<CreateRecordResponse> => {
  const resp = await request(
    'post',
    api_react_v1_crm_administrator_lists_path({ administrator_list: values })
  );
  return resp;
};

export const updateAdministratorList = async (
  values: AdministratorListSanitizedValues
): Promise<CreateRecordResponse> => {
  const { id, ...sanitizedValues } = values;
  const resp = await request(
    'patch',
    api_react_v1_crm_administrator_list_path(id, { administrator_list: sanitizedValues })
  );
  return resp;
};

export const deleteAdministratorList = async (
  id: number | string
): Promise<CreateRecordResponse> => {
  const resp = await request('delete', api_react_v1_crm_administrator_list_path(id));
  return resp;
};

export const getAdministratorListEdit = async (
  id: number | string
): Promise<AdministratorListFormValues> => {
  const resp = await request('get', edit_api_react_v1_crm_administrator_list_path(id));
  return resp;
};

export const getSubscribedAdministratorLists = async (
  filter: string
): Promise<AdministratorList[]> => {
  const resp = await request('get', api_react_v1_crm_administrator_lists_path({ filter: filter }));
  return resp.administrator_lists;
};
