import { useQuery } from 'react-query';

import { getCrmIdentities } from '../utils/backend-api/identities';
import { getDataImports } from '../utils/backend-api/identities-import';
import { DataImport } from '../utils/backend-api/models/dataImport';
import { Identity } from '../utils/backend-api/models/identity';

export const CRM_IDENTITIES_KEY = 'crmIdentities';
export const CRM_IDENTITIES_COUNT_KEY = 'crmIdentitiesCount';
export const CRM_DATA_IMPORTS_KEY = 'crmDataImports';
export const DEFAULT_PAGINATION_LIMIT = 40;

export const EXACT_MATCH_QUERY = 'exact_match';
export const SUBSTRING_QUERY = 'substring';
export const DEFAULT_QUERY_TYPE = EXACT_MATCH_QUERY;

export interface IdentityData {
  count: number;
  identities: Identity[];
}

export interface IdentityCountData {
  count: number;
}

export const useCRMIdentities = ({
  offset,
  administratorListId,
  query,
  filters,
  scope,
  metadata,
  aiSearch,
  queryEnabled,
  queryType,
  limit,
}) => {
  const {
    data: identitiesData,
    isLoading: isLoadingIdentities,
    isFetching: isFetchingIdentities,
  } = useQuery<IdentityData>(
    [CRM_IDENTITIES_KEY, administratorListId, query, queryType, filters, offset, scope, metadata],
    () => {
      return getCrmIdentities({
        limit: limit || DEFAULT_PAGINATION_LIMIT,
        offset: offset,
        administrator_list: administratorListId || '',
        query: query || '',
        query_type: queryType || '',
        filters: filters || {},
        metadata: metadata || {},
        aiSearch: aiSearch || false,
        scope: scope,
      });
    },
    {
      refetchOnWindowFocus: false,
      enabled: queryEnabled,
    }
  );
  return { identitiesData, isLoadingIdentities, isFetchingIdentities };
};

export const useCRMDataImports = (importType = null) => {
  const { data: dataImportsData, isLoading: isLoadingDataImports } = useQuery<DataImport[]>(
    [CRM_DATA_IMPORTS_KEY],
    () => getDataImports(importType)
  );
  return { dataImportsData, isLoadingDataImports };
};
