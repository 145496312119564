import { SelectFieldOption } from '@utils/interfaces';

export const ALL_CHECKED_ERROR = 'llChecked';

export const META_IMAGE_DELETED = 'deleted';

export const DEFAULT_TIPTAP_CONTENT_TEMPLATE = `
<h3>
  Body content headline (h3)
</h3>
<ul>
  <li>
    Some value prop
  </li>
  <li>
    Some other value prop
  </li>
</ul>
<p>
  The above bullets will be red checkmarks on the site.
</p>
`;
export const DEFAULT_VERIFICATION_TEXT = `
By checking the box, you agree to be contacted by The Information in order to receive the above-described products and/or services and communications in connection therewith. Read our <a href="https://www.theinformation.com/privacy" target="_blank">privacy policy</a> for more information.
`;

export enum FormActions {
  Save = 'Save',
  Publish = 'Publish',
  Unpublish = 'Unpublish',
}

export const INITIAL_STATE_LANDING_PAGE = {
  action: FormActions.Save,
  advertisementId: null,
  confirmationText: '',
  formButtonText: 'Submit',
  formFields: [
    'companyName',
    'companySize',
    'jobLevel',
    'jobFunction',
    'name',
    'organizationIndustry',
    'organizationSubIndustry',
    'postalCode',
    'subJobFunction',
    'titleAtCompany',
  ],
  formHeadline: '',
  formVerificationText: '',
  headline: '',
  leadType: '',
  metaDescription: '',
  metaImageUrl: undefined,
  metaImageImgixUrl: undefined,
  metaNoindex: true,
  metaTitle: '',
  name: '',
  newsletter: {
    value: null,
    label: '',
  },
  newsletterId: null,
  pageContentsHtml: '',
  pageContentsJson: '{}',
  publishedAt: null,
  slug: '',
  subheadline: '',
};

export const FORM_FIELD_OPTIONS: SelectFieldOption[] = [
  { label: 'Name', value: 'name' },
  { label: 'Company Name', value: 'companyName' },
  { label: 'Title at Company', value: 'titleAtCompany' },
  { label: 'Job Level', value: 'jobLevel' },
  { label: 'Job Function', value: 'jobFunction' },
  { label: 'Sub Job Function', value: 'subJobFunction' },
  { label: 'Organization Industry', value: 'organizationIndustry' },
  { label: 'Organization Sub Industry', value: 'organizationSubIndustry' },
  { label: 'Company Size', value: 'companySize' },
  { label: 'Postal Code', value: 'postalCode' },
];

export const STATUS_OK = 'ok';
