import axios from 'axios';

import { PUBLIC_URL } from '../../src/env';

const axiosInstance = axios.create({
  baseURL: PUBLIC_URL,
});

export default async function request(verb, endpoint, params = {}, isFormData = false) {
  let data;
  if (verb === 'get') {
    // if request is get, params grabbed from query string;
    data = { params: { ...params } }; // GET
  } else if (verb === 'delete') {
    // if request is delete, data key is used
    data = { data: { ...params } }; // DELETE
  } else if (isFormData) {
    data = params;
  } else {
    // otherwise, we don't need params or data key
    data = { ...params }; // POST, PATCH, PUT;
  }

  // add CSRF token if it's on the page
  const token = document.querySelector('[name=csrf-token]')?.content;
  axiosInstance.defaults.headers.common['X-CSRF-TOKEN'] = token;

  const req = axiosInstance[verb](endpoint, data);

  try {
    return (await req).data;
  } catch (err) {
    let message = err.response.data.message;
    throw Array.isArray(message) ? message : [message];
  }
}
