import React from 'react';
import { Link, useParams } from 'react-router-dom';

import usePeopleSearchDocument from '@hooks/usePeopleSearchDocument';
import { crm_identity_path } from '@routes';

import styles from './styles.module.css';

const PeopleSearchDocument = () => {
  const { identityId } = useParams();
  const {
    peopleSearchDocument: psdHighConfidence,
    isLoadingPeopleSearchDocument: isLoadingPSDHightConfidence,
  } = usePeopleSearchDocument(parseInt(identityId), 'high');
  const {
    peopleSearchDocument: psdMediumConfidence,
    isLoadingPeopleSearchDocument: isLoadingPSDMediumConfidence,
  } = usePeopleSearchDocument(parseInt(identityId), 'medium');

  if (!identityId) {
    return <h2>Missing identity Id</h2>;
  }

  return (
    <>
      <h2 className={styles.header}>People Search Document for Identity {identityId}:</h2>
      <Link to={crm_identity_path(identityId)} className={styles.backLink}>
        Back to Identity
      </Link>

      {isLoadingPSDHightConfidence ? (
        <div>Loading High Confidence data</div>
      ) : (
        <div>
          {Object.entries(psdHighConfidence['people_search_document']).map(([key, content]) => (
            <div
              className={styles.documentStatement}
              key={`${key}`}
              dangerouslySetInnerHTML={{
                __html: `${content}`,
              }}
            ></div>
          ))}
        </div>
      )}

      {isLoadingPSDMediumConfidence ? (
        <div>Loading Medium Confidence data</div>
      ) : (
        <div>
          {Object.entries(psdMediumConfidence['people_search_document']).map(([key, content]) => (
            <div
              className={styles.documentStatement}
              key={`${key}`}
              dangerouslySetInnerHTML={{
                __html: `${content}`,
              }}
            ></div>
          ))}
        </div>
      )}
    </>
  );
};

export default PeopleSearchDocument;
