import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { GroupPost } from '../../../utils/backend-api/group-posts';
import Card from './Card';

const CardList = ({ groupPosts }: { groupPosts?: GroupPost[] }) => {
  return (
    <div className="scheduled-shares-container">
      <Row>
        <Col className="scheduled-share-history mt-4">
          <h3 className="section-header">Group posts</h3>
          <ul className="list-unstyled">
            {groupPosts?.length ? (
              groupPosts.map((gp) => <Card groupPost={gp} key={gp.id} />)
            ) : (
              <li className="placeholder-list-item">No group posts</li>
            )}
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default CardList;
