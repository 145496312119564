import React from 'react';

import { useCRMDataImports } from '../../../../hooks/useIdentities';
import DataImportRow from './DataImportRow';

const IMPORT_TYPE_PEOPLE_SEARCH = 'people_search';

const ArchivedSearches = () => {
  const { dataImportsData, isLoadingDataImports } = useCRMDataImports(IMPORT_TYPE_PEOPLE_SEARCH);

  return isLoadingDataImports ? (
    <p>loading …</p>
  ) : (
    <div>
      {dataImportsData.map((dataImport) => (
        <DataImportRow key={dataImport.id} dataImport={dataImport} />
      ))}
    </div>
  );
};

export default ArchivedSearches;
