import * as Yup from 'yup';
import moment from 'moment';

const LinkedinAdSchema = () =>
  Yup.object().shape({
    linkedinAdAccount: Yup.object().shape({
      label: Yup.string().nullable(),
      value: Yup.number().required('Linkedin Ad account is required'),
    }),
    linkedinAdType: Yup.object().shape({
      label: Yup.string().nullable(),
      value: Yup.string().required('Advertisment Type is required'),
    }),
    campaignName: Yup.string().required('Campaign Name is required'),
    facetTargets: Yup.array().min(1).required('Audience target is required'),
    imageAd: Yup.mixed().when('linkedinAdType', (value) => {
      if (value.label === 'Image Ad') {
        return Yup.mixed().required('Image Ad is Required');
      }
    }),
    totalBudget: Yup.number()
      .typeError('Total Budget should be a number')
      .min(10, 'Minimum budget is $10')
      .required('Total Budget is required'),
    adText: Yup.string().when('linkedinAdType', (value) => {
      if (value.label === 'Image Ad') {
        return Yup.string()
          .max(600, 'Must be 25 characters or less')
          .required('Linkedin Ad body is required');
      } else {
        return Yup.string()
          .max(25, 'Must be 25 characters or less')
          .required('Linkedin Ad body is required');
      }
    }),

    startAt: Yup.date()
      .min(moment().toISOString(), 'Scheduled time must be in the future.')
      .required('Start date is required.'),
    adTitle: Yup.string().when('linkedinAdType', (value) => {
      if (value.label == 'Image Ad') {
        return Yup.string().max(25, 'Must be 25 characters or less');
      } else {
        return Yup.string()
          .max(25, 'Must be 25 characters or less')
          .required('Ad Title is Required');
      }
    }),
    adShareUrl: Yup.string().url().required('URL is Required'),
  });

export default LinkedinAdSchema;
