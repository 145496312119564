import * as Yup from 'yup';

const relevantCompanyIdentitiesSchema = () =>
  Yup.object().shape({
    companies: Yup.array(),
    excludeSubscribers: Yup.boolean(),
    // TODO: Add after we have recipients endpoint in notif-sender
    // excludeAlreadyNotified: Yup.boolean(),
    excludeVIPs: Yup.boolean(),
    includedLists: Yup.array(),
    excludedLists: Yup.array(),
  });

export default relevantCompanyIdentitiesSchema;
