import clsx from 'clsx';
import React, { ReactNode, useState } from 'react';

import styles from './styles.module.css';

interface ExpandableCellProps {
  children: ReactNode;
  hasContent: boolean;
}

const ExpandableCell = ({ children, hasContent }: ExpandableCellProps) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <td className={styles.fixedCellWidth}>
      {hasContent && (
        <div className="d-flex align-items-start">
          <div className={clsx({ [styles.textTruncate]: !expanded })}>{children}</div>
          <button
            className="btn btn-sm btn-light font-monospace text-nowrap ms-2 py-0"
            onClick={(e) => {
              e.stopPropagation();
              setExpanded((prevState) => !prevState);
            }}
          >
            {expanded ? '-' : '+'}
          </button>
        </div>
      )}
    </td>
  );
};

export default ExpandableCell;
