import React from 'react';
import { Col, Modal, ModalBody, ModalTitle, Row } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';

import { useIdentity } from '../../../hooks';
import { crm_identity_path, crm_identity_people_search_document_path } from '../../../routes';
import { AdministratorList } from '../../../utils/backend-api/administrator-lists';
import { IDENTITY_PROFILE_FIELDS } from '../../../utils/backend-api/identities';
import { stringifyObject } from '../../../utils/helpers';
import { pathToUrl } from '../../../utils/helpers';
import AdministratorListTag from '../AdministratorListTag';
import AddAdministratorListForm from './AddAdministratorListForm';
import AddSubscriptionForm from './AddSubscriptionForm';
import AiSummariesForm from './AiSummariesForm';
import CustomEmailsList from './CustomEmailsList';
import EditableFields from './EditableFields';
import EmailForm from './EmailForm';
import IdentityCheckbox from './IdentityCheckbox';
import IdentityNotesList from './IdentityNotesList';
import NotesForm from './NotesForm';
import Section from './Section';
import SubscriptionsList from './SubscriptionsList';
import styles from './styles.module.css';

export const IDENTITY_KEY = 'identity';

const IdentityModal = ({ identityId, setShowIdentityModal }) => {
  const { identity, isLoadingIdentity } = useIdentity(identityId);

  const getAdministratorListFor = (administratorListId): AdministratorList => {
    const filteredLists = identity.administratorLists.filter(
      (adminList) => adminList.id === administratorListId
    );
    return filteredLists[0];
  };

  return (
    <Modal dialogClassName={styles.identityModal} show={true}>
      {isLoadingIdentity ? (
        <>
          <Modal.Header>Loading...</Modal.Header>
          <ModalBody></ModalBody>
        </>
      ) : (
        <>
          <Modal.Header>
            <ModalTitle>
              <CopyToClipboard
                text={pathToUrl(crm_identity_path(identity.id))}
                onCopy={() => toast.success('share link copied to clipboard')}
              >
                <button className="btn btn-plain btn-link m-0 me-2 h-auto">
                  <i className="fa-solid fa-link"></i>
                </button>
              </CopyToClipboard>
              <EditableFields identity={identity} fields={['firstName', 'lastName']} />
            </ModalTitle>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setShowIdentityModal(false);
              }}
            ></button>
          </Modal.Header>
          <ModalBody>
            <Row>
              <Col md={4}>
                <Section header="Emails" id="emails">
                  <ul className={styles.sectionList}>
                    <li>{identity.email} (primary)</li>
                    {identity.implicitPrimary && (
                      <li>
                        <button
                          className="btn btn-sm btn-plain m-0"
                          onClick={() => setShowIdentityModal(identity.implicitPrimary.id)}
                        >
                          {identity.implicitPrimary.email}
                        </button>
                      </li>
                    )}
                    {identity.explicitPrimary && (
                      <li>
                        <button
                          className="btn btn-sm btn-plain m-0"
                          onClick={() => setShowIdentityModal(identity.explicitPrimary.id)}
                        >
                          {identity.explicitPrimary.email}
                        </button>
                      </li>
                    )}
                    {identity.implicitAlternateIdentities.map((alternateIdentity) => (
                      <li key={alternateIdentity.id}>
                        <button
                          className="btn btn-sm btn-plain m-0"
                          onClick={() => setShowIdentityModal(alternateIdentity.id)}
                        >
                          {alternateIdentity.email}
                        </button>
                      </li>
                    ))}
                    {identity.explicitAlternateIdentities.map((alternateIdentity) => (
                      <li key={alternateIdentity.id}>
                        <button
                          className="btn btn-sm btn-plain m-0"
                          onClick={() => setShowIdentityModal(alternateIdentity.id)}
                        >
                          {alternateIdentity.email}
                        </button>
                      </li>
                    ))}
                  </ul>
                </Section>
                <Section header="Lists" id="lists">
                  <AddAdministratorListForm identity={identity} />
                  <ul className={styles.sectionList}>
                    {identity.identityAdministratorLists.map((ial) => (
                      <li key={ial.id} className="mb-1">
                        <AdministratorListTag
                          identityAdministratorList={ial}
                          administratorList={getAdministratorListFor(ial.administratorListId)}
                        />
                      </li>
                    ))}
                  </ul>
                </Section>
                <Section header="Enrichment" id="enrichment">
                  <ul className={styles.sectionList}>
                    {identity.identityProfile &&
                      IDENTITY_PROFILE_FIELDS.map((field) =>
                        identity.identityProfile[field] ? (
                          <li key={field} className={styles.identityListItem}>
                            <span className={styles.fieldName}>{field}</span>:{' '}
                            <span className={styles.fieldValue}>
                              {typeof identity.identityProfile[field] === 'object'
                                ? JSON.stringify(identity.identityProfile[field])
                                : identity.identityProfile[field]}
                            </span>
                          </li>
                        ) : null
                      )}
                  </ul>
                </Section>
                <Section header="People Search" id="peopleSearch">
                  <ul className={styles.sectionList}>
                    <li>
                      <a
                        href={crm_identity_people_search_document_path(identity.id)}
                        className={styles.peopleSearchLink}
                      >
                        View Document
                      </a>
                    </li>
                    <li>
                      <AiSummariesForm identity={identity} />
                    </li>
                    <li>
                      <div className={styles.fieldName}>Latest pinecone index date: </div>
                      <div className={styles.fieldValue}>
                        {identity.latestPeopleSearchIndexDate
                          ? identity.latestPeopleSearchIndexDate
                          : `Never`}
                      </div>

                      <div>
                        <span className={styles.fieldName}>In sync with pinecone?: </span>
                        <span className={styles.fieldValue}>
                          {identity.peopleSearchMetadataUpToDate ? `Yes` : `No`}
                        </span>
                      </div>
                    </li>
                    <li id="metadata">
                      <div className={styles.fieldName}>Current metadata: </div>
                      <pre>{stringifyObject(identity.currentPeopleSearchMetadata)}</pre>
                      <div className={styles.fieldName}>Pinecone metadata: </div>
                      <pre>{stringifyObject(identity.mostRecentPeopleSearchIndexMetadata)}</pre>
                    </li>
                  </ul>
                </Section>
                <Section header="Email Consent" id="emailConsent">
                  <ul className={styles.sectionList}>
                    <li>
                      <IdentityCheckbox
                        value={identity.implicitDoNotEmail}
                        label="Do Not Email Implicit"
                        identityId={identity.id}
                        field="implicitDoNotEmail"
                      />
                    </li>
                    <li>
                      <IdentityCheckbox
                        value={identity.explicitDoNotEmail}
                        label="Do Not Email Explicit"
                        identityId={identity.id}
                        field="explicitDoNotEmail"
                      />
                    </li>
                  </ul>
                </Section>
                <Section header="Subscriptions" id="subscriptions">
                  <ul className={styles.sectionList}>
                    <AddSubscriptionForm identity={identity} />
                    <SubscriptionsList
                      identityId={identity.id}
                      newsletterSubscriptions={identity.currentNewsletterSubscriptionMapWithName}
                    />
                  </ul>
                </Section>
                <Section header="Emails Sent" id="emailsSent">
                  <ul className={styles.sectionList}>
                    <CustomEmailsList customEmails={identity.customEmails} />
                  </ul>
                </Section>
              </Col>

              <Col md={8}>
                <div className={styles.section}>
                  <h4 className={styles.sectionHeader}>Notes:</h4>
                  <IdentityNotesList
                    identityNotes={identity.identityNotes}
                    identityId={identity.id}
                  />
                  <NotesForm identity={identity} />
                </div>
                <div className={styles.section}>
                  <h4 className={styles.sectionHeader}>Email:</h4>
                  <EmailForm identities={[identity]} />
                </div>
              </Col>
            </Row>
          </ModalBody>
        </>
      )}
    </Modal>
  );
};

export default IdentityModal;
