import toast from 'react-hot-toast';
import { useQuery } from 'react-query';

import { Article, getArticle } from '../utils/backend-api/articles';

export const ARTICLE_KEY = 'article';

export default function useArticle(articleId: number | string) {
  const {
    data: article,
    isLoading: isLoadingArticle,
    isError,
  } = useQuery<Article>([ARTICLE_KEY, articleId], () => getArticle(articleId), {
    onError: () => {
      toast.error('Fetching article has failed');
    },
  });

  return { article, isLoadingArticle, isError };
}
