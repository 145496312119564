import { Field, useField } from 'formik';
import React from 'react';

import './CheckBox.scss';

const CheckBoxField = (props) => {
  const { label, name, hint, negativeFillClass, size, onChange } = props;
  const [field, meta, helpers] = useField(name);

  const handleKeyDown = (evt) => {
    // if user users tab to navigate to checkbox, and then hits
    // space bar (key code #32), that should toggle checkbox field
    if (evt.keyCode === 32) {
      evt.preventDefault();
      helpers.setValue(!field.value);
    }
  };

  const handleOnChange = () => {
    helpers.setValue(!field.value);
    if (onChange) {
      onChange(field, !field.value);
    }
  };

  return (
    <div className="CheckBoxField form-group">
      <div
        className={meta.touched && meta.error ? 'is-invalid' : ''}
        tabIndex="0"
        onKeyDown={handleKeyDown}
      >
        <label className={`checkbox-container ${size === 'large' ? '--large' : ''}`}>
          <span className="checkbox-text">{label}</span>
          <Field
            type="checkbox"
            className="checkbox-input"
            id={name}
            checked={field.value}
            name={name}
            {...field}
            onChange={() => handleOnChange()}
          />
          <span className={`checkbox-fill ${negativeFillClass ? 'fill-minus' : ''}`}></span>
        </label>
      </div>
      {hint && <small className="text-muted mt-2">{hint}</small>}
      {meta.touched && meta.error && <div className="invalid-feedback">{meta.error}</div>}
    </div>
  );
};

export default CheckBoxField;
