import { useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ListItem } from '@components/FormFields/VerticalSortableList';
import { NavItem } from '@utils/backend-api/nav_items';
import { ProjectFormValues, navSectionOptions } from '@utils/backend-api/projects';

const useMutableNavItems = (initialNavItems: NavItem[], isLoadingNavItems: boolean) => {
  const { id: projectId } = useParams();
  const { values, setFieldValue } = useFormikContext<ProjectFormValues>();

  const [navItemsState, setNavItemsState] = useState<NavItem[]>(initialNavItems);
  const [filteredNavItemsState, setFilteredNavItemsState] = useState<NavItem[]>([]);

  const updateCurrentNavItem = useCallback(
    (updates: Partial<NavItem>) => {
      const updatedNavItems = navItemsState.map((navItem) => {
        if (navItem.projectId?.toString() === projectId) {
          return { ...navItem, ...updates };
        }
        return navItem;
      });
      setNavItemsState(updatedNavItems);
    },
    [navItemsState, projectId]
  );

  const filterAndSetNavItems = () => {
    const currentProjectNavItem = getCurrentProjectNavItem();
    let newNavItemsState = [...navItemsState];

    // If current project nav item exists, update its section
    if (currentProjectNavItem) {
      currentProjectNavItem.section = values.navSection.value as string;
    } else {
      const newNavItem = {
        id: generateUniqueId(newNavItemsState),
        name: values.navName || values.title,
        projectId: parseInt(projectId, 10),
        section: values.navSection.value as string,
        order: 0,
        path: `/projects/${values.slug}`,
        active: true,
      };
      newNavItemsState = [newNavItem, ...newNavItemsState];
      setNavItemsState(newNavItemsState);
    }

    const filteredItems = newNavItemsState.filter(
      (navItem) => navItem.section === values.navSection.value
    );
    setFilteredNavItemsState(filteredItems);
  };

  const getCurrentProjectNavItem = useCallback(() => {
    return (
      navItemsState?.find((navItem) => navItem.projectId?.toString() === projectId) ?? undefined
    );
  }, [navItemsState, projectId]);

  const generateUniqueId = (navItems: NavItem[]) => {
    // Find the maximum ID in the array and add 1
    const maxId = navItems.reduce((max, item) => (item.id > max ? item.id : max), 0);
    return maxId + 1;
  };

  // Sync Formik values when navItemsState changes
  useEffect(() => {
    setFieldValue('navItems', navItemsState);
    const currentProjectNavItem = getCurrentProjectNavItem();
    if (currentProjectNavItem) {
      setFieldValue('navActive', currentProjectNavItem.active);
      setFieldValue('navName', currentProjectNavItem.name);
      setFieldValue('navSection', {
        label: navSectionOptions.find((option) => option.value === currentProjectNavItem.section)
          ?.label,
        value: currentProjectNavItem.section,
      });
    }
  }, [navItemsState]);

  // Update navItemState when navName or navActive changes
  useEffect(() => {
    if (values.navName) {
      updateCurrentNavItem({ name: values.navName, active: values.navActive });
    }
  }, [values.navName, values.navActive]);

  useEffect(() => {
    if (!isLoadingNavItems) {
      setNavItemsState(initialNavItems);
      setFieldValue('navItems', initialNavItems);
    }
  }, [initialNavItems, isLoadingNavItems, setFieldValue]);

  useEffect(() => {
    if (values.navSection) {
      filterAndSetNavItems();
    }
  }, [navItemsState, values.navSection]);

  return {
    navItemsState,
    filteredNavItemsState,
    handleNavItemChange: (newListItems: ListItem[]) => {
      const otherSectionItems = navItemsState.filter(
        (navItem) => navItem.section !== values.navSection.value
      );
      const newNavItems = newListItems.map((newListItem, index) => {
        const navItem = navItemsState.find((navItem) => navItem.id === newListItem.id);
        return {
          ...navItem,
          order: index,
        };
      });
      const combinedNavItems = [...newNavItems, ...otherSectionItems];
      setNavItemsState(combinedNavItems);
      setFieldValue('navItems', combinedNavItems);
    },
  };
};

export default useMutableNavItems;
