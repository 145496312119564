import React, { Fragment, useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { useVirtualList } from '../../hooks';
import { VIRTUAL_LISTS_KEY, usePaginatedVirtualLists } from '../../hooks/useVirtualLists';
import { virtual_list_path } from '../../routes';
import { deleteVirtualList } from '../../utils/backend-api/virtual-lists';
import VirtualListForm from './VirtualListForm';

const VirtualListsScreen = () => {
  const { virtualListId } = useParams();
  const { virtualListsData, isLoadingVirtualLists } = usePaginatedVirtualLists();
  const { virtualList, isLoadingVirtualList } = useVirtualList(virtualListId);
  const [submitting, setSubmitting] = useState(false);
  const queryClient = useQueryClient();

  const handleDeleteVirtualList = async (virtualListId) => {
    setSubmitting(true);
    try {
      const resp = await deleteVirtualList(virtualListId);
      if (resp.message == 'ok') {
        toast.success('Virtual List has been deleted');
        queryClient.invalidateQueries(VIRTUAL_LISTS_KEY);
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to delete Virtual List - is it being used by a Campaign?');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="container" id="dashboard-page">
      <div className="row">
        <div className="col-12 col-md-6">
          <h1>Virtual Lists</h1>
        </div>
        <div className="mt-4">
          {!virtualListId ? (
            <VirtualListForm />
          ) : !isLoadingVirtualList && virtualList ? (
            <VirtualListForm virtualList={virtualList} />
          ) : null}
        </div>
        <div className="mt-4">
          <table id="virtual-lists-table" className="table table-active">
            <thead>
              <tr>
                <th>Id</th>
                <th>Virtual List Name</th>
                <th>Connected Provider</th>
                <th>Administrator Lists</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {isLoadingVirtualLists ? (
                <tr>
                  <td>Loading</td>
                </tr>
              ) : (
                virtualListsData.pages.map((group, i) => (
                  <Fragment key={i}>
                    {group.result.map((virtualList) => (
                      <tr key={virtualList.id}>
                        <td>
                          <Link to={virtual_list_path(virtualList.id)}>{virtualList.id}</Link>
                        </td>
                        <td>{virtualList.listName}</td>
                        <td>{virtualList.connectedProvider.name}</td>
                        <td>
                          {virtualList.administratorLists.map((al) => `${al.listName}`).join(', ')}
                        </td>
                        <td>
                          <button
                            disabled={submitting}
                            className="btn btn-link btn-sm"
                            onClick={() => handleDeleteVirtualList(virtualList.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </Fragment>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default VirtualListsScreen;
