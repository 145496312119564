import { Form, Formik } from 'formik';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { edit_ad_schedules_path } from '../../../routes';
import { AdScheduleForm } from '../../../utils/backend-api/ad-schedules';
import { AutocompleteOption } from '../../../utils/interfaces';
import { CheckBoxGroup, DateTimeField } from '../../FormFields';
import scheduleSchema from './scheduleSchema';

interface ScheduleFormSectionProps {
  formValues: AdScheduleForm;
  onSubmit: (values) => void;
  updateFormValues: (values) => void;
  handleToggleEditing: () => void;
  scheduling: boolean;
  sectionOptions: { email: AutocompleteOption[]; onsite: AutocompleteOption[] };
}

/**
 * ScheduleFormSection: aditional form section for scheduleing ads within AdvertisementForm.
 * Only used when editing existing advertisements.
 */
const ScheduleFormSection = ({
  formValues,
  onSubmit,
  updateFormValues,
  handleToggleEditing,
  scheduling,
  sectionOptions,
}: ScheduleFormSectionProps) => {
  const { id: advertisementId } = useParams();
  const navigate = useNavigate();

  const handleAddSchedule = () => {
    updateFormValues({ newSchedule: { startDate: '', endDate: '', sections: [] } });
    handleToggleEditing();
  };

  const handleCancel = () => {
    updateFormValues({ newSchedule: null });
    handleToggleEditing();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={onSubmit}
      validationSchema={scheduleSchema}
    >
      {(formik) => (
        <Form className="ScheduleFormSection" noValidate onSubmit={formik.handleSubmit}>
          <div className="mb-5" id="schedule">
            <Row>
              <Col className="text-center">
                <h1>Schedule</h1>
              </Col>
              <Row>
                {scheduling ? (
                  <>
                    <Col xs={2}>
                      <Button variant="success" disabled={formik.isSubmitting} type="submit">
                        Save
                      </Button>
                    </Col>
                    <Col xs={3}>
                      <Button variant="danger" onClick={handleCancel}>
                        Cancel
                      </Button>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col xs={3}>
                      <Button disabled={scheduling} onClick={handleAddSchedule}>
                        New schedule
                      </Button>
                    </Col>
                    <Col xs={2}>
                      <Button
                        variant="outline-info"
                        onClick={() => navigate(edit_ad_schedules_path(advertisementId))}
                      >
                        Edit schedules
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </Row>
            {formik.values.newSchedule && (
              <>
                <hr className="divider" />
                <Col className="align-items-center">
                  <Row>
                    <Col xs={4} className="mb-3">
                      <DateTimeField
                        dateFormat={'MMM dd yyyy'}
                        hideClearButton
                        label="Start Date"
                        maxDaysOut={90}
                        name={`newSchedule.startDate`}
                        showTimeSelect={false}
                      />
                    </Col>
                    <Col xs={4} className="mb-3">
                      <DateTimeField
                        dateFormat={'MMM dd yyyy'}
                        hideClearButton
                        label="End Date"
                        maxDaysOut={90}
                        name={`newSchedule.endDate`}
                        showTimeSelect={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <label className="input-label mb-1 fs-4" htmlFor={`newSchedule.sections`}>
                      Email sections
                    </label>
                    {sectionOptions.email.map((group, idx) => (
                      <Col xs={4} className="mb-3" key={idx}>
                        <CheckBoxGroup name={`newSchedule.sections`} options={group} />
                      </Col>
                    ))}
                  </Row>
                  <Row>
                    <label className="input-label mb-1 fs-4" htmlFor={`newSchedule.sections`}>
                      On site sections
                    </label>
                    {sectionOptions.onsite.map((group, idx) => (
                      <Col xs={4} className="mb-3" key={idx}>
                        <CheckBoxGroup name={`newSchedule.sections`} options={group} />
                      </Col>
                    ))}
                  </Row>
                </Col>
              </>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ScheduleFormSection;
