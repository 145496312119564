import { FieldArray, Formik } from 'formik';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { useAdministratorLists, useConnectedProviders } from '../../hooks/';
import { VIRTUAL_LISTS_KEY } from '../../hooks/useVirtualLists';
import { createVirtualList, updateVirtualList } from '../../utils/backend-api/virtual-lists';
import { DropdownField, TextField } from '../FormFields';
import './Form.scss';
import virtualListSchema from './schema';

const INITIAL_STATE = {
  connectedProvider: { label: '', value: '' },
  listName: '',
  virtualListAdministratorLists: [],
  administratorList: { label: '', value: '' },
};

const VirtualListForm = ({ virtualList = null }) => {
  const isEditing = !!virtualList;
  const queryClient = useQueryClient();
  const { connectedProviderOptions } = useConnectedProviders();
  const { administratorListOptions } = useAdministratorLists();

  const [initialValues, setInitialValues] = useState(
    virtualList
      ? {
          connectedProvider: {
            label: virtualList.connectedProvider.name,
            value: virtualList.connectedProvider.id,
          },
          listName: virtualList.listName,
          administratorList: { label: '', value: '' },
          virtualListAdministratorLists: virtualList.administratorLists.map((al) => ({
            label: al.listName,
            value: al.id,
          })),
        }
      : INITIAL_STATE
  );

  const handleSubmit = async (values, { setSubmitting }) => {
    const { connectedProvider, listName, virtualListAdministratorLists } = values;
    setSubmitting(true);
    const sanitizedParams = {
      virtual_list: {
        connected_provider_id: connectedProvider.value,
        list_name: listName,
        virtual_list_administrator_lists_attributes: virtualListAdministratorLists.map((vlal) => ({
          administrator_list_id: vlal.value,
        })),
      },
    };
    try {
      const resp = isEditing
        ? await updateVirtualList(virtualList.id, sanitizedParams)
        : await createVirtualList(sanitizedParams);
      if (resp.message == 'ok') {
        isEditing
          ? toast.success('Virtual List has been updated')
          : toast.success('Virtual List has been created');
        queryClient.invalidateQueries(VIRTUAL_LISTS_KEY);
      }
    } catch (error) {
      console.error(error);
      toast.error(`Failed to save Virtual List`);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="ScheduledTweetForm">
      <h2>{isEditing ? 'Edit Virtual List' : 'Create Virtual List'}</h2>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={virtualListSchema}
        enableReinitialize={true}
      >
        {(formik) => (
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={6}>
                <DropdownField
                  name="connectedProvider"
                  label="Twitter Ads Account"
                  placeholder="Select a Twitter Ads Account"
                  options={connectedProviderOptions}
                />
              </Col>
            </Row>
            {formik.values.connectedProvider.value ? (
              <>
                <Row>
                  <Col md={6} className="form-group">
                    <label htmlFor="listName">Virtual List Name</label>
                    <TextField name="listName" placeholder="Enter name for this virtual list" />
                  </Col>
                </Row>
                <Row>
                  <FieldArray name="virtualListAdministratorLists">
                    {({ insert, remove, push }) => (
                      <>
                        <Col md={6}>
                          <DropdownField
                            name="administratorList"
                            label="Administrator List"
                            placeholder="Select an administrator list"
                            options={administratorListOptions}
                          />
                          <button
                            type="button"
                            className="secondary float-end"
                            id="add-list-btn"
                            onClick={() => push(formik.values.administratorList)}
                          >
                            Add List
                          </button>
                        </Col>
                        <Col md={4} id="included-lists">
                          <label>Included Lists</label>
                          {formik.values.virtualListAdministratorLists.length > 0 &&
                            formik.values.virtualListAdministratorLists.map((vlal, index) => (
                              <div className="label" key={index} id={`al-${vlal.value}`}>
                                <span>
                                  {vlal.value}: {vlal.label}
                                </span>
                                <span>
                                  <button
                                    type="button"
                                    className="secondary"
                                    onClick={() => remove(index)}
                                  >
                                    &times;
                                  </button>
                                </span>
                              </div>
                            ))}
                        </Col>
                      </>
                    )}
                  </FieldArray>
                </Row>
                <div className="schedule-tweet-btn-wrapper --black-top-border">
                  <Button
                    disabled={formik.isSubmitting}
                    type="submit"
                    className="--black"
                    id="create-virtual-list-button"
                  >
                    {isEditing ? 'Update' : 'Create'} Virtual List
                  </Button>
                </div>
              </>
            ) : null}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default VirtualListForm;
