import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { AuthorResponse, createAuthor } from '../utils/backend-api/authors';

interface AuthorCreateParams {
  name: string;
  email: string;
}

export default function useAuthorCreate() {
  const { mutate, mutateAsync, data, isLoading, isError } = useMutation(
    async (params: AuthorCreateParams) => await createAuthor(params),
    {
      onSuccess: (data: AuthorResponse) => {
        if (data.success) {
          toast.success('Author has been created');
        } else {
          toast.error(`Failed to create Author: ${data.message}`);
        }
      },
      onError: () => {
        toast.error('Creating author has failed');
      },
    }
  );
  return { mutate, mutateAsync, data, isLoading, isError };
}
