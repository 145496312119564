import moment from 'moment';
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  distribution_scheduled_tweet_path,
  distribution_scheduled_tweets_path,
} from '../../../routes';
import { deleteScheduledShare } from '../../../utils/backend-api/scheduled-shares';
import { ConfirmationModal } from '../../BaseUI';

const ScheduledTweetCard = ({
  tweet: {
    email_subject: emailSubject,
    newsletter_name: newsletterName,
    scheduled_share_id: scheduledShareId,
    share_date: shareDateTime,
    share_text: shareText,
    twitter_handle: twitterHandle,
  },
}) => {
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const toggleConfirmationModal = () => setShowDeleteConfirmationModal((prevState) => !prevState);
  const { articleId } = useParams();
  const navigate = useNavigate();

  const deleteTweet = () => {
    try {
      const resp = deleteScheduledShare(articleId, scheduledShareId);

      if (resp.success) {
        toast.success('Tweet has been deleted');
        navigate(distribution_scheduled_tweets_path(articleId));
      } else {
        toast.error('Failed to delete tweet');
      }
    } catch {
      toast.error('Failed to delete tweet');
    }
  };

  return (
    <>
      <li className="scheduled-share-list-item">
        <Row>
          <Col xs={8} className="primary-col">
            <Link to={distribution_scheduled_tweet_path(articleId, scheduledShareId)}>
              {shareText}
            </Link>
            <div className="account-name">
              <Link to={distribution_scheduled_tweet_path(articleId, scheduledShareId)}>
                @{twitterHandle}
              </Link>
            </div>
          </Col>
          <Col xs={4} className="secondary-col">
            <div className="name">
              <Link to={distribution_scheduled_tweet_path(articleId, scheduledShareId)}>
                {emailSubject}
              </Link>
            </div>
            <div className="details mb-4">
              {newsletterName && <div>{newsletterName}</div>}
              {shareDateTime && <div>Scheduled for {moment(shareDateTime).format('lll')}</div>}
            </div>
            <Button onClick={toggleConfirmationModal} className="--white">
              Delete
            </Button>
          </Col>
        </Row>
      </li>
      <ConfirmationModal
        show={showDeleteConfirmationModal}
        close={toggleConfirmationModal}
        confirmationMessage="Are you sure you want to delete this tweet?"
        buttonMessage="Delete"
        onConfirmation={deleteTweet}
      />
    </>
  );
};

export default ScheduledTweetCard;
