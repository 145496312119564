import { useQuery } from 'react-query';

import { ConnectedProvider, getConnectedProvider } from '../utils/backend-api/connected-providers';

export const CONNECTED_PROVIDER_KEY = 'connectedProvider';

export default function useConnectedProvider(connectedProviderId: number | string) {
  const { data: connectedProvider, isLoading: isLoadingConnectedProvider } =
    useQuery<ConnectedProvider>(
      CONNECTED_PROVIDER_KEY,
      async () => await getConnectedProvider(connectedProviderId)
    );

  return { connectedProvider, isLoadingConnectedProvider };
}
