import React from 'react';

import './index.scss';

export default function Pill({ className, text, icon, href = null, onClick }) {
  const pillJSX = (
    <>
      <span className="pill-text">{text}</span>
      {icon && <img src={icon} className="link-icon" />}
    </>
  );

  return href || onClick ? (
    <a href={href} onClick={onClick} className={`Pill is-link ${className || ''}`}>
      {pillJSX}
    </a>
  ) : (
    <span className={`Pill ${className || ''}`}>{pillJSX}</span>
  );
}
