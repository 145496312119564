import { api_react_v1_distribution_relevant_groups_path } from '../../routes';
import request from '../backend-api/request';
import { getRecords } from './universal';

export type RelevantGroup = {
  id: string;
  name: string;
};

export async function getRelevantGroups(): Promise<RelevantGroup[]> {
  const resp = await getRecords({
    model: 'RelevantGroup',
    attributes: ['id', 'url', 'name', 'description'],
  });
  return resp.result;
}

export async function createRelevantGroup(name: string, articleId: number | string) {
  const resp: RelevantGroup = await request(
    'post',
    api_react_v1_distribution_relevant_groups_path(articleId),
    {
      name,
    }
  );
  return resp;
}
