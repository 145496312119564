import {
  api_react_v1_push_notifications_path,
  api_react_v1_send_test_push_notification_path,
} from '../../routes';
import request from './request';
import { getRecord } from './universal';

export interface RecipientOption {
  allChecked?: boolean;
  label: string;
  value: string;
}

export interface PushNotificationBase {
  link: string;
  pushMessage: string;
  pushTitle: string;
  sendAt?: string;
}

export interface PushNotification extends PushNotificationBase {
  createdAt: string;
  formattedExcludedRecipientsString: string;
  formattedIncludedRecipientsString: string;
  status: string;
}

interface AdministratorListOption {
  label: string;
  value: string | number;
}

export interface PushNotificationFormValues extends PushNotificationBase {
  excludedRecipients: RecipientOption[];
  includedRecipients: RecipientOption[];
  administratorList: AdministratorListOption;
}

export interface PushNotificationSanitizedValues extends PushNotificationBase {
  articleId: string;
  excludedRecipients: string;
  includedRecipients: string;
  administratorListId: number | string;
}

export interface TestPushNotificationFormValues extends PushNotificationBase {
  recipients: string[];
}

export async function createPushNotification(
  values: PushNotificationSanitizedValues
): Promise<{ redirectUrl: string }> {
  const resp = await request(
    'post',
    api_react_v1_push_notifications_path({ push_notification: values })
  );
  return resp;
}

export async function sendTestPushNotification(
  values: TestPushNotificationFormValues
): Promise<{ message: string }> {
  const resp = await request(
    'post',
    api_react_v1_send_test_push_notification_path({ push_notification: values })
  );
  return resp;
}

export async function getPushNotification(id: number | string): Promise<PushNotification> {
  const resp = await getRecord({
    model: 'PushNotification',
    id,
    attributes: ['createdAt', 'link', 'pushMessage', 'pushTitle', 'sendAt', 'status'],
    helpers: ['formattedExcludedRecipientsString', 'formattedIncludedRecipientsString'],
  });
  return resp.result;
}
