import toast from 'react-hot-toast';
import { useQuery } from 'react-query';

import { GroupPost, getGroupPosts } from '../utils/backend-api/group-posts';

export const getGroupPostsKey = (articleId: number | string) => `articles/${articleId}/groupPosts`;
export default function useGroupPosts(articleId) {
  const { data: groupPosts, isLoading } = useQuery<GroupPost[]>(
    getGroupPostsKey(articleId),
    async () => await getGroupPosts(articleId),
    {
      onError: () => {
        toast.error('Fetching group posts has failed');
      },
    }
  );
  return { groupPosts, isLoading };
}
