import moment from 'moment';
import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { usePushNotificationCreate } from '../../../hooks';
import {
  PushNotificationFormValues,
  PushNotificationSanitizedValues,
} from '../../../utils/backend-api/push-notifications';
import './ConfirmationModal.scss';

interface ConfirmationModalProps {
  showModal: boolean;
  toggleShowModal: () => void;
  formValues: PushNotificationFormValues;
}

const ConfirmationModal = ({ showModal, toggleShowModal, formValues }: ConfirmationModalProps) => {
  const {
    excludedRecipients,
    includedRecipients,
    link,
    pushTitle,
    pushMessage,
    sendAt,
    administratorList,
  } = formValues;
  const { articleId } = useParams();

  const { createPushNotification, isCreatingPush, errorCreatingPush } = usePushNotificationCreate();

  const handleCloseModal = (e) => {
    if (e.target.classList[0] === 'modal-container') {
      toggleShowModal();
    }
  };

  const handleSubmit = async () => {
    const sanitizedParams: PushNotificationSanitizedValues = {
      ...formValues,
      articleId,
      includedRecipients: includedRecipients.map((el) => el.value).join(','),
      excludedRecipients: excludedRecipients.map((el) => el.value).join(','),
      administratorListId: administratorList.value,
    };
    createPushNotification(sanitizedParams);
  };

  return (
    <>
      {showModal && (
        <div className="ConfirmationModal" onClick={handleCloseModal}>
          <div className="push-modal">
            <Container>
              <Row>
                <Col className="p-4">
                  <Row>
                    <Col xs={12}>
                      <h3>
                        Are you sure you want to {sendAt ? 'schedule' : 'send'} a push notification?
                      </h3>
                    </Col>
                  </Row>
                  <main className="push-modal-content">
                    <Row className="mb-3">
                      <Col xs={12} md={3}>
                        Title:
                      </Col>
                      <Col xs={12} md={9} className="push-info">
                        {pushTitle}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={12} md={3}>
                        Message:
                      </Col>
                      <Col xs={12} md={9} className="push-info">
                        {pushMessage}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={12} md={3}>
                        Link:
                      </Col>
                      <Col xs={12} md={9} className="push-info">
                        {link}
                      </Col>
                    </Row>
                    {sendAt && (
                      <Row className="mb-3">
                        <Col xs={12} md={3}>
                          Scheduled date/time (displayed in your local time):
                        </Col>
                        <Col xs={12} md={9} className="push-info">
                          {moment(sendAt).format('lll')}
                        </Col>
                      </Row>
                    )}
                    <Row className="mb-3">
                      <Col xs={12} md={3}>
                        Include Recipients:
                      </Col>
                      <Col xs={12} md={9}>
                        {includedRecipients.length <= 0 ? (
                          <span className="no-recipients --red">No Recipients</span>
                        ) : (
                          includedRecipients.map((el) => el.label).join(', ')
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={12} md={3}>
                        Exclude Recipients:
                      </Col>
                      <Col xs={12} md={9}>
                        {excludedRecipients.map((el, index) => (
                          <span key={index} className="m-1">
                            {el.label}
                          </span>
                        ))}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={12} md={3}>
                        Administrator List:
                      </Col>
                      <Col xs={12} md={9}>
                        {administratorList.label}
                      </Col>
                    </Row>
                  </main>
                  <footer className="push-modal-footer mt-3">
                    <Row>
                      <Col xs={12} md={5}>
                        <div
                          className={`failed-notification --red ${
                            errorCreatingPush ? 'active' : ''
                          }`}
                        >
                          Something went wrong. Please try again.
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="action-wrapper">
                        <Button
                          disabled={isCreatingPush}
                          onClick={toggleShowModal}
                          className="continue-button button --white"
                          id="back-to-edit"
                        >
                          Back to Edit
                        </Button>
                        <Button
                          disabled={isCreatingPush}
                          onClick={handleSubmit}
                          className="send-button button --black"
                          id="send"
                        >
                          {isCreatingPush ? 'Processing' : 'Send'}
                        </Button>
                      </Col>
                    </Row>
                  </footer>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmationModal;
