import * as yup from 'yup';

import { imageTest } from '../../../utils/helpers';

const projectSchema = () =>
  yup.object().shape(
    {
      slug: yup.string().required('Slug is required'),
      title: yup.string().required('Title is required'),
      titleCallout: yup.string().notRequired(),
      byline: yup.string(),
      description: yup.string().required('Description is required'),
      desktopImageUrl: yup
        .mixed()
        .required('Desktop image is required')
        .test('fileType', 'Unsupported File Format', imageTest),
      mobileImageUrl: yup
        .mixed()
        .notRequired()
        .when('mobileImageUrl', {
          is: (mobileImageUrl) => mobileImageUrl,
          then: yup.mixed().test('fileType', 'Unsupported File Format', imageTest),
        }),
      googleSheetId: yup.string().notRequired(),
      partnerUnlockCode: yup.string().notRequired(),
      accessLevel: yup
        .object()
        .shape({
          label: yup.string(),
          value: yup.number(),
        })
        .notRequired(),
      article: yup
        .object()
        .shape({
          label: yup.string(),
          value: yup.number(),
        })
        .notRequired(),
      theme: yup
        .object()
        .shape({
          label: yup.string(),
          value: yup.string(),
        })
        .notRequired(),
      metaTitle: yup.string(),
      metaDescription: yup.string(),
      metaImageUrl: yup
        .mixed()
        .notRequired()
        .when('metaImageUrl', {
          is: (metaImageUrl) => metaImageUrl,
          then: yup.mixed().test('fileType', 'Unsupported File Format', imageTest),
        }),
      navItems: yup
        .array()
        .of(
          yup.object().shape({
            id: yup.number().notRequired(),
            name: yup.string().required('Name is required'),
            path: yup.string().required('Path is required'),
            section: yup.string().required('Section is required'),
            order: yup.number().required('Order is required'),
            active: yup.boolean().required('Active status is required'),
          })
        )
        .notRequired('Navigation items are not always required'),
      notes: yup.string().notRequired(),
      leadType: yup.string().notRequired(),
      projectMedia: yup
        .array()
        .of(
          yup.object().shape({
            id: yup.string().notRequired(),
            title: yup.string().notRequired('Media Title is required'),
            elementId: yup.string().notRequired('Element ID is required'),
            height: yup
              .number()
              .notRequired('Height is required')
              .positive('Height must be a positive number'),
            order: yup
              .number()
              .notRequired('Order is required')
              .min(0, 'Order must be zero or greater'),
            src: yup.string().notRequired('Source is required'),
            layout: yup
              .object()
              .shape({
                label: yup.string().required('Layout label is required'),
                value: yup.string().required('Layout value is required'),
              })
              .notRequired('Layout is required'),
            mediaType: yup
              .object()
              .shape({
                label: yup.string().required('Media Type label is required'),
                value: yup.string().required('Media Type value is required'),
              })
              .notRequired('Media Type is required'),
          })
        )
        .notRequired('Media is not always required'),
      jsonLdData: yup
        .object()
        .notRequired()
        .shape({
          name: yup.string().nullable(),
          alternateName: yup
            .array()
            .of(yup.object().shape({ label: yup.string(), value: yup.string() })),
          keywords: yup
            .array()
            .of(yup.object().shape({ label: yup.string(), value: yup.string() })),
          description: yup.string().nullable(),
          email: yup.string().nullable(),
          spatialCoverage: yup.string().nullable(),
          temporalCoverage: yup.string().nullable(),
        }),
      publishedAt: yup.date().nullable(),
    },
    [
      ['mobileImageUrl', 'mobileImageUrl'],
      ['metaImageUrl', 'metaImageUrl'],
    ]
  );

export default projectSchema;
