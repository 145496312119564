import { bulk_create_api_react_v1_crm_information_comps_path } from '../../routes';
import request from './request';

export const createInformationComps = async (
  duration,
  identityIds,
  selectAll,
  filters,
  query,
  administratorListId,
  scope = null
) => {
  return request(
    'post',
    bulk_create_api_react_v1_crm_information_comps_path(null, {
      duration: duration,
      identity_ids: identityIds,
      select_all: selectAll,
      filters: filters,
      query: query,
      list_id: administratorListId,
      scope: scope,
    })
  );
};
