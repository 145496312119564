import React, { useEffect, useMemo, useState } from 'react';

import { autocompleteEntities } from '../../../../../utils/backend-api/entities';
import {
  IdentityProfileField,
  autocompleteFieldOptions,
} from '../../../../../utils/backend-api/identity-profiles';
import { AutocompleteOption } from '../../../../../utils/interfaces';
import { AutocompleteField, MultiValueAutocompleteField, TextField } from '../../../../FormFields';
import { FILTER_AUTOCOMPLETE_TYPE, FILTER_ENTITIES_TYPE } from './options';

interface ValueInputParams {
  fieldType: string;
  identityProfileField: IdentityProfileField;
}

const ValueInput = ({ fieldType, identityProfileField }: ValueInputParams) => {
  const [autocompleteOptions, setAutocompleteOptions] = useState<AutocompleteOption[]>([]);
  const shouldShowAutoCompleteIdentityProfilesField = useMemo(() => {
    return identityProfileField && fieldType === FILTER_AUTOCOMPLETE_TYPE;
  }, [fieldType, identityProfileField]);

  useEffect(() => {
    const fetchOptions = async () => {
      if (shouldShowAutoCompleteIdentityProfilesField) {
        const options = await autocompleteFieldOptions({
          fragment: '',
          field: identityProfileField,
          maxResults: 25,
        });
        setAutocompleteOptions(options);
      }
    };
    fetchOptions();
  }, [identityProfileField, shouldShowAutoCompleteIdentityProfilesField]);

  // if field type is "entities", then we want to render a multi-value autocomplete field
  if (fieldType === FILTER_ENTITIES_TYPE) {
    return (
      <MultiValueAutocompleteField
        name="filterValue"
        placeholder="Search entities"
        fetchMethod={(value: string) =>
          autocompleteEntities({
            fragment: value,
            word_type: 'proper',
            order_entities_by: ['pdl_verified'],
          })
        }
        showPills
      />
    );
  }

  // if we're filtering by an identity profile field, then we want to render an autocomplete field
  if (shouldShowAutoCompleteIdentityProfilesField) {
    return (
      <AutocompleteField
        name="filterValue"
        placeholder="Search options"
        options={autocompleteOptions}
        fetchMethod={(value: string) =>
          autocompleteFieldOptions({ fragment: value, field: identityProfileField, maxResults: 25 })
        }
        // allow custom search entries but don't actually create any records
        createMethod={() => undefined}
        // overwrite create option to not say "Create new" since we're just searching
        formatCreateLabel={(value: string) => `Search for "${value}"`}
        valueIsInteger
      />
    );
  }

  // otherwise, we want to render a text field
  return <TextField placeholder="Filter value" name="filterValue" />;
};

export default ValueInput;
