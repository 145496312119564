import React, { useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Link, NavLink, useSearchParams } from 'react-router-dom';

import { useAdminContext } from '../../../contexts/AdminContext';
import { useFilterContext } from '../../../contexts/FilterContext';
import useAdministratorList from '../../../hooks/useAdministratorList';
import { useSubscribedAdministratorLists } from '../../../hooks/useAdministratorLists';
import {
  crm_administrator_lists_path,
  crm_new_administrator_list_path,
  crm_new_identities_import_path,
  crm_identities_people_search_path,
} from '../../../routes';
import { getCrmSideNavData } from '../../../utils/backend-api/crm-side-nav';
import AdministratorListItem from './AdministratorListItem';
import ConnectedProvider from './ConnectedProvider';
import Filters from './Filters';
import './SideNav.scss';

export const CRM_SIDE_NAV_DATA_KEY = 'crmSideNavData';

const CrmSideNav = () => {
  const { crmScope, setCrmScope, setAdministratorListFilter } = useFilterContext();
  const { allowedToManageIdentities } = useAdminContext();
  const [searchParams] = useSearchParams();
  const adminListQueryId = searchParams.get('administrator_list');
  const { administratorList, isLoadingAdministratorList } = useAdministratorList(adminListQueryId);
  const { data: sideNavData, isLoading: isLoadingSideNavData } = useQuery(
    CRM_SIDE_NAV_DATA_KEY,
    async () => await getCrmSideNavData()
  );
  const {
    administratorLists: subscribedAdministratorLists,
    isLoadingAdministratorLists: isLoadingSubscribedAdministratorLists,
  } = useSubscribedAdministratorLists('subscribed');

  useEffect(() => {
    if (!isLoadingAdministratorList && administratorList) {
      setAdministratorListFilter(administratorList);
    }
  }, [isLoadingAdministratorList]);

  // TODO: Replace with regular links when all CRM routes are hosted client-side from React
  const navigateToLink = (link) => {
    window.location.href = link;
  };

  if (isLoadingSideNavData) return null;
  return (
    <Nav className="CrmSideNav d-block">
      <div className="crm-sidebar administrator-list">
        <ul className="list-unstyled m-0">
          <li>
            <a href={crm_identities_people_search_path()} className="peopleSearchLink">
              Experimental Search
            </a>
          </li>
          <li className="d-flex justify-content-between pe-2 flex-wrap">
            <NavLink to="#" onClick={() => navigateToLink(crm_new_identities_import_path())}>
              + Add People
            </NavLink>
            <Link to={crm_new_administrator_list_path()}>+ Create List</Link>
          </li>

          <div className="mt-5 pe-3">
            <h3 className="section-header">Scope &amp; Filters</h3>
            {allowedToManageIdentities && (
              <div className="pt-1">
                <input
                  type="checkbox"
                  id="global-scope-checkbox"
                  className="filter-checkbox"
                  onClick={() => setCrmScope(crmScope === 'global' ? 'administrator' : 'global')}
                  value={crmScope === 'global' ? 'checked' : ''}
                />
                <label className="ms-2 filter-checkbox-label">Scope Globally</label>
              </div>
            )}

            <Filters />
          </div>

          {isLoadingSubscribedAdministratorLists ? (
            'Loading'
          ) : subscribedAdministratorLists?.length > 0 ? (
            <li className="mt-5 pe-3" id="my-lists">
              <div className="d-flex justify-content-between flex-wrap">
                <h3 className="section-header">My Lists</h3>
                <div style={{ fontSize: '13px' }}>
                  <Link to={crm_administrator_lists_path()}>Manage Lists</Link>
                </div>
              </div>

              <ul className="list-unstyled overflow-hidden" id="subscribable-list">
                {subscribedAdministratorLists.map((list) => (
                  <AdministratorListItem
                    administratorList={list}
                    currentAdmin={sideNavData.currentAdmin}
                    isSubscribed={true}
                    key={list.id}
                  />
                ))}
              </ul>
            </li>
          ) : null}

          {sideNavData.currentAdminConnectedProviders?.length > 0 && (
            <li className="mt-3 pe-3">
              <div className="d-flex justify-content-between flex-wrap">
                <h3 className="section-header">Accounts</h3>
                <div style={{ fontSize: '13px' }}>
                  <a className="me-2" href="/auth/google_oauth2">
                    +&nbsp;Google
                  </a>
                  <a href="/auth/twitter">+&nbsp;Twitter</a>
                </div>
              </div>

              <ul className="list-unstyled">
                {sideNavData.currentAdminConnectedProviders.map((c) => (
                  <ConnectedProvider connectedProvider={c} key={c.id} />
                ))}
              </ul>
            </li>
          )}
        </ul>
      </div>
    </Nav>
  );
};

export default CrmSideNav;
