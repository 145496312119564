import moment from 'moment';
import * as Yup from 'yup';

const customEmailFormSchema = () =>
  Yup.object().shape({
    fromIdentity: Yup.object().shape({
      value: Yup.number().nullable(), // user_id
      identityId: Yup.number().required('Email sender is required'),
      label: Yup.string(),
      email: Yup.string(),
      pictureUrl: Yup.string().nullable(),
      fieldValue: Yup.string().nullable(),
    }),
    subject: Yup.string().required('Email subject is required'),
    body: Yup.string().required('Email body is required'),
    scheduledTime: Yup.date()
      .min(moment().toISOString(), 'Scheduled time must be in the future')
      .nullable(),
  });

export default customEmailFormSchema;
