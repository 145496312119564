import moment from 'moment';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { usePushNotification } from '../../../hooks';
import { new_push_notification_path, root_path } from '../../../routes';
import { capitalize } from '../../../utils/helpers';
import './ShowPushNotification.scss';

const statusColorMapper = {
  scheduled: '--yellow',
  queued: '--orange',
  processing: '--light-green',
  sent: '--green',
};

const ShowPushNotification = () => {
  const { id: pushNotificationId, articleId } = useParams();
  const { pushNotification } = usePushNotification(pushNotificationId);

  if (!pushNotification) return null;
  return (
    <Container className="ShowPushNotification">
      <Row>
        <Col md={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 2 }}>
          <Row className="push-notification-wrapper mt-4">
            <Col className="push-notification-header">
              <h2>Push Notification Confirmation</h2>
            </Col>
          </Row>
          <Row className="confirmation-info">
            <Col md={6}>Title:</Col>
            <Col md={6}>{pushNotification.pushTitle}</Col>
          </Row>
          <Row className="confirmation-info">
            <Col md={6}>Message:</Col>
            <Col md={6} id="push-message">
              {pushNotification.pushMessage}
            </Col>
          </Row>
          <Row className="confirmation-info">
            <Col md={6}>Link:</Col>
            <Col md={6}>{pushNotification.link}</Col>
          </Row>
          <Row className="confirmation-info">
            <Col md={6}>Included Recipients:</Col>
            <Col md={6}>{pushNotification.formattedIncludedRecipientsString}</Col>
          </Row>
          <Row className="confirmation-info">
            <Col md={6}>Excluded Recipients:</Col>
            <Col md={6}>{pushNotification.formattedExcludedRecipientsString || 'N/A'}</Col>
          </Row>
          <Row className="confirmation-info">
            <Col md={6}>Scheduled for:</Col>
            <Col md={6}>
              {moment(pushNotification.sendAt || pushNotification.createdAt).format('lll')}
            </Col>
          </Row>
          <Row className="confirmation-info">
            <Col md={6}>Status:</Col>
            <Col md={6} className={`status ${statusColorMapper[pushNotification.status]}`}>
              <strong>{capitalize(pushNotification.status)}</strong>
            </Col>
          </Row>
          {!articleId && (
            <Row className="push-notification-actions">
              <Col id="action">
                <a href={root_path()} className="button --white">
                  Back to Home
                </a>
                <a href={new_push_notification_path()} className="button --black">
                  Send New Push Notification
                </a>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ShowPushNotification;
