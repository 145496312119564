import clsx from 'clsx';
import { FieldArray, useFormikContext } from 'formik';
import { isString } from 'lodash';
import React, { useState } from 'react';

import closeIcon from '@images/x_icon.svg';

import {
  PeopleSearchFormValues,
  PeopleSearchGroupFormValues,
} from '@utils/backend-api/people-search';

import SearchAttributesGroup, { DEFAULT_SEARCH_ATTRIBUTE } from '../Group';
import {
  ALL_CONDITION_JOIN_OPTION,
  FieldOptionsMapper,
  INITIAL_FIELD_OPTIONS_MAPPER,
} from '../options';
import styles from './styles.module.css';

export const DEFAULT_GROUP_VALUE: PeopleSearchGroupFormValues = {
  conditionJoin: ALL_CONDITION_JOIN_OPTION,
  searchAttributes: [DEFAULT_SEARCH_ATTRIBUTE],
};

const SearchAttributesGroups = () => {
  const [fieldOptionsMapper, setFieldOptionsMapper] = useState<FieldOptionsMapper>(
    INITIAL_FIELD_OPTIONS_MAPPER
  );

  const context = useFormikContext<PeopleSearchFormValues>();
  const { errors, values } = context;

  return (
    <FieldArray
      name="searchAttributesGroups"
      validateOnChange={false}
      render={({ remove, push }) => (
        <div className="form-group">
          {values.searchAttributesGroups.map((_group, index: number) => (
            <div
              className={styles.groupInputWrapper}
              key={index}
              data-test={`group-input-wrapper-${index}`}
            >
              <span className={styles.closeIconWrapper}>
                <a data-test={`remove-group-btn-${index}`} onClick={() => remove(index)}>
                  <img
                    src={closeIcon as unknown as string}
                    height="16"
                    width="16"
                    className="link-icon"
                  />
                </a>
              </span>
              <SearchAttributesGroup
                groupIndex={index}
                fieldOptionsMapper={fieldOptionsMapper}
                setFieldOptionsMapper={setFieldOptionsMapper}
              />
            </div>
          ))}
          <button
            data-test="add-group-btn"
            className="btn btn-primary"
            onClick={(e) => {
              e.preventDefault(); // prevents validation on all of the fields
              push(DEFAULT_GROUP_VALUE);
            }}
          >
            Add constraints
          </button>
          {isString(errors.searchAttributesGroups) && (
            <div className={clsx(styles.searchAttributesGroupError, 'invalid-feedback')}>
              {errors.searchAttributesGroups}
            </div>
          )}
        </div>
      )}
    />
  );
};

export default SearchAttributesGroups;
