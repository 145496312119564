import { useMemo } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';

import {
  PaginatedVirtualLists,
  VirtualList,
  getPaginatedVirtualLists,
  getVirtualLists,
} from '../utils/backend-api/virtual-lists';

export const VIRTUAL_LISTS_KEY = 'virtualLists';

export function usePaginatedVirtualLists() {
  const {
    data: virtualListsData,
    isLoading: isLoadingVirtualLists,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<PaginatedVirtualLists>(
    VIRTUAL_LISTS_KEY,
    ({ pageParam = 1 }) => getPaginatedVirtualLists(pageParam),
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
    }
  );

  return { virtualListsData, isLoadingVirtualLists, fetchNextPage, hasNextPage };
}

const formatVirtualListOptions = (virtualLists: VirtualList[]) => {
  return virtualLists.map((virtualList) => ({
    label: virtualList.listName,
    value: virtualList.id,
  }));
};

export default function useVirtualLists() {
  const { data: virtualLists, isLoading: isLoadingVirtualLists } = useQuery<VirtualList[]>(
    VIRTUAL_LISTS_KEY,
    getVirtualLists
  );

  const virtualListsOptions = useMemo(() => {
    if (virtualLists) return formatVirtualListOptions(virtualLists);
    // TODO: add exhaustive deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [virtualLists?.length]);
  return { virtualListsOptions, virtualLists, isLoadingVirtualLists };
}
