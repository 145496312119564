import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { crm_root_path } from '../routes';
import {
  AdministratorListFormValues,
  getAdministratorListEdit,
} from '../utils/backend-api/administrator-lists';

export const ADMINISTRATOR_LIST_EDIT_KEY = 'AdministratorListEdit';

export default function useAdministratorListEdit(id: number | string) {
  const navigate = useNavigate();
  const { data: administratorList, isLoading: isLoadingAdministratorList } =
    useQuery<AdministratorListFormValues>(
      [ADMINISTRATOR_LIST_EDIT_KEY, id],
      () => getAdministratorListEdit(id),
      {
        retry: 1,
        onError: (e) => {
          toast.error(
            e[0] ||
              'There was an error displaying this administrator list. Please contact engineering.'
          );
          return navigate(crm_root_path());
        },
      }
    );

  return { administratorList, isLoadingAdministratorList };
}
