import { Formik, useFormikContext } from 'formik';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useQueryClient } from 'react-query';

import { DropdownField, TextField } from '@components/FormFields';
import { NewsletterFormValues } from '@utils/backend-api/newsletters';

import useAuthorCreate from '../../../../hooks/useAuthorCreate';
import useAuthors, { AUTHORS_KEY } from '../../../../hooks/useAuthors';
import schema from './schema';

export const CHOOSE_AUTHOR_OPTION = { label: 'Choose author', value: '' };
const CREATE_AUTHOR_OPTION = { label: 'Create new author', value: 'create_new_author' };
const DEFAULT_AUTHOR_OPTIONS = [CHOOSE_AUTHOR_OPTION, CREATE_AUTHOR_OPTION];

interface AuthorFormValues {
  newAuthorEmail: string;
  newAuthorName: string;
}

const INITIAL_VALUES: AuthorFormValues = {
  newAuthorEmail: '',
  newAuthorName: '',
};

const AuthorPicker = () => {
  const { authors, isLoadingAuthors } = useAuthors();
  const { mutateAsync: createAuthor } = useAuthorCreate();
  const { values: newsletterFormValues, setFieldValue } = useFormikContext<NewsletterFormValues>();

  const queryClient = useQueryClient();

  const handleSubmitAuthor = async (values: AuthorFormValues) => {
    const resp = await createAuthor({ name: values.newAuthorName, email: values.newAuthorEmail });
    if (resp && resp.success) {
      const {
        administrator: { name, userId },
      } = resp;
      setFieldValue('author', {
        label: name,
        value: userId,
      });
      queryClient.invalidateQueries(AUTHORS_KEY);
    }
  };

  return (
    <Row>
      <Col className="col-6">
        {!isLoadingAuthors && authors && (
          <DropdownField
            label="Author"
            name="author"
            options={[
              ...DEFAULT_AUTHOR_OPTIONS,
              ...authors.map((a) => ({ label: a.name, value: a.id })),
            ]}
          />
        )}
      </Col>
      <Col className="col-6">
        {newsletterFormValues.author.value === CREATE_AUTHOR_OPTION.value && (
          <Formik
            onSubmit={handleSubmitAuthor}
            initialValues={INITIAL_VALUES}
            validationSchema={schema}
            enableReinitialize={true}
          >
            {({ handleSubmit, isSubmitting, isValid }) => (
              <>
                <Row>
                  <Col className="col-6">
                    <TextField label="Name" name="newAuthorName" />
                  </Col>
                  <Col className="col-6">
                    <TextField label="Email" name="newAuthorEmail" />
                  </Col>
                </Row>
                <Row>
                  <Col className="col-12">
                    <Button onClick={() => handleSubmit()} disabled={isSubmitting || !isValid}>
                      Create Author
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Formik>
        )}
      </Col>
    </Row>
  );
};

export default AuthorPicker;
