import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import { distribution_group_post_path } from '../../../routes';
import { GroupPost } from '../../../utils/backend-api/group-posts';

const Card = ({ groupPost }: { groupPost: GroupPost }) => {
  const { articleId } = useParams();
  const title = groupPost.isNew
    ? ' '
    : [`#${groupPost.id}`, groupPost.postUrl].filter(Boolean).join(' - ');
  return (
    <>
      <li className="scheduled-share-list-item">
        <Row>
          <Col xs={8} className="primary-col">
            <Link to={distribution_group_post_path(articleId, groupPost.id)}>{title}</Link>
          </Col>
        </Row>
      </li>
    </>
  );
};

export default Card;
