import debounce from 'debounce-promise';
import { useField } from 'formik';
import React, { useCallback } from 'react';
import AsyncSelect from 'react-select/async';

import createMarkup from '../../src/createMarkup';
import { autocompleteUser } from '../../utils/backend-api/users';
import './AutocompleteUserField.scss';

const CustomOption = ({ data, innerRef, innerProps }) => {
  // Wrap <span> around the matching part of the name to highlight it
  const regex = new RegExp(data.fieldValue, 'i');
  const nameWithHighlight = data.label.replace(
    regex,
    '<span className="react-select-highlighted">$&</span>'
  );
  return (
    <div className="react-select-result-container" ref={innerRef} {...innerProps}>
      <div className="react-select-img-container">
        <img src={data.pictureUrl} alt={data.label} />
      </div>
      <div className="react-select-text-container">
        <div dangerouslySetInnerHTML={createMarkup(nameWithHighlight)}></div>
        <div className="react-select-email">{data.email}</div>
      </div>
    </div>
  );
};

const DEBOUNCE_MS = 500;

export default function AutocompleteUserField(props) {
  const { label, name } = props;
  const [_field, meta, helpers] = useField(name);

  const asyncLoadOptions = async (value) => {
    const options = value.length === 0 ? [] : await autocompleteUser(value);
    return options.map((o) => ({
      value: o.id,
      identityId: o.identity_id,
      email: o.email,
      label: o.name,
      pictureUrl: o.picture_url,
      fieldValue: value,
    }));
  };

  const debounceLoadOptions = useCallback(
    debounce((value) => asyncLoadOptions(value), DEBOUNCE_MS, { leading: true }),
    [DEBOUNCE_MS]
  );

  const handleChange = (value) => {
    helpers.setValue(
      value || {
        value: '',
        identityId: '',
        email: '',
        label: '',
        pictureUrl: '',
        fieldValue: '',
      }
    );
  };

  return (
    <div
      className={`AutocompleteUserField form-group ${
        meta.touched && meta.error ? 'is-invalid' : ''
      }`}
    >
      {label && (
        <label className="input-label" htmlFor={name}>
          {label}
        </label>
      )}
      <AsyncSelect
        className={meta.touched && meta.error ? 'is-invalid' : ''}
        classNamePrefix="react-select"
        isClearable
        onChange={handleChange}
        onBlur={helpers.setTouched}
        loadOptions={debounceLoadOptions}
        components={{ Option: CustomOption }}
        inputId={props.name}
        {...props}
      />
      {meta.touched &&
        meta.error &&
        Object.values(meta.error).map((msg) => (
          <div key={msg} className="invalid-feedback">
            {msg}
          </div>
        ))}
    </div>
  );
}
