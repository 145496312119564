import { Formik } from 'formik';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

import TiptapEditor from '@components/TiptapEditor';
import { landing_pages_path } from '@routes';
import { Editor } from '@tiptap/core';
import { autocompleteNewsletters } from '@utils/backend-api/newsletters';

import { useLandingPage } from '../../../hooks';
import {
  LandingPageSanitizedValues,
  destroyLandingPage,
  updateLandingPage,
} from '../../../utils/backend-api/landing-pages';
import {
  AutocompleteField,
  CheckBoxField,
  CheckBoxGroup,
  ImageField,
  TextField,
} from '../../FormFields';
import './LandingPage.scss';
import {
  ALL_CHECKED_ERROR,
  DEFAULT_TIPTAP_CONTENT_TEMPLATE,
  DEFAULT_VERIFICATION_TEXT,
  FORM_FIELD_OPTIONS,
  FormActions,
  INITIAL_STATE_LANDING_PAGE,
  META_IMAGE_DELETED,
  STATUS_OK,
} from './constants';
import { LandingPageFormProps, LandingPageFormValues } from './interfaces';
import landingPageSchema from './landingPageSchema';

const LandingPageForm = ({
  formValues = INITIAL_STATE_LANDING_PAGE,
  isNewLandingPage = false,
}: LandingPageFormProps) => {
  const { id: landingPageId = '-1' } = useParams();
  const { landingPage, refetch: refetchLandingPage } = useLandingPage(landingPageId);
  const [tiptapContent, setTiptapContent] = useState<Editor>(null);
  const [tiptapVerification, setTiptapVerification] = useState<Editor>(null);
  const navigate = useNavigate();
  const publishedStatus =
    !isNewLandingPage && landingPage?.publishedAt ? FormActions.Unpublish : FormActions.Publish;

  const handleSubmitLandingPage = async (values: LandingPageFormValues, { setSubmitting }) => {
    const {
      action,
      advertisementId,
      confirmationText,
      formButtonText,
      formFields,
      formHeadline,
      formVerificationText,
      headline,
      leadType,
      metaDescription,
      metaImageUrl,
      metaNoindex,
      metaTitle,
      name,
      newsletter,
      pageContentsHtml,
      pageContentsJson,
      slug,
      subheadline,
      ...restVals
    } = values;
    const sanitizedParams: LandingPageSanitizedValues = {
      ...restVals,
      advertisementId,
      confirmationText,
      formButtonText,
      formFields,
      formHeadline,
      formVerificationText,
      headline,
      leadType,
      metaDescription,
      metaImageUrl: metaImageUrl === '' ? META_IMAGE_DELETED : metaImageUrl,
      metaNoindex,
      metaTitle,
      name,
      newsletterId: parseInt(newsletter.value),
      pageContentsHtml,
      pageContentsJson,
      slug,
      subheadline,
    };
    const keysToDeleteOnNull = {
      advertisementId: advertisementId,
      metaDescription: metaDescription,
      metaImageUrl: sanitizedParams.metaImageUrl,
      metaTitle: metaTitle,
      newsletterId: parseInt(newsletter.value),
    };

    // create FormData object so we can send request with content-type form-data for image uploading
    const formData = new FormData();

    for (const key in sanitizedParams) {
      if (
        key === 'metaImageUrl' &&
        sanitizedParams['metaImageUrl'] != META_IMAGE_DELETED &&
        typeof sanitizedParams[key] === 'string'
      ) {
        // if image is a string, it's a URL and we don't need to send it
        continue;
      }
      formData.append(key, sanitizedParams[key]);
    }

    if (landingPageId && !isNewLandingPage) formData.append('landingPageId', landingPageId);
    if (!metaNoindex) formData.set('metaNoindex', 'false');
    if (action === FormActions.Publish) formData.append('shouldPublish', 'true');
    if (action === FormActions.Unpublish) formData.append('shouldUnpublish', 'true');
    if (tiptapVerification) formData.set('formVerificationText', tiptapVerification.getHTML());
    if (tiptapContent) {
      formData.set('pageContentsJson', JSON.stringify(tiptapContent.getJSON()));
      formData.set('pageContentsHtml', tiptapContent.getHTML());
    }
    if (sanitizedParams.formFields[0] === ALL_CHECKED_ERROR) {
      formData.set('formFields', sanitizedParams.formFields.slice(1, -1).toString());
    }
    Object.keys(keysToDeleteOnNull).forEach((keyToDelete) => {
      if (!keysToDeleteOnNull[keyToDelete]) formData.delete(keyToDelete);
    });

    try {
      const resp = await updateLandingPage(formData);
      if (resp.message === STATUS_OK) {
        // if we're creating a new landing page, redirect to edit page
        if (resp.redirect_url) {
          toast.success('Landing Page created!');
          navigate(resp.redirect_url);
        } else {
          toast.success('Landing Page updated!');
          setSubmitting(false);
          refetchLandingPage();
        }
      }
    } catch (error) {
      toast.error(error || 'Sorry, there was a problem');
      setSubmitting(false);
    }
  };

  const handleDestroyLandingPage = async () => {
    try {
      const resp = await destroyLandingPage({ id: landingPageId });
      if (resp.message === STATUS_OK) {
        // if we're deleting a landing page, redirect to index page
        toast.success('Landing Page deleted!');
        navigate(landing_pages_path());
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <Formik
      initialValues={formValues}
      onSubmit={handleSubmitLandingPage}
      validationSchema={landingPageSchema}
      enableReinitialize
    >
      {(formik) => (
        <Form className="LandingPageForm" noValidate onSubmit={formik.handleSubmit}>
          <Row>
            <Col>
              <TextField label="Landing page name *" name="name" />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField label="Slug *" hint="Will live on /lp" name="slug" />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField label="Lead Type *" hint="For tracking form submissions" name="leadType" />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                label="Advertisement ID"
                hint="(Optional) The ID of the Advertisement to associate with this Landing Page"
                name="advertisementId"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <AutocompleteField
                fetchMethod={(value) =>
                  autocompleteNewsletters({
                    fragment: value,
                  })
                }
                hint="(Optional) The name of the Newsletter to associate with this Landing Page. Respondents will be added to this list on submission."
                isCreatable={false}
                label="Newsletter"
                name="newsletter"
                valueIsInteger
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField label="Headline *" hint="Page headline" name="headline" />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                label="Subheadline"
                hint="(Optional) Added text under the headline"
                name="subheadline"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                label="Form Headline"
                hint="(Optional) Text that appears at the top of the form"
                name="formHeadline"
              />
            </Col>
          </Row>
          <Row>
            <Col className="nux-form-fields">
              <p className="input-label mb-0">Form Fields</p>
              <p className="text-muted mt-0 mb-1 p-0 text-small">
                The NUX fields that will appear on the form
              </p>
              <CheckBoxGroup name="formFields" options={FORM_FIELD_OPTIONS} />
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="input-label mb-0">Form Verification Text</p>
              <p className="text-muted mt-0 mb-1 p-0 text-small">
                (Optional) Text the respondent acknowledges with a checkbox in order to submit the
                form
              </p>
              <TiptapEditor
                customContentHTML={landingPage?.formVerificationText || DEFAULT_VERIFICATION_TEXT}
                setTiptapData={setTiptapVerification}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                label="Confirmation Text *"
                hint="Text that appears after form submission"
                name="confirmationText"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="input-label mb-0">Page Contents</p>
              <p className="text-muted mt-0 mb-1 p-0 text-small">
                (Optional) The ancillary text that appears on the page
              </p>
              <TiptapEditor
                customContentHTML={
                  !landingPage?.pageContentsJson ? DEFAULT_TIPTAP_CONTENT_TEMPLATE : null
                }
                customContentJSON={
                  landingPage?.pageContentsJson
                    ? JSON.parse(landingPage.pageContentsJson as string)
                    : null
                }
                setTiptapData={setTiptapContent}
                showMenu
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                label="Meta Title"
                hint="(Optional) Metadata <title /> tag. Defaults to the headline"
                name="metaTitle"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                label="Meta Description"
                hint="(Optional) Metadata description. Defaults to the subheadline"
                name="metaDescription"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ImageField
                disabled={formik.isSubmitting}
                imgBtnLabel="Upload a new meta og:image"
                label="Meta Image"
                width={400}
                height={210}
                hint="(Optional) Metadata og:image. Defaults to the site fallback. Should be at least 1200x630."
                name="metaImageUrl"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CheckBoxField
                label="Meta Robots noindex"
                hint="Metadata for robots noindex to prevent search crawlers. Defaults to not allowing crawling (checked)"
                name="metaNoindex"
                value="metaNoindex"
              />
            </Col>
          </Row>
          <Row className="LandingPageForm__sticky-container">
            <Col className={`d-flex button__container${landingPageId ? ' is-edit-page' : ''}`}>
              {landingPageId && !isNewLandingPage && (
                <Button
                  disabled={formik.isSubmitting}
                  type="button"
                  className="button"
                  id="delete"
                  onClick={() => {
                    window.confirm('Are you sure you want to delete this Landing Page?') &&
                      handleDestroyLandingPage();
                  }}
                >
                  Delete
                </Button>
              )}
              <div className="update-button__container">
                <Button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="button --black"
                  id="save"
                  onClick={(event) => {
                    if (landingPage?.publishedAt) {
                      window.confirm('Are you sure you want to update this live Landing Page?')
                        ? formik.setFieldValue('action', FormActions.Save)
                        : event.preventDefault();
                    } else {
                      formik.setFieldValue('action', FormActions.Save);
                    }
                  }}
                >
                  Save
                </Button>
                {!isNewLandingPage && (
                  <Button
                    disabled={formik.isSubmitting}
                    type="submit"
                    className="button --black"
                    id="publish"
                    onClick={(event) => {
                      if (landingPage.publishedAt && publishedStatus === FormActions.Unpublish) {
                        window.confirm('Are you sure you want to unpublish this live Landing Page?')
                          ? formik.setFieldValue('action', FormActions.Unpublish)
                          : event.preventDefault();
                      } else {
                        formik.setFieldValue('action', FormActions.Publish);
                      }
                    }}
                  >
                    {publishedStatus}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default LandingPageForm;
