import clsx from 'clsx';
import { Form, Formik } from 'formik';
import { snakeCase } from 'lodash';
import React, { useMemo, useState } from 'react';

import useIdentityUpdate from '../../../../hooks/useIdentityUpdate';
import ButtonPlain from '../../../BaseUI/ButtonPlain';
import styles from './styles.module.css';

const EditableFields = ({ identity, fields }) => {
  const [isEditing, setIsEditing] = useState(false);
  const { mutate: updateIdentity } = useIdentityUpdate(identity.id);

  const initialValues = useMemo(() => {
    const initialValues = {};
    fields.map((field) => {
      initialValues[field] = identity[field];
    });
    return initialValues;
  }, [fields, identity]);

  const handleSubmit = async (values) => {
    const params = {};
    fields.map((field) => {
      params[snakeCase(field)] = values[field];
    });
    await updateIdentity(params, {
      onSuccess: () => {
        setIsEditing(false);
      },
    });
  };

  return isEditing ? (
    <div className={styles.editingField}>
      <Formik onSubmit={handleSubmit} initialValues={initialValues}>
        {(formik) => (
          <>
            <Form noValidate onSubmit={formik.handleSubmit}>
              {fields.map((field) => (
                <input
                  key={field}
                  type="text"
                  name={field}
                  className={styles.editableInput}
                  value={formik.values[field]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              ))}
              <ButtonPlain type="submit" id="submit-name-btn" disabled={formik.isSubmitting}>
                <i style={{ color: 'green' }} className="fa-solid fa-check" title="submit"></i>
              </ButtonPlain>
              <ButtonPlain onClick={() => setIsEditing(false)} disabled={formik.isSubmitting}>
                <i style={{ color: 'red' }} className="fa-solid fa-x" title="cancel"></i>
              </ButtonPlain>
            </Form>
          </>
        )}
      </Formik>
    </div>
  ) : (
    <>
      <div id="identity-name" className={styles.editableField} onClick={() => setIsEditing(true)}>
        {fields.map((field) => identity[field] || '(empty)').join(' ')}
      </div>
      <ButtonPlain onClick={() => setIsEditing(true)}>
        <i
          style={{ color: '#0d6efd' }}
          className={clsx('fa-solid', 'fa-pen-to-square', styles.editBtn)}
          title="edit"
        ></i>
      </ButtonPlain>
    </>
  );
};

export default EditableFields;
