import { useField } from 'formik';
import React from 'react';

import './RadioField.scss';

const RadioField = (props) => {
  const { name, label, options, disabled, onChange } = props;
  const [field, meta, helpers] = useField(name);

  return (
    <div className="RadioField form-group d-flex flex-column justify-content-start">
      <div>{label && <label htmlFor={name}>{label}</label>}</div>
      <div className="d-flex flex-column">
        {options.map((option) => (
          <div key={option.value}>
            <div className={`input-container  ${disabled ? 'disabled' : ''}`}>
              <input
                {...field}
                {...props}
                checked={field.value === option.value}
                id={`${field.name}-${option.value}`}
                type="radio"
                onChange={() => (onChange ? onChange() : helpers.setValue(option.value))}
              />
              <label className="ml-2 radio-label-text" htmlFor={`${field.name}-${option.value}`}>
                {option.label}
              </label>
            </div>
          </div>
        ))}
      </div>
      {meta.touched && meta.error && (
        <div className="invalid-feedback" style={{ display: 'inline-block' }}>
          {meta.error}
        </div>
      )}
    </div>
  );
};

export default RadioField;
