import React, { Fragment } from 'react';
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import Imgix from 'react-imgix';
import { useNavigate } from 'react-router-dom';

import { useProjects } from '@hooks';
import { ACCESS_LEVEL_LABELS } from '@utils/backend-api/projects';

import { edit_project_path, new_project_path } from '../../routes';
import './Projects.scss';

const Projects = () => {
  const navigate = useNavigate();
  const { projectsData, isLoadingProjects, fetchNextPage, hasNextPage } = useProjects();

  if (isLoadingProjects) return null;
  return (
    <Container className="Projects">
      <Row className="align-items-center">
        <Col>
          <h1>Projects</h1>
        </Col>
        <Col sm={3} className="button-col">
          <Button onClick={() => navigate(new_project_path())}>New Project</Button>
        </Col>
      </Row>
      <Table className="Projects-table" striped bordered hover>
        <thead>
          <tr>
            <th>Title</th>
            <th>Main Image</th>
            <th>Access Level</th>
          </tr>
        </thead>
        <tbody>
          {projectsData.pages.map((group, i) => (
            <Fragment key={i}>
              {group.result.map(({ accessLevel, id, metaImageUrl, title }) => (
                <tr key={id} onClick={() => navigate(edit_project_path(id))}>
                  <td>
                    <h3>{title}</h3>
                  </td>
                  <td className="td-image">
                    {metaImageUrl.includes('imgix') ? (
                      <Imgix src={metaImageUrl} width={320} imgixParams={{ fit: 'scale' }} />
                    ) : (
                      <img src={metaImageUrl} width={320} alt="external-image" />
                    )}
                  </td>
                  <td>
                    <h2>{ACCESS_LEVEL_LABELS[Number(accessLevel)]}</h2>
                  </td>
                </tr>
              ))}
            </Fragment>
          ))}
        </tbody>
      </Table>
      {hasNextPage && (
        <Row className="justify-content-center">
          <Col sm={3} className="w-auto">
            <Button variant="outline-dark" onClick={() => fetchNextPage()}>
              more
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Projects;
