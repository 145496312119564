import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { IDENTITY_KEY } from '..';
import { cancelNewsletterSubscriptionAction } from '../../../../utils/backend-api/newsletter-subscription-actions';
import styles from './styles.module.css';

const SubscriptionsList = ({ newsletterSubscriptions, identityId }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const queryClient = useQueryClient();

  const handleDeleteSubscription = async (newsletterId) => {
    setIsSubmitting(true);
    try {
      const response = await cancelNewsletterSubscriptionAction({
        newsletterIds: [newsletterId],
        identityIds: [identityId],
      });
      if (response.error) {
        toast.error(response.error);
      }
      if (response.message) {
        toast.success(response.message);
      }
      queryClient.invalidateQueries([IDENTITY_KEY, identityId]);
    } catch (e) {
      toast.error(`there was an error removing the subscription ${e}`);
    }
    setIsSubmitting(false);
  };

  return newsletterSubscriptions.length === 0 ? (
    <li>No current subscriptions</li>
  ) : (
    newsletterSubscriptions.map((newsletter) => (
      <li key={newsletter.id}>
        <span>{newsletter.name}</span>
        <button
          id={`subscription-del-btn-${newsletter.id}`}
          className={styles.deleteButton}
          onClick={() => handleDeleteSubscription(newsletter.id)}
          disabled={isSubmitting}
        >
          &times;
        </button>
      </li>
    ))
  );
};

export default SubscriptionsList;
