import {
  api_react_v1_twitter_line_item_path,
  api_react_v1_twitter_line_items_path,
} from '../../routes';
import { ConnectedProvider } from './connected-providers';
import request from './request';
import { getRecord, getRecords } from './universal';
import { VirtualList } from './virtual-lists';

export interface TwitterLineItem {
  id: number;
  connectedProvider: ConnectedProvider;
  error: string;
  startAt: string;
  status: string;
  totalBudgetMicro: number;
  bidAmountMicro: number;
  tweetId: number;
  virtualList: VirtualList;
  analyticsUrl: string;
}

export interface PaginatedTwitterLineItems {
  result: TwitterLineItem[];
  nextPage: number;
}

const MAX_CAMPAIGNS_PER_PAGE = 25;

export const getPaginatedTwitterLineItems = async (
  articleId: number | null,
  page: number
): Promise<PaginatedTwitterLineItems> =>
  await getRecords({
    model: 'TwitterLineItem',
    attributes: [
      'id',
      'status',
      'totalBudgetMicro',
      'bidAmountMicro',
      'tweetId',
      'startAt',
      'error',
    ],
    helpers: ['analyticsUrl'],
    relations: {
      connectedProvider: { attributes: ['id', 'name'] },
      virtualList: { attributes: ['id', 'listName'] },
    },
    identifiers: articleId ? { articleId } : {},
    order: { startAt: 'asc' },
    page: page,
    maxPerPage: MAX_CAMPAIGNS_PER_PAGE,
  });

export const getTwitterLineItem = async (
  twitterLineItemId: string | number
): Promise<TwitterLineItem> => {
  const resp = await getRecord({
    id: twitterLineItemId,
    model: 'TwitterLineItem',
    attributes: [
      'id',
      'status',
      'totalBudgetMicro',
      'bidAmountMicro',
      'tweetId',
      'startAt',
      'error',
    ],
    relations: {
      connectedProvider: { attributes: ['name', 'id'] },
      virtualList: { attributes: ['id', 'listName'] },
    },
  });
  return resp.result;
};

// TODO: Add types for these methods -----
export async function createTwitterLineItem(values) {
  const resp = await request('post', api_react_v1_twitter_line_items_path(values));
  return resp;
}

export async function updateTwitterLineItem(twitterLineItemId, values) {
  const resp = await request('put', api_react_v1_twitter_line_item_path(twitterLineItemId, values));
  return resp;
}

export async function deleteTwitterLineItem(twitterLineItemId) {
  const resp = await request('delete', api_react_v1_twitter_line_item_path(twitterLineItemId));
  return resp;
}
