import { useQuery } from 'react-query';

import { NewsletterFormValues, getNewsletter } from '../utils/backend-api/newsletters';

export const NEWSLETTERS_KEY = 'newsletters';

export default function useNewsletter(newsletterId: number) {
  const { data: newsletter, isLoading: isLoadingNewsletter } = useQuery<NewsletterFormValues>(
    [NEWSLETTERS_KEY, newsletterId],
    () => getNewsletter(newsletterId),
    {
      enabled: !!newsletterId,
    }
  );

  return { newsletter, isLoadingNewsletter };
}
