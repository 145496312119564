import { getRecord, getRecords } from './universal';

export interface Offer {
  code: string;
  emailBody: string;
  emailSubject: string;
  header: string;
  id: string;
  initalPrice: string;
  name: string;
  offerExpiresAt: string;
  offerType: string;
  stripePlan: string;
}

export const getOffers = async (): Promise<Offer[]> => {
  const resp = await getRecords({
    model: 'Offer',
    attributes: [
      'code',
      'emailBody',
      'emailSubject',
      'header',
      'id',
      'initialPrice',
      'name',
      'offerExpiresAt',
      'offerType',
      'stripePlan',
    ],
  });
  return resp.result;
};

export const getOffer = async (offerId: number | string): Promise<Offer> => {
  const resp = await getRecord({
    id: offerId,
    model: 'Offer',
    attributes: [
      'code',
      'emailBody',
      'emailSubject',
      'header',
      'id',
      'initialPrice',
      'name',
      'offerExpiresAt',
      'offerType',
      'stripePlan',
    ],
  });
  return resp.result;
};
