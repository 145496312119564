import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { ConnectedProvider, getConnectedProviders } from '../utils/backend-api/connected-providers';

export const CONNECTED_PROVIDERS_KEY = 'connectedProviders';

const formatConnectedProviderOptions = (connectedProviders: ConnectedProvider[]) => {
  return connectedProviders.map((provider) => ({
    label: provider.name,
    value: provider.id,
  }));
};

export default function useConnectedProviders(providerName?: string | null) {
  const { data: connectedProviders, isLoading: isLoadingConnectedProviders } = useQuery<
    ConnectedProvider[]
  >(CONNECTED_PROVIDERS_KEY, async () => await getConnectedProviders(providerName));

  const connectedProviderOptions = useMemo(() => {
    if (connectedProviders) return formatConnectedProviderOptions(connectedProviders);
  }, [connectedProviders]);

  return { connectedProviderOptions, connectedProviders, isLoadingConnectedProviders };
}
