import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import AdvertisementForm from './AdvertisementForm';
import './NewAdvertisement.scss';

/**
 * NewAdvertisement: Page for creating new advertisements.
 * Uses shared AdvertisementForm component.
 */
const NewAdvertisement = () => {
  return (
    <Container className="NewAdvertisement">
      <Row>
        <Col md={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 2 }}>
          <Row>
            <Col xs={12}>
              <h2 className="mb-1">Setup new advertisement</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mb-4">
              <hr className="divider" />
            </Col>
          </Row>
          <AdvertisementForm isNewAd />
        </Col>
      </Row>
    </Container>
  );
};

export default NewAdvertisement;
