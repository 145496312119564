import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { deleteAdministratorList } from '../utils/backend-api/administrator-lists';
import { ADMINISTRATOR_LIST_EDIT_KEY } from './useAdministratorListEdit';
import { ADMINISTRATOR_LISTS_KEY } from './useAdministratorLists';

export default function useAdministratorListDelete(id: number | string) {
  const queryClient = useQueryClient();

  const { mutate, mutateAsync, data, isLoading, isError } = useMutation(
    async () => await deleteAdministratorList(id),
    {
      onSuccess: (data) => {
        toast.success('Administrator List has been deleted');
        queryClient.invalidateQueries(ADMINISTRATOR_LISTS_KEY);
        queryClient.invalidateQueries([ADMINISTRATOR_LIST_EDIT_KEY, data.id]);
      },
      onError: () => {
        toast.error('Deleting administrator list has failed');
      },
    }
  );
  return { mutate, mutateAsync, data, isLoading, isError };
}
