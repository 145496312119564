import { api_react_v1_autocomplete_email_suggestions_path } from '../../routes';
import { AutocompleteParams, AutocompleteRecord } from '../interfaces';
import request from './request';

export async function autocompleteEmailSuggestions(
  values: AutocompleteParams
): Promise<AutocompleteRecord[]> {
  const resp = await request('get', api_react_v1_autocomplete_email_suggestions_path(values));
  return resp.email_suggestions;
}
