import moment from 'moment';
import React, { Fragment, useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { Link, useParams } from 'react-router-dom';

import { useTwitterLineItem, useTwitterLineItems } from '../../hooks';
import { TWITTER_LINE_ITEMS_KEY } from '../../hooks/useTwitterLineItems';
import { distribution_twitter_campaign_path, virtual_list_path } from '../../routes';
import { deleteTwitterLineItem } from '../../utils/backend-api/twitter-line-items';
import TwitterLineItemForm from './TwitterLineItemForm';
import { DOLLARS_TO_MICRO } from './TwitterLineItemForm';

const TwitterCampaignsScreen = () => {
  const { twitterLineItemId, articleId } = useParams();
  const { twitterLineItemsData, isLoadingTwitterLineItems } = useTwitterLineItems(articleId);
  const { twitterLineItem, isLoadingTwitterLineItem } = useTwitterLineItem(twitterLineItemId);
  const [submitting, setSubmitting] = useState(false);
  const queryClient = useQueryClient();

  const handleDeleteTwitterLineItem = async (twitterLineItemId) => {
    setSubmitting(true);
    try {
      const resp = await deleteTwitterLineItem(twitterLineItemId);
      if (resp.message == 'ok') {
        toast.success('Line Item has been deleted');
        queryClient.invalidateQueries(TWITTER_LINE_ITEMS_KEY);
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to delete Line Item');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-6">
          <h1>Twitter Campaigns</h1>
          <a href="/auth/twitter_ads">+ Add Twitter Ads Account"</a>
        </div>
        <div className="mt-4">
          {!twitterLineItemId ? (
            <TwitterLineItemForm articleId={articleId} />
          ) : !isLoadingTwitterLineItem && twitterLineItem ? (
            <TwitterLineItemForm articleId={articleId} twitterLineItem={twitterLineItem} />
          ) : null}
        </div>
        <div className="mt-4">
          <table id="twitter-campaigns-table" className="table table-active">
            <thead>
              <tr>
                <th>Id</th>
                <th>Account</th>
                <th>Start Date</th>
                <th>Tweet</th>
                <th>Total Budget</th>
                <th>Daily Budget</th>
                <th>List</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {isLoadingTwitterLineItems ? (
                <tr>
                  <td>Loading</td>
                </tr>
              ) : (
                twitterLineItemsData.pages.map((group, i) => (
                  <Fragment key={i}>
                    {group.result.map((twitterLineItem) => (
                      <tr key={twitterLineItem.id}>
                        <td>
                          <Link
                            to={distribution_twitter_campaign_path(articleId, twitterLineItem.id)}
                          >
                            {twitterLineItem.id}
                          </Link>
                        </td>
                        <td>{twitterLineItem.connectedProvider?.name}</td>
                        <td>{moment(twitterLineItem.startAt).format('MMMM Do YYYY, h:mm:ss a')}</td>
                        <td>
                          <a
                            href={`https://twitter.com/ti/status/${twitterLineItem.tweetId}`}
                            target="_blank"
                          >
                            {twitterLineItem.tweetId}
                          </a>
                        </td>
                        <td>${twitterLineItem.totalBudgetMicro / DOLLARS_TO_MICRO}</td>
                        <td>${twitterLineItem.bidAmountMicro / DOLLARS_TO_MICRO}</td>
                        <td>
                          {twitterLineItem.virtualList && (
                            <a href={virtual_list_path(twitterLineItem.virtualList.id)}>
                              {twitterLineItem.virtualList.listName}
                            </a>
                          )}
                        </td>
                        <td>
                          <span title={twitterLineItem.error}>{twitterLineItem.status}</span>
                        </td>
                        <td>
                          {twitterLineItem.analyticsUrl && (
                            <a
                              className="btn-link"
                              target="_blank"
                              href={twitterLineItem.analyticsUrl}
                            >
                              Analytics
                            </a>
                          )}
                          <button
                            disabled={submitting}
                            className="btn btn-link btn-sm"
                            onClick={() => handleDeleteTwitterLineItem(twitterLineItem.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </Fragment>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TwitterCampaignsScreen;
