import React from 'react';
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import useAdSchedules from '../../hooks/useAdSchedules';
import {
  advertisements_path,
  edit_advertisements_path,
  new_advertisements_path,
} from '../../routes';
import './AdSchedules.scss';

/**
 * AdSchedules: Page for displaying all schedules for existing ads
 * listed in descending order by start_time
 */
const AdSchedules = () => {
  const navigate = useNavigate();
  const { scheduleGroups, isLoadingAdSchedules, fetchNextPage, hasNextPage } = useAdSchedules();

  if (isLoadingAdSchedules) return null;
  return (
    <Container className="AdSchedules">
      <h1>Advertisement Schedules</h1>
      <Row className="justify-content-between align-items-center">
        <Col sm={9}>
          <Row className="justify-content-start header-row">
            <Col>Click any row to edit</Col>|{' '}
            <Col className="all-ads">
              <a href={advertisements_path()}>See all ads</a>
            </Col>
          </Row>
        </Col>
        <Col sm={3} className="button-col">
          <Button onClick={() => navigate(new_advertisements_path())}>New Ad</Button>
        </Col>
      </Row>
      <Table className="AdSchedules-table" striped bordered hover>
        <thead>
          <tr>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Sections</th>
            <th>Sponsor</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(scheduleGroups).map((group, idx) => (
            <tr key={idx}>
              <td>{group[0].startDate}</td>
              <td>{group[0].endDate}</td>
              <td>
                {group.map(({ id, section }) => (
                  <div key={`${id}-${section}`}>{section}</div>
                ))}
              </td>
              <td>
                {group.map(({ id, advertisementId, advertisement: { sponsorName } }) => (
                  <div key={`${id}-${sponsorName}`}>
                    <Link to={edit_advertisements_path(advertisementId)}>
                      <u>{sponsorName}</u>
                    </Link>
                  </div>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {hasNextPage && (
        <Row className="justify-content-center">
          <Col sm={3} className="w-auto">
            <Button variant="outline-dark" disabled={isLoadingAdSchedules} onClick={fetchNextPage}>
              more
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default AdSchedules;
