import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import '../src/snakbar';
import 'channels';
require.context('../images', true);

// Support component names relative to this directory:
var componentRequireContext = require.context('components', true);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);

Rails.start();
ActiveStorage.start();
