import * as Yup from 'yup';

const virtualListSchema = () =>
  Yup.object().shape({
    connectedProvider: Yup.object().shape({
      label: Yup.string().nullable(),
      value: Yup.number().required('Virtual List account is required'),
    }),
    listName: Yup.string().required('You must specify a list name'),
    virtual_list_administrator_lists_attributes: Yup.array(
      Yup.object().shape({
        administrator_list_id: Yup.number(),
      })
    ),
  });

export default virtualListSchema;
