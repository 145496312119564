import copy from 'copy-to-clipboard';
import { Formik } from 'formik';
import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';

import { SendTestForm, Tooltip } from '../';
import { sendCustomEmail } from '../../../utils/backend-api/custom-emails';
import {
  AutocompleteUserField,
  CheckBoxField,
  DateTimeField,
  TextAreaField,
  TextField,
} from '../../FormFields';
import './CustomEmailForm.scss';
import customEmailFormSchema from './schema';

const INITIAL_STATE = {
  fromIdentity: {
    value: '',
    identityId: '',
    email: '',
    label: '',
    pictureUrl: '',
    fieldValue: '',
  },
  subject: '',
  body: '',
  scheduledTime: '',
  useTiTemplate: false,
  useGeneralTiEmailForReply: false,
};

const CustomEmailForm = ({
  recipients,
  relevantLinks,
  unsubscribeUrl,
  excludeAlreadyNotified,
  articleId,
}) => {
  const handleSubmit = async (values, { setSubmitting }) => {
    const {
      fromIdentity: { value: from_user_id },
      subject,
      body,
      scheduledTime: scheduled_time,
      useTiTemplate: use_ti_template,
      useGeneralTiEmailForReply: use_general_ti_email_for_reply,
    } = values;
    const sanitizedParams = {
      unsubscribe_url: unsubscribeUrl,
      from_user_id,
      subject,
      body,
      scheduled_time,
      recipients,
      use_ti_template,
      use_general_ti_email_for_reply,
      article_id: articleId,
      exclude_already_notified: excludeAlreadyNotified,
    };
    try {
      const resp = await sendCustomEmail(sanitizedParams);
      if (resp.success) {
        toast.success('Email queued successfully');
      } else {
        toast.error(resp.message);
      }
    } catch {
      toast.error('There was an issue queuing your email. Please contact support.');
    } finally {
      setSubmitting(false);
    }
  };

  const handleCopyLink = (url) => {
    copy(`<a href=${url}>LINK</a>`);
  };

  return (
    <div className="CustomEmailForm">
      {relevantLinks?.length > 0 && (
        <Col className="relevant-links">
          <Tooltip onClick content="Copied Article URL" direction="bottom">
            <div className="title">Relevant Links</div>
            {relevantLinks.map((link) => (
              <div key={link.name} onClick={() => handleCopyLink(link.url)} className="link">
                Copy {link.name}
              </div>
            ))}
          </Tooltip>
        </Col>
      )}
      <Formik
        onSubmit={handleSubmit}
        initialValues={INITIAL_STATE}
        validationSchema={customEmailFormSchema}
      >
        {(formik) => (
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={6}>
                <AutocompleteUserField
                  name="fromIdentity"
                  label="From"
                  placeholder="Select who you want this email to come from"
                />
              </Col>
              <Col lg={6}>
                <TextField name="subject" label="Subject" />
              </Col>
            </Row>
            <CheckBoxField
              name="useTiTemplate"
              label="Use TI Marketing Template (if blank, sends email with no template)"
            />
            <CheckBoxField
              name="useGeneralTiEmailForReply"
              label="Set 'reply_to' as info@theinformation.com (if blank, it's the same the 'from' user's email)"
            />
            <TextAreaField name="body" label="Body" minRows={6} />
            <DateTimeField
              name="scheduledTime"
              label="Optional: Scheduled Time (If left blank, email will send now)"
              maxDaysOut={7}
            />
            <SendTestForm
              includedFields={[
                {
                  fieldName: 'fromIdentity',
                  sanitizedName: 'from_user_id',
                  value: formik.values.fromIdentity?.value,
                },
                { fieldName: 'subject' },
                { fieldName: 'body' },
                {
                  fieldName: 'useTiTemplate',
                  sanitizedName: 'use_ti_template',
                  touchNotRequired: true,
                },
                {
                  sanitizedName: 'article_id',
                  value: articleId,
                },
              ]}
              testMethod={sendCustomEmail}
            />
            <Button disabled={formik.isSubmitting} type="submit" className="submit-button --black">
              {formik.values.scheduledTime ? 'Schedule' : 'Send Email'}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CustomEmailForm;
