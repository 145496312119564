import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import {
  PushNotificationSanitizedValues,
  createPushNotification,
} from '../utils/backend-api/push-notifications';

export default function usePushNotificationCreate() {
  const navigate = useNavigate();

  const { mutate, isLoading, isError } = useMutation(
    async (sanitizedParams: PushNotificationSanitizedValues) =>
      await createPushNotification(sanitizedParams),
    {
      onSuccess: (res) => {
        if (res.redirectUrl) {
          toast.success(
            "Push notification created successfully and started processing if it's scheduled to send now."
          );
          navigate(res.redirectUrl);
        }
      },
      onError: (e) => {
        toast.error(
          e[0] ||
            'There was an error displaying this administrator list. Please contact engineering.'
        );
      },
    }
  );

  return {
    createPushNotification: mutate,
    isCreatingPush: isLoading,
    errorCreatingPush: isError,
  };
}
