import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { RelevantGroup, createRelevantGroup } from '../utils/backend-api/relevant-groups';
import { RELEVANT_GROUPS_KEY } from './useRelevantGroups';

export default function useRelevantGroupsCreate(articleId: number | string) {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isError } = useMutation(
    async (relevantGroup: RelevantGroup) =>
      await createRelevantGroup(relevantGroup.name, articleId),
    {
      onMutate: async (newRelevantGroup: RelevantGroup) => {
        await queryClient.cancelQueries(RELEVANT_GROUPS_KEY);
        const prevRelevantGroups = queryClient.getQueryData<RelevantGroup[]>(RELEVANT_GROUPS_KEY);
        if (prevRelevantGroups) {
          queryClient.setQueryData<RelevantGroup[]>(RELEVANT_GROUPS_KEY, [
            ...prevRelevantGroups,
            newRelevantGroup,
          ]);
        }
        return { prevRelevantGroups };
      },
      onSuccess: () => {
        toast.success('Relevant group has been created');
      },
      onError: (_err, _variables, context) => {
        toast.error('Creating relevant group has failed');
        if (context?.prevRelevantGroups)
          queryClient.setQueryData<RelevantGroup[]>(
            RELEVANT_GROUPS_KEY,
            context.prevRelevantGroups
          );
      },
      onSettled: () => {
        queryClient.invalidateQueries(RELEVANT_GROUPS_KEY);
      },
    }
  );
  return { mutate, isLoading, isError };
}
