import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { Newsletter, getNewsletters } from '../utils/backend-api/newsletters';

export const NEWSLETTERS_KEY = 'newsletters';

const formatNewsletterOptions = (newsletters: Newsletter[]) =>
  newsletters.map((newsletter) => ({
    label: newsletter.name,
    value: newsletter.id,
  }));

export default function useNewsletters(filter = {}) {
  const { data: newsletters, isLoading: isLoadingNewsletters } = useQuery<Newsletter[]>(
    [NEWSLETTERS_KEY, filter],
    () => getNewsletters(filter)
  );

  const newsletterOptions = useMemo(() => {
    if (newsletters) return formatNewsletterOptions(newsletters);
  }, [newsletters]);

  return { newsletters, newsletterOptions, isLoadingNewsletters };
}
