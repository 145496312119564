import * as Yup from 'yup';

const schema = () =>
  Yup.object().shape({
    newAuthorEmail: Yup.string()
      .email('You must provide a valid email address')
      .required('You must provide an email address'),
    newAuthorName: Yup.string().required('You must provide a name'),
  });

export default schema;
