import React from 'react';

interface TitleCalloutPreviewProps {
  title: string;
  callout?: string;
}

const TitleCalloutPreview = ({ title, callout }: TitleCalloutPreviewProps) => {
  const calloutWords = callout ? callout.split(' ') : [];
  const titleWords = title.split(' ');
  const isCalloutInTitle = calloutWords.every((word) => titleWords.includes(word));

  return (
    <p className="title-callout-preview">
      {titleWords.map((word, index) => {
        const isCalloutWord =
          (calloutWords.includes(word) ||
            (calloutWords.length === 0 && index === titleWords.length - 1)) &&
          isCalloutInTitle;

        return (
          <span key={index} className={`${isCalloutWord ? 'text-magenta' : ''}`}>
            {word}{' '}
          </span>
        );
      })}
      {!isCalloutInTitle && calloutWords.length > 0 && (
        <span className={'text-magenta'}> {callout}</span>
      )}
    </p>
  );
};

export default TitleCalloutPreview;
