import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { deleteAdSchedule } from '../utils/backend-api/ad-schedules';

export default function useAdScheduleDelete() {
  const { mutate, mutateAsync, isLoading, isError } = useMutation(
    async (id: number) => await deleteAdSchedule(id),
    {
      onSuccess: () => {
        toast.success('Schedule deleted!');
      },
      onError: () => {
        toast.error('There was an issue deleting this schedule');
      },
    }
  );
  return { mutate, mutateAsync, isLoading, isError };
}
