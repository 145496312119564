import React, { Fragment } from 'react';
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { useLandingPages } from '../../hooks';
import { edit_landing_pages_path, new_landing_pages_path } from '../../routes';
import './LandingPages.scss';

const PUBLISHED_STATUS = {
  Published: 'Published',
  Draft: 'Draft',
};

/**
 * LandingPages: Page for displaying all existing landing pages
 * listed in descending order by time created
 */
const LandingPages = () => {
  const navigate = useNavigate();
  const { landingPagesData, isLoadingLandingPages, fetchNextPage, hasNextPage } = useLandingPages();

  if (isLoadingLandingPages) return null;
  return (
    <Container className="LandingPages">
      <Row className="align-items-center">
        <Col>
          <h1>Landing Pages</h1>
        </Col>
        <Col sm={3} className="button-col">
          <Button onClick={() => navigate(new_landing_pages_path())}>New Landing Page</Button>
        </Col>
      </Row>
      <Table className="LandingPages-table" striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Headline</th>
            <th>Newsletter</th>
            <th>Advertiser</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {landingPagesData.pages.map((group, i) => (
            <Fragment key={i}>
              {group.result.map(
                ({ advertisementSponsorName, headline, name, newsletterName, id, publishedAt }) => (
                  <tr key={id} onClick={() => navigate(edit_landing_pages_path(id))}>
                    <td>
                      <p>{name}</p>
                    </td>
                    <td>
                      <p>{headline}</p>
                    </td>
                    <td>{newsletterName && <p>{newsletterName}</p>}</td>
                    <td>{advertisementSponsorName && <p>{advertisementSponsorName}</p>}</td>
                    <td>
                      {publishedAt ? (
                        <p className="status__published">{PUBLISHED_STATUS.Published}</p>
                      ) : (
                        <p className="status__draft">{PUBLISHED_STATUS.Draft}</p>
                      )}
                    </td>
                  </tr>
                )
              )}
            </Fragment>
          ))}
        </tbody>
      </Table>
      {hasNextPage && (
        <Row className="justify-content-center">
          <Col sm={3} className="w-auto">
            <Button variant="outline-dark" onClick={() => fetchNextPage()}>
              more
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default LandingPages;
