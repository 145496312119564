import clsx from 'clsx';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  DATA_IMPORT_STATUS_COMPLETE,
  DATA_IMPORT_STATUS_ERROR,
} from '@utils/backend-api/models/dataImport.ts';

import { crm_pdl_search_people_by_attributes_path, crm_root_path } from '../../../../routes.js';
import styles from './styles.module.scss';

const DataImportRow = ({ dataImport }) => {
  const [showData, setShowData] = useState(false);
  const [showError, setShowError] = useState(false);

  return (
    <div className={styles.importRow}>
      <div className={styles.createdAt}>{dataImport.created_at}</div>
      <div className={styles.name}>{dataImport.filename}</div>
      <div className={styles.result}>{dataImport.row_count} new identities added</div>
      <div className={clsx(styles.status, styles[dataImport.status])}>
        {dataImport.status === DATA_IMPORT_STATUS_ERROR ? (
          <button
            onClick={() => setShowError(!showError)}
            className={clsx(styles.dataBtn, styles.error)}
          >
            {showError ? 'hide error' : 'show error'}
          </button>
        ) : (
          dataImport.status
        )}
      </div>
      {showError && <div className={styles.errorMessage}>{dataImport.error}</div>}
      <div className={styles.actions}>
        {dataImport.administrator_list_id && dataImport.status == DATA_IMPORT_STATUS_COMPLETE && (
          <div>
            <Link
              className={styles.dataBtn}
              to={crm_root_path({ administrator_list: dataImport.administrator_list_id })}
            >
              View Admin List
            </Link>
          </div>
        )}

        <div>
          <Link
            className={styles.dataBtn}
            to={crm_pdl_search_people_by_attributes_path({ data_import_id: dataImport.id })}
          >
            Edit and Rerun Query
          </Link>
        </div>
        {showData ? (
          <div className={styles.data}>
            <button className={styles.dataBtn} onClick={() => setShowData(false)}>
              Hide Data
            </button>
            <div>{dataImport.data}</div>
          </div>
        ) : (
          <div>
            <button className={styles.dataBtn} onClick={() => setShowData(true)}>
              Show Data
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DataImportRow;
