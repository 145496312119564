import { useQuery } from 'react-query';

import { Project, getProject } from '../utils/backend-api/projects';

export const PROJECT_KEY = 'project';

export default function useProject(projectId: number | string) {
  const {
    data: project,
    isLoading: isLoadingProject,
    isFetching,
    refetch,
  } = useQuery<Project>(PROJECT_KEY, async () => await getProject(projectId));

  return {
    project,
    isLoadingProject,
    isFetching,
    refetch,
  };
}
