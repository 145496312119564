import { Field, useField } from 'formik';
import React from 'react';

const TextField = (props) => {
  const { label, name, hint } = props;
  const [field, meta] = useField(name);

  return (
    <div className="TextField form-group">
      {label && (
        <label className="input-label" htmlFor={name}>
          {label}
        </label>
      )}
      <Field
        className={`form-control ${meta.touched && meta.error ? 'is-invalid' : ''}`}
        id={name}
        {...field}
        {...props}
      />
      {hint && <p className="text-muted m-0 p-1 text-small">{hint}</p>}
      {meta.touched && meta.error && <div className="invalid-feedback">{meta.error}</div>}
    </div>
  );
};

export default TextField;
