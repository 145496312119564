import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';

import LinkedinAdForm from './Form';
import './LinkedinAd.scss';

const LinkedinAd = ({ isEditing }) => {
  return (
    <>
      <Container className="LinkedinAd">
        <Row>
          <LinkedinAdForm isEditing={isEditing} />
        </Row>
      </Container>
    </>
  );
};

export default LinkedinAd;
