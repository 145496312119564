import { Formik } from 'formik';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import { CheckBoxField, TextField } from '../FormFields';
import searchSchema from './searchSchema';

const SearchForm = ({
  setQuery,
  setQueryType,
  placeholder,
  onSubmitFn,
  showQueryType,
  reset = true,
}) => {
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setQuery(values.query);
    setQueryType(values.substringMatch);
    setSubmitting(false);
    onSubmitFn();

    if (reset) {
      resetForm();
    }
  };

  return (
    <div>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ query: '', substringMatch: false }}
        validationSchema={searchSchema}
        enableReinitialize={true}
      >
        {(formik) => (
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Row className="mt-3">
              {showQueryType && (
                <Col md={2}>
                  <CheckBoxField name="substringMatch" label="Substring" />
                </Col>
              )}
              <Col md={7}>
                <TextField
                  name="query"
                  className="crm-header-input"
                  placeholder={placeholder}
                  value={formik.values.query}
                />
              </Col>
              <Col md={1} className="text-end">
                <button
                  disabled={formik.isSubmitting || !formik.values.query}
                  type="submit"
                  className="btn btn-sm btn-outline-secondary"
                >
                  Search
                </button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SearchForm;
