import { Formik, useFormikContext } from 'formik';
import snakeCase from 'lodash/snakeCase';
import React, { useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';

import { TextField } from '../../FormFields';
import './SendTestForm.scss';
import sendTestSchema from './schema';

function SendTestForm({ includedFields, label, testMethod }) {
  const outerFormikForm = useFormikContext();

  const [isTestSent, setTestSent] = useState(false);

  const handleSubmit = async (values, { setFieldError }) => {
    try {
      // remove all whitespace and convert emails to array separated by commas
      const recipients = values.recipients.replace(/\s+/g, '').split(',');
      const sanitizedIncludedFields = {};
      for (let field of includedFields) {
        // convert key names to snake case for rails backend
        let sanitizedName = field.sanitizedName || snakeCase(field.fieldName);
        let value = field.value || outerFormikForm.values[field.fieldName];
        sanitizedIncludedFields[sanitizedName] = value;
      }

      const sanitizedParams = { ...sanitizedIncludedFields, is_test: true, recipients };

      await testMethod(sanitizedParams);

      setTestSent(true);
      setTimeout(() => setTestSent(false), 5000);
    } catch (err) {
      setFieldError('recipients', 'There was an issue sending the test email.');
    }
  };

  const isTestDisabled = useCallback(
    (recipientsValue) =>
      !recipientsValue ||
      includedFields.some(
        (field) =>
          ((outerFormikForm.errors[field.fieldName] && outerFormikForm.touched[field.fieldName]) ||
            (!outerFormikForm.touched[field.fieldName] &&
              !outerFormikForm.values[field.fieldName] &&
              !field.touchNotRequired)) &&
          field.fieldName // if no field name, doesn't come from form and don't disable
      ),
    [includedFields]
  );

  return (
    <Formik
      validationSchema={sendTestSchema}
      onSubmit={handleSubmit}
      initialValues={{ recipients: '' }}
    >
      {(formik) => (
        <div className="SendTestForm">
          <TextField name="recipients" label={label || 'Send a test email to (comma separated):'} />
          {isTestSent ? (
            <span className="sent-success --green">Sent Successfully</span>
          ) : (
            <Button
              className={`--white ${
                formik.errors.recipients && formik.touched.recipients ? 'large-margin' : ''
              }`}
              onClick={formik.handleSubmit}
              disabled={isTestDisabled(formik.values.recipients)}
            >
              Send Test
            </Button>
          )}
        </div>
      )}
    </Formik>
  );
}

export default SendTestForm;
