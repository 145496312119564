import { Formik } from 'formik';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';

import { useSubscribedAdministratorLists } from '@hooks';
import { importIdentities } from '@utils/backend-api/identities-import';

import { AutocompleteField, CheckBoxField, TextAreaField, TextField } from '../../FormFields';

type Values = {
  emails: string;
  administratorList?: { label: string; value: string };
  shouldCreateUser: boolean;
  leadType?: string;
};

const INITIAL_VALUES: Values = {
  emails: '',
  administratorList: null,
  shouldCreateUser: false,
  leadType: '',
};

const ImportEmailsForm = () => {
  const { administratorListOptions, isLoadingAdministratorLists } =
    useSubscribedAdministratorLists('manual');

  const handleSubmit = async (values: Values, { resetForm }) => {
    try {
      const sanitizedParams = {
        emails: values.emails,
        administrator_list_id: values.administratorList?.value,
        should_create_user: values.shouldCreateUser,
        lead_type: values.leadType,
      };

      await importIdentities(sanitizedParams);
      toast.success('Emails imported!');
      resetForm();
    } catch (error) {
      toast.error(error || 'Sorry, there was a problem');
    }
  };

  return (
    <Formik initialValues={INITIAL_VALUES} onSubmit={handleSubmit}>
      {(formik) => (
        <Form noValidate onSubmit={formik.handleSubmit}>
          <TextAreaField
            name="emails"
            label="Paste In Email Addresses (comma separated)"
            minRows={6}
          />

          {!isLoadingAdministratorLists && administratorListOptions.length && (
            <AutocompleteField
              label="Add to list (optional)"
              name="administratorList"
              options={administratorListOptions}
              isCreatable={false}
            />
          )}

          <div className="email-import-btn">
            <Button disabled={formik.isSubmitting} type="submit" id="send" className="mt-0">
              Import
            </Button>
            <CheckBoxField name="shouldCreateUser" label="Create as TI user" />
          </div>

          {formik.values.shouldCreateUser && <TextField name="leadType" label="Lead type" />}
        </Form>
      )}
    </Formik>
  );
};

export default ImportEmailsForm;
