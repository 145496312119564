import { useMemo } from 'react';
import { useQuery } from 'react-query';

import {
  AdministratorList,
  AdministratorListsParams,
  getAdministratorLists,
  getSubscribedAdministratorLists,
} from '@utils/backend-api/administrator-lists';

export const ADMINISTRATOR_LISTS_KEY = 'administratorLists';

const formatAdministratorListOptions = (administratorLists: AdministratorList[]) => {
  return administratorLists.map((administratorList) => ({
    label: administratorList.listName,
    value: administratorList.id,
  }));
};

export default function useAdministratorLists(params = {} as AdministratorListsParams) {
  const { connectedProviderId, administratorId, listType } = params;

  const { data: administratorLists, isLoading: isLoadingAdministratorLists } = useQuery<
    AdministratorList[]
  >([ADMINISTRATOR_LISTS_KEY, params], () =>
    getAdministratorLists({ connectedProviderId, administratorId, listType })
  );

  const administratorListOptions = useMemo(() => {
    if (administratorLists) return formatAdministratorListOptions(administratorLists);
    // TODO: add exhaustive deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [administratorLists?.length]);

  return { administratorListOptions, administratorLists, isLoadingAdministratorLists };
}

export function useSubscribedAdministratorLists(filter?: 'subscribed' | 'unsubscribed' | 'manual') {
  const { data: administratorLists, isLoading: isLoadingAdministratorLists } = useQuery<
    AdministratorList[]
  >([ADMINISTRATOR_LISTS_KEY, filter], () => getSubscribedAdministratorLists(filter));

  const administratorListOptions = useMemo(() => {
    if (administratorLists) return formatAdministratorListOptions(administratorLists);
    // TODO: add exhaustive deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [administratorLists?.length]);

  return { administratorListOptions, administratorLists, isLoadingAdministratorLists };
}
