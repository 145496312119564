import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { updateIdentity } from '../utils/backend-api/identities';
import { IDENTITY_KEY } from './useIdentity';

interface IdentityUpdateParams {
  firstName?: string;
  lastName?: string;
}

export default function useIdentityUpdate(identityId: number) {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError } = useMutation(
    async (params: IdentityUpdateParams) => await updateIdentity(identityId, params),
    {
      onSuccess: () => {
        toast.success('Identity has been updated');
        queryClient.invalidateQueries([IDENTITY_KEY, identityId]);
      },
      onError: () => {
        toast.error('Updating identity has failed');
      },
    }
  );
  return { mutate, isLoading, isError };
}
