import { useQuery } from 'react-query';

import { VirtualList, getVirtualList } from '../utils/backend-api/virtual-lists';

export const VIRTUAL_LIST_KEY = 'virtualList';

export default function useVirtualList(virtualListId: number | string) {
  const { data: virtualList, isLoading: isLoadingVirtualList } = useQuery<VirtualList>(
    [VIRTUAL_LIST_KEY, virtualListId],
    async () => await getVirtualList(virtualListId)
  );

  return { virtualList, isLoadingVirtualList };
}
