import { bulk_update_api_react_v1_crm_identity_sources_path } from '../../routes';
import request from './request';

export const updateStarredIdentitySources = async (
  isStarred,
  identityIds,
  selectAll = false,
  filters = {},
  query = null,
  administratorListId = null,
  scope = null
) => {
  return request(
    'patch',
    bulk_update_api_react_v1_crm_identity_sources_path(null, {
      is_starred: isStarred,
      identity_ids: identityIds,
      select_all: selectAll,
      filters: filters,
      query: query,
      list_id: administratorListId,
      scope: scope,
    })
  );
};
