import { DateTime } from 'aws-sdk/clients/devicefarm';

import { FilterValue } from '../../components/Crm/SideNav/Filters/SqlFilters';
import {
  api_react_v1_crm_email_domain_options_path,
  api_react_v1_crm_identity_generate_ai_summaries_path,
  api_react_v1_crm_identity_path,
  send_email_api_react_v1_crm_identities_path,
} from '../../routes';
import { AutocompleteOption, Response } from '../interfaces';
import { AdministratorList } from './administrator-lists';
import request from './request';
import { getRecord } from './universal';

export const IDENTITY_PROFILE_FIELDS = [
  'ai_knows_about',
  'ai_interested_in',
  'bio',
  'birthDate',
  'companyCity',
  'companyName',
  'companySize',
  'email',
  'emails',
  'facebookUsername',
  'githubUsername',
  'jobLevel',
  'jobRole',
  'jobTitle',
  'linkedinUsername',
  'locationCountry',
  'locationLocality',
  'locationName',
  'locationRegion',
  'personalEmails',
  'twitterFollowerCount',
  'twitterUsername',
];

interface MetaDataSubscriptionStatus {
  label: string;
  value: string;
}

export interface CrmMetadata {
  subscriptionStatuses: MetaDataSubscriptionStatus[];
}

export interface CrmIdentityFilter {
  id: string;
  label: string;
  field: string;
  operator: string;
  operatorLabel?: string;
  value: FilterValue | (string | number)[];
  type: string;
}

interface Newsletter {
  id: number;
  name: string;
}

interface IdentityNote {
  administratorEmail: string;
  createdAt: DateTime;
  id: number;
  noteContent: string;
}

interface IdentityProfile {
  ai_knows_about: string;
  ai_interested_in: string;
  bio: string;
  birthDate: string;
  companyCity: string;
  companyName: string;
  companySize: string;
  email: string;
  emails: string[];
  facebookUsername: string;
  githubUsername: string;
  jobLevel: string;
  jobRole: string;
  jobRitle: string;
  linkedinUsername: string;
  locationCountry: string;
  locationLocality: string;
  locationName: string;
  locationRegion: string;
  personalEmails: string[];
  twitterFollowerCount: string;
  twitterUsername: string;
}

interface CustomEmail {
  id: number;
  offerId: number;
  sentAt: DateTime;
  subject: string;
}

export interface IdentityAdministratorList {
  id: number;
  administratorListId: number;
}

export interface Identity {
  aiSummariesGenerated: boolean;
  currentPeopleSearchMetadata: Record<string, string | number>;
  mostRecentPeopleSearchIndexMetadata: Record<string, string | number>;
  peopleSearchMetadataUpToDate: boolean;
  latestPeopleSearchIndexDate: string;
  currentThirdPartyNewsletterSubscriptionMapWithName: Newsletter[];
  currentNewsletterSubscriptionMapWithName: Newsletter[];
  email: string;
  firstName: string;
  lastName: string;
  id: number;
  identityNotes: IdentityNote[];
  identityProfile: IdentityProfile;
  identityAdministratorLists: IdentityAdministratorList[];
  administratorLists: AdministratorList[];
  implicitPrimary: Identity;
  explicitPrimary: Identity;
  implicitAlternateIdentities: Identity[];
  explicitAlternateIdentities: Identity[];
  implicitDoNotEmail: boolean;
  explicitDoNotEmail: boolean;
  customEmails: CustomEmail[];
}

interface getCrmParams {
  limit?: number;
  offset?: number;
  administrator_list?: number | string;
  query?: string;
  query_type?: string;
  filters?: Array<CrmIdentityFilter>;
  metadata?: CrmMetadata;
  aiSearch: boolean;
  scope?: string;
}
// implement this with queryCache
export async function getCrmIdentities({
  limit,
  offset,
  administrator_list,
  query,
  query_type,
  filters,
  metadata,
  aiSearch,
  scope,
}: getCrmParams) {
  return request('get', '/crm/crm_identities', {
    limit,
    offset,
    administrator_list,
    query,
    query_type,
    filters,
    metadata,
    aiSearch,
    scope,
  });
}

export async function getCrmCount({
  limit,
  offset,
  administrator_list,
  query,
  filters,
  scope,
}: getCrmParams) {
  return request('get', '/crm/crm_count', {
    limit,
    offset,
    administrator_list,
    query,
    filters,
    scope,
  });
}

export const sendEmailToIdentities = async ({
  emailSubject,
  emailBody,
  identityIds,
  offerId,
  from,
  selectAll = false,
  filters = {},
  query = null,
  administratorListId = null,
}) => {
  return request('post', send_email_api_react_v1_crm_identities_path(null), {
    from: from,
    email_subject: emailSubject,
    email_body: emailBody,
    identity_ids: identityIds,
    select_all: selectAll,
    filters: filters,
    query: query,
    list_id: administratorListId,
    offer_id: offerId,
  });
};

export async function getIdentity(id: number): Promise<Identity> {
  const resp = await getRecord({
    id,
    model: 'Identity',
    attributes: [
      'id',
      'email',
      'firstName',
      'lastName',
      'implicitDoNotEmail',
      'explicitDoNotEmail',
    ],
    helpers: [
      'currentNewsletterSubscriptionMapWithName',
      'aiSummariesGenerated',
      'currentPeopleSearchMetadata',
      'mostRecentPeopleSearchIndexMetadata',
      'peopleSearchMetadataUpToDate',
      'latestPeopleSearchIndexDate',
    ],
    relations: {
      identityAdministratorLists: {
        attributes: ['id', 'administratorListId'],
      },
      administratorLists: { attributes: ['listName', 'id', 'color', 'listType'] },
      identityProfile: {
        attributes: IDENTITY_PROFILE_FIELDS,
      },
      implicitPrimary: { attributes: ['email', 'id'] },
      explicitPrimary: { attributes: ['email', 'id'] },
      implicitAlternateIdentities: { attributes: ['email', 'id'] },
      explicitAlternateIdentities: { attributes: ['email', 'id'] },
      identityNotes: {
        attributes: ['id', 'createdAt', 'noteContent'],
        helpers: ['administratorEmail'],
      },
      customEmails: { attributes: ['id', 'offerId', 'sentAt', 'subject'] },
    },
  });
  return resp.result;
}

export async function updateIdentity(identityId: number, params): Promise<Response> {
  const resp = await request('patch', api_react_v1_crm_identity_path(identityId), {
    identity: params,
  });
  return resp;
}

export async function generateAiSummaries(identityId: number): Promise<Response> {
  const resp = await request(
    'post',
    api_react_v1_crm_identity_generate_ai_summaries_path(identityId)
  );
  return resp;
}

export async function getEmailDomains(fragment?: string): Promise<AutocompleteOption[]> {
  const resp = await request('get', api_react_v1_crm_email_domain_options_path({ fragment }));
  return resp?.domains;
}
