import 'aws-sdk';
import clsx from 'clsx';
import { Formik, FormikHelpers } from 'formik';
import pluralize from 'pluralize';
import React, { useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useQuery, useQueryClient } from 'react-query';

import { useSubscribedAdministratorLists } from '@hooks/useAdministratorLists';
import { CRM_DATA_IMPORTS_KEY } from '@hooks/useIdentities';
import {
  DataImportStatus,
  createCsvImport,
  getImportStatus,
} from '@utils/backend-api/identities-import';

import { AutocompleteField, CheckBoxField, TextField } from '../../FormFields';

interface CsvFormValues {
  file?: File;
  administratorList?: { label: string; value: number };
  replaceExistingList: boolean;
  shouldCreateUser: boolean;
  leadType?: string;
}

const INITIAL_CSV_IMPORT_VALUES: CsvFormValues = {
  administratorList: { label: '', value: 0 },
  file: null,
  replaceExistingList: false,
  shouldCreateUser: false,
  leadType: '',
};

const uploadToS3 = async (url: string, urlFields: Record<string, string>, file: File) => {
  const formData = new FormData();

  Object.keys(urlFields).forEach((key) => formData.append(key, urlFields[key]));

  formData.append('file', file);

  const uploadResponse = await fetch(url, {
    method: 'POST',
    body: formData,
  });

  return uploadResponse.status === 201;
};

const getPresignedUrl = async (file: File) => {
  const params = new URLSearchParams({ filename: file.name });

  const response = await fetch(`/uploads/presigned_s3?${params}`);
  const responseJson = await response.json();

  const url = responseJson?.url;
  const urlFields = responseJson.url_fields;

  if (url && urlFields && (await uploadToS3(url, urlFields, file))) {
    return urlFields.key;
  } else {
    throw new Error('CSV upload has failed.');
  }
};

const ImportCsvForm = () => {
  const queryClient = useQueryClient();
  const { administratorListOptions, isLoadingAdministratorLists } =
    useSubscribedAdministratorLists('manual');

  const fileInputRef = useRef<HTMLInputElement>();

  const [dataImportId, setDataImportId] = useState<null | number>(null);
  const [showStatus, setShowStatus] = useState(false);
  const [status, setStatus] = useState<DataImportStatus | null>();

  useQuery(
    ['CrmImportStatus', dataImportId],
    async () => {
      const response = await getImportStatus(dataImportId);

      if (['complete', 'error'].includes(response.status)) {
        setDataImportId(null);
        queryClient.invalidateQueries(CRM_DATA_IMPORTS_KEY);
        if (response.status === 'complete') {
          toast.success('CSV import complete!');
        } else {
          toast.error('CSV import has failed.');
        }
      }

      // Persist the response in state since data will be nullified once `enabled` is set to false
      setStatus(response);
      return response;
    },
    {
      enabled: !!dataImportId,
      refetchInterval: 2000,
      onError: () => {
        setDataImportId(null);
        queryClient.invalidateQueries(CRM_DATA_IMPORTS_KEY);
        toast.error('There was an error');
      },
    }
  );

  const handleSubmit = async (
    values: CsvFormValues,
    { resetForm }: FormikHelpers<CsvFormValues>
  ) => {
    try {
      const csvKey = await getPresignedUrl(values.file);

      const sanitizedParams = {
        filename: values.file.name,
        csv_key: csvKey,
        administrator_list_id: values.administratorList?.value,
        replace_existing_list: values.replaceExistingList,
        should_create_user: values.shouldCreateUser,
        lead_type: values.leadType,
      };

      const response = await createCsvImport(sanitizedParams);

      setDataImportId(response.data_import_id);
      setShowStatus(true);

      resetForm();

      toast.success('CSV uploaded!');
    } catch (error) {
      console.error(error);
      toast.error('CSV upload has failed.');
    }
  };

  return (
    <Formik initialValues={INITIAL_CSV_IMPORT_VALUES} onSubmit={handleSubmit}>
      {({ handleSubmit, isValid, dirty, resetForm, setFieldValue, values }) => (
        <Form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label>Upload CSV File of Contacts</label>
            <p className="small">
              {/* TODO: Pull the following list from `ImportedContactBuilder::CONTACT_ATTRIBUTES.keys.join(", ")` */}
              Supported headers: email, given_name, middle_name, surname, nickname, job_title,
              picture_url, birthday, gender, company_name, phone_number, web_page, suffix,
              im_address, manager_name, notes, office_location, street_address, city, postal_code,
              state, country, secondary_address, utm_campaign, utm_medium, utm_source,
              source_type_extended, signed_up_at, job_function, organization_industry
            </p>
            <div className="import-identity-csv-pending-container">
              CSV import is in progress
              <span className="import-identity-csv-progress"></span>
              <button className="btn btn-link import-identity-csv-cancel">Cancel</button>
            </div>
            <div className="input-container">
              <input
                type="file"
                id="csv"
                accept="text/csv"
                required
                ref={fileInputRef}
                onChange={(event) => {
                  setFieldValue('file', event.currentTarget.files[0]);
                }}
              />
            </div>
          </div>

          {!isLoadingAdministratorLists && administratorListOptions.length && (
            <AutocompleteField
              label="Add to list (optional)"
              name="administratorList"
              options={administratorListOptions}
              isCreatable={false}
              valueIsInteger
            />
          )}
          {values.administratorList.value > 0 && (
            <CheckBoxField
              label="Replace existing list (this will delete people on the list not include in the CSV)?"
              name="replaceExistingList"
            />
          )}

          {showStatus ? (
            <>
              {status && (
                <div>
                  {status.status === 'processing' ? (
                    <p>CSV import is in progress: {status.progress}%</p>
                  ) : (
                    <div className={clsx('import-identity-csv-error', status.status)}>
                      {status.status == 'error' ? (
                        <>
                          <p>
                            <b>Failed to import CSV</b>
                          </p>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: status.error.replaceAll('\n', '<br/>'),
                            }}
                          ></p>
                        </>
                      ) : (
                        <p>
                          <b>Successfully</b> imported{' '}
                          {pluralize('row', status.row_count - status.error_count, true)} and failed
                          to import {pluralize('row', status.error_count, true)}.{' '}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              )}
              {status?.csv_errors_url && (
                <a
                  className="btn btn-sm btn-secondary me-1"
                  href={status.csv_errors_url}
                  target="_blank"
                >
                  Download CSV With Errors
                </a>
              )}
              <button
                className="btn btn-primary btn-sm"
                onClick={() => {
                  setDataImportId(null);
                  setShowStatus(false);
                  setStatus(null);
                  fileInputRef.current.value = null;
                  resetForm();
                }}
              >
                {status?.status === 'processing' ? 'Cancel' : 'Try Again'}
              </button>
            </>
          ) : (
            <>
              <div className="email-import-btn">
                <Button
                  disabled={!(isValid && dirty) || !!dataImportId}
                  type="submit"
                  id="send"
                  className="mt-0"
                >
                  Upload
                </Button>
                <CheckBoxField name="shouldCreateUser" label="Create as TI user" />
              </div>
              {values.shouldCreateUser && <TextField name="leadType" label="Lead type" />}
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ImportCsvForm;
