import moment from 'moment';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import useTalk from '../../hooks/useTalk';
import { new_talk_path, root_path } from '../../routes';
import './ConfirmTalk.scss';

const ConfirmTalk = () => {
  const { slug } = useParams();
  const { talk, isLoadingTalk } = useTalk(slug);

  if (isLoadingTalk) return null;

  return (
    <Container className="ConfirmTalk">
      <Row>
        <Col md={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 2 }}>
          <Row className="edit-talk-section">
            <Col>
              <h2>Subscriber Conversation Confirmation</h2>
              <div>Here are the details for your subscriber conversation.</div>
            </Col>
          </Row>
          <div className="edit-talk-section">
            <h3 className="confirmation-section-title">Settings</h3>
            <Row className="confirmation-info">
              <Col md={6}>Name</Col>
              <Col md={6}>{talk.name}</Col>
            </Row>
            <Row className="confirmation-info">
              <Col md={6}>Host</Col>
              <Col md={6}>{talk.createdByIdentity.name}</Col>
            </Row>
            <Row className="confirmation-info">
              <Col md={6}>Description</Col>
              <Col md={6} id="talk-description">
                {talk.description}
              </Col>
            </Row>
            <Row className="confirmation-info">
              <Col md={6}>For Subscribers to</Col>
              <Col md={6}>{talk.newsletter?.name || 'The Information'}</Col>
            </Row>
            <Row className="confirmation-info">
              <Col md={6}>Offer Code</Col>
              <Col md={6} id="talk-description">
                {talk.offer?.code || 'None'}
              </Col>
            </Row>
            <Row className="confirmation-info">
              <Col md={6}>Scheduled Time</Col>
              <Col md={6}>
                {talk.scheduledTime ? moment(talk.scheduledTime).format('lll') : 'Now'}
              </Col>
            </Row>
            <Row className="confirmation-info">
              <Col md={6}>Link</Col>
              <Col md={6}>
                <a
                  href={talk.talkUrl}
                  className="talk-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {talk.talkUrl}
                </a>
              </Col>
            </Row>
            <Row className="confirmation-info">
              <Col md={6}>Meta Image</Col>
              <Col md={6}>
                {talk.imgixUrl ? (
                  <img id="talk-image" src={talk.imgixUrl} width={200} height={105} />
                ) : (
                  'Default'
                )}
              </Col>
            </Row>
          </div>
          <Row className="edit-talk-section">
            <Col id="back-to-home">
              <a href={root_path()} className="button --white">
                Back to Home
              </a>
              <a href={new_talk_path()} className="button --black">
                Create Another Subscriber Conversation
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ConfirmTalk;
