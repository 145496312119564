import {
  api_react_v1_pdl_search_people_by_attributes_path,
  api_react_v1_people_search_status_path,
} from '../../routes';
import { AutocompleteOption, AutocompleteRecord } from '../interfaces';
import { AdministratorList } from './administrator-lists';
import { Identity } from './identities';
import { IdentityProfileField } from './identity-profiles';
import request from './request';

export type PdlSearchField = IdentityProfileField | 'company_email_domain';

export interface AttributeFieldOption {
  label: string;
  value: PdlSearchField;
}

export interface PeopleSearchAttribute {
  field?: AttributeFieldOption;
  values: AutocompleteOption[];
}

export type ConditionJoinLabel = 'All' | 'Any';
export type ConditionJoin = 'all' | 'any';
export type ConditionJoinOption = {
  label: ConditionJoinLabel;
  value: ConditionJoin;
};
export interface PeopleSearchGroup {
  conditionJoin: ConditionJoin;
  searchAttributes: PeopleSearchAttribute[];
}

export interface PeopleSearchGroupFormValues {
  conditionJoin: ConditionJoinOption;
  searchAttributes: PeopleSearchAttribute[];
}

export interface PeopleSearchFormValues {
  conditionJoin: ConditionJoinOption;
  newAdministratorListName?: string;
  existingAdministratorList?: AutocompleteRecord;
  searchAttributesGroups: PeopleSearchGroupFormValues[];
  size: number;
  searchName?: string;
}

export interface SanitizedSearchAttributesGroup {
  conditionJoin: string;
  searchAttributes: { field: PdlSearchField; values: string[] }[];
}

export interface SanitizedSearchParams
  extends Omit<
    PeopleSearchFormValues,
    'existingAdministratorList' | 'conditionJoin' | 'searchAttributesGroups'
  > {
  existingAdministratorListId?: number | string;
  conditionJoin: string;
  searchAttributesGroups: SanitizedSearchAttributesGroup[];
}

export async function searchPeopleByAttributes(values: SanitizedSearchParams): Promise<string> {
  const resp = await request('post', api_react_v1_pdl_search_people_by_attributes_path(), values);
  return resp.sidekiqJobId;
}

export interface SearchPeopleData {
  existingAdministratorList?: Pick<AdministratorList, 'id' | 'listName'>;
  newAdministratorList?: Pick<AdministratorList, 'id' | 'listName'>;
  errorMessage: string;
  foundPeople: Pick<Identity, 'id' | 'email'>[];
  status: string;
}

export async function getSearchPeopleJobStatus(sidekiqJobId: string): Promise<SearchPeopleData> {
  return await request('get', api_react_v1_people_search_status_path(sidekiqJobId));
}
