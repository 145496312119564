import {
  bulk_cancel_api_react_v1_crm_newsletter_subscription_actions_path,
  bulk_create_api_react_v1_crm_newsletter_subscription_actions_path,
} from '../../routes';
import request from './request';

export const createNewsletterSubscriptionAction = async ({
  newsletterIds,
  identityIds,
  type,
  selectAll = false,
  filters = {},
  query = null,
  administratorListId = null,
  scope = null,
}) => {
  return request(
    'post',
    bulk_create_api_react_v1_crm_newsletter_subscription_actions_path(null, {
      newsletter_ids: newsletterIds,
      identity_ids: identityIds,
      type: type,
      select_all: selectAll,
      filters: filters,
      query: query,
      list_id: administratorListId,
      scope: scope,
    })
  );
};

export const cancelNewsletterSubscriptionAction = async ({
  newsletterIds,
  identityIds,
  selectAll = false,
  filters = {},
  query = null,
  administratorListId = null,
  scope = null,
}) => {
  return request(
    'patch',
    bulk_cancel_api_react_v1_crm_newsletter_subscription_actions_path(null, {
      newsletter_ids: newsletterIds,
      identity_ids: identityIds,
      select_all: selectAll,
      filters: filters,
      query: query,
      list_id: administratorListId,
      scope: scope,
    })
  );
};
