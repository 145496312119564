import * as Yup from 'yup';

const newsletterSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('You must give the newsletter a name'),
    frequency: Yup.string().required('You must provide a frequency that the newsletter is sent'),
    lang: Yup.string().required('You must provide a language, likely: "en"'),
    author: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string().required('You must choose an author for this newsletter'),
    }),
    order: Yup.number().required('You must fill out order'),
    previewDays: Yup.number().required('You must fill out preview days'),
    rtsuPreviewDays: Yup.number().required('You must fill out RTSU Preview Days'),
    accessLevel: Yup.number().required('You must select who can subscribe to the newsletter'),
    active: Yup.boolean(),
    paid: Yup.boolean(),
    tiBundled: Yup.boolean(),
    thirdParty: Yup.boolean(),
    autoSubscribe: Yup.boolean(),
    autoSubscribeLead: Yup.boolean(),
  });

export default newsletterSchema;
