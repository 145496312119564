import React, { createContext, useContext } from 'react';

interface CurrentAdminContext {
  currentAdminId?: number;
  crmEmailToken?: string;
  environment?: string;
  allowedToManageUsers?: boolean;
  allowedToManageIdentities?: boolean;
  allowedToManageDistribution?: boolean;
  allowedToManageNewsletters?: boolean;
  allowedToManageAdvertisements?: boolean;
}

export const AdminContext = createContext<CurrentAdminContext>({
  currentAdminId: null,
  crmEmailToken: null,
  environment: 'development',
  allowedToManageUsers: false,
  allowedToManageIdentities: false,
  allowedToManageDistribution: false,
  allowedToManageNewsletters: false,
  allowedToManageAdvertisements: false,
});

export const AdminContextProvider = ({
  children,
  currentAdminId,
  crmEmailToken,
  environment,
  allowedToManageUsers,
  allowedToManageIdentities,
  allowedToManageDistribution,
  allowedToManageNewsletters,
  allowedToManageAdvertisements,
}) => {
  const value = {
    currentAdminId,
    crmEmailToken,
    environment,
    allowedToManageUsers,
    allowedToManageIdentities,
    allowedToManageAdvertisements,
    allowedToManageDistribution,
    allowedToManageNewsletters,
  };
  return <AdminContext.Provider value={value}>{children}</AdminContext.Provider>;
};

export const useAdminContext = (): CurrentAdminContext => {
  const context = useContext(AdminContext);
  return context;
};
