import moment from 'moment';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';

import { useArticle } from '../../../hooks';
import { root_path } from '../../../routes';
import DistributionSideNav from '../SideNav';
import './Layout.scss';

export const useArticleContext = () => useOutletContext();

const DistributionDashboardLayout = () => {
  const { articleId } = useParams();
  const { article, isLoadingArticle, isError } = useArticle(articleId);

  useEffect(() => {
    if (!isLoadingArticle && (isError || !article?.publishedAt)) {
      // if article is not published or doesn't exist then redirect to homepage
      toast.error('This is not a published article');
      // Need to relocate like this because home page is not in react-router
      window.location = root_path;
    }
  }, [isLoadingArticle]);

  if (isLoadingArticle) return null;
  return (
    <div className="DistributionDashboardLayout container">
      <Row className="article-details">
        <Col>
          <span className="article-detail">{article.title || article.emailSubject}</span>
          <span className="article-detail">{moment(article.publishedAt).format('lll')}</span>
          <span className="article-detail">{article.authorsAsSentence}</span>
        </Col>
      </Row>
      <Row>
        <Col md={3} xl={2}>
          <DistributionSideNav />
        </Col>
        <Col md={9} xl={10} className="main-content" id="DistributionOutletContainer">
          <Outlet context={{ article }} />
        </Col>
      </Row>
    </div>
  );
};

export default DistributionDashboardLayout;
