import { getRecords } from './universal';

export interface CannedMessage {
  active: boolean;
  id: string;
  body: string;
  subject: string;
}

export const getCannedMessages = async (): Promise<CannedMessage[]> => {
  const resp = await getRecords({
    model: 'CannedMessage',
    attributes: ['active', 'id', 'body', 'subject'],
  });
  return resp.result;
};
