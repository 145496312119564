import {
  crm_identities_csv_export_path,
  crm_identities_csv_export_status_path,
} from '../../routes';
import request from './request';

export const createCsvExport = async (query, administratorListId, filters, scope) => {
  return request('post', crm_identities_csv_export_path(), {
    query: query,
    administrator_list_id: administratorListId,
    filters: filters,
    scope: scope,
  });
};

export const getCsvExportStatus = async (dataImportId) => {
  return request(
    'get',
    crm_identities_csv_export_status_path(null, { data_import_id: dataImportId })
  );
};
