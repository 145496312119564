import { useQuery } from 'react-query';

import { Offer, getOffer } from '../utils/backend-api/offers';

export const OFFER_KEY = 'offer';

export default function useOffer(offerId: number | string) {
  const { data: offer, isLoading: isLoadingOffer } = useQuery<Offer>(
    [OFFER_KEY, offerId],
    async () => await getOffer(offerId),
    {
      enabled: !!offerId,
    }
  );

  return { offer, isLoadingOffer };
}
