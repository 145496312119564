import { Formik } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { useGroupPostsCreate, useGroupPostsUpdate, useRelevantGroups } from '../../../hooks';
import { distribution_group_post_path } from '../../../routes';
import { GroupPost } from '../../../utils/backend-api/group-posts';
import { RelevantGroup } from '../../../utils/backend-api/relevant-groups';
import GroupPostFields from './GroupPostFields';
import './GroupPostForm.scss';
import postSchema from './schema';

export type GroupPostFormValues = {
  id: string;
  relevantGroup: SelectType;
  postUrl: string;
  body: string;
  urlParam: string;
};

// TODO: Move to DropdownField after it's converted to ts
export type SelectType = {
  label: string;
  value: string;
};

const formatGroupPost: (
  groupPost: GroupPost,
  relevantGroups: RelevantGroup[]
) => GroupPostFormValues = (groupPost, relevantGroups) => {
  const relevantGroup = relevantGroups?.find((g) => g.id === groupPost.relevantGroupId);
  return {
    ...groupPost,
    relevantGroup: { label: relevantGroup?.name || '', value: relevantGroup?.id || '' },
  };
};

export default function GroupPostForm({ groupPost }: { groupPost: GroupPost }) {
  const { relevantGroups } = useRelevantGroups();
  const initialValues = formatGroupPost(groupPost, relevantGroups);
  const navigate = useNavigate();

  const { articleId, groupPostId } = useParams();
  const { mutate: createGroupPost } = useGroupPostsCreate(articleId);
  const { mutate: updateGroupPost } = useGroupPostsUpdate(articleId);

  const onSubmit = async (values: GroupPostFormValues, { setSubmitting }) => {
    const groupPost: GroupPost = {
      ...values,
      relevantGroupId: values.relevantGroup.value,
    };

    setSubmitting(false);
    if (values.id) {
      await updateGroupPost(groupPost);
    } else {
      await createGroupPost(groupPost, {
        onSuccess: (newGroupPost: GroupPost) =>
          navigate(distribution_group_post_path(articleId, newGroupPost.id)),
      });
    }
  };

  return (
    <div className="GroupPostForm">
      <h2>{groupPostId ? `Edit Post #${groupPostId}` : 'Create Post'}</h2>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={postSchema}
        enableReinitialize={true}
      >
        {({ handleSubmit }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <GroupPostFields />
          </Form>
        )}
      </Formik>
    </div>
  );
}
