import { useQuery } from 'react-query';

import { LandingPage, getLandingPage } from '../utils/backend-api/landing-pages';

export const LANDING_PAGE_KEY = 'landing_page';

export default function useLandingPage(landingPageId: number | string) {
  const {
    data: landingPage,
    isLoading: isLoadingLandingPage,
    isFetching,
    refetch,
  } = useQuery<LandingPage>(LANDING_PAGE_KEY, async () => await getLandingPage(landingPageId));

  return {
    landingPage,
    isLoadingLandingPage,
    isFetching,
    refetch,
  };
}
