import { useQuery } from 'react-query';

import { LinkedinAd, getLinkedinAd } from '../utils/backend-api/linkedin';

export const LINKEDIN_AD = 'LinkedinAd';

export default function useLinkedinAd(LinkedinAdId: number | string) {
  const { data: linkedinAd, isLoading: isLoadingLinkedinAd } = useQuery<LinkedinAd>(
    LINKEDIN_AD,
    async () => await getLinkedinAd(LinkedinAdId),
    {
      cacheTime: 1000,
    }
  );
  return { linkedinAd, isLoadingLinkedinAd };
}
