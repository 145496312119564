import { Formik } from 'formik';
import snakeCase from 'lodash/snakeCase';
import startCase from 'lodash/startCase';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import chevronDown from '../../../images/chevron-down.svg';
import chevronRight from '../../../images/chevron-right.svg';

import { getAdminEmailGroupedAdministratorLists } from '../../../utils/backend-api/administrator-lists';
import { autocompleteEntities } from '../../../utils/backend-api/entities';
import {
  getRelevantIdentities,
  getSuggestedCompanies,
} from '../../../utils/backend-api/relevant-companies';
import { Autosave } from '../../BaseUI';
import {
  CheckBoxField,
  CheckBoxGroup,
  MultiValueAutocompleteField,
  RadioField,
} from '../../FormFields';
import './RelevantCompanyIdentitiesForm.scss';
import relevantCompanySchema from './schema';

const INITIAL_LEAD_TYPE_OPTION = 'all';
const LEAD_TYPE_OPTIONS = {
  all: 'All Types of Leads',
  tiLeads: 'TI Leads Only',
  nonTiLeads:
    'Non-TI Leads Only (leads that have never provided their email through our site or app)',
};

const INITIAL_STATE = {
  companies: [],
  excludeSubscribers: true,
  excludeAlreadyNotified: true,
  excludeVIPs: false,
  includedLists: [],
  excludedLists: [],
  leadsType: INITIAL_LEAD_TYPE_OPTION,
};

const RelevantCompanyIdentitiesForm = ({
  relevantIdentities,
  setRelevantIdentities,
  setExcludeAlreadyNotified,
}) => {
  const { data: suggestedCompanies, isLoading: isCoDataLoading } = useQuery(
    'suggestedCompanies',
    async () => await getSuggestedCompanies(articleId)
  );
  const { data: administratorListMap, isLoading: isAdminListLoading } = useQuery(
    'administratorListMap',
    async () => await getAdminEmailGroupedAdministratorLists()
  );

  const [showAdminsListsMap, setShowAdminsListMap] = useState({});
  const [showRelevantIdentities, setShowRelevantIdentities] = useState(false);
  const [error, setError] = useState();
  const { mutate: fetchRelevantIdentities, isLoading: isrelevantidentitiesLoading } = useMutation(
    async ({ articleId, sanitizedValues }) =>
      await getRelevantIdentities(articleId, sanitizedValues),
    {
      onSuccess: (data) => {
        setRelevantIdentities(data);
      },
      onError: () => {
        setError('Getting relevant identities has failed');
      },
    }
  );

  const { articleId } = useParams();

  const addCompanyToList = (name, currentList, updateList) => {
    const currentListHasCompany = currentList.some((value) => value.label === name);
    if (!currentListHasCompany) {
      updateList('companies', [...currentList, { label: name, value: name }]);
    }
  };

  useEffect(() => {
    if (!isAdminListLoading) {
      const showAdminsListMap = {};
      for (let key in administratorListMap) {
        showAdminsListMap[key] = false;
      }
      setShowAdminsListMap(showAdminsListMap);
    }
  }, [administratorListMap, isAdminListLoading]);

  const toggleAdminsList = (email) => {
    setShowAdminsListMap((prevState) => ({ ...prevState, [email]: !prevState[email] }));
  };

  const handleSubmit = async (values) => {
    setError(null);
    const sanitizedValues = {
      companies: values.companies.map((c) => c.label),
      exclude_subscribers: values.excludeSubscribers,
      exclude_already_notified: values.excludeAlreadyNotified,
      exclude_vips: values.excludeVIPs,
      included_lists: values.includedLists,
      excluded_lists: values.excludedLists,
      leads_type: snakeCase(values.leadsType),
    };
    setExcludeAlreadyNotified(values.excludeAlreadyNotified);
    fetchRelevantIdentities({ articleId, sanitizedValues });
  };

  const customListsJSX = ({ isIncludedList, toggleOnly = false }) => {
    if (isAdminListLoading) {
      return;
    }
    return (
      <div>
        {Object.keys(administratorListMap).map((adminEmail) => {
          return (
            <div key={adminEmail}>
              <div className="toggle-custom-list-wrapper">
                {toggleOnly && (
                  <span className="toggle-view-more" onClick={() => toggleAdminsList(adminEmail)}>
                    {adminEmail} {showAdminsListsMap[adminEmail] ? '(hide)' : '(view)'}
                    <img
                      src={showAdminsListsMap[adminEmail] ? chevronDown : chevronRight}
                      width="12px"
                      height="12px"
                    />
                  </span>
                )}
              </div>
              {!toggleOnly && showAdminsListsMap[adminEmail] && (
                <CheckBoxGroup
                  name={isIncludedList ? 'includedLists' : 'excludedLists'}
                  options={administratorListMap[adminEmail].map((list) => ({
                    label: list.list_name,
                    value: list.id,
                  }))}
                  negativeFillClass={!isIncludedList}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="RelevantCompanyIdentitiesForm">
      <h2 className="form-header">To:</h2>
      <Col className="relevant-identities">
        <div className="count">
          {relevantIdentities.length} {relevantIdentities.length === 1 ? 'result' : 'results'}
        </div>
        {isrelevantidentitiesLoading ? (
          <div>Loading...</div>
        ) : (
          <div>
            <span
              className="toggle-view-more"
              onClick={() => setShowRelevantIdentities((prevState) => !prevState)}
            >
              {showRelevantIdentities ? (
                <span>
                  hide
                  <img src={chevronDown} width="12px" height="12px" />
                </span>
              ) : (
                <span>
                  view
                  <img src={chevronRight} width="12px" height="12px" />
                </span>
              )}
            </span>
            {showRelevantIdentities && (
              <div className="relevant-identities-list">
                {relevantIdentities.slice(0, 20).map((identity) => (
                  <span key={identity.email} className="relevant-email">
                    {identity.email}
                  </span>
                ))}
                {relevantIdentities.length > 20 && <span>...</span>}
              </div>
            )}
          </div>
        )}
      </Col>
      <Row className="relevant-identities-form">
        <Col>
          <Formik
            onSubmit={handleSubmit}
            initialValues={INITIAL_STATE}
            validationSchema={relevantCompanySchema}
          >
            {(formik) => (
              <Form noValidate onSubmit={formik.handleSubmit}>
                <Autosave debounceMs={500} />
                {error && (
                  <div>
                    <div className="--red">{error}</div>
                    <Button onClick={formik.handleSubmit}>Try Again</Button>
                  </div>
                )}
                <MultiValueAutocompleteField
                  label="Search CRM for Companies"
                  name="companies"
                  fetchMethod={(value) =>
                    autocompleteEntities({
                      fragment: value,
                      entity_type: 'organization',
                      word_type: 'proper',
                    })
                  }
                />
                {isCoDataLoading ? (
                  <div className="suggested-companies">Loading...</div>
                ) : (
                  <div className="suggested-companies">
                    <span className="suggested-company">Suggestions: </span>
                    {suggestedCompanies.map((name) => (
                      <Button
                        size="sm"
                        key={name}
                        className="suggested-company --black"
                        onClick={() =>
                          addCompanyToList(name, formik.values.companies, formik.setFieldValue)
                        }
                      >
                        {startCase(name)}
                      </Button>
                    ))}
                  </div>
                )}
                <CheckBoxField label="Exclude Subscribers" name="excludeSubscribers" />
                <CheckBoxField
                  label="Exclude Already Notified (Note that these people will be excluded when the email is sent, but will not be excluded in the recipients count above)"
                  name="excludeAlreadyNotified"
                />
                <CheckBoxField label="Exclude VIPs (5K+ Twitter Followers)" name="excludeVIPs" />
                <RadioField
                  name="leadsType"
                  label="What type of leads do you want to include?"
                  options={Object.keys(LEAD_TYPE_OPTIONS).map((leadOption) => ({
                    label: LEAD_TYPE_OPTIONS[leadOption],
                    value: leadOption,
                  }))}
                />
                <Row className="custom-lists-wrapper">
                  <Col lg={6}>
                    <h3>Custom Lists</h3>
                    {customListsJSX({ toggleOnly: true })}
                  </Col>
                  <Col lg={3}>
                    <h4>Include:</h4>
                    {customListsJSX({ isIncludedList: true })}
                  </Col>
                  <Col lg={3}>
                    <h4>Exclude:</h4>
                    {customListsJSX({ isIncludedList: false })}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};

export default RelevantCompanyIdentitiesForm;
