import clsx from 'clsx';
import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { distribution_events_path } from '../../routes';
import { getArticleData } from '../../utils/backend-api/distribution-list';
import headerStyles from '../styles.module.css';
import './DistributionList.scss';
import DistributionPageTable from './DistributionPageTable';

const tableOptions = {
  Embargoed: 'embargoedArticles',
  'Recently Published': 'recentlyPublished',
  'Top (past 45 days)': 'topArticles',
};

const DistributionList: FC = () => {
  const [currentTable, setCurrentTable] = React.useState<
    'Embargoed' | 'Recently Published' | 'Top (past 45 days)'
  >('Top (past 45 days)');
  const { data: articleData, isLoading: isLoadingArticleData } = useQuery(
    'aggregateArticleData',
    getArticleData
  );

  // TODO: Update this to loading spinner (it might take a bit...)
  if (isLoadingArticleData) return null;
  return (
    <div className="container-fluid" id="dashboard-page">
      <div className="row">
        <div className="col-md-12">
          <div className={headerStyles.headerBtnContainer}>
            <Link to={distribution_events_path()}>Distribution Events</Link>
          </div>
          <div className={headerStyles.header}>
            <h2>Distribution List - {currentTable}</h2>
            <ul className={headerStyles.filterList}>
              <li
                className={clsx(headerStyles.filterLi, {
                  [headerStyles.filterLiActive]: currentTable === 'Top (past 45 days)',
                })}
              >
                <button onClick={() => setCurrentTable('Top (past 45 days)')}>Top</button>
              </li>
              <li
                className={clsx(headerStyles.filterLi, {
                  [headerStyles.filterLiActive]: currentTable === 'Recently Published',
                })}
              >
                <button onClick={() => setCurrentTable('Recently Published')}>
                  Recently Published
                </button>
              </li>
              <li
                className={clsx(headerStyles.filterLi, {
                  [headerStyles.filterLiActive]: currentTable === 'Embargoed',
                })}
              >
                <button onClick={() => setCurrentTable('Embargoed')}>Embargoed</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <DistributionPageTable
            title={currentTable}
            articles={articleData[tableOptions[currentTable]]}
          />
        </div>
      </div>
    </div>
  );
};

export default DistributionList;
