import { SelectFieldOption } from '@utils/interfaces';

import { autocomplete_user_path } from '../../routes';
import request from './request';
import { getRecords } from './universal';

export interface User {
  email: string;
  id: number;
  identityId: number;
  name: string;
  identityProfile?: {
    jobTitle?: string;
    jobRole?: string;
    companyName?: string;
    companyIndustry?: string;
    skills?: string[];
    interests?: string[];
  };
}

export const SUBSCRIPTION_STATUS_ACTIVE = 'active';
export const SUBSCRIPTION_STATUS_BLOCKED = 'blocked';
export const SUBSCRIPTION_STATUS_CANCELED = 'canceled';
export const SUBSCRIPTION_STATUS_CANCELED_BUT_PAID = 'canceled_but_paid_through';
export const SUBSCRIPTION_STATUS_COMPED = 'comped';
export const SUBSCRIPTION_STATUS_CORPORATE = 'corporate';
export const SUBSCRIPTION_STATUS_LEAD = 'never_added_payment_info';
export const SUBSCRIPTION_STATUS_PAST_DUE = 'past_due';

export const ACCESS_LEVEL_VALUES: SelectFieldOption[] = [
  { label: 'Pro', value: 4 },
  { label: 'Yearly', value: 3 },
  { label: 'Monthly', value: 2 },
  { label: 'Any Paying Sub', value: 1 },
];

export type RecipientType = 'new_subscribers' | 'new_leads' | 'viewers';

export async function autocompleteUser(value: string) {
  const resp = await request('get', autocomplete_user_path({ fragment: value }));
  return resp.users;
}

export interface PaginatedArticleRecipients {
  result: User[];
  nextPage: number;
}

type IdentifierObject = Record<string, string | number>;
type RelationIdentifierObject = Record<string, Record<string, string | number>>;

export const getArticleRecipients = async (
  articleId: number | string,
  recipientType: RecipientType,
  page: number
): Promise<PaginatedArticleRecipients> => {
  let identifiers: IdentifierObject;
  let relationIdentifiers: RelationIdentifierObject;

  switch (recipientType) {
    case 'viewers':
      relationIdentifiers = {
        userEvents: { articleId },
      };
      break;
    case 'new_leads':
      identifiers = { createdViaArticle: articleId, subscribedViaArticle: null };
      break;
    case 'new_subscribers':
      identifiers = { subscribedViaArticle: articleId };
      break;
  }

  return await getRecords({
    model: 'User',
    attributes: ['id', 'email', 'identityId'],
    helpers: ['name'],
    relations: {
      identityProfile: {
        attributes: [
          'jobTitle',
          'jobRole',
          'companyName',
          'companyIndustry',
          'skills',
          'interests',
        ],
      },
    },
    identifiers,
    relationIdentifiers,
    page: page,
    maxPerPage: 100,
  });
};
