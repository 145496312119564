import { capitalize } from 'lodash';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import { DATA_IMPORT_STATUS_COMPLETE } from '@utils/backend-api/models/dataImport';

import { useCRMDataImports } from '../../../hooks/useIdentities';
import { crm_root_path } from '../../../routes.js';

const DataImportsTable = () => {
  const { dataImportsData, isLoadingDataImports } = useCRMDataImports();

  return isLoadingDataImports ? (
    <p>loading …</p>
  ) : (
    <table className="table align-left">
      <thead>
        <tr>
          <th>Upload date</th>
          <th>Type</th>
          <th>File name</th>
          <th>Admin List</th>
          <th>Total rows</th>
          <th>Errors</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {dataImportsData.map((dataImport) => (
          <tr key={dataImport.id}>
            <td>{dataImport.created_at}</td>
            <td>{dataImport.import_type}</td>
            <td>{dataImport.filename}</td>
            <td>
              {dataImport.administrator_list_id &&
                dataImport.status == DATA_IMPORT_STATUS_COMPLETE && (
                  <div>
                    <Link
                      className="text-decoration-underline text-secondary text-small"
                      to={crm_root_path({ administrator_list: dataImport.administrator_list_id })}
                    >
                      View Admin List
                    </Link>
                  </div>
                )}
            </td>
            <td>{dataImport.row_count}</td>
            <td>
              {dataImport.error_count}{' '}
              {dataImport.csv_errors_url && (
                <NavLink to={dataImport.csv_errors_url} className="csv-imports-table-download-link">
                  Download
                </NavLink>
              )}
            </td>
            <td>
              <span
                title={dataImport.error ?? ''}
                style={dataImport.error ? { cursor: 'help' } : {}}
              >
                {capitalize(dataImport.status)} {dataImport.queued && `${dataImport.progress}%`}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DataImportsTable;
