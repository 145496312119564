import React from 'react';

import Bold from '@images/tiptap-icons/bold.svg';
import ClearAllStyles from '@images/tiptap-icons/format-clear.svg';
import Heading1 from '@images/tiptap-icons/h-1.svg';
import Heading2 from '@images/tiptap-icons/h-2.svg';
import Heading3 from '@images/tiptap-icons/h-3.svg';
import Italic from '@images/tiptap-icons/italic.svg';
import OrderedList from '@images/tiptap-icons/list-ordered.svg';
import BulletList from '@images/tiptap-icons/list-unordered.svg';
import Strike from '@images/tiptap-icons/strikethrough.svg';
import Paragraph from '@images/tiptap-icons/text.svg';

import './MenuBar.scss';

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <section className="tiptap__menu-bar">
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        className={editor.isActive('bold') ? 'is-active' : ''}
      >
        <img
          src={Bold as unknown as string}
          height="16"
          width="16"
          className="link-icon"
          title="Bold"
        />
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        className={editor.isActive('italic') ? 'is-active' : ''}
      >
        <img
          src={Italic as unknown as string}
          height="16"
          width="16"
          className="link-icon"
          title="Italic"
        />
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        className={editor.isActive('strike') ? 'is-active' : ''}
      >
        <img
          src={Strike as unknown as string}
          height="16"
          width="16"
          className="link-icon"
          title="Strike"
        />
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().setParagraph().run()}
        className={`${editor.isActive('paragraph') ? 'is-active' : ''} has-neighbor`}
      >
        <img
          src={Paragraph as unknown as string}
          height="16"
          width="16"
          className="link-icon"
          title="Paragraph"
        />
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
      >
        <img
          src={Heading1 as unknown as string}
          height="16"
          width="16"
          className="link-icon"
          title="Heading 1"
        />
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
      >
        <img
          src={Heading2 as unknown as string}
          height="16"
          width="16"
          className="link-icon"
          title="Heading 2"
        />
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={`${editor.isActive('heading', { level: 3 }) ? 'is-active' : ''} has-neighbor`}
      >
        <img
          src={Heading3 as unknown as string}
          height="16"
          width="16"
          className="link-icon"
          title="Heading 3"
        />
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive('bulletList') ? 'is-active' : ''}
      >
        <img
          src={BulletList as unknown as string}
          height="16"
          width="16"
          className="link-icon"
          title="Bullet List"
        />
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={`${editor.isActive('orderedList') ? 'is-active' : ''} has-neighbor`}
      >
        <img
          src={OrderedList as unknown as string}
          height="16"
          width="16"
          className="link-icon"
          title="Ordered List"
        />
      </button>
      <button
        type="button"
        className="has-neighbor"
        onClick={() => editor.chain().focus().clearNodes().run()}
      >
        <img
          src={ClearAllStyles as unknown as string}
          height="16"
          width="16"
          className="link-icon"
          title="Clear all styles"
        />
      </button>
      <button
        type="button"
        className="has-text"
        onClick={() => editor.chain().focus().undo().run()}
        disabled={!editor.can().chain().focus().undo().run()}
      >
        Undo
      </button>
      <button
        type="button"
        className="has-text"
        onClick={() => editor.chain().focus().redo().run()}
        disabled={!editor.can().chain().focus().redo().run()}
      >
        Redo
      </button>
    </section>
  );
};

export default MenuBar;
