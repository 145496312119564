import React, { FC, useState } from 'react';
import toast from 'react-hot-toast';

import { AdministratorList } from '../../../utils/backend-api/administrator-lists';
import { IdentityAdministratorList } from '../../../utils/backend-api/identities';
import { deleteIdentityAdministratorList } from '../../../utils/backend-api/identity-administrator-lists';
import styles from './styles.module.css';

interface Props {
  identityAdministratorList: IdentityAdministratorList;
  administratorList: AdministratorList;
}

const AdministratorListTag: FC<Props> = ({ identityAdministratorList, administratorList }) => {
  const [display, setDisplay] = useState('inline');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDeleteIdentityAdministratorList = async () => {
    setIsSubmitting(true);
    try {
      const res = await deleteIdentityAdministratorList(identityAdministratorList.id);
      if (res.success) {
        setDisplay('none');
        toast.success('List removed!');
      } else {
        toast.error('There was an error removing the list');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <span
      className="administrator-list-tag"
      style={{ backgroundColor: administratorList.color, display }}
      id={`ial-${identityAdministratorList.id}`}
    >
      {administratorList.listName}
      {administratorList.listType === 'manual' && (
        <>
          <button
            disabled={isSubmitting}
            className={styles.deleteButton}
            onClick={handleDeleteIdentityAdministratorList}
          >
            &times;
          </button>
        </>
      )}
    </span>
  );
};

export default AdministratorListTag;
