import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { NewsletterParams, updateNewsletter } from '../utils/backend-api/newsletters';

export default function useNewsletterUpdate(newsletterId: number) {
  const { mutate, data, isLoading, isError } = useMutation(
    async (params: NewsletterParams) => await updateNewsletter(newsletterId, params),
    {
      onSuccess: (data) => {
        if (data.success) {
          toast.success('Newsletter has been updated');
        } else {
          toast.error(`Failed to update Newsletter: ${data.message}`);
        }
      },
      onError: () => {
        toast.error('Failed to update Newsletter');
      },
    }
  );
  return { mutate, data, isLoading, isError };
}
