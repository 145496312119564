import { useQuery } from 'react-query';

import { TwitterLineItem, getTwitterLineItem } from '../utils/backend-api/twitter-line-items';

export const TWITTER_LINE_ITEM_KEY = 'twitterCampaign';

export default function useTwitterLineItem(twitterLineItemId: number | string) {
  const { data: twitterLineItem, isLoading: isLoadingTwitterLineItem } = useQuery<TwitterLineItem>(
    [TWITTER_LINE_ITEM_KEY, twitterLineItemId],
    async () => await getTwitterLineItem(twitterLineItemId)
  );

  return { twitterLineItem, isLoadingTwitterLineItem };
}
