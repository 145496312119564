import moment from 'moment';
import React, { useState } from 'react';

import useOffer from '../../../../hooks/useOffer';
import styles from './styles.module.css';

const CustomEmail = ({ customEmail }) => {
  const [offerId, setOfferId] = useState(null);
  const { offer, isLoadingOffer } = useOffer(offerId);

  return (
    <div className={styles.customEmailContainer}>
      <div>{customEmail.subject}</div>
      <div className={styles.customEmailSecondary}>
        {moment(customEmail.sentAt).format('lll')}
        &nbsp;
        {customEmail.offerId ? (
          <button
            className="bg-body border-0 btn-link btn-plain"
            onClick={() => setOfferId(offerId ? null : customEmail.offerId)}
          >
            w/ offer
          </button>
        ) : (
          ''
        )}
      </div>
      {offerId && !isLoadingOffer && (
        <div className="mt-1">
          <div className={styles.offerCode}>{offer.code}</div>
          <div className={styles.offerName}>{offer.name}</div>
        </div>
      )}
    </div>
  );
};

export default CustomEmail;
