import clsx from 'clsx';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import ClipLoader from 'react-spinners/ClipLoader';

import useAdministratorList, { ADMINISTRATOR_LIST_KEY } from '@hooks/useAdministratorList';

import { useAdminContext } from '../../../../contexts/AdminContext';
import { useAdministratorListCreate, useAdministratorListUpdate } from '../../../../hooks';
import {
  ARTICLE_AUTOMATIC_LIST_TYPE_OPTION,
  AdministratorListFormValues,
  GLOBAL_AUTOMATIC_LIST_TYPE_OPTION,
  GLOBAL_MANUAL_LIST_TYPE_OPTION,
  LIST_TYPE_OPTIONS,
  USER_AUTOMATIC_LIST_TYPE_OPTION,
} from '../../../../utils/backend-api/administrator-lists';
import { autocompleteEntities } from '../../../../utils/backend-api/entities';
import {
  CheckBoxField,
  DropdownField,
  MultiValueAutocompleteField,
  TextAreaField,
  TextField,
} from '../../../FormFields';
import styles from '../styles.module.css';
import ArticleFormPartial from './ArticleFormPartial';
import validationSchema from './validationSchema';

const AdministratorListForm = ({
  initialValues,
  canEditArticle = true,
}: {
  initialValues: AdministratorListFormValues;
  canEditArticle: boolean;
}) => {
  const [administratorListId, setAdministratorListId] = useState(initialValues.id);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPopulating, setIsPopulating] = useState(false);
  const { mutateAsync: createAdministratorList } = useAdministratorListCreate();
  const { mutateAsync: updateAdministratorList } = useAdministratorListUpdate();
  const { administratorList, isLoadingAdministratorList, isRefetchingAdministratorList } =
    useAdministratorList(administratorListId);
  const { allowedToManageIdentities } = useAdminContext();
  const [showListTypeHelper, setShowListTypeHelper] = useState(false);

  const queryClient = useQueryClient();

  const isEditForm = !!administratorListId;

  // check to see if we've gotten update population counts. Set a refetch if not
  useEffect(() => {
    if (administratorList && !isRefetchingAdministratorList) {
      if (!['idle', 'failed'].includes(administratorList.status)) {
        setTimeout(() => {
          queryClient.invalidateQueries([ADMINISTRATOR_LIST_KEY, administratorListId]);
        }, 3000);
      } else {
        setIsPopulating(false);
      }
    }
  }, [
    administratorList,
    administratorListId,
    queryClient,
    isLoadingAdministratorList,
    isRefetchingAdministratorList,
  ]);

  const handleSubmit = async (values: AdministratorListFormValues) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    const personalAttributes =
      values.personalAttributesCompanyName ||
      values.personalAttributesJobLevel ||
      values.personalAttributesJobRole ||
      values.personalAttributesCompanyIndustry ||
      values.personalAttributesLocation ||
      values.personalAttributesGender
        ? {
            companyIndustry: values.personalAttributesCompanyIndustry
              ? values.personalAttributesCompanyIndustry
              : [],
            companyName: values.personalAttributesCompanyName
              ? values.personalAttributesCompanyName
              : [],
            gender: values.personalAttributesGender ? values.personalAttributesGender : [],
            location: values.personalAttributesLocation ? values.personalAttributesLocation : [],
            jobLevel: values.personalAttributesJobLevel ? values.personalAttributesJobLevel : [],
            jobRole: values.personalAttributesJobRole ? values.personalAttributesJobRole : [],
          }
        : null;
    const permissions =
      values.permissionsIgnoreMarketingEmailOptOut || values.permissionsAllowDuplicateArticleSends
        ? {
            ignoreMarketingEmailOptOut: values.permissionsIgnoreMarketingEmailOptOut,
            allowDuplicateArticleSends: values.permissionsAllowDuplicateArticleSends,
          }
        : null;
    const sanitizedValues = {
      articleId: values.article?.value,
      guiFilters: {
        subscriptionStatuses: values.subscriptionStatuses,
        accessLevels: values.accessLevels,
        personalAttributes: personalAttributes,
        permissions: permissions,
      },
      id: administratorListId,
      listName: values.listName,
      listDescription: values.listDescription,
      isShared: values.isShared,
      query: values.query,
      isArchived: values.isArchived,
      entities: values.entities
        ? values.entities.map((e) => (typeof e === 'object' ? e.value : e))
        : [],
      listType: values.listType.value,
      entityJoinTypes: [
        values.entityJoinTypesFollows && 'follows',
        values.entityJoinTypesEmployedBy && 'employed_by',
        values.entityJoinTypesViewedArticles && 'viewed_articles',
        values.entityJoinTypesProfile && 'profile_source',
      ].filter(Boolean), // filter out the undefined
    };
    const method = isEditForm ? updateAdministratorList : createAdministratorList;
    const resp = await method(sanitizedValues);

    if (resp.success) {
      setIsPopulating(true);
      if (isEditForm) queryClient.invalidateQueries([ADMINISTRATOR_LIST_KEY, administratorListId]);
      else setAdministratorListId(resp.id);
    }
    setIsSubmitting(false);
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
    >
      {(formik) => (
        <Form noValidate onSubmit={formik.handleSubmit}>
          {/* Basic Fields for all Lists */}
          <TextField label="List name" name="listName" onBlur={formik.handleSubmit} />
          <TextAreaField
            label="List description"
            name="listDescription"
            onBlur={formik.handleSubmit}
          />

          {/* Choose List Type */}
          <DropdownField
            label="List type"
            name="listType"
            onChange={formik.handleSubmit}
            className={styles.formGroupNoMargin}
            options={
              allowedToManageIdentities
                ? LIST_TYPE_OPTIONS
                : LIST_TYPE_OPTIONS.filter(
                    (o) => o.value !== GLOBAL_AUTOMATIC_LIST_TYPE_OPTION.value
                  )
            }
          />
          <div className={styles.listTypeHelperContainer}>
            {showListTypeHelper ? (
              <>
                <button
                  className={styles.linkButton}
                  onClick={() => setShowListTypeHelper(!showListTypeHelper)}
                  type="button"
                >
                  Hide List Type Helpers
                </button>
                <ul className={styles.unstyledList}>
                  {LIST_TYPE_OPTIONS.map((lto) => (
                    <li key={lto.value} className={styles.listItem}>
                      <span className={styles.listItemName}>{lto.label}</span> -{' '}
                      <span className={styles.listItemDescription}>{lto.description}</span>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <button
                className={styles.linkButton}
                onClick={() => setShowListTypeHelper(!showListTypeHelper)}
                type="button"
              >
                Not sure which list type to use?
              </button>
            )}
          </div>

          {/* Article Auto Lists: */}
          {formik.values.listType.value === ARTICLE_AUTOMATIC_LIST_TYPE_OPTION.value ? (
            <ArticleFormPartial
              canEditArticle={canEditArticle}
              formik={formik}
              initialValues={initialValues}
            />
          ) : null}

          {/* Non-Article Auto Lists:  */}
          {[
            USER_AUTOMATIC_LIST_TYPE_OPTION.value,
            GLOBAL_AUTOMATIC_LIST_TYPE_OPTION.value,
          ].includes(formik.values.listType.value) && (
            <>
              <MultiValueAutocompleteField
                label="Search entities to populate auto-list with"
                name="entities"
                fetchMethod={(value) =>
                  autocompleteEntities({
                    fragment: value,
                    relationship_sources: ['manual', 'topic', 'google'],
                    order_entities_by: ['pdl_verified'],
                  })
                }
              />
              <CheckBoxField
                label="Follows"
                name="entityJoinTypesFollows"
                onChange={formik.handleSubmit}
              />
              <CheckBoxField
                label="Employed By"
                name="entityJoinTypesEmployedBy"
                onChange={formik.handleSubmit}
              />
              <CheckBoxField
                label="Viewed Articles"
                name="entityJoinTypesViewedArticles"
                onChange={formik.handleSubmit}
              />
              <CheckBoxField
                label="Profile Source"
                name="entityJoinTypesProfile"
                onChange={formik.handleSubmit}
              />
              <p>
                <b>OR</b>
              </p>
              <TextAreaField
                label="Query clause (starts with 'SELECT identities.id FROM identities')"
                name="query"
                placeholder="add query clause here"
                minRows={6}
                onBlur={formik.handleSubmit}
              />
            </>
          )}

          <div className="mt-4">
            {![
              GLOBAL_AUTOMATIC_LIST_TYPE_OPTION.value,
              GLOBAL_MANUAL_LIST_TYPE_OPTION.value,
              ARTICLE_AUTOMATIC_LIST_TYPE_OPTION.value,
            ].includes(formik.values.listType.value) && (
              <CheckBoxField
                label="Is shared"
                name="isShared"
                hint="Shared lists are vieweable by all TI Newsletter Administrators. Only you have control to
            edit/delete it."
                onChange={formik.handleSubmit}
              />
            )}
            {isEditForm && (
              <CheckBoxField
                label="Is archived"
                name="isArchived"
                hint="Archiving this list will hide it from the CRM view."
                onChange={formik.handleSubmit}
              />
            )}
            <Button
              variant="success"
              disabled={!formik.isValid || (isEditForm && !formik.dirty) || formik.isSubmitting}
              type="submit"
            >
              {isEditForm ? 'Update List' : 'Create List'}
            </Button>
            {!administratorList ? null : administratorList.status === 'failed' ? (
              <div className={clsx(styles.targetedText, styles.failed)}>
                {administratorList.lastPopulatedSummary}
              </div>
            ) : (
              <div className={clsx(styles.targetedText, isPopulating ? styles.populating : null)}>
                {isPopulating && (
                  <ClipLoader
                    size={12}
                    color="inherit"
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    title={
                      administratorList.status == 'needs_populated'
                        ? 'Waiting in Queue'
                        : 'Populating'
                    }
                  />
                )}
                &nbsp;
                {administratorList.identityCount} identities targeted
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AdministratorListForm;
