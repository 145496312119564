export const pathToUrl = (path: string): string => {
  return window.location.protocol + '//' + window.location.host + path;
};

export const firstName = (fullName: string): string => {
  return fullName ? fullName.split(' ')[0] : '';
};

export const parameterize = (str: string): string => {
  return str
    .replaceAll(' ', '-')
    .replaceAll('&', 'and')
    .replace(/\?|&|'|\/|\\|\$/gi, '')
    .toLowerCase();
};

export const generateRandomString = (): string => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

// Copied over from Reader (if update here update there)
export const capitalize = (str: string): string => {
  return str?.replace(/#/, '').replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
};

export const stringifyObject = (
  obj: Record<string, unknown>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  replacer: (key: string, val: any) => string = null,
  spaces = 2
): string => {
  return JSON.stringify(obj, replacer, spaces);
};

export const imageTest = (value) =>
  (value?.type &&
    ['image/gif', 'image/jpg', 'image/jpeg', 'image/png', 'image/webp'].includes(value.type)) ||
  (typeof value === 'string' && value.includes('imgix'));
