import React, { Fragment } from 'react';
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import Imgix from 'react-imgix';
import { Link, useNavigate } from 'react-router-dom';

import { useAdvertisements } from '../../hooks';
import { ad_schedules_path, edit_advertisements_path, new_advertisements_path } from '../../routes';
import './Advertisements.scss';

/**
 * Advertisements: Page for displaying all existing ads
 * listed in descending order by time created
 */
const Advertisements = () => {
  const navigate = useNavigate();
  const { advertisementsData, isLoadingAdvertisements, fetchNextPage, hasNextPage } =
    useAdvertisements();

  if (isLoadingAdvertisements) return null;
  return (
    <Container className="Advertisements">
      <Row className="align-items-center">
        <Col>
          <h1>Advertisements</h1>
          <Link to={ad_schedules_path()}>Ad Schedules</Link>
        </Col>
        <Col sm={3} className="button-col">
          <Button onClick={() => navigate(new_advertisements_path())}>New Ad</Button>
        </Col>
      </Row>
      <Table className="Advertisements-table" striped bordered hover>
        <thead>
          <tr>
            <th>Logo</th>
            <th>Sponsor</th>
            <th>Thumbnail</th>
            <th>Header</th>
            <th>Runs</th>
          </tr>
        </thead>
        <tbody>
          {advertisementsData.pages.map((group, i) => (
            <Fragment key={i}>
              {group.result.map(
                ({
                  id,
                  externalImageUrl,
                  sponsorName,
                  header,
                  adImageImgixUrl,
                  schedulesListFormat,
                  sponsorLogoImgixUrl,
                }) => (
                  <tr key={id} onClick={() => navigate(edit_advertisements_path(id))}>
                    <td className="td-logo">
                      {sponsorLogoImgixUrl && (
                        <Imgix src={sponsorLogoImgixUrl} width={50} height={50} />
                      )}
                    </td>
                    <td>
                      <h2>{sponsorName}</h2>
                    </td>
                    <td className="td-image">
                      {adImageImgixUrl ? (
                        <Imgix
                          src={adImageImgixUrl}
                          width={285}
                          height={235}
                          imgixParams={{ fit: 'crop' }}
                        />
                      ) : (
                        <img src={externalImageUrl} width={285} height={235} alt="external-image" />
                      )}
                    </td>
                    <td>
                      <h2>{header}</h2>
                    </td>
                    <td>
                      {schedulesListFormat.length > 0 ? (
                        schedulesListFormat.map((run, idx) => (
                          <p key={`${run}-${idx}`} className="schedule-item">
                            {run}
                          </p>
                        ))
                      ) : (
                        <h3>no upcoming schedules</h3>
                      )}
                    </td>
                  </tr>
                )
              )}
            </Fragment>
          ))}
        </tbody>
      </Table>
      {hasNextPage && (
        <Row className="justify-content-center">
          <Col sm={3} className="w-auto">
            <Button variant="outline-dark" onClick={() => fetchNextPage()}>
              more
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Advertisements;
