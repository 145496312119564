import { useInfiniteQuery } from 'react-query';

import {
  PaginatedTwitterLineItems,
  getPaginatedTwitterLineItems,
} from '../utils/backend-api/twitter-line-items';

export const TWITTER_LINE_ITEMS_KEY = 'twitter_line_items';

export default function useTwitterLineItems(articleId = null) {
  const {
    data: twitterLineItemsData,
    isLoading: isLoadingTwitterLineItems,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<PaginatedTwitterLineItems>(
    TWITTER_LINE_ITEMS_KEY,
    ({ pageParam = 1 }) => getPaginatedTwitterLineItems(articleId, pageParam),
    {
      getNextPageParam: (lastPage) => lastPage?.nextPage,
    }
  );

  return { twitterLineItemsData, isLoadingTwitterLineItems, fetchNextPage, hasNextPage };
}
