import { api_react_v1_virtual_list_path, api_react_v1_virtual_lists_path } from '../../routes';
import { AdministratorList } from './administrator-lists';
import { ConnectedProvider } from './connected-providers';
import request from './request';
import { getRecord, getRecords } from './universal';

export interface VirtualList {
  id: number;
  listName: string;
  connected_provider: ConnectedProvider;
  administrator_lists: AdministratorList;
}

export interface PaginatedVirtualLists {
  nextPage: number;
  result: VirtualList[];
}

const MAX_LISTS_PER_PAGE = 100;

export const getPaginatedVirtualLists = async (page: number): Promise<PaginatedVirtualLists> =>
  await getRecords({
    model: 'VirtualList',
    attributes: ['id', 'listName'],
    relations: {
      connectedProvider: { attributes: ['id', 'name'] },
      administratorLists: { attributes: ['id', 'listName'] },
    },
    order: { id: 'desc' },
    page: page,
    maxPerPage: MAX_LISTS_PER_PAGE,
  });

export const getVirtualLists = async (): Promise<VirtualList[]> => {
  const resp = await getRecords({
    model: 'VirtualList',
    attributes: ['id', 'listName'],
    order: { list_name: 'asc' },
  });
  return resp.result;
};

export const getVirtualList = async (virtualListId: string | number): Promise<VirtualList> => {
  const resp = await getRecord({
    id: virtualListId,
    model: 'VirtualList',
    attributes: ['id', 'listName'],
    relations: {
      connectedProvider: { attributes: ['name', 'id'] },
      administratorLists: { attributes: ['listName', 'id'] },
    },
  });
  return resp.result;
};

// TODO: Add types for these methods -----
export async function createVirtualList(values) {
  const resp = await request('post', api_react_v1_virtual_lists_path(values));
  return resp;
}

export async function updateVirtualList(virtualListId, values) {
  const resp = await request('put', api_react_v1_virtual_list_path(virtualListId, values));
  return resp;
}

export async function deleteVirtualList(virtualListId) {
  const resp = await request('delete', api_react_v1_virtual_list_path(virtualListId));
  return resp;
}
