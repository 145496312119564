import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { getScheduledShares } from '../../../utils/backend-api/scheduled-shares';
import ScheduledTweetList from './CardList';
import ScheduledTweetForm from './Form';

export const SCHEDULED_TWEETS_KEY = 'scheduled-tweets';

function ScheduledTweet({ isEditing }) {
  const { data: tweets, isLoading: isLoadingTweets } = useQuery(
    SCHEDULED_TWEETS_KEY,
    async () => await getScheduledShares(articleId)
  );
  const { articleId } = useParams();

  return (
    <div>
      <ScheduledTweetForm isEditing={isEditing} />
      {!isLoadingTweets && <ScheduledTweetList tweets={tweets} />}
    </div>
  );
}

export default ScheduledTweet;
