import React from 'react';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import AudienceGenerator from '@components/AudienceGenerator';
import AudienceLists from '@components/AudienceLists';
import PeopleSearchDocument from '@components/Crm/PeopleSearchDocument';
import EditLandingPage from '@components/LandingPages/EditLandingPage';
import NewLandingPage from '@components/LandingPages/NewLandingPage';

import { AdminContextProvider } from '../../contexts/AdminContext';
import AdSchedules from '../AdSchedules';
import EditAdSchedules from '../AdSchedules/EditAdSchedules';
import Advertisements from '../Advertisements';
import EditAdvertisement from '../Advertisements/EditAdvertisement';
import NewAdvertisement from '../Advertisements/NewAdvertisement';
import EditAdministratorList from '../Crm/AdministratorLists/Edit';
import AdministratorListsIndex from '../Crm/AdministratorLists/Index';
import NewAdministratorList from '../Crm/AdministratorLists/New';
import CrmTable from '../Crm/CrmTable';
import IdentitiesImport from '../Crm/IdentitiesImport';
import CrmLayout from '../Crm/Layout';
import PeopleSearch from '../Crm/PeopleSearch';
import ArticleDashboard from '../DistributionDashboard/ArticleDashboard';
import DistributionDashboardLayout from '../DistributionDashboard/Layout';
import LinkedinAd from '../DistributionDashboard/LinkedinAd';
import LinkedinAdList from '../DistributionDashboard/LinkedinAd/LinkedinAdList';
import RelevantCompanyIdentities from '../DistributionDashboard/RelevantCompanyIdentities';
import RelevantGroupPosts from '../DistributionDashboard/RelevantGroupPosts';
import RtsuControl from '../DistributionDashboard/RtsuControl';
import ScheduledTweet from '../DistributionDashboard/ScheduledTweet';
import DistributionEvents from '../DistributionEvents';
import DistributionList from '../DistributionList';
import LandingPages from '../LandingPages';
import Newsletters from '../Newsletters';
import NewsletterBuilder from '../Newsletters/NewsletterBuilder';
import PageNotFound from '../PageNotFound';
import Projects from '../Projects';
import EditProject from '../Projects/EditProject';
import NewProject from '../Projects/NewProject';
import NewPushNotification from '../PushNotifications/NewPushNotification';
import PushNotificationForm from '../PushNotifications/NewPushNotification/Form';
import ShowPushNotification from '../PushNotifications/ShowPushNotification';
import ConfirmTalk from '../Talks/ConfirmTalk';
import NewTalk from '../Talks/NewTalk';
import TwitterCampaignsScreen from '../TwitterCampaigns/TwitterCampaignsScreen';
import VirtualListsScreen from '../VirtualLists/VirtualListsScreen';
import './App.scss';

const queryClient = new QueryClient();

const App = ({
  currentAdminId,
  crmEmailToken,
  environment,
  allowedToManageUsers,
  allowedToManageIdentities,
  allowedToManageDistribution,
  allowedToManageAdvertisements,
  allowedToManageNewsletters,
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <BrowserRouter>
          <AdminContextProvider
            currentAdminId={currentAdminId}
            crmEmailToken={crmEmailToken}
            environment={environment}
            allowedToManageUsers={allowedToManageUsers}
            allowedToManageIdentities={allowedToManageIdentities}
            allowedToManageDistribution={allowedToManageDistribution}
            allowedToManageAdvertisements={allowedToManageAdvertisements}
            allowedToManageNewsletters={allowedToManageNewsletters}
          >
            <Routes>
              {allowedToManageAdvertisements && (
                <>
                  {/* Ad Schedules */}
                  <Route path="/ad-schedules" element={<AdSchedules />} />
                  <Route path="/advertisements/:id/ad-schedules" element={<EditAdSchedules />} />
                  {/* Advertisements */}
                  <Route path="advertisements/new" element={<NewAdvertisement />} />
                  <Route path="advertisements" element={<Advertisements />} />
                  <Route path="advertisements/:id" element={<EditAdvertisement />} />
                  {/* Landing Pages */}
                  <Route path="landing-pages/new" element={<NewLandingPage />} />
                  <Route path="landing-pages" element={<LandingPages />} />
                  <Route path="landing-pages/:id" element={<EditLandingPage />} />
                </>
              )}
              {allowedToManageDistribution && (
                <>
                  {' '}
                  {/* Distribution List */}
                  <Route path="distribution-list" element={<DistributionList />} />
                  {/* Distribution Events */}
                  <Route path="distribution-events" element={<DistributionEvents />} />
                </>
              )}
              {allowedToManageNewsletters && (
                <>
                  {/* Newsletters */}
                  <Route path="admin_newsletter" element={<NewsletterBuilder />} />
                  <Route path="admin_newsletters" element={<Newsletters />} />
                  <Route path="admin_newsletters/:id" element={<NewsletterBuilder />} />
                </>
              )}

              {/* Projects */}
              <Route path="projects" element={<Projects />} />
              <Route path="projects/new" element={<NewProject />} />
              <Route path="projects/:id" element={<EditProject />} />
              {/* Push Notifications */}
              <Route path="push-notifications/new" element={<NewPushNotification />} />
              <Route path="push-notifications/:id" element={<ShowPushNotification />} />
              {/* Talks */}
              <Route path="talks/new" element={<NewTalk />} />
              <Route path="talks/:slug" element={<ConfirmTalk />} />
              {/* Virtual Lists */}
              <Route
                path="virtual-lists"
                element={<VirtualListsScreen />}
                key={window.location.href}
              />
              {/* Virtual Lists */}
              <Route path="virtual-lists" element={<VirtualListsScreen />} />
              <Route
                path="virtual-lists/:virtualListId"
                element={<VirtualListsScreen />}
                key={window.location.href}
              />
              {/* CRM Space */}
              <Route path="crm" element={<CrmLayout />}>
                {/* Administrator lists */}
                <Route path="administrator-lists">
                  <Route index element={<AdministratorListsIndex />} />
                  <Route path="new" element={<NewAdministratorList />} />
                  <Route path=":id/edit" element={<EditAdministratorList />} />
                </Route>
                {/* People Import */}
                <Route path="people">
                  <Route path="new" element={<IdentitiesImport />} />
                </Route>
                {/* CRM Table */}
                <Route index element={<CrmTable />} />
                <Route path=":identityId" element={<CrmTable />} />
                <Route
                  path="identities/:identityId/people_search_document"
                  element={<PeopleSearchDocument />}
                />
                <Route path="people">
                  <Route path="search-by-attributes" element={<PeopleSearch />} />
                </Route>
              </Route>
              {allowedToManageDistribution && (
                <>
                  {/* Distribution Center */}
                  <Route path="distribution/:articleId" element={<DistributionDashboardLayout />}>
                    <Route index element={<ArticleDashboard />} />
                    <Route path="scheduled-tweets" element={<ScheduledTweet />} />
                    <Route
                      path="scheduled-tweets/:scheduledShareId"
                      element={<ScheduledTweet isEditing={true} key={window.location.href} />}
                    />
                    <Route path="relevant-companies/new" element={<RelevantCompanyIdentities />} />
                    <Route path="group-posts" element={<RelevantGroupPosts />} />
                    <Route
                      path="group-posts/:groupPostId"
                      element={<RelevantGroupPosts />}
                      key={window.location.href}
                    />
                    <Route path="linkedin-ad/new" element={<LinkedinAd />} />
                    <Route
                      path="linkedin-ad/:linkedinAdId"
                      element={<LinkedinAd isEditing={true} />}
                    />
                    <Route path="linkedin-ad/list" element={<LinkedinAdList />} />
                    <Route path="push-notifications/new" element={<PushNotificationForm />} />
                    <Route path="push-notifications/:id" element={<ShowPushNotification />} />
                    {/* Twitter Campaigns */}
                    <Route path="twitter-campaigns" element={<TwitterCampaignsScreen />} />
                    <Route path="rtsu-campaigns" element={<RtsuControl />} />
                    <Route
                      path="twitter-campaigns/:twitterLineItemId"
                      element={<TwitterCampaignsScreen />}
                      key={window.location.href}
                    />
                    {/* Audience Generator */}
                    <Route path="administrator-lists" element={<AudienceLists />} />
                    <Route path="audience-generator" element={<AudienceGenerator />} />
                  </Route>
                </>
              )}
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </AdminContextProvider>

          <Toaster />
        </BrowserRouter>
      </div>
    </QueryClientProvider>
  );
};

export default App;
