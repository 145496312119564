import debounce from 'debounce-promise';
import { useField } from 'formik';
import React from 'react';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';

import './AutocompleteField.scss';

const DEBOUNCE_MS = 500;

/**
 * AutocompleteField: General purpose autocomplete field for use with Formik
 */
const AutocompleteField = (props) => {
  const {
    createMethod,
    fetchMethod,
    hint,
    inputLengthFetchThreshold = 2,
    isCreatable = true,
    label,
    name,
    options,
    valueIsInteger,
  } = props;
  const [field, meta, helpers] = useField(name);
  const SelectFieldComponent = isCreatable ? AsyncCreatableSelect : AsyncSelect;

  const asyncLoadOptions = async (value) => {
    let loadedOptions = options || [];
    if (value.length >= inputLengthFetchThreshold) {
      // if fetchMethod is present, use it, otherwise, just look at the options list
      loadedOptions = fetchMethod
        ? await fetchMethod(value)
        : options.filter((o) => o.label.toLowerCase().includes(value.toLowerCase()));
    }
    return loadedOptions.map((option) => ({
      label: option?.label || option,
      value: option?.value || option,
    }));
  };

  const debounceLoadOptions = debounce((value) => asyncLoadOptions(value), DEBOUNCE_MS, {
    leading: true,
  });

  const handleChange = async (option) => {
    //If a new option is being created, onChange sends back __isNew__ property
    const {
      label = '',
      value = valueIsInteger ? 0 : '',
      __isNew__: isNewlyCreated = false,
    } = { ...option };

    let selectedOption = { ...option, label, value };

    if (option && isNewlyCreated && createMethod) {
      const result = await createMethod(value);
      selectedOption = { label, value: valueIsInteger && result?.id ? result.id : value };
    }

    helpers.setValue(selectedOption);
  };

  const onBlur = () => {
    helpers.setTouched;
    if (props.onBlur) {
      props.onBlur();
    }
  };

  return (
    <div
      className={`AutocompleteField form-group ${meta.touched && meta.error ? 'is-invalid' : ''}`}
    >
      {label && (
        <label className="input-label" htmlFor={name}>
          {label}
        </label>
      )}
      <SelectFieldComponent
        {...props}
        {...field}
        key={JSON.stringify(options)}
        inputId={name}
        className={meta.touched && meta.error ? 'is-invalid' : ''}
        classNamePrefix="react-select"
        onChange={handleChange}
        onBlur={onBlur}
        cacheOptions
        defaultOptions
        defaultValue={field.value?.label && field.value}
        isClearable
        loadOptions={debounceLoadOptions}
      />
      {hint && <div className="text-muted m-0 p-1 text-small">{hint}</div>}
      {meta.touched && meta.error && (
        <div className="invalid-feedback">{meta.error.value || meta.error}</div>
      )}
    </div>
  );
};

export default AutocompleteField;
