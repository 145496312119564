import { Formik } from 'formik';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { IDENTITY_KEY } from '../../../../hooks/useIdentity';
import { generateAiSummaries } from '../../../../utils/backend-api/identities';

const AiSummariesForm = ({ identity }) => {
  const [generatedAiSummaries, setGeneratedAiSummaries] = useState(false);
  const queryClient = useQueryClient();

  const handleSubmit = async (values, actions) => {
    try {
      const response = await generateAiSummaries(identity.id);

      if (response.success) {
        queryClient.invalidateQueries([IDENTITY_KEY, identity.id]);
        toast.success(response.message);
        setGeneratedAiSummaries(true);
        actions.resetForm();
      } else {
        toast.error(response.message);
      }
    } catch (e) {
      toast.error(`There was an error creating the AI summaries: ${e}`);
    }
  };

  const aiSummariesGenerated = () =>
    identity.identityProfile?.aiKnowsAbout && identity.identityProfile?.aiInterestedIn;

  return (
    <Formik onSubmit={handleSubmit} initialValues={{}}>
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <Button type="submit" disabled={formik.isSubmitting || generatedAiSummaries}>
            {(formik.isSubmitting && `Generating Summaries...`) ||
              (aiSummariesGenerated() ? `Re-generate AI Summaries` : `Generate AI Summaries`)}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default AiSummariesForm;
