import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import LandingPageForm from './LandingPageForm/index';
import './NewLandingPage.scss';

/**
 * NewLandingPage: Page for creating new Landing Pages.
 * Uses shared LandingPageForm component.
 */
const NewLandingPage = () => {
  return (
    <Container className="NewLandingPage">
      <Row>
        <Col md={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 2 }}>
          <Row>
            <Col xs={12}>
              <h2 className="mb-1">Setup new Landing Page</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mb-4">
              <hr className="divider" />
            </Col>
          </Row>
          <LandingPageForm isNewLandingPage />
        </Col>
      </Row>
    </Container>
  );
};

export default NewLandingPage;
