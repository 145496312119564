import { Formik } from 'formik';
import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { useNewsletters } from '../../hooks';
import { createTalk } from '../../utils/backend-api/talks';
import { getRecords } from '../../utils/backend-api/universal';
import {
  AutocompleteField,
  AutocompleteUserField,
  DateTimeField,
  DropdownField,
  ImageField,
  TextAreaField,
  TextField,
} from '../FormFields';
import newTalkSchema from './newTalkSchema';

const INITIAL_STATE = {
  createdByIdentity: {
    value: '',
    identityId: '',
    email: '',
    label: '',
    pictureUrl: '',
    fieldValue: '',
  },
  description: '',
  image: '',
  offer: { label: '', value: '' },
  name: '',
  newsletter: { label: '', value: '' },
  scheduledTime: '',
};

const fetchOffers = async (value) => {
  // TODO: handle for expired offers
  const offers = await getRecords({
    model: 'Offer',
    attributes: ['code', 'id'],
    subStringIdentifiers: { code: value },
    order: { created_at: 'desc' },
  });
  return offers?.result?.map((o) => ({ label: o.code, value: o.id }));
};

export default function NewTalk() {
  const { newsletterOptions, isLoadingNewsletters } = useNewsletters();
  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const sanitizedParams = {
      name: values.name,
      created_by_identity_id: values.createdByIdentity.identityId,
      description: values.description,
      scheduled_time: values.scheduledTime || null, // convert empty string to null
      newsletter_id: values.newsletter?.value || null, // convert empty string to null
      offer_id: values.offer?.value || null, // convert empty string to null
      image: values.image || null, // convert empty string to null
    };
    // create FormData object so we can send request with content-type form-data for image uploading
    const formData = new FormData();
    for (let key in sanitizedParams) {
      if (sanitizedParams[key]) {
        formData.append(key, sanitizedParams[key]);
      }
    }
    try {
      const redirectUrl = await createTalk(formData);
      toast.success('Talk was successfully created.');
      if (redirectUrl) {
        navigate(redirectUrl);
      }
    } catch {
      toast.error(
        'There was an issue creating the talk. Please contact the engineering team for support.'
      );
      setSubmitting(false);
    }
  };

  if (isLoadingNewsletters) return null;
  return (
    <Container id="new-talk-page">
      <Row>
        <Col md={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 2 }}>
          <Row className="edit-talk-section">
            <Col>
              <h2>Subscriber Conversation Setup</h2>
              <div>Create a live-audio discussion for your subscribers.</div>
            </Col>
          </Row>
          <Formik
            onSubmit={handleSubmit}
            initialValues={INITIAL_STATE}
            validationSchema={newTalkSchema}
          >
            {(formik) => (
              <Form noValidate onSubmit={formik.handleSubmit}>
                <Row>
                  <Col md={6} className="edit-talk-section">
                    <TextField name="name" label="Subscriber Conversation name" />
                  </Col>
                  <Col md={6} className="edit-talk-section">
                    <AutocompleteUserField
                      name="createdByIdentity"
                      label="Host name"
                      placeholder="Select a host"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="edit-talk-section">
                    <TextAreaField
                      name="description"
                      label="Subscriber conversation description"
                      minRows={4}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="edit-talk-section">
                    <DropdownField
                      name="newsletter"
                      label="Optional: Is this for a specific newsletter's subscribers? (If left unselected, only TI subscribers will be able to join)"
                      placeholder=""
                      options={[{ label: 'N/A', value: '' }, ...newsletterOptions]}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="edit-talk-section">
                    <AutocompleteField
                      name="offer"
                      label="Optional: Select an offer for the subscriber conversation"
                      maxDaysOut={90}
                      fetchMethod={fetchOffers}
                      isCreatable={false}
                      inputLengthFetchThreshold={1}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="edit-talk-section">
                    <ImageField
                      name="image"
                      label="Optional: Upload an image for Open Graph (to display when sharing talk - default image is TI logo)"
                      hint="Should be ratio equivalent to 1200w x 630h (1.91/1)"
                      height={105}
                      width={200}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="edit-talk-section">
                    <DateTimeField
                      name="scheduledTime"
                      label="Optional: Start date/time (If left blank, talk will start now)"
                      maxDaysOut={90}
                    />
                  </Col>
                </Row>
                <div className="edit-talk-section --black-top-border" id="continue-col">
                  <Button
                    disabled={formik.isSubmitting}
                    type="submit"
                    className="continue-button button --black"
                    id="create-talk-button"
                  >
                    Create Subscriber Conversation
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </Container>
  );
}
