import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { useAdminContext } from '../../contexts/AdminContext';
import styles from './styles.module.css';

const PageNotFound = () => {
  const permissions = useAdminContext();
  const {
    allowedToManageUsers,
    allowedToManageIdentities,
    allowedToManageDistribution,
    allowedToManageAdvertisements,
    allowedToManageNewsletters,
  } = permissions;

  return (
    <Container className="mt-5" id="dashboard-page">
      <Row>
        <Col>
          <h2>Page Not Found</h2>
          <p>Check to make sure that you have proper permissions to view this page.</p>
          <p className={styles.permissionListHeader}>Your Permissions:</p>
          <ul className={styles.permissionList}>
            <li>
              <span className={styles.permissionIndicator}>
                {allowedToManageUsers ? 'Allowed' : 'Not Allowed'}
              </span>{' '}
              to Manage Users
            </li>
            <li>
              <span className={styles.permissionIndicator}>
                {allowedToManageIdentities ? 'Allowed' : 'Not Allowed'}
              </span>{' '}
              to Manage Identities
            </li>
            <li>
              <span className={styles.permissionIndicator}>
                {allowedToManageDistribution ? 'Allowed' : 'Not Allowed'}
              </span>{' '}
              to Manage Distribution
            </li>
            <li>
              <span className={styles.permissionIndicator}>
                {allowedToManageAdvertisements ? 'Allowed' : 'Not Allowed'}
              </span>{' '}
              to Manage Advertisements
            </li>
            <li>
              <span className={styles.permissionIndicator}>
                {allowedToManageNewsletters ? 'Allowed' : 'Not Allowed'}
              </span>{' '}
              to Manage Newsletters
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default PageNotFound;
