import moment from 'moment';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { IDENTITY_KEY } from '..';
import { deleteIdentityNote } from '../../../../utils/backend-api/identity-notes';
import styles from './styles.module.css';

const IdentityNotesList = ({ identityNotes, identityId }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const queryClient = useQueryClient();

  const handleDeleteIdentityNote = async (identityNoteId) => {
    setIsSubmitting(true);
    try {
      const response = await deleteIdentityNote(identityNoteId);
      if (response.success) {
        queryClient.invalidateQueries([IDENTITY_KEY, identityId]);
        toast.success('Note removed');
      } else {
        toast.error('failed to remove note');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return identityNotes.map((note) => (
    <div className={styles.noteContainer} key={note.id}>
      <div>{note.noteContent}</div>{' '}
      <div className={styles.noteAuthor}>
        {moment(note.createdAt).format('ll')} - {note.administratorEmail}
        <button
          id={`identity-note-del-btn-${note.id}`}
          className={styles.deleteButton}
          onClick={() => handleDeleteIdentityNote(note.id)}
          disabled={isSubmitting}
        >
          &times;
        </button>
      </div>
    </div>
  ));
};

export default IdentityNotesList;
