import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { AdScheduleSanitizedValues, createAdSchedule } from '../utils/backend-api/ad-schedules';

export default function useAdScheduleCreate() {
  const { mutate, mutateAsync, isLoading, isError } = useMutation(
    async (params: AdScheduleSanitizedValues) => await createAdSchedule(params),
    {
      onSuccess: () => {
        toast.success('Schedule added!');
      },
      onError: (e: string) => {
        toast.error(e || 'Sorry, there was a problem');
      },
    }
  );
  return { mutate, mutateAsync, isLoading, isError };
}
