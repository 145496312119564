import React, { createContext, useContext } from 'react';

import { CrmIdentityFilter, CrmMetadata } from '../utils/backend-api/identities';

export type AdministratorListFilterType = {
  administratorId: number;
  id: number;
  color: string;
  listDescription: string;
  listName: string;
  listType: string;
} | null;

type CrmFilterContext = {
  filters?: CrmIdentityFilter[];
  setFilters?: (filters: CrmIdentityFilter[]) => void;
  metadata?: CrmMetadata;
  setMetadata?: (metadata: CrmMetadata) => void;
  administratorListFilter?: AdministratorListFilterType;
  setAdministratorListFilter?: (filter: AdministratorListFilterType) => void;
  crmScope?: string;
  setCrmScope?: (crmScope: string) => void;
  limit?: number;
  setLimit?: (limit: number) => void;
  aiQueryActive?: boolean;
  setAiQueryActive?: (aiQueryActive: boolean) => void;
  queryEnabled?: boolean;
  setQueryEnabled?: (queryEnabled: boolean) => void;
  queryType?: string;
  setQueryType?: (queryType: string) => void;
};

export const FilterContext = createContext<CrmFilterContext>({});

export const FilterContextProvider = ({
  children,
  filters,
  setFilters,
  metadata,
  limit,
  setLimit,
  setMetadata,
  aiQueryActive,
  setAiQueryActive,
  administratorListFilter,
  setAdministratorListFilter,
  crmScope,
  setCrmScope,
  queryEnabled,
  setQueryEnabled,
  queryType,
  setQueryType,
}) => {
  const value = {
    filters,
    setFilters,
    metadata,
    setMetadata,
    limit,
    setLimit,
    aiQueryActive,
    setAiQueryActive,
    administratorListFilter,
    setAdministratorListFilter,
    crmScope,
    setCrmScope,
    queryEnabled,
    setQueryEnabled,
    queryType,
    setQueryType,
  };
  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};

export const useFilterContext = (): CrmFilterContext => {
  const context = useContext(FilterContext);
  return context;
};
