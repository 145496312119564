import { last, range } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Toaster } from 'react-hot-toast';
import { toast } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useQueryClient } from 'react-query';
import { Link, useParams } from 'react-router-dom';

import { useAdminContext } from '@contexts/AdminContext';
import { crm_edit_administrator_list_path, crm_root_path } from '@routes';

import { useFilterContext } from '../../../contexts/FilterContext';
import {
  CRM_IDENTITIES_COUNT_KEY,
  CRM_IDENTITIES_KEY,
  DEFAULT_PAGINATION_LIMIT,
  EXACT_MATCH_QUERY,
  SUBSTRING_QUERY,
  useCRMIdentities,
} from '../../../hooks/useIdentities';
import { CrmIdentityFilter } from '../../../utils/backend-api/identities';
import { updateStarredIdentitySources } from '../../../utils/backend-api/identity-sources';
import { Identity } from '../../../utils/backend-api/models/identity';
import { generateRandomString } from '../../../utils/helpers';
import { pathToUrl } from '../../../utils/helpers';
import BulkActions from '../BulkActions';
import ExportCSV from '../ExportCSV';
import IdentityModal from '../IdentityModal';
import SearchForm from '../SearchForm';
import styles from './styles.module.css';

type FilterType = Omit<CrmIdentityFilter, 'id'>;

const twitterUrl = (twitterHandle: string) => {
  return twitterHandle ? `https://twitter.com/${twitterHandle}` : '';
};

const SUBSCRIPTION_STATUS_MAP = {
  active: { title: 'Active', shorthand: 'A' },
  canceled: { title: 'Canceled', shorthand: 'C' },
  canceled_but_paid_through: { title: 'Canceled & Paid', shorthand: 'C&P' },
  never_added_payment_info: { title: 'No Payment', shorthand: 'No P' },
  unsubscribed: { title: 'Unsubscribed', shorthand: 'Unsub' },
  past_due: { title: 'Past Due', shorthand: 'PD' },
  trialing: { title: 'Trial', shorthand: 'T' },
};

const CrmTable = () => {
  const {
    administratorListFilter,
    setAdministratorListFilter,
    filters,
    setFilters,
    metadata,
    limit,
    aiQueryActive,
    setAiQueryActive,
    crmScope,
    queryEnabled,
    queryType,
    setQueryType,
    setQueryEnabled,
  } = useFilterContext();

  const { identityId } = useParams();
  const { currentAdminId } = useAdminContext();
  const isAdmin = administratorListFilter?.administratorId === currentAdminId;
  const [identities, setIdentities] = useState<Identity[]>([]);
  const [identitiesCount, setIdentitiesCount] = useState<number>(0);
  const [selectedIdentities, setSelectedIdentities] = useState<Identity[]>([]);
  const [allIdentitiesSelected, setAllIdentitiesSelected] = useState<boolean>(false);

  const [page, setPage] = useState<number>(1);
  const [paginationOptions, setPaginationOptions] = useState<number[]>([]);
  const [query, setQuery] = useState<string>('');
  const [showBulkActions, setShowBulkActions] = useState<boolean>(false);
  const [bulkAction, setBulkAction] = useState(null);
  const [starredIdentities, setStarredIdentities] = useState({});
  const [showIdentityModal, setShowIdentityModal] = useState<number | string>(identityId);
  const paginationLimit = DEFAULT_PAGINATION_LIMIT;
  const queryClient = useQueryClient();

  const setSubstringMatch = (value) => setQueryType(value ? SUBSTRING_QUERY : EXACT_MATCH_QUERY);

  const queryTypeForDisplay = () => (aiQueryActive ? 'AI' : queryType);

  const offset = () => {
    return (page - 1) * DEFAULT_PAGINATION_LIMIT;
  };

  const { identitiesData, isLoadingIdentities, isFetchingIdentities } = useCRMIdentities({
    offset: offset(),
    administratorListId: administratorListFilter?.id,
    query: query,
    filters: filters,
    metadata: metadata,
    limit: limit,
    aiSearch: aiQueryActive,
    queryEnabled: queryEnabled,
    queryType: queryType,
    scope: crmScope,
  });

  useEffect(() => {
    if (queryEnabled && !isLoadingIdentities) {
      setIdentities(identitiesData.identities);
    } else {
      setIdentities([]);
    }
    // TODO: add exhaustive deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingIdentities, identitiesData?.identities.length, queryEnabled]);

  useEffect(() => {
    if (queryEnabled && !isLoadingIdentities) {
      setIdentitiesCount(identitiesData.count);
    } else {
      setIdentitiesCount(0);
    }
  }, [isLoadingIdentities, identitiesData?.count, queryEnabled]);

  useEffect(() => {
    queryClient.invalidateQueries(CRM_IDENTITIES_KEY);
    queryClient.invalidateQueries(CRM_IDENTITIES_COUNT_KEY);
  }, [query, administratorListFilter?.id, filters?.length, crmScope, queryClient]);

  const sendIndividualEmail = (identity: Identity) => {
    setSelectedIdentities([identity]);
    setBulkAction('send-email');
  };

  const toggleStarred = async (identityId, newState) => {
    await updateStarredIdentitySources(newState, [identityId]);
    // we maintain local changes to the starred states to avoid refreshing data
    //  from the server while still properly showing the updated starred state
    setStarredIdentities({ ...starredIdentities, [identityId]: newState });
  };

  const handleCheckboxToggle = (identity: Identity) => {
    const newSelectedIdentities = selectedIdentities.includes(identity)
      ? selectedIdentities.filter((i) => i.id !== identity.id)
      : [...selectedIdentities, identity];
    setSelectedIdentities(newSelectedIdentities);
  };

  const handleSelectAll = () => {
    setSelectedIdentities(allIdentitiesSelected ? [] : identities);
    setAllIdentitiesSelected((prevValue) => !prevValue);
  };

  useEffect(() => {
    setShowBulkActions(
      (selectedIdentities && selectedIdentities.length > 0) || allIdentitiesSelected
    );
    // TODO: add exhaustive deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIdentities.length, allIdentitiesSelected]);

  const identitiesShowing = () => {
    if (identities.length == 0) {
      return 'No identities found.';
    } else if (identities.length === identitiesCount) {
      return `Showing all ${identities.length} identities`;
    } else if (identities.length < identitiesCount) {
      const start = (page - 1) * paginationLimit + 1;
      const end = Math.min(start + (paginationLimit - 1), identitiesCount);

      return `Showing ${start} - ${end} of ${identitiesCount} identities`;
    } else {
      return `Showing last ${identities.length} of ${identitiesCount}`;
    }
  };

  useEffect(() => {
    const paginationStart = Math.max(1, page - 4);
    const totalPages = Math.ceil(identitiesCount / paginationLimit);
    const paginationEnd = Math.min(totalPages, page + 4);
    const paginationRange = range(paginationStart, paginationEnd + 1);

    setPaginationOptions(paginationRange);
    // TODO: add exhaustive deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, identitiesCount]);

  const removeFilter = (filterId) => {
    setFilters(filters.filter((filter) => filter.id !== filterId));
  };

  const addFilter = ({ field, label, operator, operatorLabel, type, value }: FilterType) => {
    const id = generateRandomString();
    setFilters([...filters, { id, field, label, operator, operatorLabel, type, value }]);
  };

  const loadingRows = () => {
    return (
      <>
        {[1, 2, 3].map((i) => (
          <tr key={`loading-row-${i}`}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ii) => (
              <td key={`loading-column-${ii}`}>
                <Skeleton />
              </td>
            ))}
          </tr>
        ))}
      </>
    );
  };

  return (
    <>
      {showIdentityModal && (
        <IdentityModal setShowIdentityModal={setShowIdentityModal} identityId={showIdentityModal} />
      )}
      <Toaster />
      <Row className="mt-3">
        <Col md={6}>
          <button
            className="btn btn-sm btn-outline-dark"
            onClick={() => {
              setQuery(null);
              setPage(1);
              setAiQueryActive(!aiQueryActive);
            }}
          >
            {aiQueryActive ? 'Use Filters' : 'Use AI'}
          </button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={5}>
          <BulkActions
            showBulkActions={showBulkActions}
            identities={selectedIdentities}
            selectAll={allIdentitiesSelected}
            bulkAction={bulkAction}
            setBulkAction={setBulkAction}
            filters={filters}
            query={query}
            administratorListId={administratorListFilter?.id}
            setAllIdentitiesSelected={setAllIdentitiesSelected}
            setSelectedIdentities={setSelectedIdentities}
          />

          {query && (
            <div className="mt-3">
              Showing results for &quot;{query}&quot; -
              <span className="text-muted">{queryTypeForDisplay()} </span>
              {!aiQueryActive && (
                <button className="btn btn-sm btn-plain" onClick={() => setQuery(null)}>
                  &times;
                </button>
              )}
            </div>
          )}
          {aiQueryActive && (
            <div className="font-monospace text-muted" style={{ fontSize: '12px' }}>
              Limit: {limit}
            </div>
          )}
          {aiQueryActive &&
            (metadata?.subscriptionStatuses?.length > 0 ? (
              <div>
                <div className="fw-bold">Pinecone metadata:</div>
                {metadata.subscriptionStatuses.map((status) => (
                  <div key={status.value}>
                    <span className="font-monospace text-muted" style={{ fontSize: '12px' }}>
                      {status.label}
                    </span>{' '}
                  </div>
                ))}
              </div>
            ) : (
              <div className="fw-bold">No pinecone metadata</div>
            ))}
          {(filters?.length > 0 || administratorListFilter) && !aiQueryActive && (
            <div>
              <div className="fw-bold">Filtered to:</div>
              {filters.map((filter) => (
                <div key={filter.id} className="">
                  <span className="font-monospace text-muted" style={{ fontSize: '12px' }}>
                    {filter.label} {filter.operatorLabel.toLowerCase()} &quot;
                    {Array.isArray(filter.value) ? filter.value.join(', ') : filter.value}
                    &quot;
                  </span>{' '}
                  <button
                    className="btn btn-sm btn-plain text-danger"
                    onClick={() => removeFilter(filter.id)}
                  >
                    &times;
                  </button>
                </div>
              ))}
              {administratorListFilter && (
                <div className="">
                  <span style={{ color: administratorListFilter.color, fontSize: '12px' }}>
                    {administratorListFilter.listName}
                  </span>{' '}
                  <button
                    className="btn btn-sm btn-plain ms-1"
                    onClick={() => setAdministratorListFilter(null)}
                  >
                    &times;
                  </button>
                  <CopyToClipboard
                    text={pathToUrl(
                      crm_root_path({ administrator_list: administratorListFilter.id })
                    )}
                    onCopy={() =>
                      toast.success(`${administratorListFilter.listName} link copied to clipboard`)
                    }
                  >
                    <button className="btn btn-plain btn-link m-0 ms-2 h-auto">
                      <i className="fa-solid fa-link"></i>
                    </button>
                  </CopyToClipboard>
                  {isAdmin && (
                    <span className={styles.editLink}>
                      -{' '}
                      <Link to={crm_edit_administrator_list_path(administratorListFilter.id)}>
                        edit
                      </Link>
                    </span>
                  )}
                  <div className="text-secondary text-small">
                    {administratorListFilter.listDescription}
                  </div>
                </div>
              )}
            </div>
          )}
        </Col>
        <Col md={7}>
          <SearchForm
            setQuery={setQuery}
            setQueryType={setSubstringMatch}
            showQueryType={!aiQueryActive}
            placeholder={aiQueryActive ? `Ask a question` : `Search identities by email`}
            reset={false}
            onSubmitFn={() => setQueryEnabled(true)}
          />
        </Col>
      </Row>

      <table className="table table-hover table-crm align-left mt-2">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                name="identity"
                id="select-all-checkbox"
                checked={allIdentitiesSelected}
                onChange={handleSelectAll}
                className="identity-checkbox"
              />
            </th>
            <th style={{ minWidth: '5rem' }}>Action</th>
            <th>Starred</th>
            <th>Secondary</th>
            <th>Name</th>
            <th>Email</th>
            <th>Notes Snippet</th>
            <th>Company</th>
            <th>TI Sub State</th>
            <th>Twitter</th>
            <th>AI Summaries Generated</th>
          </tr>
        </thead>
        <tbody>
          {isFetchingIdentities
            ? loadingRows()
            : identities.map((identity) => (
                <tr
                  key={`identity-row-${identity.id}`}
                  id={`identity-${identity.id}`}
                  className={
                    identity.custom_emails_with_offer_count > 0 ? styles.hasOffer : styles.noOffer
                  }
                >
                  <td>
                    <input
                      type="checkbox"
                      name="identity"
                      id={`identity-input-${identity.id}`}
                      value={identity.id}
                      checked={selectedIdentities.includes(identity)}
                      onChange={() => handleCheckboxToggle(identity)}
                      className="identity-checkbox"
                    />
                  </td>
                  <td>
                    <button
                      className="btn btn-sm btn-send-email"
                      onClick={() => sendIndividualEmail(identity)}
                    >
                      <i
                        className="fa-solid fa-paper-plane --magenta"
                        title={`Send email to ${identity.email}`}
                      ></i>
                    </button>
                    <button
                      className="btn btn-sm btn-plain"
                      onClick={() => setShowIdentityModal(identity.id)}
                    >
                      {identity.identity_notes_count > 0 ? (
                        <i
                          className="fa-regular fa-comment-dots --magenta"
                          title={`${identity.email} has ${identity.identity_notes_count} note(s)`}
                        ></i>
                      ) : (
                        <i
                          className="fa-solid fa-comment-slash --grey"
                          title={`${identity.email} does not have a note`}
                        ></i>
                      )}
                    </button>
                  </td>
                  <td className="text-center">
                    <button
                      className="btn btn-sm"
                      onClick={() =>
                        toggleStarred(
                          identity.id,
                          !(typeof starredIdentities[identity.id] !== 'undefined'
                            ? starredIdentities[identity.id]
                            : identity.identity_source_is_starred)
                        )
                      }
                    >
                      {(
                        typeof starredIdentities[identity.id] !== 'undefined'
                          ? starredIdentities[identity.id]
                          : identity.identity_source_is_starred
                      ) ? (
                        <i className="fas fa-star --magenta"></i>
                      ) : (
                        <i className="fa-regular fa-star --magenta"></i>
                      )}
                    </button>
                  </td>
                  <td className="text-center">
                    {(identity.implicit_primary_id || identity.explicit_primary_id) && (
                      <span className="primary-icon non-primary-icon">X</span>
                    )}
                  </td>
                  <td>
                    <span>
                      <button
                        className="btn btn-sm btn-plain"
                        onClick={() => setShowIdentityModal(identity.id)}
                      >
                        {identity.name}
                      </button>
                    </span>
                  </td>
                  <td>
                    <span>
                      <button
                        className="btn btn-sm btn-plain"
                        onClick={() => setShowIdentityModal(identity.id)}
                      >
                        {identity.email}
                      </button>
                    </span>
                  </td>
                  <td>{identity.identity_notes_content}</td>
                  <td>
                    {aiQueryActive ? (
                      <span>{identity.company_name}</span>
                    ) : (
                      <button
                        className="btn btn-sm"
                        onClick={() =>
                          addFilter({
                            field: 'identities.email',
                            label: 'Email',
                            operator: 'ILIKE',
                            operatorLabel: 'includes',
                            type: 'string',
                            value: `@${identity.email.split('@')[1]}`,
                          })
                        }
                      >
                        {identity.company_name}
                      </button>
                    )}
                  </td>
                  <td>
                    {identity.user &&
                      (aiQueryActive ? (
                        <span>
                          {SUBSCRIPTION_STATUS_MAP[identity.user.subscription_status].shorthand}
                        </span>
                      ) : (
                        <button
                          className="btn btn-sm"
                          title={SUBSCRIPTION_STATUS_MAP[identity.user.subscription_status]?.title}
                          onClick={() =>
                            addFilter({
                              field: 'users.subscription_status',
                              label: "User's Subscription Status",
                              operator: '=',
                              operatorLabel: 'equals',
                              type: 'string',
                              value: 'identity.user.subscription_status',
                            })
                          }
                        >
                          {SUBSCRIPTION_STATUS_MAP[identity.user.subscription_status].shorthand}
                        </button>
                      ))}
                  </td>
                  <td>
                    {identity.twitter_handle && (
                      <a
                        title={`${identity.twitter_handle}`}
                        href={twitterUrl(identity.twitter_handle)}
                        target="_blank"
                      >
                        {identity.twitter_follower_count}
                      </a>
                    )}
                  </td>
                  <td>{identity.ai_summaries_generated ? `Yes` : `No`}</td>
                </tr>
              ))}
        </tbody>
      </table>
      <p>
        {queryEnabled
          ? !isLoadingIdentities && identitiesShowing()
          : `Perform a search${aiQueryActive ? '' : ' or add filters'} to get started`}
      </p>

      {!isLoadingIdentities && (
        <>
          {page > 1 && (
            <>
              <a
                href="#"
                onClick={() => setPage(page - 1)}
                id="pagination-option-next"
                style={{ fontSize: 18, padding: 5 }}
              >
                Prev
              </a>
              {' - '}
            </>
          )}
          {paginationOptions.map((i, index) => (
            <Fragment key={`pagination-option-${i}`}>
              <span>
                <a
                  href="#"
                  onClick={() => setPage(i)}
                  id={`pagination-option-${i}`}
                  style={{ fontWeight: page === i ? 'bold' : 'normal', padding: 5 }}
                >
                  {i}
                </a>
              </span>
              {index < paginationOptions.length - 1 && ' - '}
            </Fragment>
          ))}
          {last(paginationOptions) > page && (
            <>
              {' - '}
              <a
                href="#"
                onClick={() => setPage(page + 1)}
                id={`pagination-option-next`}
                style={{ padding: 5 }}
              >
                Next
              </a>
            </>
          )}
        </>
      )}
      <ExportCSV
        query={query}
        administratorListId={administratorListFilter?.id}
        filters={filters}
        scope={crmScope}
      />
    </>
  );
};

export default CrmTable;
