import { Formik } from 'formik';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { useSubscribedAdministratorLists } from '@hooks';
import { sendTestPushNotification } from '@utils/backend-api/push-notifications';
import { PushNotificationFormValues, RecipientOption } from '@utils/backend-api/push-notifications';
import { getRecord } from '@utils/backend-api/universal';

import { SendTestForm } from '../../BaseUI';
import {
  AutocompleteField,
  CheckBoxGroup,
  DateTimeField,
  TextAreaField,
  TextField,
} from '../../FormFields';
import ConfirmationModal from './ConfirmationModal';
import newPushNotificationSchema from './schema';

export const PUSH_NOTIFICATION_TITLE_MAX_CHAR_COUNT = 40;
export const PUSH_NOTIFICATION_MESSAGE_MAX_CHAR_COUNT = 150;

const INITIAL_STATE = {
  pushTitle: '',
  pushMessage: '',
  link: '',
  includedRecipients: [],
  excludedRecipients: [],
  sendAt: '',
  administratorList: { label: '', value: '' },
};

const INCLUDED_RECIPIENTS: RecipientOption[] = [
  { label: 'All', value: 'all', allChecked: true },
  { label: 'Subscribers', value: 'active' },
  { label: 'Leads', value: 'never_added_payment_info' },
  { label: 'Trialers', value: 'trialing' },
  { label: 'Canceled But Paid Through', value: 'canceled_but_paid_through' },
  { label: 'Past Due', value: 'past_due' },
  { label: 'Canceled', value: 'canceled' },
];
const EXCLUDED_RECIPIENTS: RecipientOption[] = [
  { value: 'comped', label: 'Comped' },
  { value: 'corporate', label: 'Corporate' },
  { value: 'gift', label: 'Gift' },
  { value: 'team', label: 'Team' },
];

const PushNotificationForm = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<PushNotificationFormValues>(INITIAL_STATE);
  const [recipientOptions, setRecipientOptions] = useState({
    included: INCLUDED_RECIPIENTS,
    excluded: EXCLUDED_RECIPIENTS,
  });
  const { administratorListOptions, isLoadingAdministratorLists } =
    useSubscribedAdministratorLists('manual');
  const { isLoading } = useQuery(
    'pushNotification',
    async () => {
      if (articleId) {
        const resp = await getRecord({
          id: articleId,
          model: 'Article',
          helpers: ['includedRecipientOptions', 'excludedRecipientOptions', 'shareUrl'],
        });
        return resp.result;
      }
    },
    {
      onSuccess: (data) => {
        if (data) {
          setRecipientOptions({
            included: data.includedRecipientOptions,
            excluded: data.excludedRecipientOptions,
          });
          setFormValues((prevState) => ({ ...prevState, link: data.shareUrl }));
        }
      },
    }
  );

  const { articleId } = useParams();

  const toggleShowModal = () => setShowModal((prevState) => !prevState);

  const handleSubmit = async (values) => {
    setFormValues({
      excludedRecipients: recipientOptions.excluded.filter((r) =>
        values.excludedRecipients.find((el) => el === r.value)
      ),
      includedRecipients: recipientOptions.included.filter((r) =>
        values.includedRecipients.find((el) => el === r.value)
      ),
      link: values.link,
      pushTitle: values.pushTitle,
      pushMessage: values.pushMessage,
      sendAt: values.sendAt,
      administratorList: values.administratorList,
    });
    toggleShowModal();
  };

  if (isLoading) return null;
  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        initialValues={formValues}
        validationSchema={newPushNotificationSchema()}
        enableReinitialize={true}
      >
        {(formik) => {
          return (
            <Form noValidate onSubmit={formik.handleSubmit}>
              <Row>
                <Col xs={12}>
                  <TextAreaField
                    name="pushTitle"
                    label="Title"
                    minRows={2}
                    maxCharCount={PUSH_NOTIFICATION_TITLE_MAX_CHAR_COUNT}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <TextAreaField
                    name="pushMessage"
                    label="Message"
                    minRows={4}
                    maxCharCount={PUSH_NOTIFICATION_MESSAGE_MAX_CHAR_COUNT}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <TextField name="link" label="URL" />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs={12}>
                  <DateTimeField
                    name="sendAt"
                    label="Optional: Scheduled Time (If left blank, push notification will send now)"
                    maxDaysOut={14}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <h3 className="mb-0">Choose Recipients</h3>
                  <span>Who should receive the Push Notification</span>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="mb-4">
                  <hr className="divider" />
                </Col>
              </Row>
              <Row className="g-5 mb-4">
                <Col xs={6}>
                  <Row className="recipient-section">
                    <h4>Pick segments of recipients</h4>

                    <Col xs={6}>
                      <CheckBoxGroup
                        name="includedRecipients"
                        options={recipientOptions.included}
                        label="Include"
                        size="large"
                      />
                    </Col>
                    <Col xs={6}>
                      <CheckBoxGroup
                        name="excludedRecipients"
                        options={recipientOptions.excluded}
                        label="Exclude"
                        size="large"
                        negativeFillClass
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={1} className="d-flex flex-column justify-content-center">
                  <h4 className="label-or">-OR-</h4>
                </Col>
                <Col xs={5} className="ml-2">
                  <div className="recipient-section">
                    <h4>Pick an Administrator List</h4>
                    <Col xs={12}>
                      {!isLoadingAdministratorLists && administratorListOptions.length && (
                        <AutocompleteField
                          name="administratorList"
                          options={administratorListOptions}
                          isCreatable={false}
                        />
                      )}
                    </Col>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <h3 className="mb-1">Test Push Notification</h3>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="mb-4">
                  <hr className="divider" />
                </Col>
              </Row>
              <SendTestForm
                includedFields={[
                  { fieldName: 'pushTitle' },
                  { fieldName: 'pushMessage' },
                  { fieldName: 'link' },
                ]}
                label="Send a test push notification to the following emails (comma separated):"
                testMethod={sendTestPushNotification}
              />
              <hr className="mt-2 mb-2" />
              <Row>
                <Col className="d-flex justify-content-end">
                  <Button
                    disabled={
                      formik.isSubmitting || !formik.isValid || !Object.keys(formik.touched).length
                    }
                    type="submit"
                    className="continue-button button --black"
                    id="send"
                  >
                    Continue
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
      <ConfirmationModal
        showModal={showModal}
        toggleShowModal={toggleShowModal}
        formValues={formValues}
      />
    </>
  );
};

export default PushNotificationForm;
