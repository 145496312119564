import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import './ImageField.scss';

const ImageField = (props) => {
  const { label, name, disabled, hint, height, width, imgBtnLabel, setImageSrc = null } = props;
  const [field, meta, helpers] = useField(name);
  const [thumb, setThumb] = useState(field.value);
  const [uploadTouched, setUploadTouched] = useState(false);

  useEffect(() => {
    setThumb(field.value);
    if (setImageSrc) setImageSrc(field.value);
  }, [field.value, setImageSrc]);

  const handleImageChange = (event) => {
    const reader = new FileReader();
    const file = event.target.files[0];

    // on new upload overwrite thumb to read file image
    if (file) {
      reader.onloadend = () => {
        setThumb(reader.result);
        if (setImageSrc) setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadNewImageSideEffect = () => {
    setUploadTouched(true);
    helpers.setValue('');
  };

  return (
    <div className="ImageField form-group">
      <Row>
        <Col>
          <div>
            <label className="input-label" htmlFor={name}>
              {label || 'Image'}
            </label>
            {hint && <div className="hint">{hint}</div>}
            {!field.value && (
              <input
                className="form-control"
                disabled={disabled}
                id={name}
                name={name}
                type="file"
                accept="image/png, image/gif, image/jpg, image/jpeg, image/webp"
                onChange={(event) => {
                  helpers.setValue(event.currentTarget.files[0]);
                  handleImageChange(event);
                }}
              />
            )}
          </div>
          <div className={(uploadTouched || meta.touched) && meta.error ? 'is-invalid' : ''} />
          {(uploadTouched || meta.touched) && meta.error && (
            <div className="invalid-feedback">{meta.error}</div>
          )}
          {thumb && (
            <div className="thumb">
              <img
                src={thumb}
                alt={field.value?.name || ''}
                className="img-thumbnail mt-2"
                height={height || 200}
                width={width || 200}
              />
              <button
                type="button"
                className="remove-image-button"
                disabled={disabled}
                onClick={uploadNewImageSideEffect}
                title="Remove image"
              >
                ✕
              </button>
            </div>
          )}
        </Col>
      </Row>
      {field.value && (
        <Row>
          <Col>
            <Button className="--black" disabled={disabled} onClick={uploadNewImageSideEffect}>
              {imgBtnLabel || 'Upload new image'}
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ImageField;
