import toast from 'react-hot-toast';
import { useQuery } from 'react-query';

import { AdministratorList, getAdministratorList } from '../utils/backend-api/administrator-lists';

export const ADMINISTRATOR_LIST_KEY = 'administrator_list';

export default function useAdministratorList(administratorListId: number | string | null) {
  const {
    data: administratorList,
    isLoading: isLoadingAdministratorList,
    isRefetching: isRefetchingAdministratorList,
    isError,
  } = useQuery<AdministratorList>(
    [ADMINISTRATOR_LIST_KEY, administratorListId],
    () => getAdministratorList(administratorListId),
    {
      onError: () => {
        toast.error('Fetching administrator_list has failed');
      },
      enabled: !!administratorListId,
    }
  );

  const administratorListEntitiesAsOptions = administratorList?.entities?.map((e) => ({
    label: e.name,
    value: e.id,
  }));
  const administratorListListTypeAsOption = {
    value: administratorList?.listType,
    label: administratorList?.listType,
  };

  return {
    administratorList,
    isLoadingAdministratorList,
    isRefetchingAdministratorList,
    isError,
    administratorListEntitiesAsOptions,
    administratorListListTypeAsOption,
  };
}
