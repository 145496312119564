import clsx from 'clsx';
import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';

import { useNewsletters } from '../../hooks';
import { admin_newsletter_path, edit_admin_newsletter_path } from '../../routes';
import headerStyles from '../styles.module.css';
import styles from './styles.module.css';

const FILTERS = {
  all: {},
  paid: { paid: true },
  free: { paid: false },
  thirdParty: { thirdParty: true },
  inHouse: { thirdParty: false },
};

const Newsletters = () => {
  const [currentFilter, setCurrentFilter] = useState('all');
  const { newsletters, isLoadingNewsletters } = useNewsletters(
    currentFilter ? FILTERS[currentFilter] : {}
  );

  return (
    <div className="container-fluid" id="dashboard-page">
      <Row>
        <div className="col-md-12">
          <div className={headerStyles.headerBtnContainer}>
            <Link className="" to={admin_newsletter_path()}>
              + Create Newsletter
            </Link>
          </div>
          <div>
            <div className={headerStyles.header}>
              <h2>Newsletters</h2>
              <ul className={headerStyles.filterList}>
                <li
                  className={clsx(headerStyles.filterLi, {
                    [headerStyles.filterLiActive]: currentFilter === 'all',
                  })}
                >
                  <button onClick={() => setCurrentFilter('all')}>All</button>
                </li>
                <li
                  className={clsx(headerStyles.filterLi, {
                    [headerStyles.filterLiActive]: currentFilter === 'paid',
                  })}
                >
                  <button onClick={() => setCurrentFilter('paid')}>Paid</button>
                </li>
                <li
                  className={clsx(headerStyles.filterLi, {
                    [headerStyles.filterLiActive]: currentFilter === 'free',
                  })}
                >
                  <button onClick={() => setCurrentFilter('free')}>Free</button>
                </li>
                <li
                  className={clsx(headerStyles.filterLi, {
                    [headerStyles.filterLiActive]: currentFilter === 'thirdParty',
                  })}
                >
                  <button onClick={() => setCurrentFilter('thirdParty')}>Third-Party</button>
                </li>
                <li
                  className={clsx(headerStyles.filterLi, {
                    [headerStyles.filterLiActive]: currentFilter === 'inHouse',
                  })}
                >
                  <button onClick={() => setCurrentFilter('inHouse')}>In-House</button>
                </li>
              </ul>
            </div>

            {isLoadingNewsletters ? (
              <div>Loading...</div>
            ) : (
              newsletters.map((newsletter) => (
                <div key={newsletter.id} className={styles.newsletterContainer}>
                  <div className={styles.newsletterHeaderContainer}>
                    <h3 className={styles.newsletterHeader}>{newsletter.name}</h3>
                    <div>
                      <span className={styles.newsletterFlag}>
                        {newsletter.paid ? 'Paid' : 'Free'}
                      </span>
                      <span className={styles.newsletterFlag}>
                        {newsletter.thirdParty ? 'Third Party' : 'In-House'}
                      </span>
                    </div>
                  </div>
                  <div className={styles.linkContainer}>
                    <Link
                      className={styles.newsletterLink}
                      to={edit_admin_newsletter_path(newsletter.id)}
                    >
                      edit
                    </Link>
                  </div>
                  <p className={styles.newsletterDescription}>
                    {ReactHtmlParser(newsletter.description)}
                  </p>
                </div>
              ))
            )}
          </div>
        </div>
      </Row>
    </div>
  );
};

export default Newsletters;
