import moment from 'moment';
import * as Yup from 'yup';

const newTalkSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    createdByIdentity: Yup.object().shape({
      value: Yup.number().nullable(),
      identityId: Yup.number().required('Host is required'),
      label: Yup.string(),
      email: Yup.string(),
      pictureUrl: Yup.string().nullable(),
      fieldValue: Yup.string().nullable(),
    }),
    newsletter: Yup.object().shape({
      label: Yup.string().nullable(),
      value: Yup.number().typeError('This is not a valid newsletter').nullable(),
    }),
    offer: Yup.object().shape({
      label: Yup.string().nullable(),
      value: Yup.number().typeError('This is not a valid offer').nullable(),
    }),
    scheduledTime: Yup.date()
      .min(moment().toISOString(), 'Scheduled time must be in the future')
      .nullable(),
    image: Yup.mixed()
      .test(
        'fileType',
        'Unsupported File Format',
        (value) =>
          (value?.type &&
            ['image/gif', 'image/jpg', 'image/jpeg', 'image/png', 'image/webp'].includes(
              value.type
            )) ||
          (typeof value === 'string' && value.includes('imgix')) ||
          !value
      ) // optional photo
      .nullable(),
  });

export default newTalkSchema;
