import startCase from 'lodash/startCase';
import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useCRMDataImport } from '@hooks/useDataImports';
import { PDL_SEARCH_ADMIN_LIST_ID } from '@src/env';
import { SearchPeopleData, getSearchPeopleJobStatus } from '@utils/backend-api/people-search';

import { crm_root_path } from '../../../routes';
import ArchivedSearches from './ArchivedSearches';
import PeopleSearchForm from './Form';
import { MAX_EMPLOYEES_SEARCH } from './Form/schema';
import './PeopleSearch.scss';

const INITIAL_SEARCH_PEOPLE_DATA: SearchPeopleData = {
  status: null,
  foundPeople: [],
  newAdministratorList: null,
  existingAdministratorList: null,
  errorMessage: null,
};

const COMPLETE_STATUS = 'complete';
const QUEUED_STATUS = 'queued';
const WORKING_STATUS = 'working';
const FAILED_STATUS = 'failed';
const INTERRUPTED_STATUS = 'interrupted';
const RETRYING_STATUS = 'retrying';

const STATUS_COLORS = {
  [COMPLETE_STATUS]: '--green',
  [QUEUED_STATUS]: '--yellow',
  [WORKING_STATUS]: '--yellow',
  [FAILED_STATUS]: '--red',
  [INTERRUPTED_STATUS]: '--red',
  [RETRYING_STATUS]: '--red',
};

const FETCH_SEARCH_DATA_INTERVAL = 5000;

const PeopleSearch = () => {
  const [searchParams] = useSearchParams();
  const dataImportId = searchParams.get('data_import_id');

  const { dataImportData, isLoadingDataImport } = useCRMDataImport(dataImportId);
  const [searchPeopleJobId, setSearchPeopleJobId] = useState<string | null>();
  const [searchPeopleData, setSearchPeopleData] = useState<SearchPeopleData>(
    INITIAL_SEARCH_PEOPLE_DATA
  );
  const timerId = useRef<ReturnType<typeof setInterval>>();

  // Set up interval to get status of PDL search
  useEffect(() => {
    if (searchPeopleJobId) {
      if (searchPeopleData.status !== COMPLETE_STATUS) {
        timerId.current = setInterval(async () => {
          const searchPeopleData = await getSearchPeopleJobStatus(searchPeopleJobId);
          setSearchPeopleData(searchPeopleData);
        }, FETCH_SEARCH_DATA_INTERVAL);
      } else {
        setSearchPeopleJobId(null);
        clearInterval(timerId.current);
      }
    }
    return () => clearInterval(timerId.current);
  }, [searchPeopleData, searchPeopleJobId]);

  const getInitialFormData = () => {
    return {
      ...dataImportData.data,
      conditionJoin: {
        label: dataImportData.data.conditionJoin,
        value: dataImportData.data.conditionJoin,
      },
      searchName: dataImportData.filename,
      searchAttributesGroups: dataImportData.data.searchAttributesGroups.map((sag) => ({
        conditionJoin: { value: sag.conditionJoin, label: sag.conditionJoin },
        searchAttributes: sag.searchAttributes.map((sa) => ({
          field: { label: sa.field, value: sa.field },
          values: sa.values.map((val) => ({ label: val, value: val })),
        })),
      })),
    };
  };

  const initialFormData =
    dataImportId && !isLoadingDataImport && dataImportData.data ? getInitialFormData() : undefined;

  return (
    <div className="PeopleSearch row">
      <div className="col-md-9">
        <div className="form-header mb-4">Create Leads by Searching People Data Labs (PDL)</div>
        <div className="mb-4">
          This tool prospects people with specific matching attributes such as the company they work
          for, the industry they work in, their job role, and more. This tool adds them as new
          identities to our database, allowing us to then send marketing emails to them. There are a
          few things to note about this tool:
          <ul>
            <li>The results can be added to a new administrator list, or an existing one</li>
            <li>
              The results are intended to find people that aren&apos;t already in our system, and
              will exclude identities that we already know.
            </li>
            <li>
              The maximum number of people that we can prospect per call/minute is{' '}
              {MAX_EMPLOYEES_SEARCH}
            </li>
            <li>
              The process may take some time; stay on the page to wait for the search to complete.
            </li>
            <li>
              Profile enrichment will take extra time after the results are retrieved, so extra
              profile details that appear in the CRM might not immediately display.
            </li>
          </ul>
        </div>
        {searchPeopleData.status && (
          <div className="response-section mb-4">
            <div>
              Search Status:{' '}
              <span
                className={`${
                  searchPeopleData.status ? STATUS_COLORS[searchPeopleData.status] : ''
                }`}
              >
                {startCase(searchPeopleData.status)}
              </span>
            </div>
            {searchPeopleData.foundPeople.length > 0 && (
              <div className="mb-4">
                {searchPeopleData.foundPeople.length}{' '}
                {searchPeopleData.foundPeople.length === 1 ? 'person' : 'people'} found and added to
                our database.
                {PDL_SEARCH_ADMIN_LIST_ID && (
                  <span>
                    They will be auto-added to the{' '}
                    <a
                      className="--red"
                      href={crm_root_path({
                        administrator_list: PDL_SEARCH_ADMIN_LIST_ID,
                      })}
                      target="_blank"
                    >
                      global admin list
                    </a>{' '}
                    of people found from PDL
                  </span>
                )}
                {searchPeopleData.newAdministratorList && (
                  <div>
                    They were added to the new Administrator List:{' '}
                    <a
                      className="--red"
                      href={crm_root_path({
                        administrator_list: searchPeopleData.newAdministratorList.id,
                      })}
                      target="_blank"
                    >
                      {searchPeopleData.newAdministratorList.listName}
                    </a>
                  </div>
                )}
                {searchPeopleData.existingAdministratorList && (
                  <div>
                    They were added to the existing Administrator List:{' '}
                    <a
                      className="--red"
                      href={crm_root_path({
                        administrator_list: searchPeopleData.existingAdministratorList.id,
                      })}
                      target="_blank"
                    >
                      {searchPeopleData.existingAdministratorList.listName}
                    </a>
                  </div>
                )}
              </div>
            )}
            {searchPeopleData.status == COMPLETE_STATUS &&
              searchPeopleData.foundPeople.length === 0 && (
                <div>No new records were returned from PDL matching those attributes.</div>
              )}
            {searchPeopleData.errorMessage && <div>Error: {searchPeopleData.errorMessage}</div>}
          </div>
        )}
        {(!searchPeopleData.status ||
          [COMPLETE_STATUS, FAILED_STATUS, INTERRUPTED_STATUS].includes(searchPeopleData.status)) &&
          !isLoadingDataImport && (
            <PeopleSearchForm
              setSearchPeopleJobId={setSearchPeopleJobId}
              setSearchPeopleData={setSearchPeopleData}
              searchPeopleData={INITIAL_SEARCH_PEOPLE_DATA}
              initialFormData={initialFormData}
            />
          )}
      </div>
      <div className="col-md-3">
        <div className="form-header mb-4">Search History</div>
        <ArchivedSearches />
      </div>
    </div>
  );
};

export default PeopleSearch;
