import toast from 'react-hot-toast';
import { useQuery } from 'react-query';

import {
  AdministratorList,
  getArticleAdministratorLists,
} from '@utils/backend-api/administrator-lists';

export const ARTICLE_ADMINISTRATOR_LISTS_KEY = 'article_administrator_lists';

export default function useArticleAdministratorLists(articleId: number | string) {
  const {
    data: administratorLists,
    isLoading: isLoadingAdministratorLists,
    isError,
  } = useQuery<AdministratorList[]>(
    [ARTICLE_ADMINISTRATOR_LISTS_KEY, articleId],
    () => getArticleAdministratorLists(articleId),
    {
      onError: () => {
        toast.error('Fetching article administrator_lists has failed');
      },
    }
  );

  return { administratorLists, isLoadingAdministratorLists, isError };
}
