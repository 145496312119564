import clsx from 'clsx';
import { useField } from 'formik';
import { snakeCase } from 'lodash';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactS3Uploader from 'react-s3-uploader';

import { api_react_v1_signed_aws_path } from '../../routes';
import styles from './styles.module.css';

interface ImageUploaderProps {
  name: string;
  label: string;
  hint?: string;
  s3path?: string;
}

const ImageUploader = ({ name, label, hint, s3path }: ImageUploaderProps) => {
  const [field, meta, helpers] = useField(name);
  const setImageUrl = (signedUrl) => {
    if (!signedUrl) return;
    const urlComponents = signedUrl.split('?');
    helpers.setValue(urlComponents[0]);
  };

  const onProgress = (progress) => {
    helpers.setValue(`Uploading - ${progress}%`);
  };

  const onError = (error) => {
    console.error('Upload Error: ', error);
  };

  return (
    <Row className="mb-4">
      <Col>
        <label className={styles.label}>{label}</label>
        <Row>
          <Col md={10}>
            <input
              className={clsx('form-control', meta.touched && meta.error ? 'is-invalid' : '')}
              type="text"
              id={name}
              {...field}
            />
            {meta.error && (
              <p className="m-0 p-1 text-small text-danger invalid-feedback">{meta.error}</p>
            )}
          </Col>
          <Col md={2}>
            <ReactS3Uploader
              signingUrl={api_react_v1_signed_aws_path()}
              signingUrlMethod="GET"
              s3path={s3path || snakeCase(name).replace(/_/gi, '-')}
              accept="image/*"
              onProgress={onProgress}
              onError={onError}
              onFinish={(s3Response) => {
                setImageUrl(s3Response.signedUrl);
              }}
              uploadRequestHeaders={{}}
              contentDisposition="auto"
              autoUpload={true}
            />
          </Col>
        </Row>

        {hint && <p className="text-muted m-0 p-1 text-small">{hint}</p>}
      </Col>
    </Row>
  );
};

export default ImageUploader;
