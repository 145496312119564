import { useInfiniteQuery } from 'react-query';

import {
  PaginatedDistributionEvents,
  getPaginatedDistributionEvents,
} from '../utils/backend-api/distribution-events';

export const DISTRIBUTION_EVENTS_KEY = 'distribution_events';

export default function useDistributionEvents(articleId = null) {
  const {
    data: distributionEventsData,
    isLoading: isLoadingDistributionEvents,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<PaginatedDistributionEvents>(
    DISTRIBUTION_EVENTS_KEY,
    ({ pageParam = 1 }) => getPaginatedDistributionEvents(articleId, pageParam),
    {
      getNextPageParam: (lastPage) => lastPage?.nextPage,
    }
  );

  return { distributionEventsData, isLoadingDistributionEvents, fetchNextPage, hasNextPage };
}
