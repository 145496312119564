import { getRecord } from './universal';

interface User {
  name: string;
}

export interface Administrator {
  id: number;
  email: string;
  user: User;
  allowedToManageIdentities: boolean;
}

export async function getAdministrator(id: number | string): Promise<Administrator> {
  const resp = await getRecord({
    id,
    model: 'Administrator',
    attributes: ['id', 'email', 'allowed_to_manage_identities'],
    relations: { user: { attributes: ['name'] } },
  });
  return resp.result;
}
