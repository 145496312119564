import { Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useConnectedProviders, useScheduledShare } from '../../../hooks';
import {
  distribution_scheduled_tweet_path,
  distribution_scheduled_tweets_path,
} from '../../../routes';
import {
  createScheduledShare,
  updateScheduledShare,
} from '../../../utils/backend-api/scheduled-shares';
import { DateTimeField, DropdownField, TextAreaField } from '../../FormFields';
import './Form.scss';
import { SCHEDULED_TWEETS_KEY } from './index';
import scheduledTweetSchema from './schema';

const INITIAL_STATE = {
  connectedProvider: { label: '', value: '' },
  shareText: '',
  shareDateTime: '',
};

export default function ScheduledTweetForm({ isEditing }) {
  const { articleId, scheduledShareId } = useParams();
  const { connectedProviderOptions, isLoadingConnectedProviders } =
    useConnectedProviders('twitter');
  const { scheduledShare: tweet, isLoadingScheduledShare } = isEditing
    ? useScheduledShare(scheduledShareId)
    : { scheduledShare: null, isLoadingScheduledShare: false };
  const [initialValues, setInitialValues] = useState(INITIAL_STATE);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting }) => {
    const { connectedProvider, shareText, shareDateTime } = values;
    setSubmitting(true);
    const sanitizedParams = {
      scheduled_share: {
        article_id: articleId,
        connected_provider_id: connectedProvider.value,
        share_text: shareText,
        share_date: shareDateTime,
        share_type: 'twitter',
      },
    };
    try {
      const resp = isEditing
        ? await updateScheduledShare(articleId, scheduledShareId, sanitizedParams)
        : await createScheduledShare(articleId, sanitizedParams);
      if (resp.success) {
        isEditing
          ? toast.success('Tweet has been updated')
          : toast.success(
              shareDateTime ? 'Tweet has been scheduled' : 'Tweet draft has been saved'
            );
        queryClient.invalidateQueries(SCHEDULED_TWEETS_KEY);
        navigate(distribution_scheduled_tweet_path(articleId, resp.id));
      }
    } catch {
      toast.error('Failed to save tweet');
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (isEditing && !isLoadingScheduledShare) {
      const { connectedProviderOption, shareText, shareDate } = tweet;
      if (tweet) {
        setInitialValues({
          connectedProvider: connectedProviderOption,
          shareText,
          shareDateTime: shareDate ? moment(shareDate).format('lll') : '',
        });
      } else {
        // if error loading tweet, then just load main scheduled tweets dashboard
        navigate(distribution_scheduled_tweets_path(articleId));
      }
    }
  }, [isEditing, isLoadingScheduledShare]);

  return (
    <div className="ScheduledTweetForm">
      <h2>{isEditing ? 'Edit Tweet' : 'Schedule Tweet'}</h2>
      {!isLoadingScheduledShare && !isLoadingConnectedProviders && (
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={scheduledTweetSchema}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form noValidate onSubmit={formik.handleSubmit}>
              <Row>
                <Col md={6}>
                  <DropdownField
                    name="connectedProvider"
                    label="From"
                    placeholder="Select a twitter account"
                    options={connectedProviderOptions}
                  />
                </Col>
                <Col md={6}>
                  <DateTimeField
                    name="shareDateTime"
                    timeIntervals={5}
                    minTime={moment().subtract(5, 'minutes')}
                    maxTime={moment().add(2, 'weeks')}
                    label="Send Tweet At"
                    maxDaysOut={14}
                    helperText={'Set a time in the past to send tweet now'}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextAreaField name="shareText" label="Tweet Body" minRows={4} />
                </Col>
              </Row>
              <div className="schedule-tweet-btn-wrapper --black-top-border">
                <Button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="--black"
                  id="create-tweet-button"
                >
                  {isEditing ? 'Update' : 'Schedule'} Tweet
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}
