import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink, useParams } from 'react-router-dom';

import { useAdminContext } from '../../../contexts/AdminContext';
import {
  distribution_administrator_lists_path,
  distribution_audience_generator_path,
  distribution_dashboard_path,
  distribution_group_posts_path,
  distribution_linkedin_ad_list_path,
  distribution_new_push_notification_path,
  distribution_new_relevant_company_path,
  distribution_rtsu_campaign_path,
  distribution_scheduled_tweets_path,
  distribution_twitter_campaigns_path,
} from '../../../routes';
import './SideNav.scss';

const DistributionDashboardSideNav = () => {
  const { articleId } = useParams();
  const { allowedToManageIdentities } = useAdminContext();

  return (
    <Nav
      defaultActiveKey={distribution_dashboard_path(articleId)}
      className="DistributionDashboardSideNav"
    >
      <div className="group">
        <div className="group-header">Distro Features</div>
        <NavLink to={distribution_dashboard_path(articleId)} className="nav-link" end>
          Article Dashboard
        </NavLink>
        <NavLink to={distribution_scheduled_tweets_path(articleId)} className="nav-link">
          Schedule Tweet
        </NavLink>
        <NavLink to={distribution_group_posts_path(articleId)} className="nav-link">
          Group Posts
        </NavLink>
        <NavLink to={distribution_new_relevant_company_path(articleId)} className="nav-link">
          Relevant Company
        </NavLink>
        <NavLink to={distribution_new_push_notification_path(articleId)} className="nav-link">
          Push Notification
        </NavLink>
        <NavLink to={distribution_linkedin_ad_list_path(articleId)} className="nav-link">
          LinkedIn Campaigns
        </NavLink>
        <NavLink to={distribution_twitter_campaigns_path(articleId)} className="nav-link">
          Twitter Campaigns
        </NavLink>
        <NavLink to={distribution_rtsu_campaign_path(articleId)} className="nav-link">
          RTSU Controls
        </NavLink>
        <NavLink to={distribution_administrator_lists_path(articleId)} className="nav-link">
          Audience Lists
        </NavLink>
        {allowedToManageIdentities && (
          <NavLink to={distribution_audience_generator_path(articleId)} className="nav-link">
            Audience Generator
          </NavLink>
        )}
      </div>
    </Nav>
  );
};

export default DistributionDashboardSideNav;
