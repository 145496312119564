import { useInfiniteQuery } from 'react-query';

import {
  PaginatedLandingPages,
  getPaginatedLandingPages,
} from '../utils/backend-api/landing-pages';

export default function useLandingPages() {
  const {
    data: landingPagesData,
    isLoading: isLoadingLandingPages,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<PaginatedLandingPages>(
    'landing_pages',
    ({ pageParam = 1 }) => getPaginatedLandingPages(pageParam),
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
    }
  );

  return { landingPagesData, isLoadingLandingPages, fetchNextPage, hasNextPage };
}
