import React from 'react';

import CustomEmail from './CustomEmail';

const CustomEmailsList = ({ customEmails }) => {
  if (customEmails.length == 0) {
    return <div>No custom emails sent yet</div>;
  }
  return customEmails.map((customEmail) => (
    <CustomEmail key={customEmail.id} customEmail={customEmail} />
  ));
};

export default CustomEmailsList;
