import React, { useState } from 'react';

import { createCsvExport, getCsvExportStatus } from '../../utils/backend-api/identities-csv-export';

const POLL_INTERVAL = 2000;

const ExportCSV = ({ query, administratorListId, filters, scope }) => {
  const [downloadState, setDownloadState] = useState(null);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [downloadUrl, setDownloadUrl] = useState(null);

  const handleDownload = async () => {
    setDownloadState('downloading');
    try {
      const response = await createCsvExport(query, administratorListId, filters, scope);
      startPoll(response.data_import_id);
    } catch (e) {
      console.error('Failed to export CSV: ', e);
    }
  };

  const startPoll = (dataImportId) => {
    const poll = async (resolve, reject) => {
      try {
        const response = await getCsvExportStatus(dataImportId);
        if (['complete', 'error'].includes(response.status)) {
          setDownloadState(response.status);
          if (response.status === 'complete') setDownloadUrl(response.url);
          return resolve(response);
        }
        setDownloadProgress(response.progress);
      } catch (e) {
        console.error('polling error: ', e);
      }
      setTimeout(poll, POLL_INTERVAL, resolve, reject);
    };

    return new Promise(poll);
  };

  return (
    <div className="export-csv-container mt-2">
      {downloadState === 'downloading' ? (
        <div className="">
          Generating CSV...
          <span className="export-identity-csv-progress">{downloadProgress}%</span>
        </div>
      ) : downloadState === 'error' ? (
        <div className="export-identity-csv-error">Failed to generate CSV. Please try again.</div>
      ) : downloadState === 'complete' ? (
        <div className="">
          CSV Generated -{' '}
          <a href={downloadUrl} target="_blank" className="font-italic text-primary">
            Download Now
          </a>
        </div>
      ) : (
        <button
          onClick={handleDownload}
          className="btn btn-link m-0 p-0 export-identity-csv-button"
        >
          Download CSV
        </button>
      )}
    </div>
  );
};

export default ExportCSV;
