import moment from 'moment';

import {
  api_react_v1_create_ad_schedules_path,
  api_react_v1_delete_ad_schedules_path,
  api_react_v1_update_ad_schedules_path,
} from '../../routes';
import { Advertisement } from './advertisements';
import request from './request';
import { getRecord, getRecords } from './universal';

export interface AdSchedule {
  id: number | string;
  advertisement: Advertisement;
  advertisementId: string | number;
  availableSections: string[];
  section: string;
  startDate: string;
  endDate: string;
}

export interface AdScheduleFormValues extends Pick<AdSchedule, 'id' | 'startDate' | 'endDate'> {
  sections: string[];
}

export interface NewAdScheduleFormValues
  extends Pick<AdScheduleFormValues, 'startDate' | 'endDate'> {
  sections: string[];
}

export interface AdScheduleForm {
  newSchedule?: NewAdScheduleFormValues;
}
export interface AdScheduleSanitizedValues
  extends Pick<AdSchedule, 'advertisementId' | 'startDate' | 'endDate'> {
  sections: string[];
}

const MAX_SCHEDULES_PER_PAGE = 25;

export const getPaginatedAdSchedules = async (page: number) =>
  await getRecords({
    model: 'AdSchedule',
    attributes: ['advertisementId', 'endDate', 'id', 'section', 'startDate'],
    comparisonIdentifiers: { endDate: { comparison: '>=', value: moment() } },
    relations: { advertisement: { attributes: ['sponsorName'] } },
    order: { startDate: 'asc', endDate: 'asc' },
    page: page,
    maxPerPage: MAX_SCHEDULES_PER_PAGE,
  });

export const getAdSchedule = async (adScheduleId: number | string) => {
  const resp = await getRecord({
    model: 'AdSchedule',
    id: adScheduleId,
    attributes: ['advertisementId', 'endDate', 'section', 'startDate'],
    relations: { advertisement: { attributes: ['header', 'sponsorName'] } },
    classHelpers: ['availableSections'],
  });
  return resp.result;
};

export async function createAdSchedule(values: AdScheduleSanitizedValues) {
  const resp = await request('post', api_react_v1_create_ad_schedules_path(values));
  return resp;
}

export async function updateAdSchedule(values: AdScheduleSanitizedValues) {
  const resp = await request('put', api_react_v1_update_ad_schedules_path(values));
  return resp;
}

export async function deleteAdSchedule(id: number | string) {
  const resp = await request('delete', api_react_v1_delete_ad_schedules_path(id));
  return resp;
}
