import React from 'react';
import { Modal, ModalBody, ModalTitle } from 'react-bootstrap';

import EmailForm from '../../Crm/IdentityModal/EmailForm';

const EmailModal = ({ identities, hideModal }) => {
  return (
    <Modal show={true}>
      <Modal.Header>
        <ModalTitle>Email Identities</ModalTitle>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={hideModal}
        ></button>
      </Modal.Header>
      <ModalBody>
        <EmailForm identities={identities} />
      </ModalBody>
    </Modal>
  );
};

export default EmailModal;
