import { flatMapDeep, snakeCase, startCase, uniqueId } from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useQuery } from 'react-query';

import useArticleRecipients from '../../../hooks/useArticleRecipients';
import { recipientsTopProfileValuesForArticle } from '../../../utils/backend-api/identity-profiles';
import { User } from '../../../utils/backend-api/users';
import { RecipientType } from '../../../utils/backend-api/users';
import ExpandableCell from './ExpandableCell';
import styles from './styles.module.css';

interface RecipientTableProps {
  articleId: number | string;
  recipientType: RecipientType;
  showIdentityModal: Dispatch<SetStateAction<number>>;
  emailModalUsers: () => void;
}

const RecipientTable = ({
  articleId,
  recipientType,
  showIdentityModal,
  emailModalUsers,
}: RecipientTableProps) => {
  const { data, isLoading, fetchNextPage, hasNextPage } = useArticleRecipients(
    articleId,
    recipientType
  );

  const { data: topValuesData, isLoading: isLoadingTopValues } = useQuery(
    ['topProfileValues', articleId, recipientType],
    async () => await recipientsTopProfileValuesForArticle(articleId, recipientType)
  );

  const recipientRow = (user: User) => {
    return (
      <tr
        className={styles.userLi}
        key={user.id}
        onClick={() => showIdentityModal(user.identityId)}
      >
        {Object.keys(user).map((key) => {
          const skillsOrInterests = ['skills', 'interests'].includes(key);
          const elementKey = `${key}-${user.id}`;

          return skillsOrInterests ? (
            <ExpandableCell key={elementKey} hasContent={!!user[key]}>
              {user[key]}
            </ExpandableCell>
          ) : (
            <td className="text-nowrap" key={elementKey}>
              {user[key]}
            </td>
          );
        })}
      </tr>
    );
  };

  const topValuesHeaderRow = () => {
    return (
      <tr className="table-light">
        {Object.keys(sanitizedRecipients[0]).map((key) => (
          <th key={uniqueId(key)} className="align-top">
            {topValuesData[snakeCase(key)] && (
              <ul className="m-0">
                {topValuesData[snakeCase(key)]?.map((value) => (
                  <li className={styles.topValue} key={uniqueId(value.name)}>
                    {value.name}&nbsp;({value.count})
                  </li>
                ))}
              </ul>
            )}
          </th>
        ))}
      </tr>
    );
  };

  if (isLoading || isLoadingTopValues) return <p>Loading …</p>;

  const recipients = flatMapDeep(data.pages, (p) => p.result);

  if (recipients.length === 0) return <p>No recipients</p>;

  const sanitizedRecipients = recipients.map((r) => {
    return {
      id: r.id,
      identityId: r.identityId,
      name: r.name,
      email: r.email,
      jobTitle: r.identityProfile?.jobTitle,
      jobRole: r.identityProfile?.jobRole,
      companyName: r.identityProfile?.companyName,
      companyIndustry: r.identityProfile?.companyIndustry,
      skills: r.identityProfile?.skills?.join(', '),
      interests: r.identityProfile?.interests?.join(', '),
    };
  });

  return (
    <>
      <button className="btn btn-secondary btn-sm mb-3" onClick={emailModalUsers}>
        Email All
      </button>
      <Table bordered responsive hover>
        <thead>
          <tr>
            {Object.keys(sanitizedRecipients[0]).map((key) => (
              <th key={uniqueId(key)} className="text-nowrap">
                {key === 'id' ? 'User Id' : startCase(key)}
              </th>
            ))}
          </tr>
          {topValuesHeaderRow()}
        </thead>
        <tbody>{sanitizedRecipients.map((user) => recipientRow(user))}</tbody>
      </Table>
      {hasNextPage && (
        <Button variant="outline-dark" onClick={() => fetchNextPage()} disabled={isLoading}>
          Load more
        </Button>
      )}
    </>
  );
};

export default RecipientTable;
