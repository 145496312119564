import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { Article, updateArticle } from '../utils/backend-api/articles';
import { ARTICLE_KEY } from './useArticle';

export default function useArticleUpdate(articleId: number | string) {
  const queryClient = useQueryClient();
  const articleKeys = [ARTICLE_KEY, articleId];
  const { mutate, isLoading, error } = useMutation(
    async (values: Partial<Article>) => await updateArticle(articleId, values),
    {
      onSuccess: () => {
        toast.success('Article has been updated');
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (_err, _variables) => {
        toast.error('Updating article has failed');
      },
      onSettled: () => {
        queryClient.invalidateQueries(articleKeys);
      },
    }
  );
  return {
    updateArticle: mutate,
    isLoadingArticleUpdate: isLoading,
    articleUpdateError: error,
  };
}
