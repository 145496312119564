import { Formik } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';

import useEntityJoinCreate from '../../../hooks/useEntityJoinCreate';
import { autocompleteEntities, createEntity } from '../../../utils/backend-api/entities';
import { Autosave } from '../../BaseUI';
import { AutocompleteField } from '../../FormFields';

const INITIAL_VALUES = { entity: null };

const CreateTagForm = ({ articleId }) => {
  const { mutateAsync: createEntityJoin } = useEntityJoinCreate();

  const handleSubmitTag = async (values, { resetForm }) => {
    const entityId = values.entity?.value;
    if (!entityId) return null;
    const sanitizedParams = {
      entity_id: entityId,
      parent_type: 'Article',
      parent_id: parseInt(articleId),
      salience: 1.0,
      relationship_source: 'manual',
    };
    await createEntityJoin(sanitizedParams);
    resetForm();
  };

  return (
    <Formik onSubmit={handleSubmitTag} initialValues={INITIAL_VALUES} enableReinitialize={true}>
      {(formik) => (
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Autosave debounceMs={500} />
          <AutocompleteField
            name="entity"
            placeholder="Add a tag..."
            isClearable
            fetchMethod={(value: string) =>
              autocompleteEntities({
                fragment: value,
              })
            }
            createMethod={(value: string) =>
              createEntity({
                name: value,
                entity_type: 'ti_custom',
                word_type: 'type_unknown',
              })
            }
            valueIsInteger
          />
        </Form>
      )}
    </Formik>
  );
};

export default CreateTagForm;
