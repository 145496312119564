import clsx from 'clsx';
import { useFormikContext } from 'formik';
import React, { Dispatch, SetStateAction, useCallback } from 'react';

import closeIcon from '@images/x_icon.svg';

import { DropdownField } from '@components/FormFields';
import { getEmailDomains } from '@utils/backend-api/identities';
import { autocompleteFieldOptions } from '@utils/backend-api/identity-profiles';
import { AttributeFieldOption, PeopleSearchAttribute } from '@utils/backend-api/people-search';

import groupStyles from '../Group/styles.module.css';
import ValuesInput from '../ValuesInput';
import {
  ATTRIBUTE_FIELD_OPTIONS,
  FIELD_COMPANY_EMAIL_DOMAIN,
  FieldOptionsMapper,
} from '../options';
import styles from './styles.module.css';

interface SearchAttributeConditionProps {
  attribute: PeopleSearchAttribute;
  groupConditionJoin: 'all' | 'any';
  currentConditionName: string;
  conditionIdx: number;
  fieldOptionsMapper: FieldOptionsMapper;
  removeCondition: (index: number) => void;
  setFieldOptionsMapper: Dispatch<SetStateAction<FieldOptionsMapper>>;
}

const SearchAttributeCondition = ({
  attribute,
  groupConditionJoin,
  currentConditionName,
  conditionIdx,
  fieldOptionsMapper,
  removeCondition,
  setFieldOptionsMapper,
}: SearchAttributeConditionProps) => {
  const fieldName = `${currentConditionName}.field`;
  const valuesName = `${currentConditionName}.values`;
  const fieldValue = attribute.field.value;

  const { setFieldValue } = useFormikContext();

  // we overwrite onchange for the field so that we can store the possible values for each field,
  // so we only have to fetch the possible values once.
  const updateFieldAndFetchOptions = useCallback(
    async (field: AttributeFieldOption) => {
      const updatedFieldValue = field.value;

      setFieldValue(fieldName, field);
      // in case there's a value for a different type of field, clear it
      if (attribute.values) {
        setFieldValue(valuesName, '');
      }

      // only update field options mapper if the key doesn't already have values
      const currentFieldOptions = fieldOptionsMapper[updatedFieldValue];
      if (currentFieldOptions.length === 0) {
        const options =
          updatedFieldValue === FIELD_COMPANY_EMAIL_DOMAIN
            ? await getEmailDomains()
            : await autocompleteFieldOptions({
                fragment: '',
                field: updatedFieldValue,
                pdlOnly: true,
              });
        setFieldOptionsMapper(
          (prevFieldOptionsMapper: FieldOptionsMapper): FieldOptionsMapper => ({
            ...prevFieldOptionsMapper,
            [updatedFieldValue]: options,
          })
        );
      }
    },
    [setFieldOptionsMapper, attribute, fieldName, valuesName, setFieldValue, fieldOptionsMapper]
  );

  return (
    <div className={styles.SearchAttributeCondition}>
      <div className={clsx('row', styles.attributeInputWrapper)}>
        <div className="col-2">
          {conditionIdx > 0 && (
            <div className={groupStyles.conditionJoinWrapper}>
              <div className={clsx(groupStyles.conditionJoinValue, styles.conditionJoinMargin)}>
                {groupConditionJoin === 'all' ? 'AND' : 'OR'}
              </div>
            </div>
          )}
        </div>
        <div className="col-10 row">
          <div className="col-xs-12 col-sm-6">
            <DropdownField
              onChange={updateFieldAndFetchOptions}
              name={fieldName}
              label="This attribute"
              options={ATTRIBUTE_FIELD_OPTIONS}
            />
          </div>
          <div className="col-xs-12 col-sm-6">
            <ValuesInput
              valueOptions={fieldOptionsMapper[fieldValue]}
              name={valuesName}
              field={fieldValue}
            />
          </div>
        </div>
        <span className={styles.closeIconWrapper}>
          <a
            data-test={`remove-condition-btn-${conditionIdx}`}
            onClick={() => removeCondition(conditionIdx)}
          >
            <img
              src={closeIcon as unknown as string}
              height="16"
              width="16"
              className="link-icon"
            />
          </a>
        </span>
      </div>
    </div>
  );
};
export default SearchAttributeCondition;
