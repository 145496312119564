import clsx from 'clsx';
import { FieldArray, useFormikContext } from 'formik';
import React, { Dispatch, SetStateAction } from 'react';

import { DropdownField } from '@components/FormFields';
import { PeopleSearchAttribute, PeopleSearchFormValues } from '@utils/backend-api/people-search';

import SearchAttributeCondition from '../Condition';
import { CONDITION_JOIN_OPTIONS, FieldOptionsMapper } from '../options';
import styles from './styles.module.css';

export const DEFAULT_SEARCH_ATTRIBUTE: PeopleSearchAttribute = {
  field: { label: '', value: null },
  values: [],
};

interface SearchAttributesGroupProps {
  groupIndex: number;
  fieldOptionsMapper: FieldOptionsMapper;
  setFieldOptionsMapper: Dispatch<SetStateAction<FieldOptionsMapper>>;
}

const SearchAttributesGroup = ({
  groupIndex,
  fieldOptionsMapper,
  setFieldOptionsMapper,
}: SearchAttributesGroupProps) => {
  const { values } = useFormikContext<PeopleSearchFormValues>();
  const currentGroupValues = values.searchAttributesGroups[groupIndex];
  const currentGroupName = `searchAttributesGroups.${groupIndex}`;

  return (
    <div className={clsx(styles.SearchAttributesGroup, 'row')}>
      <div className={clsx(styles.conditionJoinWrapper, 'col-1')}>
        {groupIndex > 0 && (
          <div className={clsx(styles.conditionJoinValue)}>
            {values.conditionJoin.value === 'all' ? 'AND' : 'OR'}
          </div>
        )}
      </div>
      <div className={clsx(styles.groupContainer, 'col-11 bg-accented')}>
        <FieldArray
          name={`${currentGroupName}.searchAttributes`}
          validateOnChange={false}
          render={({ remove, push }) => (
            <div>
              <div className="col-xs-12">
                <DropdownField
                  options={CONDITION_JOIN_OPTIONS}
                  name={`${currentGroupName}.conditionJoin`}
                  append="of the following conditions match"
                />
              </div>
              {currentGroupValues.searchAttributes.map((attribute, index: number) => (
                <SearchAttributeCondition
                  key={`section${groupIndex}index${index}`}
                  attribute={attribute}
                  currentConditionName={`${currentGroupName}.searchAttributes.${index}`}
                  fieldOptionsMapper={fieldOptionsMapper}
                  groupConditionJoin={currentGroupValues.conditionJoin.value}
                  removeCondition={remove}
                  conditionIdx={index}
                  setFieldOptionsMapper={setFieldOptionsMapper}
                />
              ))}
              <button
                data-test={`add-condition-btn-${groupIndex}`}
                className="btn btn-secondary mb-0"
                onClick={(e) => {
                  e.preventDefault(); // prevents validation on all of the fields
                  push(DEFAULT_SEARCH_ATTRIBUTE);
                }}
              >
                Add condition
              </button>
            </div>
          )}
        />
      </div>
    </div>
  );
};
export default SearchAttributesGroup;
