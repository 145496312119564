import {
  IDENTITY_PROFILE_COMPANY_NAME,
  IDENTITY_PROFILE_JOB_ROLE,
  IDENTITY_PROFILE_JOB_TITLE,
  IdentityProfileField,
} from '../../../../../utils/backend-api/identity-profiles';

export const HELPER_TEXT = {
  autocomplete: 'Select autocompleted options from the dropdown or search a custom value',
  date: 'Format dates like YYYY/MM/DD',
  subscriptionStatus:
    'valid values: never_added_payment_info, active, canceled, canceled_but_paid_through, trialing, past_due, comped',
  boolean: 'use = true, or = false',
  id: 'requires the numeric id of the resource, i.e., article_id',
  count: 'i.e., = 0, > 1, < 100, etc.',
  location: 'e.g., "San Francisco", "California", "United States"',
  followedTopics:
    'e.g., "Enter as many topics as you want, and people that follow any of these topics will be returned',
};

export interface FilterOption {
  label: string;
  value: string;
  type: string;
  helper?: string;
  identityProfileField?: IdentityProfileField;
}

export const FILTER_STRING_TYPE = 'string';
export const FILTER_NUMBER_TYPE = 'number';
export const FILTER_DATE_TYPE = 'date';
export const FILTER_BOOLEAN_TYPE = 'boolean';
export const FILTER_ENTITIES_TYPE = 'entities';
export const FILTER_AUTOCOMPLETE_TYPE = 'autocomplete';

export const FILTER_OPTIONS: FilterOption[] = [
  {
    label: 'Company Name (auto)',
    value: `users.${IDENTITY_PROFILE_COMPANY_NAME},identity_profiles.${IDENTITY_PROFILE_COMPANY_NAME}`,
    helper: HELPER_TEXT.autocomplete,
    type: FILTER_AUTOCOMPLETE_TYPE,
    identityProfileField: IDENTITY_PROFILE_COMPANY_NAME,
  },
  {
    label: 'Company Name (free)',
    value: `users.${IDENTITY_PROFILE_COMPANY_NAME},identity_profiles.${IDENTITY_PROFILE_COMPANY_NAME}`,
    type: FILTER_STRING_TYPE,
  },
  {
    label: 'Company Description',
    value: 'identity_profiles.company_descriptions',
    type: FILTER_STRING_TYPE,
  },
  {
    label: 'Job title',
    value: `users.title_at_company,identity_profiles.${IDENTITY_PROFILE_JOB_TITLE}`,
    helper: HELPER_TEXT.autocomplete,
    type: FILTER_AUTOCOMPLETE_TYPE,
    identityProfileField: IDENTITY_PROFILE_JOB_TITLE,
  },
  {
    label: 'Job role',
    value: `identity_profiles.${IDENTITY_PROFILE_JOB_ROLE}`,
    helper: HELPER_TEXT.autocomplete,
    type: FILTER_AUTOCOMPLETE_TYPE,
    identityProfileField: IDENTITY_PROFILE_JOB_ROLE,
  },
  {
    label: 'Subscription Status',
    value: 'users.subscription_status',
    helper: HELPER_TEXT.subscriptionStatus,
    type: FILTER_STRING_TYPE,
  },
  {
    label: 'Starred',
    value: 'identity_sources.is_starred',
    helper: HELPER_TEXT.boolean,
    type: FILTER_BOOLEAN_TYPE,
  },
  {
    label: 'Email Count',
    value: 'identity_sources.email_count',
    helper: HELPER_TEXT.count,
    type: FILTER_NUMBER_TYPE,
  },
  {
    label: 'Meeting Count',
    value: 'identity_sources.meeting_count',
    helper: HELPER_TEXT.count,
    type: FILTER_NUMBER_TYPE,
  },
  {
    label: 'Twitter Follower Count',
    value: 'identity_profiles.twitter_follower_count',
    helper: HELPER_TEXT.count,
    type: FILTER_NUMBER_TYPE,
  },
  {
    label: 'First Email',
    value: 'identity_sources.first_email_at',
    helper: HELPER_TEXT.date,
    type: FILTER_DATE_TYPE,
  },
  {
    label: 'First Meeting',
    value: 'identity_sources.first_meeting_at',
    helper: HELPER_TEXT.date,
    type: FILTER_DATE_TYPE,
  },
  {
    label: 'Most Recent Email',
    value: 'identity_sources.most_recent_email_at',
    helper: HELPER_TEXT.date,
    type: FILTER_DATE_TYPE,
  },
  {
    label: 'Most Recent Meeting',
    value: 'identity_sources.most_recent_meeting_at',
    helper: HELPER_TEXT.date,
    type: FILTER_DATE_TYPE,
  },
  {
    label: 'Subscribed Via Article',
    value: 'users.subscribed_via_article',
    helper: HELPER_TEXT.id,
    type: FILTER_NUMBER_TYPE,
  },
  {
    label: 'Created Via Article',
    value: 'users.created_via_article',
    helper: HELPER_TEXT.id,
    type: FILTER_NUMBER_TYPE,
  },
  {
    label: 'Offer Count',
    value: 'count(custom_emails.id)',
    helper: HELPER_TEXT.count,
    type: FILTER_NUMBER_TYPE,
  },
  {
    label: 'City',
    value: 'identities.assumed_locality',
    helper: HELPER_TEXT.location,
    type: FILTER_STRING_TYPE,
  },
  {
    label: 'State',
    value: 'identities.assumed_region',
    helper: HELPER_TEXT.location,
    type: FILTER_STRING_TYPE,
  },
  {
    label: 'Country',
    value: 'identities.assumed_country',
    helper: HELPER_TEXT.location,
    type: FILTER_STRING_TYPE,
  },
  {
    label: 'Follows topic',
    value: 'entities.id',
    helper: HELPER_TEXT.followedTopics,
    type: FILTER_ENTITIES_TYPE,
  },
];

const OPERATOR_EQUALS_OPTION = { label: 'Equals', value: '=' };
const OPERATOR_NOTEQUALS_OPTION = { label: "Doesn't equal", value: '!=' };
const OPERATOR_LESS_THAN_OPTION = { label: 'Less than', value: '<' };
const OPERATOR_GREATER_THAN_OPTION = { label: 'Greater than', value: '>' };
const OPERATOR_ILIKE_OPTION = { label: 'Includes substring', value: 'ILIKE' };
const OPERATOR_ANY_OPTION = { label: 'Includes', value: '= ANY' };

export const OPERATOR_STRING_OPTIONS = [
  OPERATOR_EQUALS_OPTION,
  OPERATOR_NOTEQUALS_OPTION,
  OPERATOR_ILIKE_OPTION,
];
export const OPERATOR_DATE_OPTIONS = [
  OPERATOR_EQUALS_OPTION,
  OPERATOR_LESS_THAN_OPTION,
  OPERATOR_GREATER_THAN_OPTION,
];
export const OPERATOR_NUMBER_OPTIONS = [
  OPERATOR_EQUALS_OPTION,
  OPERATOR_NOTEQUALS_OPTION,
  OPERATOR_LESS_THAN_OPTION,
  OPERATOR_GREATER_THAN_OPTION,
];
export const OPERATOR_BOOLEAN_OPTIONS = [OPERATOR_EQUALS_OPTION, OPERATOR_NOTEQUALS_OPTION];
export const OPERATOR_AUTOCOMPLETE_OPTIONS = [OPERATOR_EQUALS_OPTION, OPERATOR_ILIKE_OPTION];
export const OPERATOR_ENTITIES_OPTIONS = [OPERATOR_ANY_OPTION];

export const DEFAULT_OPERATOR_OPTIONS = [
  OPERATOR_EQUALS_OPTION,
  OPERATOR_NOTEQUALS_OPTION,
  OPERATOR_LESS_THAN_OPTION,
  OPERATOR_GREATER_THAN_OPTION,
  OPERATOR_ILIKE_OPTION,
];

// only show operator options that are compatible with the field type we're filtering on
export const determineOperatorOptions = (fieldType: string) => {
  switch (fieldType) {
    case FILTER_STRING_TYPE:
      return OPERATOR_STRING_OPTIONS;
    case FILTER_AUTOCOMPLETE_TYPE:
      return OPERATOR_AUTOCOMPLETE_OPTIONS;
    case FILTER_ENTITIES_TYPE:
      return OPERATOR_ENTITIES_OPTIONS;
    case FILTER_DATE_TYPE:
      return OPERATOR_DATE_OPTIONS;
    case FILTER_NUMBER_TYPE:
      return OPERATOR_NUMBER_OPTIONS;
    case FILTER_BOOLEAN_TYPE:
      return OPERATOR_BOOLEAN_OPTIONS;
    default: // shouldn't reach this but if we do return all operator options
      return DEFAULT_OPERATOR_OPTIONS;
  }
};
