import { PeopleSearchFormValues } from '../people-search';

export const DATA_IMPORT_STATUS_COMPLETE = 'complete';
export const DATA_IMPORT_STATUS_ERROR = 'error';

export interface DataImport {
  administrator_list_id: number;
  created_at: string;
  csv_errors_url: string;
  data: PeopleSearchFormValues;
  error: string;
  error_count: number;
  filename: string;
  id: number;
  import_type: string;
  jid: string;
  progress: number;
  queued: boolean;
  row_count: number;
  status: string;
}
