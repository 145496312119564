import {
  api_react_v1_crm_identity_note_path,
  api_react_v1_crm_identity_notes_path,
} from '../../routes';
import { Response } from '../interfaces';
import request from './request';

export async function createIdentityNote(
  identityId: number,
  noteContent: string
): Promise<Response> {
  const resp = await request(
    'post',
    api_react_v1_crm_identity_notes_path({
      identity_note: {
        identity_id: identityId,
        note_content: noteContent,
      },
    })
  );
  return resp;
}

export async function deleteIdentityNote(identityNoteId: number): Promise<Response> {
  const resp = await request('delete', api_react_v1_crm_identity_note_path(identityNoteId));
  return resp;
}
