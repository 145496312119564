import clsx from 'clsx';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Row } from 'react-bootstrap';

import { useAdminContext } from '../../../contexts/AdminContext';
import useNewsletterCreate from '../../../hooks/useNewsletterCreate';
import useNewsletterUpdate from '../../../hooks/useNewsletterUpdate';
import { edit_admin_newsletter_path } from '../../../routes';
import { parameterize } from '../../../utils/helpers';
import { CheckBoxField, RadioField, TextAreaField, TextField } from '../../FormFields';
import ImageUploader from '../../ImageUploader';
import AuthorPicker from './AuthorPicker';
import newsletterSchema from './schema';
import styles from './styles.module.css';

export const LEAD_NEWSLETTER_OPTION = { label: 'All users', value: 0 };
export const SUBSCRIBER_NEWSLETTER_OPTION = { label: 'Only subscribers', value: 2 };
export const PRO_NEWSLETTER_OPTION = { label: 'Only Pro subscribers', value: 4 };
export const ACCESS_LEVEL_OPTIONS = [
  LEAD_NEWSLETTER_OPTION,
  SUBSCRIBER_NEWSLETTER_OPTION,
  PRO_NEWSLETTER_OPTION,
];

const NewsletterBuilderForm = ({ initialValues, buildType, newsletterId = null }) => {
  const { mutate: createNewsletter } = useNewsletterCreate();
  const { mutate: updateNewsletter } = useNewsletterUpdate(newsletterId);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const { environment } = useAdminContext();

  const toggleAdvanced = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowAdvanced(!showAdvanced);
  };

  const handleSubmit = async (values) => {
    const {
      author,
      cardImageUrl,
      description,
      heroImagePhoneUrl,
      landingPageDescription,
      landingPageHeading,
      name,
      readLatestPath,
    } = values;

    const sanitizedParams = {
      ...values,
      authorId: author.value,
      cardImagePath:
        cardImageUrl && cardImageUrl.split(`${environment}/`).length > 1
          ? cardImageUrl.split(`${environment}/`)[1]
          : cardImageUrl,
      heroImagePhonePath:
        heroImagePhoneUrl && heroImagePhoneUrl.split(`${environment}/`).length > 1
          ? heroImagePhoneUrl.split(`${environment}/`)[1]
          : initialValues.heroImagePhonePath,
      landingPageHeading: landingPageHeading || name,
      landingPageDescription: landingPageDescription || description,
      readLatestPath: readLatestPath || `/features/${parameterize(name)}`,
    };

    if (buildType === 'edit') {
      await updateNewsletter(sanitizedParams);
    } else {
      await createNewsletter(sanitizedParams, {
        onSuccess: (data) => {
          if (data?.success) {
            window.location.href = edit_admin_newsletter_path(data.id);
          } else {
            console.error('Failed to create newsletter', data);
          }
        },
      });
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={newsletterSchema}
      enableReinitialize={true}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <div className="row gy-3">
            <div className="col-12">
              <label className={styles.label}>Name</label>
              <TextField name="name" />
            </div>
            <div className="col-12">
              <label className={styles.label}>Description</label>
              <TextAreaField name="description" />
            </div>
            <div className="col-12 form-group">
              <AuthorPicker />
            </div>
            <div className="col-6">
              <label className={styles.label}>Frequency</label>
              <TextField
                name="frequency"
                hint="Weekly, Occasional, Four Times a Week, Periodically, Daily, Every Friday, Every
                Other Week, Monthly"
              />
            </div>
            <div className="col-6">
              <label className={styles.label}>Customer IO Segment ID</label>
              <TextField name="customerioSegmentId" />
            </div>

            <div className="col-12">
              <ImageUploader
                name="cardImageUrl"
                label="card image url"
                hint="Enter full url for an existing image on CDN or click 'Choose File' to upload a new image"
                s3path="newsletter-hero-images"
              />
            </div>
            <div className="form-check">
              <RadioField
                name="accessLevel"
                label="Who should be able to subscribe to this newsletter?"
                options={ACCESS_LEVEL_OPTIONS}
              />
            </div>
            {buildType !== 'free' && (
              <>
                <div className="col-12">
                  <h2 className={styles.sectionHeader}>Stripe</h2>
                  <label className={styles.label}>Stripe Monthly Price ID</label>
                  <TextField name="stripeMonthlyPriceId" />
                </div>
                <div className="col-12">
                  <label className={styles.label}>Stripe Annual Price ID</label>
                  <TextField name="stripeAnnualPriceId" />
                </div>
                <div className="col-12">
                  <label className={styles.label}>Stripe API Key Name</label>
                  <TextField name="stripeApiKeyName" />
                </div>
                <div className="col-12">
                  <label className={styles.label}>Stripe Publishable Key Name</label>
                  <TextField name="stripePublishableKeyName" />
                </div>
              </>
            )}

            <div className="col-12">
              <div className="card bg-light my-3">
                <div className="card-body">
                  <div className="form-check">
                    <CheckBoxField label="active" name="active" className="form-check-input" />
                  </div>
                  <div className="form-check">
                    <CheckBoxField label="paid" name="paid" className="form-check-input" />
                  </div>
                  <div className="form-check">
                    <CheckBoxField
                      label="ti_bundled"
                      name="tiBundled"
                      className="form-check-input"
                    />
                  </div>
                  <div className="form-check">
                    <CheckBoxField
                      label="third_party"
                      name="thirdParty"
                      className="form-check-input"
                    />
                  </div>
                  <div className="form-check">
                    <CheckBoxField
                      label="auto_subscribe"
                      name="autoSubscribe"
                      className="form-check-input"
                    />
                  </div>
                  <div className="form-check">
                    <CheckBoxField
                      label="auto_subscribe_lead"
                      name="autoSubscribeLead"
                      className="form-check-input"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h2 className={styles.sectionHeader}>Advanced Settings</h2>
              <button
                className={clsx('btn', 'btn-plain', styles.btnPlain)}
                onClick={toggleAdvanced}
              >
                {showAdvanced ? 'Hide Advanced Settings' : 'Show Advanced Settings'}
              </button>
              {showAdvanced && (
                <Row>
                  <div className="col-6">
                    <label className={styles.label}>Theme</label>
                    <TextField name="theme" hint="add specific theme or leave blank" />
                  </div>
                  <div className="col-6">
                    <label className={styles.label}>Language</label>
                    <TextField name="lang" hint="en, cn, etc." />
                  </div>
                  <div className="col-6">
                    <label className={styles.label}>Order</label>
                    <TextField
                      name="order"
                      hint="Any integer, most are set to 0. Higher numbers push it towards the top of the list."
                    />
                  </div>
                  <div className="col-6">
                    <label className={styles.label}>Preview Days</label>
                    <TextField
                      name="previewDays"
                      hint="e.g. 0, 5, 999999 (999999 means no preview)"
                    />
                  </div>
                  <div className="col-6">
                    <label className={styles.label}>RTSU Preview Days</label>
                    <TextField name="rtsuPreviewDays" hint="e.g. 0, 7, 10, 999999" />
                  </div>
                  <div className="col-12">
                    <label className={styles.label}>Choose Plan Text</label>
                    <TextField
                      name="choosePlanText"
                      hint="e.g. -- if you want even more exclusive modest proposals you can <b>get a paid subscription</b>"
                    />
                  </div>
                  <div className="col-12">
                    <label className={styles.label}>After Signup URL</label>
                    <TextField
                      name="afterSignupUrl"
                      hint="https://www.theinformation.com/subscribe/promotion?offer=ce_100_nl"
                    />
                  </div>

                  <div className="col-12">
                    <label className={styles.label}>Landing Page Heading</label>
                    <TextField name="landingPageHeading" />
                  </div>
                  <div className="col-12">
                    <label className={styles.label}>Landing Page Description</label>
                    <TextAreaField
                      name="landingPageDescription"
                      hint="Two or three sentences on what this Newsletter is about"
                    />
                  </div>
                  <div className="col-12">
                    <label className={styles.label}>Read Latest Path</label>
                    <TextField name="readLatestPath" />
                  </div>
                  <div className="col-12">
                    <h2 className={styles.sectionHeader}>Email</h2>

                    <label className={styles.label}>Email Style (CSS)</label>
                    <TextField name="emailStyle" />
                  </div>
                  <div className="col-6">
                    <label className={styles.label}>From Name</label>
                    <TextField name="fromName" />
                  </div>
                  <div className="col-6">
                    <label className={styles.label}>From Email</label>
                    <TextField name="fromEmail" />
                  </div>
                  <div className="col-6">
                    <label className={styles.label}>Reply To</label>
                    <TextField name="replyTo" />
                  </div>
                  <div className="col-12">
                    <h2 className={styles.sectionHeader}>Images</h2>

                    <ImageUploader
                      name="logoImageUrl"
                      label="Logo Image URL"
                      hint="Enter full url for an existing image on CDN or click 'Choose File' to upload a new image"
                    />
                  </div>
                  <div className="col-12">
                    <ImageUploader
                      name="heroImagePhoneUrl"
                      label="Hero Image URL"
                      hint="Enter full url for an existing image on CDN or click 'Choose File' to upload a new image"
                    />
                  </div>
                </Row>
              )}
            </div>
            <div className="col-12 mb-5">
              <button className="btn btn-primary" type="submit" disabled={isSubmitting || !isValid}>
                {buildType === 'edit' ? 'Update Newsletter' : 'Create Newsletter'}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default NewsletterBuilderForm;
