import moment from 'moment';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import useArticleUpdate from '../../../hooks/useArticleUpdate';
import { useArticleContext } from '../Layout';

const RtsuControl = () => {
  const { article } = useArticleContext();
  const { articleId } = useParams();
  const { updateArticle } = useArticleUpdate(articleId);

  const rtsuScheduledCopy = article.sendRtsuAt
    ? `Originally scheduled for ${moment(article.sendRtsuAt).format('lll')}`
    : 'RTSU not scheduled';

  const RtsuJSX = () => {
    if (article.rtsuSentAt) {
      return <div>RTSU was already sent at {moment(article.rtsuSentAt).format('lll')}</div>;
    } else if (!article.sendRtsu) {
      return (
        <div className="d-flex flex-column align-items-start">
          <span>RTSU has been paused</span>
          <span>{rtsuScheduledCopy}</span>
          <div className="d-flex flex-wrap">
            <Button
              className="me-4 --black"
              onClick={() => updateArticle({ sendRtsu: true, sendRtsuAt: moment() })}
            >
              Send RTSU Now
            </Button>
            {article.sendRtsuAt && (
              <Button className="--white" onClick={() => updateArticle({ sendRtsu: true })}>
                Send RTSU as Scheduled
              </Button>
            )}
          </div>
        </div>
      );
    } else if (article.sendRtsu && moment() < moment(article.sendRtsuAt)) {
      return (
        <div className="d-flex flex-column align-items-start">
          <span>
            RTSU is scheduled to be delivered at {moment(article.sendRtsuAt).format('lll')}
          </span>
          <Button className="--black" onClick={() => updateArticle({ sendRtsu: false })}>
            Pause RTSU
          </Button>
        </div>
      );
    } else if (article.sendRtsu && moment() > moment(article.sendRtsuAt)) {
      return <div>RTSU is in process of delivery</div>;
    }
  };

  return (
    <div className="RtsuControl mb-4">
      <h2>RTSU Status:</h2>
      {RtsuJSX()}
    </div>
  );
};

export default RtsuControl;
