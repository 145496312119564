import { useFormikContext } from 'formik';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useArticle, useRelevantGroups, useRelevantGroupsCreate } from '../../../hooks';
import { READER_URL } from '../../../src/env';
import { Article } from '../../../utils/backend-api/articles';
import { RelevantGroup } from '../../../utils/backend-api/relevant-groups';
import { DropdownField, TextAreaField, TextField } from '../../FormFields';
import { GroupPostFormValues, SelectType } from './GroupPostForm';
import './GroupPostForm.scss';

const generateUrlParam = () => Math.random().toString(36).substring(2, 8);

const getArticleDisplayUrl = (article: Article, urlParam: string) =>
  !!article?.slug && !!urlParam.length
    ? `${READER_URL}/articles/${article.slug}?rgp=${urlParam}`
    : '';

const GroupPostFields = () => {
  const { articleId, groupPostId } = useParams();
  const { article } = useArticle(articleId);
  const { values, isSubmitting, setFieldValue } = useFormikContext<GroupPostFormValues>();
  const { mutate: createRelevantGroup } = useRelevantGroupsCreate(articleId);
  const relevantGroupToOption = (g: RelevantGroup) => ({ label: g.name, value: g.id });
  const { relevantGroups } = useRelevantGroups();
  const relevantGroupOptions = relevantGroups?.map(relevantGroupToOption) || [];
  const articleDisplayUrl = getArticleDisplayUrl(article, values.urlParam);

  const onCreateOption = (label: string) => {
    createRelevantGroup(
      { id: Math.random().toString(), name: label },
      {
        onSuccess: (newRelevantGroup: RelevantGroup) => {
          onChangeRelevantGroup(relevantGroupToOption(newRelevantGroup));
        },
      }
    );
  };

  const onChangeRelevantGroup = (newRelevantGroup: SelectType) => {
    const urlParam = generateUrlParam();
    if (!values.urlParam) setFieldValue('urlParam', urlParam);
    if (!values.body && !groupPostId)
      setFieldValue('body', getArticleDisplayUrl(article, urlParam));
    setFieldValue('relevantGroup', newRelevantGroup);
  };

  const onPostUrlChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setFieldValue('postUrl', e.target.value.split('?')[0]);

  if (!article.publishOnWeb || !article.slug) {
    return <p>Article is not published on web, or has a missing slug</p>;
  }

  return (
    <>
      <Row>
        <Col xs={6}>
          <DropdownField
            name="relevantGroup"
            label="Relevant Group"
            placeholder="Select a group"
            options={relevantGroupOptions}
            onCreateOption={onCreateOption}
            onChange={onChangeRelevantGroup}
          />
        </Col>
        <Col xs={6}>
          <div className="form-group">
            <label className="input-label">Article URL</label>
            <input value={articleDisplayUrl} className="form-control" disabled={true} />
          </div>
        </Col>
      </Row>
      <TextAreaField name="body" label="Post Body" minRows={4} disabled={!values.urlParam} />
      <TextField
        name="postUrl"
        label="Post Permalink"
        onChange={onPostUrlChange}
        disabled={!values.urlParam}
      />
      <div className="d-flex justify-content-end --black-top-border">
        <Button disabled={isSubmitting} type="submit" className="--black">
          Save Post
        </Button>
      </div>
    </>
  );
};

export default GroupPostFields;
