import React, { ReactNode, useState } from 'react';

import chevronDown from '../../../images/chevron-down.svg';
import chevronRight from '../../../images/chevron-right.svg';

import styles from './styles.module.css';

const Section = ({
  children,
  header,
  id,
  defaultOpenState = false,
}: {
  children: ReactNode;
  header: string;
  id: string;
  defaultOpenState?: boolean;
}) => {
  const [open, setOpen] = useState(defaultOpenState);

  return (
    <div className={styles.section} id={id}>
      <h4 className={styles.sectionHeader} onClick={() => setOpen(!open)}>
        {header}{' '}
        <img
          src={(open ? chevronRight : chevronDown) as unknown as string}
          width="12px"
          height="12px"
        />
      </h4>
      {open && children}
    </div>
  );
};

export default Section;
