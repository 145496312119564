import toast from 'react-hot-toast';
import { useQuery } from 'react-query';

import {
  PeopleSearchDocument,
  getPeopleSearchDocument,
} from '../utils/backend-api/people-search-documents';

export const PEOPLE_SEARCH_DOCUMENT_KEY = 'people_search_document';

export default function usePeopleSearchDocument(identityId: number, confidence: 'high' | 'medium') {
  const {
    data: peopleSearchDocument,
    isLoading: isLoadingPeopleSearchDocument,
    isError,
  } = useQuery<PeopleSearchDocument>(
    [PEOPLE_SEARCH_DOCUMENT_KEY, identityId, confidence],
    () => getPeopleSearchDocument({ identityId, confidence }),
    {
      onError: () => {
        toast.error('Fetching people_search_document has failed');
      },
    }
  );

  return { peopleSearchDocument, isLoadingPeopleSearchDocument, isError };
}
