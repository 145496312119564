import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { CRM_SIDE_NAV_DATA_KEY } from '../components/Crm/SideNav';
import { deleteConnectedProvider } from '../utils/backend-api/connected-providers';

export default function useConnectedProviderDelete(id: number | string) {
  const queryClient = useQueryClient();

  const { mutate, mutateAsync, data, isLoading, isError } = useMutation(
    async () => await deleteConnectedProvider(id),
    {
      onSuccess: () => {
        toast.success('Account has been deleted');
        queryClient.invalidateQueries(CRM_SIDE_NAV_DATA_KEY);
      },
      onError: () => {
        toast.error('Deleting account has failed');
      },
    }
  );
  return { mutate, mutateAsync, data, isLoading, isError };
}
