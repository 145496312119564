import * as Yup from 'yup';
import moment from 'moment';

const twitterCampaignSchema = () =>
  Yup.object().shape({
    connectedProvider: Yup.object().shape({
      label: Yup.string().nullable(),
      value: Yup.number().required('Twitter Ads account is required'),
    }),
    virtualList: Yup.object().shape({
      label: Yup.string().nullable(),
      value: Yup.number().required('Virtual List is required'),
    }),
    bidAmount: Yup.number().required('A daily budget is required.').min(1, 'Must be at least $1'),
    totalBudget: Yup.number().required('A total budget is required.').min(1, 'Must be at least $1'),
    startAt: Yup.date()
      .min(moment().toISOString(), 'Scheduled time must be in the future')
      .nullable(),
    tweet: Yup.object().shape({
      label: Yup.string().nullable(),
      value: Yup.number().required('You must choose a tweet to promote'),
    }),
  });

export default twitterCampaignSchema;
