import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import { useAdminContext } from '@contexts/AdminContext';
import useAdministrator from '@hooks/useAdministrator';
import useArticleAdministratorLists from '@hooks/useArticleAdministratorLists';
import { distribution_audience_generator_path } from '@routes';

import AdministratorListItem from '../Crm/SideNav/AdministratorListItem';
import styles from '../styles.module.css';

const AudienceLists = () => {
  const { articleId } = useParams();
  const { administratorLists, isLoadingAdministratorLists } =
    useArticleAdministratorLists(articleId);
  const { currentAdminId, allowedToManageIdentities } = useAdminContext();
  const { administrator: currentAdmin, isLoadingAdministrator } = useAdministrator(currentAdminId);

  return (
    <>
      <Row>
        <Col className="me-4">
          <div className={styles.header}>
            <h2>Audience Lists</h2>
            <Link className="mt-1" to={distribution_audience_generator_path(articleId)}>
              + Create New List
            </Link>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {!isLoadingAdministratorLists && !isLoadingAdministrator && (
            <ul className="list-unstyled overflow-hidden" id="subscribable-list">
              {administratorLists.map((adminList) => (
                <AdministratorListItem
                  administratorList={adminList}
                  currentAdmin={currentAdmin}
                  forceShowEdit={allowedToManageIdentities}
                  isSubscribed={true}
                  key={adminList.id}
                />
              ))}
            </ul>
          )}
        </Col>
      </Row>
    </>
  );
};

export default AudienceLists;
