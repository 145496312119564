import React from 'react';

import useConnectedProviderDelete from '../../../../hooks/useConnectedProviderDelete';
import styles from './style.module.css';

const ConnectedProvider = ({ connectedProvider }) => {
  const { mutate: deleteConnectedProvider } = useConnectedProviderDelete(connectedProvider.id);

  const handleRemoveConnectedProvider = () => {
    const userResp = confirm('Are you sure you want to delete this Account?');
    if (userResp) {
      deleteConnectedProvider();
    }
  };

  return (
    <li
      id={`connected-provider-${connectedProvider.id}`}
      className={`connected-account-name ${connectedProvider.has_failed ? 'failed' : ''}`}
    >
      <span className="description">{connectedProvider.account_description}</span>
      <span className={`status`}>
        <span className="text">&nbsp;({connectedProvider.contact_count})</span>
        {connectedProvider.has_failed && (
          <span className="error">
            Reauthentication Required -{' '}
            <a href={`/auth/${connectedProvider.provider}?reconnect=true`}>Reauthenticate</a>
          </span>
        )}
        {connectedProvider.is_reconnecting && (
          <span className="error">
            Account reconnection in progess -{' '}
            <a href={`/auth/${connectedProvider.provider}?reconnect=true`}>Try again</a>
          </span>
        )}
      </span>
      <button className={styles.deleteBtn} onClick={handleRemoveConnectedProvider}>
        Delete
      </button>
    </li>
  );
};
export default ConnectedProvider;
