import { Formik } from 'formik';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';

import { AutocompleteField, DropdownField, IntegerField, TextField } from '@components/FormFields';
import { autocompleteAdministratorLists } from '@utils/backend-api/administrator-lists';
import {
  PeopleSearchFormValues,
  SanitizedSearchParams,
  SearchPeopleData,
  searchPeopleByAttributes,
} from '@utils/backend-api/people-search';

import SearchAttributesGroups, { DEFAULT_GROUP_VALUE } from './SearchAttributes/Groups';
import { ALL_CONDITION_JOIN_OPTION, CONDITION_JOIN_OPTIONS } from './SearchAttributes/options';
import peopleSearchSchema, { MAX_EMPLOYEES_SEARCH } from './schema';

const INITIAL_FORM_STATE: PeopleSearchFormValues = {
  newAdministratorListName: '',
  existingAdministratorList: null,
  conditionJoin: ALL_CONDITION_JOIN_OPTION,
  searchAttributesGroups: [DEFAULT_GROUP_VALUE],
  size: 1,
  searchName: null,
};

interface PeopleSearchFormProps {
  setSearchPeopleJobId: (jobId: string) => void;
  setSearchPeopleData: (data: SearchPeopleData) => void;
  searchPeopleData: SearchPeopleData;
  initialFormData: PeopleSearchFormValues | unknown;
}

const sanitizeParams = (values: PeopleSearchFormValues): SanitizedSearchParams => {
  return {
    newAdministratorListName: values.newAdministratorListName,
    existingAdministratorListId: values.existingAdministratorList?.value,
    conditionJoin: values.conditionJoin.value,
    searchAttributesGroups: values.searchAttributesGroups.map((group) => ({
      conditionJoin: group.conditionJoin.value,
      searchAttributes: group.searchAttributes.map((attribute) => ({
        field: attribute.field.value,
        values: attribute.values.map((option) => option.value),
      })),
    })),
    size: values.size,
    searchName: values.searchName,
  };
};

const PeopleSearchForm = ({
  setSearchPeopleJobId,
  setSearchPeopleData,
  searchPeopleData,
  initialFormData = INITIAL_FORM_STATE,
}: PeopleSearchFormProps) => {
  const handleSubmit = async (values: PeopleSearchFormValues) => {
    try {
      const jobId = await searchPeopleByAttributes(sanitizeParams(values));
      setSearchPeopleJobId(jobId);
      setSearchPeopleData({ ...searchPeopleData, status: 'queued' });
    } catch (e) {
      toast.error(`There was an error searching people by company - error: ${e}`);
    }
    // scroll to top of page since results are at top and form can be really long
    window.scrollTo(0, 0);
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialFormData}
      validationSchema={peopleSearchSchema}
    >
      {(formik) => (
        <Form noValidate onSubmit={formik.handleSubmit}>
          <DropdownField
            options={CONDITION_JOIN_OPTIONS}
            name="conditionJoin"
            append="of the following conditions match"
          />
          <SearchAttributesGroups />
          <IntegerField
            label={`Enter the Number of Employees to Retrieve (max ${MAX_EMPLOYEES_SEARCH})`}
            name="size"
          />
          <TextField
            label="Optional: Add found people to a new administrator list"
            name="newAdministratorListName"
            placeholder="Enter a title here"
          />
          <AutocompleteField
            label="Optional: Add found people to an existing administrator list"
            name="existingAdministratorList"
            fetchMethod={(value: string) =>
              autocompleteAdministratorLists({ fragment: value, scope: 'pdl_friendly' })
            }
            valueIsInteger
            isCreatable={false}
            placeholder="Search existing administrator lists by name"
          />
          <TextField
            label="Optional: Add a name for this search"
            name="searchName"
            placeholder="Enter a name here"
          />
          <Button type="submit">Search for People</Button>
        </Form>
      )}
    </Formik>
  );
};

export default PeopleSearchForm;
