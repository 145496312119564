import React, { useCallback, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import AdministratorListForm from '@components/Crm/AdministratorLists/Form';
import useArticle from '@hooks/useArticle';
import { distribution_administrator_lists_path } from '@routes';
import { ARTICLE_AUTOMATIC_LIST_TYPE_OPTION } from '@utils/backend-api/administrator-lists';
import { SUBSCRIPTION_STATUS_CANCELED, SUBSCRIPTION_STATUS_LEAD } from '@utils/backend-api/users';

import styles from '../styles.module.css';

const INITIAL_FORM_STATE = {
  accessLevels: [],
  article: {
    label: '',
    value: '',
  },
  entities: ['allChecked'],
  listName: '',
  listDescription: '',
  listType: ARTICLE_AUTOMATIC_LIST_TYPE_OPTION,
  isShared: false,
  query: '',
  isArchived: false,
  noUser: false,
  entityJoinTypesViewedArticles: true,
  entityJoinTypesFollows: true,
  entityJoinTypesEmployedBy: true,
  entityJoinTypesProfile: true,
  subscriptionStatuses: [SUBSCRIPTION_STATUS_LEAD, SUBSCRIPTION_STATUS_CANCELED],
};

const AudienceGenerator = () => {
  const { articleId } = useParams();
  const { article, isLoadingArticle } = useArticle(articleId);

  const listName = useCallback(() => {
    return article ? `Article ${articleId} - ${article.title} List` : '';
  }, [article, articleId]);

  const listDescription = useCallback(() => {
    return article ? `AdministratorList around article ${articleId}` : '';
  }, [article, articleId]);

  const initialValues = useMemo(() => {
    return {
      ...INITIAL_FORM_STATE,
      article: { value: parseInt(articleId), label: article?.title },
      listName: listName(),
      listDescription: listDescription(),
    };
  }, [articleId, article?.title, listDescription, listName]);

  return (
    <Row>
      <Col className="me-4">
        <div className={styles.header}>
          <h2>Create New List</h2>
          <Link className="mt-1" to={distribution_administrator_lists_path(articleId)}>
            Manage Lists
          </Link>
        </div>
        {isLoadingArticle ? (
          'Loading'
        ) : (
          <AdministratorListForm initialValues={initialValues} canEditArticle={false} />
        )}
      </Col>
    </Row>
  );
};

export default AudienceGenerator;
