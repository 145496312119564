import { api_react_v1_crm_connected_provider_path } from '../../routes';
import { Response } from '../interfaces';
import request from './request';
import { getRecord, getRecords } from './universal';

export interface ConnectedProvider {
  id: string;
  name: string;
}

export const getConnectedProviders = async (
  providerName?: string | null
): Promise<ConnectedProvider[]> => {
  const resp = await getRecords({
    model: 'ConnectedProvider',
    attributes: ['id', 'name'],
    identifiers: providerName ? { provider: providerName } : {},
  });
  return resp.result;
};

export const getConnectedProvider = async (
  connectedProviderId: number | string
): Promise<ConnectedProvider> => {
  const resp = await getRecord({
    model: 'ConnectedProvider',
    id: connectedProviderId,
    attributes: ['id'],
    helpers: ['tweets'],
  });
  return resp.result;
};

export const deleteConnectedProvider = async (id: number | string): Promise<Response> => {
  const resp = await request('delete', api_react_v1_crm_connected_provider_path(id));
  return resp;
};
