import { FormikProps } from 'formik';
import React from 'react';
import { Button } from 'react-bootstrap';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';

import { DropdownField, TextField } from '@components/FormFields';
import {
  ProjectFormValues,
  layoutTypeOptions,
  mediaTypeOptions,
} from '@utils/backend-api/projects';

interface TopSectionMediaFormProps {
  mediaSectionFormRef: React.RefObject<HTMLDivElement>;
  formik: FormikProps<ProjectFormValues>;
  index: number;
  onDeleteMedia: (index: number) => void;
}

const TopSectionMediaForm = ({
  mediaSectionFormRef,
  formik,
  index,
  onDeleteMedia,
}: TopSectionMediaFormProps) => {
  return (
    <div
      style={{
        padding: '12px',
        marginTop: '12px',
        marginBottom: '12px',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        borderRadius: '4px',
      }}
    >
      <Row>
        <Col>
          <Button
            id={`delete-button-${index}`}
            name={`delete-button-${index}`}
            variant="danger"
            onClick={() => onDeleteMedia(index)}
          >
            Delete Media
          </Button>
        </Col>
      </Row>
      <Row ref={mediaSectionFormRef}>
        <Col>
          <DropdownField
            id={`input-media-type-${index}`}
            name={`projectMedia.${index}.mediaType`}
            label="Type"
            options={mediaTypeOptions}
          />
        </Col>
      </Row>
      {formik.values.projectMedia[index]?.mediaType.value === 'iframe' && (
        <>
          <Row>
            <Col>
              <TextField
                name={`projectMedia.${index}.src`}
                label="Source URL"
                hint="The iframe src"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField name={`projectMedia.${index}.title`} label="Title" />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField name={`projectMedia.${index}.elementId`} label="Element ID" />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField name={`projectMedia.${index}.height`} label="Height" />
            </Col>
          </Row>
          <Row>
            <Col>
              <DropdownField
                name={`projectMedia.${index}.layout`}
                label="Layout"
                options={layoutTypeOptions}
              />
            </Col>
          </Row>
        </>
      )}
      {formik.values.projectMedia[index]?.layout.value === 'image' && (
        <Row>
          <Col>
            <p>Image media type not yet supported.</p>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default TopSectionMediaForm;
