import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { IDENTITY_KEY } from '..';
import { updateIdentity } from '../../../../utils/backend-api/identities';
import styles from './styles.module.css';

const camelToSnakeCase = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

const IdentityCheckbox = ({ label, identityId, field, value }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const queryClient = useQueryClient();
  const fieldSnake = camelToSnakeCase(field);

  const handleUpdateIdentity = async () => {
    setIsSubmitting(true);
    try {
      const sanitizedParams = {};
      sanitizedParams[fieldSnake] = !value;
      const response = await updateIdentity(identityId, sanitizedParams);
      if (response.success) {
        queryClient.invalidateQueries([IDENTITY_KEY, identityId]);
        toast.success('Identity succesfully updated');
      } else {
        toast.error('failed to update identity');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <input
        type="checkbox"
        id={field}
        name={field}
        disabled={isSubmitting}
        checked={value}
        onChange={handleUpdateIdentity}
        className={styles.checkbox}
      />
      <span className={styles.fieldName}>{label}</span>
    </>
  );
};

export default IdentityCheckbox;
