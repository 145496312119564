import {
  api_react_v1_distribution_relevant_company_identities_path,
  api_react_v1_distribution_suggested_article_companies_path,
} from '../../routes';
import request from './request';

export interface Identity {
  id: number;
  email: string;
}

interface sanitizedRelevantIdentitiesParams {
  companies: string[];
  exclude_subscribers: boolean;
  exclude_already_notified: boolean;
  exclude_vips: boolean;
  included_lists: number[];
  excluded_lists: number[];
}

export async function getRelevantIdentities(
  articleId: number | string,
  values: sanitizedRelevantIdentitiesParams
): Promise<Identity[]> {
  const resp = await request(
    'get',
    api_react_v1_distribution_relevant_company_identities_path(articleId),
    values
  );
  return resp.identities;
}

export async function getSuggestedCompanies(articleId: number | string): Promise<string[]> {
  const resp = await request(
    'get',
    api_react_v1_distribution_suggested_article_companies_path(articleId)
  );
  return resp.companies;
}
