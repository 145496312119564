import moment from 'moment';
import * as yup from 'yup';

const adScheduleSchema = () =>
  yup.object().shape({
    startDate: yup
      .date()
      .typeError('Start date is required')
      .min(moment().startOf('day'), 'Start date must be today or in the future')
      .required('Start date is required'),
    endDate: yup
      .date()
      .typeError('End date is required')
      .min(yup.ref('startDate'), 'End date must be after start date')
      .required('End date is required'),
    sections: yup.array(),
  });

export default adScheduleSchema;
