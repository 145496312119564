import toast from 'react-hot-toast';
import { useQuery } from 'react-query';

import { Administrator, getAdministrator } from '../utils/backend-api/administrators';

export const ADMINISTRATOR_KEY = 'administrator';

export default function useAdministrator(administratorId: number | string) {
  const {
    data: administrator,
    isLoading: isLoadingAdministrator,
    isError,
  } = useQuery<Administrator>(
    [ADMINISTRATOR_KEY, administratorId],
    () => getAdministrator(administratorId),
    {
      onError: () => {
        toast.error('Fetching administrator has failed');
      },
    }
  );

  return { administrator, isLoadingAdministrator, isError };
}
