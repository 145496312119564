import { FilterValue } from '../../components/Crm/SideNav/Filters/SqlFilters';
import { api_react_v1_crm_identities_people_search_document_path } from '../../routes';
import request from './request';

export interface PeopleSearchDocument {
  id: string;
  label: string;
  field: string;
  operator: string;
  operatorLabel?: string;
  value: FilterValue | number[];
  type: string;
}

export interface getPeopleSearchDocumentParams {
  identityId: number | string;
  confidence: string;
}
// implement this with queryCache
export async function getPeopleSearchDocument({
  identityId,
  confidence,
}: getPeopleSearchDocumentParams) {
  return request(
    'get',
    api_react_v1_crm_identities_people_search_document_path(identityId, { confidence: confidence }),
    {}
  );
}
