import { Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useArticle, useLinkedinAd, useLinkedinAdAccounts } from '../../../hooks';
import { distribution_linkedin_ad_list_path } from '../../../routes';
import {
  autoCompleteTargetFacet,
  linkedinActivateCampaign,
  linkedinAdUpdate,
  linkedinCreate,
} from '../../../utils/backend-api/linkedin';
import {
  DateTimeField,
  DropdownField,
  ImageField,
  MultiValueAutocompleteField,
  TextAreaField,
  TextField,
} from '../../FormFields';
import LinkedinAdSchema from './schema';

const INITIAL_STATE = {
  linkedinAdAccount: { label: '', value: '' },
  linkedinAdType: { label: '', value: '' },
  campaignName: '',
  imageAd: '',
  adText: '',
  startAt: '',
  facetTargets: [],
  totalBudget: '',
  adTitle: '',
  adShareUrl: '',
};

const LINKEDIN_AD_TYPE_OPTIONS = [
  { label: 'Text Ad', value: 'Text Ad' },
  { label: 'Image Ad', value: 'Image Ad' },
];

const LinkedinAdForm = ({ isEditing }) => {
  const { linkedinAdAccountsOptions, isLoadingLinkedinAdAccounts } = useLinkedinAdAccounts();
  const [initialState, setInitialState] = useState(INITIAL_STATE);
  const navigate = useNavigate();
  const { articleId, linkedinAdId } = useParams();
  const { article, isLoadingArticle } = useArticle(articleId);
  const [activationStatus, setActiviationStatus] = useState('Activate');
  const { linkedinAd, isLoadingLinkedinAd } = isEditing
    ? useLinkedinAd(linkedinAdId)
    : { isLoadingLinkedinAd: false };

  const { mutate: createLinkedinAd, isLoading: isCreatingLinkedinAd } = useMutation(
    async (sanitizedParams) => await linkedinCreate(articleId, sanitizedParams),
    {
      onSuccess: () => {
        toast.success('Ad Campaign has been set up Successfully');
        navigate(distribution_linkedin_ad_list_path(articleId));
      },
      onError: () => {
        toast.error('Something went wrong! Please try again');
      },
    }
  );

  const { mutate: updateLinkedinAd, isLoading: isLinkedinAdUpdateLoading } = useMutation(
    async (sanitizedParams) => {
      return await linkedinAdUpdate(articleId, linkedinAdId, sanitizedParams);
    },
    {
      onSuccess: () => {
        toast.success('Ad Campaign been updated Successfully');
        navigate(distribution_linkedin_ad_list_path(articleId));
      },
      onError: () => {
        toast.error('Something went wrong! please try again');
      },
    }
  );

  const { mutate: activateCampaign, isLoading: isActivating } = useMutation(
    async (sanitizedParams) => {
      setActiviationStatus('Activating..');
      await linkedinActivateCampaign(articleId, sanitizedParams);
    },
    {
      onSuccess: () => {
        toast.success('Ad Campaign been updated Successfully activated');
        navigate(distribution_linkedin_ad_list_path(articleId));
      },
      onError: () => {
        toast.error('Something went wrong! plase try again');
      },
    }
  );

  useEffect(() => {
    if (
      !isLoadingLinkedinAd &&
      !isCreatingLinkedinAd &&
      !isLoadingArticle &&
      !isLoadingLinkedinAdAccounts
    ) {
      if (isEditing) {
        setInitialState({
          linkedinAdAccount: {
            label: linkedinAd.linkedinAdAccount.name,
            value: linkedinAd.linkedinAdAccount.id,
          },
          linkedinAdType: {
            label: linkedinAd.adType,
            value: linkedinAd.adType,
          },
          campaignName: linkedinAd.name,
          adText: linkedinAd.adText,
          imageAd: linkedinAd.imgixUrl,
          startAt: moment(linkedinAd.startAt).format('ll'),
          facetTargets: linkedinAd.facetTargets,
          totalBudget: parseInt(linkedinAd.totalBudget) / 100,
          adTitle: linkedinAd.adTitle,
          adShareUrl: linkedinAd.adShareUrl,
        });
      } else {
        setInitialState({ ...INITIAL_STATE, adShareUrl: article.shareUrl });
      }
    }
  }, [isLoadingArticle, isLoadingLinkedinAd, isLoadingLinkedinAdAccounts]);

  const handleSubmit = async (values) => {
    const {
      adShareUrl,
      adText,
      adTitle,
      campaignName,
      facetTargets,
      linkedinAdAccount,
      linkedinAdType,
      imageAd,
      startAt,
      totalBudget,
    } = values;
    const formData = new FormData();
    const sanitizedParams = {
      linkedin_ad_account_id: linkedinAdAccount.value,
      ad_type: linkedinAdType.value,
      campaign_name: campaignName,
      ad_text: adText,
      ad_image: imageAd,
      start_at: moment(startAt).toDate(),
      facet_targets: facetTargets,
      total_budget: totalBudget,
      ad_title: adTitle,
      ad_share_url: adShareUrl,
    };
    // convert the facet_targets to JSON and skip uploading the image if it's just a link
    for (let key in sanitizedParams) {
      if (key === 'facet_targets') {
        formData.append(key, JSON.stringify(sanitizedParams[key]));
      } else if (key === 'ad_image' && typeof sanitizedParams[key] === 'string') {
        continue;
      } else {
        formData.append(key, sanitizedParams[key]);
      }
    }

    isEditing ? updateLinkedinAd(formData) : createLinkedinAd(formData);
  };
  if (
    isLoadingArticle ||
    isCreatingLinkedinAd ||
    isLoadingLinkedinAdAccounts ||
    isLoadingLinkedinAd
  )
    return null;
  const canEdit = linkedinAd?.status !== 'active' && linkedinAd?.status !== 'activating';
  return (
    <div className="LinkedinAdForm">
      <h2>{isEditing ? 'Edit' : 'Schedule New'} Linkedin Advertisement</h2>
      {!canEdit && <h3 className="--red">You can't update an active campaign</h3>}
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialState}
        validationSchema={LinkedinAdSchema}
        enableReinitialize={true}
      >
        {(formik) => (
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Row>
              <Col>
                <DropdownField
                  name="linkedinAdAccount"
                  label="Ad Account"
                  placeholder="Select Linkedin Ad Account"
                  options={linkedinAdAccountsOptions}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <DropdownField
                  name="linkedinAdType"
                  label="Advertisement Type"
                  placeholder="Select Advertisement Type"
                  options={LINKEDIN_AD_TYPE_OPTIONS}
                />
              </Col>
            </Row>
            {formik.values.linkedinAdType.value === 'Image Ad' && (
              <Row>
                <ImageField
                  name="imageAd"
                  label="Image Advertisement"
                  hint="Horizontal (Min: 640 x 360, Max: 7680 x 4320), Vertical (Min: 360 x 360, Max: 4320 x 4320)"
                />
              </Row>
            )}
            <Row>
              <Col>
                <TextField name="campaignName" label="Campaign Name" />
              </Col>
            </Row>
            <Row>
              <Col>
                <MultiValueAutocompleteField
                  label="Search Linkedin for Companies"
                  name="facetTargets"
                  isDisabled={formik.values.linkedinAdAccount.value === ''}
                  fetchMethod={(value) =>
                    autoCompleteTargetFacet({
                      entity: value,
                      selectedFacets: formik.values.facetTargets,
                      accountNumber: formik.values.linkedinAdAccount.value,
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <DateTimeField
                  dateFormat={'MMM dd yyyy'}
                  hideClearButton
                  label="Start Date"
                  maxDaysOut={90}
                  name="startAt"
                  showTimeSelect={false}
                />
              </Col>
            </Row>
            {formik.values.linkedinAdType.value === 'Text Ad' && (
              <Row>
                <Col>
                  <TextField name="adTitle" label="Title" />
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <TextField name="totalBudget" label="Total Budget" />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextField name="adShareUrl" label="Share Link" />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextAreaField name="adText" label="Share Text" minRows={4} />
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end">
                <Button
                  disabled={
                    !formik.isValid || isCreatingLinkedinAd || isLinkedinAdUpdateLoading || !canEdit
                  }
                  type="submit"
                  className="submit-button button --black"
                  id="schedule"
                >
                  {isEditing ? 'Update Linkedin Ad' : 'Create Linkedin Ad'}
                </Button>
                {isEditing && (
                  <Button
                    className="button btn --blue"
                    disabled={isActivating || !canEdit}
                    onClick={() => {
                      activateCampaign({ id: linkedinAdId });
                    }}
                  >
                    {activationStatus}
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LinkedinAdForm;
