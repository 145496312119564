import { useQuery } from 'react-query';

import { RelevantGroup, getRelevantGroups } from '../utils/backend-api/relevant-groups';

export const RELEVANT_GROUPS_KEY = 'relevantGroups';

export default function useRelevantGroups() {
  const { data: relevantGroups, isLoading } = useQuery<RelevantGroup[]>(
    RELEVANT_GROUPS_KEY,
    getRelevantGroups
  );

  return { relevantGroups, isLoading };
}
