import React, { useEffect, useState } from 'react';

import { useConnectedProvider } from '../../hooks';
import { DropdownField } from '../FormFields';

const TweetPicker = ({ connectedProviderId }) => {
  const { connectedProvider, isLoadingConnectedProvider } =
    useConnectedProvider(connectedProviderId);
  const [tweetOptions, setTweetOptions] = useState([]);

  useEffect(() => {
    if (!isLoadingConnectedProvider && connectedProvider.tweets) {
      setTweetOptions(
        connectedProvider.tweets.map((tweet) => ({ value: tweet.id, label: tweet.text }))
      );
    }
  }, [isLoadingConnectedProvider]);

  if (isLoadingConnectedProvider) return null;
  return (
    <DropdownField
      name="tweet"
      label="Tweet"
      placeholder="Select a Tweet to Promote"
      options={tweetOptions}
    />
  );
};

export default TweetPicker;
