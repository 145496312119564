import * as Yup from 'yup';

const postSchema = () =>
  Yup.object().shape({
    relevantGroup: Yup.object().shape({
      label: Yup.string().nullable(),
      value: Yup.number().required('Relevant group is required'),
    }),
    postUrl: Yup.string().url('Post URL must be a URL'),
    body: Yup.string().required('Post body is required'),
  });

export default postSchema;
