import { useInfiniteQuery } from 'react-query';

import { PaginatedProjects, getPaginatedProjects } from '../utils/backend-api/projects';

export default function useProjects() {
  const {
    data: projectsData,
    isLoading: isLoadingProjects,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<PaginatedProjects>(
    'projects',
    ({ pageParam = 1 }) => getPaginatedProjects(pageParam),
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
    }
  );

  return { projectsData, isLoadingProjects, fetchNextPage, hasNextPage };
}
