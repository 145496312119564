import { useInfiniteQuery } from 'react-query';

import {
  PaginatedArticleRecipients,
  RecipientType,
  getArticleRecipients,
} from '../utils/backend-api/users';

export const ARTICLE_RECIPIENTS_KEY = 'article_recipients';

export default function useArticleRecipients(
  articleId: number | string,
  recipientType: RecipientType
) {
  const { data, isLoading, fetchNextPage, hasNextPage } =
    useInfiniteQuery<PaginatedArticleRecipients>(
      [ARTICLE_RECIPIENTS_KEY, articleId, recipientType],
      ({ pageParam = 1 }) => getArticleRecipients(articleId, recipientType, pageParam),
      {
        getNextPageParam: (lastPage) => lastPage?.nextPage,
      }
    );

  return { data, isLoading, fetchNextPage, hasNextPage };
}
