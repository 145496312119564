import toast from 'react-hot-toast';
import { useQuery } from 'react-query';

import { EntityJoin, getArticleEntities } from '@utils/backend-api/entities';

export const ARTICLE_ENTITIES_KEY = 'article_entities';

export default function useArticleEntities(articleId: number | string | null) {
  const {
    data: articleEntityJoins,
    isLoading: isLoadingArticleEntities,
    isRefetching: isRefetchingArticleEntities,
    isError,
  } = useQuery<EntityJoin[]>(
    [ARTICLE_ENTITIES_KEY, articleId],
    () => getArticleEntities(articleId),
    {
      onError: () => {
        toast.error('Fetching article entities has failed');
      },
      enabled: !!articleId,
    }
  );

  // filter out dups, sort by name, and parse to label/value obj
  const articleEntities = articleEntityJoins
    ? articleEntityJoins
        .filter((ej, index, self) => self.findIndex((o) => o.entity.id === ej.entity.id) === index)
        .sort((a, b) => {
          return a.entity.name > b.entity.name ? 1 : -1;
        })
        .map((ej) => ({ label: ej.entity.name, value: ej.entity.id }))
    : null;

  return {
    articleEntityJoins,
    articleEntities,
    isLoadingArticleEntities,
    isRefetchingArticleEntities,
    isError,
  };
}
