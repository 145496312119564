import startCase from 'lodash/startCase';

import {
  IDENTITY_PROFILE_COMPANY_INDUSTRY,
  IDENTITY_PROFILE_COMPANY_NAME,
  IDENTITY_PROFILE_JOB_LEVEL,
  IDENTITY_PROFILE_JOB_ROLE,
  IDENTITY_PROFILE_JOB_TITLE,
} from '@utils/backend-api/identity-profiles';
import { ConditionJoinOption, PdlSearchField } from '@utils/backend-api/people-search';
import { AutocompleteOption } from '@utils/interfaces';

export const ALL_CONDITION_JOIN_OPTION: ConditionJoinOption = { label: 'All', value: 'all' };
export const ANY_CONDITION_JOIN_OPTION: ConditionJoinOption = { label: 'Any', value: 'any' };

export const CONDITION_JOIN_OPTIONS: ConditionJoinOption[] = [
  ALL_CONDITION_JOIN_OPTION,
  ANY_CONDITION_JOIN_OPTION,
];

export const FIELD_COMPANY_EMAIL_DOMAIN = 'company_email_domain';
export const FIELD_COMPANY_INDUSTRY = IDENTITY_PROFILE_COMPANY_INDUSTRY;
export const FIELD_COMPANY_NAME = IDENTITY_PROFILE_COMPANY_NAME;
export const FIELD_JOB_LEVEL = IDENTITY_PROFILE_JOB_LEVEL;
export const FIELD_JOB_ROLE = IDENTITY_PROFILE_JOB_ROLE;
export const FIELD_JOB_TITLE = IDENTITY_PROFILE_JOB_TITLE;

export const ATTRIBUTE_FIELDS: PdlSearchField[] = [
  FIELD_COMPANY_EMAIL_DOMAIN,
  FIELD_COMPANY_NAME,
  FIELD_COMPANY_INDUSTRY,
  FIELD_JOB_LEVEL,
  FIELD_JOB_ROLE,
  FIELD_JOB_TITLE,
];

export const ATTRIBUTE_FIELD_OPTIONS = ATTRIBUTE_FIELDS.map((fieldName) => ({
  label: startCase(fieldName),
  value: fieldName,
}));

export interface FieldOptionsMapper {
  [FIELD_COMPANY_EMAIL_DOMAIN]: AutocompleteOption[];
  [FIELD_COMPANY_INDUSTRY]: AutocompleteOption[];
  [FIELD_COMPANY_NAME]: AutocompleteOption[];
  [FIELD_JOB_LEVEL]: AutocompleteOption[];
  [FIELD_JOB_ROLE]: AutocompleteOption[];
  [FIELD_JOB_TITLE]: AutocompleteOption[];
}

export const INITIAL_FIELD_OPTIONS_MAPPER = {
  [FIELD_COMPANY_INDUSTRY]: [],
  [FIELD_COMPANY_NAME]: [],
  [FIELD_COMPANY_EMAIL_DOMAIN]: [],
  [FIELD_JOB_LEVEL]: [],
  [FIELD_JOB_ROLE]: [],
  [FIELD_JOB_TITLE]: [],
};
