import * as Yup from 'yup';

import {
  FILTER_AUTOCOMPLETE_TYPE,
  FILTER_ENTITIES_TYPE,
  determineOperatorOptions,
} from './options';

const filtersSchema = () =>
  Yup.object().shape({
    field: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string().required('Field is required'),
    }),
    operator: Yup.object().when('field', (field) => {
      return Yup.object().shape({
        label: Yup.string(),
        value: Yup.string()
          .oneOf(
            determineOperatorOptions(field.type).map((o) => o.value),
            'This is not a valid operator for this field type'
          )
          .required('Operator is required'),
      });
    }),
    filterValue: Yup.object().when('field', (field) => {
      if (field.type === FILTER_ENTITIES_TYPE) {
        return Yup.array(
          Yup.object().shape({
            label: Yup.string().nullable(),
            value: Yup.number().required('Valid record is required'),
          })
        )
          .min(1)
          .required('At least one record is required');
      } else if (field.type === FILTER_AUTOCOMPLETE_TYPE) {
        return Yup.object().shape({
          label: Yup.string().nullable(),
          value: Yup.string().required('Value is required'),
        });
      } else {
        return Yup.string().required('Value is required');
      }
    }),
  });

export default filtersSchema;
