import {
  api_react_v1_autocomplete_linkedin_entities_path,
  api_react_v1_distribution_linkedin_activate_campaign_path,
  api_react_v1_distribution_linkedin_authenticate_path,
  api_react_v1_distribution_linkedin_create_path,
  api_react_v1_distribution_linkedin_update_path,
} from '../../routes';
import request from './request';
import { getRecord, getRecords } from './universal';

export interface LinkedinAdAccount {
  id: string;
  name: string;
}

export interface LinkedinAd {
  adShareUrl: string;
  adTitle: string;
  adText: string;
  adType: string;
  createdAt: string;
  facetTargets: LinkedinAdAccount;
  imgixUrl: string;
  id: string;
  status: string;
  startAt: string;
  name: string;
  totalBudget: string;
}

export async function checkLinkedinAuth(articleId) {
  const resp = await request(
    'get',
    api_react_v1_distribution_linkedin_authenticate_path(articleId)
  );
  return resp;
}

export const getLinkedinAdAccounts = async (): Promise<LinkedinAdAccount[]> => {
  const resp = await getRecords({
    model: 'LinkedinAdAccount',
    attributes: ['id', 'name'],
  });
  return resp.result;
};

export const getLinkedinAdList = async (articleId: string | number): Promise<LinkedinAd[]> => {
  const resp = await getRecords({
    model: 'LinkedinCampaign',
    attributes: ['id', 'name', 'status', 'startAt', 'createdAt'],
    identifiers: articleId ? { articleId } : {},
    helpers: ['adPreviewLink'],
    order: { createdAt: 'desc' },
  });
  return resp.result;
};

export const getLinkedinAd = async (LinkedinAdId: string | number): Promise<LinkedinAd> => {
  const resp = await getRecord({
    model: 'LinkedinCampaign',
    id: LinkedinAdId,
    attributes: [
      'adShareUrl',
      'adTitle',
      'adText',
      'adType',
      'facetTargets',
      'id',
      'status',
      'startAt',
      'totalBudget',
      'name',
    ],
    helpers: ['imgixUrl'],
    relations: { LinkedinAdAccount: { attributes: ['name', 'id'] } },
  });
  return resp.result;
};

export async function autoCompleteTargetFacet(values) {
  const resp = await request('get', api_react_v1_autocomplete_linkedin_entities_path(values));
  return resp.entities;
}

export async function linkedinCreate(articleId, values) {
  const resp = await request(
    'post',
    api_react_v1_distribution_linkedin_create_path(articleId),
    values,
    true
  );
  return resp;
}

export async function linkedinAdUpdate(articleId, linkedinAdId, values) {
  const resp = await request(
    'put',
    api_react_v1_distribution_linkedin_update_path(articleId, linkedinAdId),
    values,
    true
  );
  return resp;
}

export async function linkedinActivateCampaign(articleId, campaignId) {
  const resp = await request(
    'post',
    api_react_v1_distribution_linkedin_activate_campaign_path(articleId, campaignId)
  );
  return resp;
}
