import { Formik } from 'formik';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { IDENTITY_KEY } from '..';
import { autocompleteAdministratorLists } from '../../../../utils/backend-api/administrator-lists';
import { createIdentityAdministratorList } from '../../../../utils/backend-api/identity-administrator-lists';
import { AutocompleteField } from '../../../FormFields';

const AddAdministratorListForm = ({ identity }) => {
  const queryClient = useQueryClient();

  const handleSubmit = async (values, actions) => {
    const result = await createIdentityAdministratorList({
      administratorListIds: [values.administratorListId.value],
      identityIds: [identity.id],
    });
    if (result) {
      queryClient.invalidateQueries([IDENTITY_KEY, identity.id]);
      toast.success('Added to Admin List');
      actions.resetForm();
    } else {
      toast.error('Failed to add to Admin List');
    }
  };

  return (
    <Formik onSubmit={handleSubmit} initialValues={{}}>
      {(formik) => (
        <Form noValidate onSubmit={formik.handleSubmit}>
          <AutocompleteField
            placeholder="Select a list to add"
            name="administratorListId"
            isCreatable={false}
            fetchMethod={(value) =>
              autocompleteAdministratorLists({
                fragment: value,
              })
            }
            valueIsInteger
          />
          <Button type="submit" disabled={!formik.values.administratorListId}>
            Add Administrator List
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default AddAdministratorListForm;
