import { useQuery } from 'react-query';

import { Author, getAuthors } from '../utils/backend-api/authors';

export const AUTHORS_KEY = 'authors';

export default function useAuthors() {
  const {
    data: authors,
    isLoading: isLoadingAuthors,
    isFetching: isFetchingAuthors,
  } = useQuery<Author[]>(AUTHORS_KEY, async () => await getAuthors());

  return { authors, isLoadingAuthors, isFetchingAuthors };
}
