import { useQuery } from 'react-query';

import { PushNotification, getPushNotification } from '../utils/backend-api/push-notifications';

export const PUSH_NOTIFICATION_KEY = 'pushNotification';

export default function usePushNotification(id?: number | string) {
  const { data: pushNotification, isLoading: isLoadingPushNotification } =
    useQuery<PushNotification>(
      [PUSH_NOTIFICATION_KEY, id],
      async () => await getPushNotification(id),
      {
        // only call this if a pushb notification id is passed
        enabled: !!id,
      }
    );

  return { pushNotification, isLoadingPushNotification };
}
