import toast from 'react-hot-toast';
import { useQuery } from 'react-query';

import { Identity, getIdentity } from '../utils/backend-api/identities';

export const IDENTITY_KEY = 'identity';

export default function useIdentity(identityId: number) {
  const {
    data: identity,
    isLoading: isLoadingIdentity,
    isError,
  } = useQuery<Identity>([IDENTITY_KEY, identityId], () => getIdentity(identityId), {
    onError: () => {
      toast.error('Fetching identity has failed');
    },
  });

  return { identity, isLoadingIdentity, isError };
}
