import { api_react_v1_administrators_path } from '../../routes';
import request from './request';
import { getRecords } from './universal';

export interface Author {
  id: string;
  name: string;
  email: string;
  userId: number;
}

export interface AuthorResponse {
  success: boolean;
  message: string;
  administrator: Author;
}

export const getAuthors = async (): Promise<Author[]> => {
  const resp = await getRecords({
    model: 'User',
    attributes: ['id', 'name', 'email'],
    relations: {
      administrator: { attributes: ['isActive', 'id'] },
    },
    relationIdentifiers: { administrator: { isActive: true } },
    order: { name: 'asc' },
  });
  return resp.result;
};

export const createAuthor = async ({ name, email }): Promise<AuthorResponse> => {
  const resp = await request('post', api_react_v1_administrators_path(), {
    administrator: {
      name: name,
      email: email,
    },
  });

  return resp;
};
