import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Outlet, useSearchParams } from 'react-router-dom';

import {
  AdministratorListFilterType,
  FilterContextProvider,
} from '../../../contexts/FilterContext';
import { DEFAULT_PAGINATION_LIMIT, DEFAULT_QUERY_TYPE } from '../../../hooks/useIdentities';
import { CrmIdentityFilter } from '../../../utils/backend-api/identities';
import CrmSideNav from '../SideNav';

const CrmLayout = () => {
  const [searchParams] = useSearchParams();
  const administratorListId = searchParams.get('administrator_list');
  const [administratorListFilter, setAdministratorListFilter] =
    useState<AdministratorListFilterType>(null);
  const [filters, setFilters] = useState<CrmIdentityFilter[]>([]);
  const [metadata, setMetadata] = useState({ subscriptionStatuses: [] });
  const [limit, setLimit] = useState(DEFAULT_PAGINATION_LIMIT);
  const [aiQueryActive, setAiQueryActive] = useState(false);
  const [crmScope, setCrmScope] = useState('administrator');
  const [queryEnabled, setQueryEnabled] = useState<boolean>(!!administratorListId);
  const [queryType, setQueryType] = useState<string>(DEFAULT_QUERY_TYPE);

  return (
    <FilterContextProvider
      filters={filters}
      setFilters={setFilters}
      metadata={metadata}
      setMetadata={setMetadata}
      limit={limit}
      setLimit={setLimit}
      aiQueryActive={aiQueryActive}
      setAiQueryActive={setAiQueryActive}
      administratorListFilter={administratorListFilter}
      setAdministratorListFilter={setAdministratorListFilter}
      crmScope={crmScope}
      setCrmScope={setCrmScope}
      queryEnabled={queryEnabled}
      setQueryEnabled={setQueryEnabled}
      queryType={queryType}
      setQueryType={setQueryType}
    >
      <div className="CrmLayout container-fluid">
        <Row>
          <Col md={3} lg={2}>
            <CrmSideNav />
          </Col>
          <Col md={9} lg={10} className="main-content">
            <Outlet />
          </Col>
        </Row>
      </div>
    </FilterContextProvider>
  );
};

export default CrmLayout;
