import clsx from 'clsx';
import { getIn, useField, useFormikContext } from 'formik';
import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import './DropdownField.scss';

/**
 * @param {{name, label, options, ...}} props
 * options => [{label: "label 1", value: 1}, {label: "label 2", value: 2}, ...]
 * where label is displayed, and value is an optional value attached for the item.
 *
 * for making group labels:
 * options => [
 *  {
 *    label: "Group A Label", <= this is optional, no group label if left as ""
 *    options: [{
 *      label: "Group A label 1",
 *      value: "Group A value 1",
 *    }]
 *  },
 *  ... Group B
 * ]
 */

// checks if value and label are empty and if so returns true
const isDefaultValue = (valueObj) => {
  return !valueObj?.value && !valueObj?.label;
};

const DropdownField = (props) => {
  const { append, label, name, onChange, className = 'form-group' } = props;
  const [field, meta, helpers] = useField(name);
  const { errors } = useFormikContext();
  const apiError = getIn(errors, name);
  const SelectComponent = props.onCreateOption ? CreatableSelect : Select;
  const formatGroupLabel = (data) =>
    data.label && (
      <div>
        <span>{data.label}</span>
        <hr></hr>
      </div>
    );

  const handleChange = (value) => {
    helpers.setValue(
      value || {
        label: '',
        value: '',
      }
    );
    if (onChange) onChange(value);
  };

  return (
    <div className={clsx('DropdownField', className, { 'with-append-label': append })}>
      {label && (
        <label className="input-label" htmlFor={name}>
          {label}
        </label>
      )}
      <SelectComponent
        {...field}
        // display null for value if value is empty
        value={isDefaultValue(field.value) ? null : field.value}
        id={name}
        inputId={name}
        className={meta.touched && meta.error ? ' is-invalid' : ''}
        classNamePrefix="react-select"
        formatGroupLabel={formatGroupLabel}
        {...props}
        onChange={handleChange}
        onBlur={helpers.setTouched}
      />
      {append && (
        <label className="input-label mx-2" htmlFor={name}>
          {append}
        </label>
      )}
      {meta.touched && (meta.error?.value || apiError) && (
        <div className="invalid-feedback">{meta.error?.value || apiError}</div>
      )}
    </div>
  );
};

export default DropdownField;
